import { ValueOf } from '@approvalmax/types';
import { z, ZodObject } from 'zod';

import { fieldNames } from './BackupEmailUpdatePopup.constants';
import { messages } from './BackupEmailUpdatePopup.messages';
import { Stages } from './BackupEmailUpdatePopup.types';

export const schema: Record<ValueOf<Stages>, ZodObject<any>> = {
    [Stages.emailEnter]: z.object({
        [fieldNames.email]: z.string().trim().min(1, messages.errorEmailEmpty).email(messages.errorEmailInvalid),
        [fieldNames.verificationCode]: z.any(),
    }),
    [Stages.emailVerify]: z.object({
        [fieldNames.email]: z.string().trim().min(1, messages.errorEmailEmpty).email(messages.errorEmailInvalid),
        [fieldNames.verificationCode]: z.string().min(3, messages.errorEmailVerificationCodeRequired),
        [fieldNames.stateId]: z.string().nullable(),
    }),
};
