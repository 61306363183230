import { Route as RouteType } from 'modules/page';
import { FC, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { PageErrorBoundary, RouterPageWrapper } from 'shared/components';

export const PageRoute: FC<RouteType> = (props) => {
    const { path, exact = true, strict = false, component: Component } = props;

    return (
        <Route
            path={path}
            exact={exact}
            strict={strict}
            key={Array.isArray(path) ? path[0] : path}
            render={() => (
                <RouterPageWrapper>
                    <PageErrorBoundary>
                        <Suspense>
                            <Component />
                        </Suspense>
                    </PageErrorBoundary>
                </RouterPageWrapper>
            )}
        />
    );
};

PageRoute.displayName = 'PageRoute';
