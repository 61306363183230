import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { GenerateTwoFaBackupCodes } from './useGenerateTwoFaBackupCodes.types';

export const useGenerateTwoFaBackupCodes = (mutationOptions?: RequestMutationOptions<GenerateTwoFaBackupCodes>) => {
    return useMutate(authApiPaths.generateTwoFaBackupCodes, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
