import { LazyGetDataParams, QueryOptions } from '@approvalmax/data';
import { useLazyGetLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';

export const useLazyGetAttachment = (queryOptions?: QueryOptions<Blob>) =>
    useLazyGetLegacy<LazyGetDataParams, Blob>(requestsApiPaths.getAttachment, {
        method: 'get',
        apiVersion: 'v1',
        responseType: 'blob',
        queryOptions,
    });
