import { messages } from './PasswordValidation.messages';
import { PasswordCriteria } from './PasswordValidation.types';

export const passwordCriteria: PasswordCriteria[] = [
    { test: (value) => value.length >= 8, message: messages.leastCharacters },
    { test: (value) => /\d/.test(value), message: messages.containsNumber },
    { test: (value) => /[a-z]/.test(value) && /[A-Z]/.test(value), message: messages.containsUpperLowerCase },
    {
        test: (value) => /[[\]{}()<>\\/+*^\-_=!?.,:;|$€£¥¢#@&%"']+/.test(value),
        message: messages.containsSpecialCharacter,
    },
];
