import { Data } from './HelpStripe.types';

export const data: Data = {
    'workflow-xero-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders',
                text: 'How can I restrict the Requesters of Purchase Orders?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
            {
                href: 'https://www.youtube.com/watch?v=xiLfB1Zamns',
                text: 'How to restrict PO requesters',
            },
            {
                href: 'https://www.youtube.com/watch?v=ICsbl--G-U0',
                text: 'How to raise a PO',
            },
        ],
    },
    'workflow-qbooks-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-26-1-2021',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-29-1-2021',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-are-classes-and-locations-used-for',
                text: 'What are Classes and Locations used for?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-qbooks-vendor': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-26-1-2021',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-29-1-2021',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-qbooks-expense': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-26-1-2021',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-29-1-2021',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-are-classes-and-locations-used-for',
                text: 'What are Classes and Locations used for?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-quickbooks-online-expenses',
                text: 'How can I restrict the requesters of QuickBooks Online Expenses',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-make-expense-fields-mandatory',
                text: 'How can I make Expense fields mandatory',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-qbooks-journal-entry': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-26-1-2021',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-29-1-2021',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-are-classes-and-locations-used-for',
                text: 'What are Classes and Locations used for?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-invoice': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-quote': {
        links: [
            {
                href: ' https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-creditnotes-payable': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-creditnotes-receivable': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-contact': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-amaxpay-xero-batch-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-bill-batch-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-xero-batch-payments',
                text: 'How can I restrict the requesters of Xero Batch Payments?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-airwallex-xero-batch-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-qbooks-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-26-1-2021',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-29-1-2021',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders-3-2-2021',
                text: 'How can I restrict the Requesters of Purchase Orders?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-are-classes-and-locations-used-for',
                text: 'What are Classes and Locations used for?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
            {
                href: 'https://www.youtube.com/watch?v=xiLfB1Zamns',
                text: 'How to restrict PO requesters',
            },
            {
                href: 'https://www.youtube.com/watch?v=ICsbl--G-U0',
                text: 'How to raise a PO',
            },
        ],
    },
    'workflow-netsuite-vendor-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-bills',
                text: 'How can I restrict the Requesters of Bills?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite',
                text: 'How can I copy workflow settings to another Organisation?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-in-netsuite',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-netsuite',
                text: 'What is a Default Approver?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-netsuite-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders-netsuite',
                text: 'How can I restrict the Requesters of Purchase Orders?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite',
                text: 'How can I copy workflow settings to another Organisation?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-in-netsuite',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-netsuite',
                text: 'What is a Default Approver?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-netsuite-expense-report': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite',
                text: 'How can I copy workflow settings to another Organisation?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-netsuite',
                text: 'What is a Default Approver?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-in-netsuite',
                text: 'Parallel and sequential approvals',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-netsuite-sales-order': {
        links: [
            {
                text: 'How can I copy workflow settings to another Organisation?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite',
            },
            {
                text: 'What is a Default Approver? ',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-netsuite',
            },
            {
                text: 'Parallel and sequential approvals',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-in-netsuite',
            },
        ],
        videos: [
            {
                text: 'How to set up an approval workflow',
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
            },
            {
                text: 'How to set up an Approval Matrix',
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
            },
        ],
    },
    'workflow-netsuite-vendor-return-authorization': {
        links: [
            {
                text: 'Is it possible to unreject a Vendor Return Authorization?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/is-it-possible-to-unreject-a-vendor-return-authorization-netsuite',
            },
            {
                text: 'How to pull Vendor Return Authorizations from NetSuite',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-pull-vendor-return-authorization-from-netsuite',
            },
            {
                text: 'How does the approval of NetSuite Vendor Return Authorizations work?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-does-the-approval-of-netsuite-vendor-return-authorization-work',
            },
            {
                text: 'How can I stop pulling Vendor Return Authorization requests from NetSuite?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-stop-pulling-vendor-return-authorization-requests-from-netsuite',
            },
            {
                text: 'Why are Vendor Return Authorization requests not getting pulled from NetSuite to ApprovalMax?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/why-are-vendor-return-authorization-requests-not-getting-pulled-from-netsuite-to-approvalmax',
            },
        ],
        videos: [
            {
                text: 'How to set up an approval workflow',
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
            },
            {
                text: 'How to set up an Approval Matrix',
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
            },
        ],
    },
    'workflow-netsuite-bill-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite',
                text: 'How can I copy workflow settings to another Organisation?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-in-netsuite',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-netsuite',
                text: 'What is a Default Approver?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-dear-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-dear',
                text: 'How can I copy workflow settings to another Organisation?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-in-dear',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-dear',
                text: 'What is a Default Approver?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'workflow-xero-manual-journal': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/parallel-and-sequential-approvals',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/what-is-a-default-approver',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How can I copy workflow settings to another Organisation?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-xero-manual-journal',
                text: 'How can I restrict the Requesters of Manual Journals?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-make-xero-manual-journal-fields-mandatory',
                text: 'How can I make Manual Journal fields mandatory?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-po-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-create-suppliers-directly-in-approvalmax',
                text: 'How can I enable the creation of Suppliers in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders',
                text: 'How can I restrict the Requesters of Purchase Orders?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'approvalmatrix-review-and-coding-xero-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-bill-review-and-coding-feature-works',
                text: 'How does Bill review / editing work?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'reviewermatrix-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-review-step',
                text: 'What is the Review step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-setup-a-review-step',
                text: 'How to setup the Review step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-the-reviewer-can-do',
                text: 'What can a Reviewer do?',
            },
        ],
    },
    'approvalmatrix-xero-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-bill-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-create-suppliers-directly-in-approvalmax',
                text: 'How can I enable the creation of Suppliers in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-xero-bills',
                text: 'How can I restrict the Requesters of Bills?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'approvalmatrix-xero-invoice': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-creditnotes-receivable': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-creditnotes-payable': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-amaxpay-xero-batch-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How alternative rules in the approval matrix work',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-quote': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How alternative rules in the approval matrix work',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-bill-batch-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-airwallex-xero-batch-payment': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-manual-journal': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/setting-up-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/kb/articles/how-alternative-rules-in-the-approval-matrix-work',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-xero-manual-journal-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-xero-manual-journal',
                text: 'How can I restrict the Requesters of Manual Journals?',
            },
        ],
    },
    'approvalmatrix-amaxpay-xero-batch-payment-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-batch-payments',
                text: 'How can I restrict the Requesters of Batch Payments?',
            },
        ],
    },
    'approvalmatrix-xero-bill-batch-payment-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-xero-batch-payments',
                text: 'How can I restrict the Requesters of Xero Batch Payments?',
            },
        ],
    },
    'approvalmatrix-airwallex-xero-batch-payment-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-airwallex-batch-payment',
                text: 'How can I restrict the Requesters of Airwallex Batch Payment?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-enable-beneficiary-creation-in-approvalmax',
                text: 'How can I enable beneficiary creation in ApprovalMax?',
            },
        ],
    },
    'approvalmatrix-xero-contact': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'approvalmatrix-qbooks-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-26-1-2021',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-qbooks-po-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-enable-the-creation-of-vendors-in-approvalmax',
                text: 'How can I enable the creation of Vendors in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders-3-2-2021',
                text: 'How can I restrict the Requesters of Purchase Orders?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'approvalmatrix-qbooks-vendor': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-26-1-2021',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-qbooks-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-26-1-2021',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-qbooks-bill-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-enable-the-creation-of-vendors-in-approvalmax',
                text: 'How can I enable the creation of Vendors in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-quickbooks-online-bills',
                text: 'How can I restrict the Requesters of Bills?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'approvalmatrix-qbooks-expense': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix',
                text: 'How to set up approval matrix',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-26-1-2021',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-qbooks-expense-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-enable-the-creation-of-vendors-in-approvalmax',
                text: 'How can I enable the creation of Vendors in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-expenses',
                text: 'How can I restrict the Requesters of Expenses?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'approvalmatrix-qbooks-journal-entry': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix',
                text: 'How can I set up an approval matrix?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-26-1-2021',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-qbooks-journal-entry-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-quickbooks-online-journal-entries',
                text: 'How can I restrict the Requesters of QuickBooks Online Journal Entries?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another? (QuickBooks Online)',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-make-journal-entry-fields-mandatory',
                text: 'How can I make QuickBooks Online Journal Entry fields mandatory?',
            },
        ],
    },
    'approvalmatrix-netsuite-vendor-bill': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix-29-3-2022',
                text: 'How can I set up an approval matrix?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-1-4-2022',
                text: 'How alternative rules in the approval matrix work?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-netsuite-vendor-bill-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
                text: 'How can I copy rules from one user to another?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-bills',
                text: 'How can I restrict the Requesters of Bills?',
            },
        ],
    },
    'approvalmatrix-netsuite-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix-29-3-2022',
                text: 'How can I set up an approval matrix?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-1-4-2022',
                text: 'How alternative rules in the approval matrix work?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-netsuite-po-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
                text: 'How can I copy rules from one user to another?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders-netsuite',
                text: 'How can I restrict the Requesters of Purchase Orders?',
            },
        ],
    },
    'approvalmatrix-netsuite-expense-report-requester': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix-29-3-2022',
                text: 'How can I set up an approval matrix?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-1-4-2022',
                text: 'How alternative rules in the approval matrix work',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-netsuite-sales-order': {
        links: [
            {
                text: 'How can I set up an approval matrix?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix-29-3-2022',
            },
            {
                text: 'How alternative rules in the approval matrix work',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-1-4-2022',
            },
            {
                text: 'How can I copy rules from one user to another?',
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
            },
        ],
        videos: [
            {
                text: 'How to set up an Approval Matrix',
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
            },
        ],
    },
    'approvalmatrix-dear-po': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-set-up-an-approval-matrix-dear',
                text: 'How can I set up an approval matrix?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-dear',
                text: 'How alternative rules in the approval matrix work?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-dear',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'users-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-add-users-in-approvalmax',
                text: 'How can I add users in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-delete-offboard-a-user-from-approvalmax-quickbooks-online',
                text: 'How to delete (offboard) a user in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-assign-a-new-role-to-a-user-21-1-2021',
                text: 'How can I assign a new role to a user?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-administrators-manage-delegates-21-1-2021',
                text: 'How can Administrators manage Substitutes?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/roles-in-approvalmax-21-1-2021',
                text: 'Roles in ApprovalMax',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=AIuWp93v3Z4',
                text: 'How to add and invite a user',
            },
        ],
    },
    'users-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-add-users-in-approvalmax',
                text: 'How can I add users in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-delete-a-user-from-approvalmax-14-6-2021',
                text: 'How to delete (offboard) a user in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-assign-a-new-role-to-a-user',
                text: 'How can I assign a new role to a user?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-administrators-manage-delegates',
                text: 'How can Administrators manage Substitutes?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/roles-in-approvalmax',
                text: 'Roles in ApprovalMax',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/user-permissions-for-contact-details-section',
                text: 'Extra permissions in ApprovalMax',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=AIuWp93v3Z4',
                text: 'How to add and invite a user',
            },
        ],
    },
    'users-netsuite': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-add-users-in-approvalmax-27-3-2022',
                text: 'How can I add users in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-delete-offboard-a-user-in-approvalmax-netsuite',
                text: 'How to delete (offboard) a user in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-assign-a-new-role-to-a-user-27-3-2022',
                text: 'How can I assign a new role to a user?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-administrators-manage-delegates-netsuite',
                text: 'How can Administrators manage Substitutes?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/roles-in-approvalmax-netsuite',
                text: 'Roles in ApprovalMax',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=AIuWp93v3Z4',
                text: 'How to add and invite a user',
            },
        ],
    },
    'users-dear': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-add-users-in-approvalmax-dear',
                text: 'How can I add users in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-delete-offboard-a-user-in-approvalmax-netsuite',
                text: 'How to delete (offboard) a user in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-assign-a-new-role-to-a-user-dear',
                text: 'How can I assign a new role to a user?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-administrators-manage-delegates-dear',
                text: 'How can Administrators manage Substitutes?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/roles-in-approvalmax-dear',
                text: 'Roles in ApprovalMax',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=AIuWp93v3Z4',
                text: 'How to add and invite a user',
            },
        ],
    },
    'users-none': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-add-users-in-approvalmax-standalone',
                text: 'How can I add users in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-delete-offboard-a-user-in-approvalmax-standalone',
                text: 'How to delete (offboard) a user in ApprovalMax?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-assign-a-new-role-to-a-user-stand-alone',
                text: 'How can I assign a new role to a user?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-administrators-manage-delegates-22-11-2021',
                text: 'How can Administrators manage Substitutes?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/roles-in-approvalmax-standalone',
                text: 'Roles in ApprovalMax',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=AIuWp93v3Z4',
                text: 'How to add and invite a user',
            },
        ],
    },
    'workflow-standalone': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/parallel-and-sequential-approvals-stand-alone',
                text: 'Parallel and sequential approvals',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-default-approver-stand-alone',
                text: 'What is default approver',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-standalone',
                text: 'How can I copy workflow settings to another Organisation?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=EVee7XQ1_hE',
                text: 'How to set up an approval workflow',
            },
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'approvalmatrix-standalone': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-alternative-rules-in-the-approval-matrix-work-stand-alone',
                text: 'How to add an alternative rule',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-create-new-fields-in-request-forms',
                text: 'How to create new fields in a request form',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-stand-alone',
                text: 'How can I copy rules from one user to another?',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=KSi_nhmf7vU&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=3',
                text: 'How to set up an Approval Matrix',
            },
        ],
    },
    'reports-standart-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/where-to-find-reporting-function',
                text: 'How report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-xero',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'reports-standart-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/where-can-i-find-the-reporting-function#This_is_how_your_personal_QuickBooks_Online_report_can_be_set_up',
                text: 'How report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-quickbooks-online',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'reports-standart-netsuite': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/where-can-i-find-the-reporting-function-netsuite',
                text: 'How report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-netsuite',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'reports-standart-dear': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/where-can-i-find-the-reporting-function-dear',
                text: 'How report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-dear',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'reports-standart-none': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/where-i-can-find-a-reporting-function-stand-alone',
                text: 'How report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-stand-alone',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'reports-line-item-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/line-item-reports-xero#This_is_how_you_set_up_your_own_Xero_line_item_report',
                text: 'How line item report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-xero',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'reports-line-item-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/line-item-reports-quickbooks-online#This_is_how_you_set_up_your_own_line_item_report_in_QuickBooks_Online',
                text: 'How line item report can be set up',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/report-scheduler-quickbooks-online',
                text: 'Report scheduler',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=wgzEWHb1gCQ&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=6',
                text: 'How to set up reports in ApprovalMax',
            },
        ],
    },
    'auto-approvalmatrix-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-xero',
                text: 'What is the auto-approval step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-auto-approval-step-xero',
                text: 'How to set up an auto-approval step',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=y9w6cPb5uRc&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=16',
                text: 'How to configure Auto-approvals?',
            },
        ],
    },
    'auto-approvalmatrix-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-quickbooks-online',
                text: 'What is the auto-approval step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-auto-approval-step-quickbooks-online',
                text: 'How to set up an auto-approval step',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=y9w6cPb5uRc&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=16',
                text: 'How to configure Auto-approvals?',
            },
        ],
    },
    'auto-approvalmatrix-none': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step',
                text: 'What is the auto-approval step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-an-auto-approval-step',
                text: 'How to set up an auto-approval step',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=y9w6cPb5uRc&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=16',
                text: 'How to configure Auto-approvals?',
            },
        ],
    },
    'auto-approvalmatrix-netsuite': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-netsuite',
                text: 'What is the auto-approval step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-an-auto-approval-step-netsuite',
                text: 'How to set up an auto-approval step',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=y9w6cPb5uRc&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=16',
                text: 'How to configure Auto-approvals?',
            },
        ],
    },
    'auto-approvalmatrix-dear': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-an-auto-approval-step-dear',
                text: 'What is the auto-approval step?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-an-auto-approval-step-dear',
                text: 'How to set up an auto-approval step',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=y9w6cPb5uRc&list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy&index=16',
                text: 'How to configure Auto-approvals?',
            },
        ],
    },
    'copy-workflow-popup-single-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations',
                text: 'How to copy workflow settings to another organisation?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-single-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation',
                text: 'How to copy workflow settings to another organisation?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-single-netsuite': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite',
                text: 'How to copy workflow settings to another organisation?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-single-standalone': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-standalone',
                text: 'How to copy workflow settings to another organisation?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-multiple-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-workflow-settings-to-other-organisations#How_to_copy_a_workflow_to_multiple_other_workflows',
                text: 'How to copy workflow settings to multiple other organisations?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-multiple-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation#How_to_copy_a_workflow_to_multiple_other_workflows',
                text: 'How to copy workflow settings to multiple other organisations?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-multiple-netsuite': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-netsuite#How_to_copy_a_workflow_to_multiple_other_workflows',
                text: 'How to copy workflow settings to multiple other organisations?',
            },
        ],
        videos: [],
    },
    'copy-workflow-popup-multiple-standalone': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-workflow-settings-to-another-organisation-standalone#How_to_copy_a_workflow_to_multiple_other_workflows',
                text: 'How to copy workflow settings to multiple other organisations?',
            },
        ],
        videos: [],
    },
    'budget-checking-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-budget-feature-is-for',
                text: 'What is the Budget checking feature?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/who-can-upload-sync-budgets-from-xero',
                text: 'Who can upload/sync budgets to/from Xero?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-upload-any-budget-file-to-approvalmax',
                text: 'How to upload a budget file to ApprovalMax',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-upload-xero-budget-in-approvalmax',
                text: 'How to upload Xero Budgets into ApprovalMax',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-sync-budget-with-xero',
                text: 'How to sync a Budget with Xero',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-force-budget-sync-with-xero',
                text: 'How to force a Budget sync with Xero',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-stop-syncing-budgets',
                text: 'How can I stop syncing budgets? ',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/budget-checking-filters',
                text: 'Budget Checking filters',
            },
        ],
        videos: [
            {
                href: 'https://www.youtube.com/watch?v=XPXwcO1d6E4',
                text: 'How to configure and perform budget checking',
            },
        ],
    },
    'budget-checking-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-budget-checking-feature-quickbooks-online',
                text: 'What is the Budget Checking feature?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/what-is-possible-with-budget-checking-quickbooks-online',
                text: 'What is possible with Budget Checking?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/who-can-sync-budgets-from-quickbooks-online',
                text: 'Who is allowed to sync budgets from QuickBooks Online?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-sync-a-budget-with-quickbooks-online',
                text: 'How does syncing a budget with QuickBooks Online work?',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-stop-syncing-budgets-quickbooks-online',
                text: 'How can I stop syncing budgets?',
            },
        ],
    },
    'goods-received-notes-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-goods-received-note-for-xero-po',
                text: 'How it works',
            },
        ],
    },
    'goods-received-notes-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-goods-received-note-for-quickbooks-online-po',
                text: 'How it works',
            },
        ],
    },
    'copy-rule-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-xero',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'copy-rule-qbooks': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-quickbooks-online',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'copy-rule-netsuite': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-netsuite',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'copy-rule-dear': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-copy-rules-from-one-user-to-another-dear',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'copy-rule-standalone': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-copy-rules-from-one-user-to-another-stand-alone',
                text: 'How can I copy rules from one user to another?',
            },
        ],
    },
    'bank-accounts-xero': {
        links: [
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-setup-a-bank-account-connection-in-approvalmax',
                text: 'How to set up a bank account connection',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-change-a-name-of-a-bank-account',
                text: 'How to change a bank account name',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-change-matching-between-connected-account-and-xero-account',
                text: 'How to change matching to Xero account',
            },
            {
                href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-remove-a-connected-bank-account',
                text: 'How to remove a bank account connection',
            },
        ],
    },
    'bank-accounts-none': {
        links: [],
    },
};
