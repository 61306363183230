import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { SendTwoFaDisablingEmailCode } from './useSendTwoFaDisablingEmailCode.types';

export const useSendTwoFaDisablingEmailCode = (
    mutationOptions?: RequestMutationOptions<SendTwoFaDisablingEmailCode>
) => {
    return useMutate(authApiPaths.sendTwoFaDisablingEmailCode, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
