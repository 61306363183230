import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity', {
    titleAutomaticLogOut: 'Automatic log out',
    titleSecurity: 'Security',

    optionSession: 'Log me out after 15 minutes of inactivity',
    verifyDescription:
        'Backup options can be used during Two-factor authentication when you have no access to the Authenticator app',

    buttonSetup: 'Setup',
    buttonChange: 'Change',
});
