import { AmCaptureImage, AmPayLargeImage, CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import {
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
    TryPayOrCapturePopupOrigin,
} from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { messages } from './PayAndCapturePromotion.messages';
import { PayAndCapturePromotionProps } from './PayAndCapturePromotion.types';

export const PayAndCapturePromotion: FC<PayAndCapturePromotionProps> = memo((props) => {
    const { type } = props;

    const { closePromotion } = useAddonPromotions();
    const setTryPayOrCapturePopupOpen = useSetRecoilState(tryPayOrCapturePopupOpenState);
    const setTryPayOrCapturePopupInfo = useSetRecoilState(tryPayOrCapturePopupInfoState);

    const onClose = useCallback(() => {
        closePromotion(type);
    }, [closePromotion, type]);

    const onTry = useCallback(() => {
        setTryPayOrCapturePopupOpen(true);
        setTryPayOrCapturePopupInfo({ type, origin: TryPayOrCapturePopupOrigin.CreateNewPopup });
    }, [setTryPayOrCapturePopupInfo, setTryPayOrCapturePopupOpen, type]);

    return (
        <Box
            width={280}
            height='fit-content'
            spacing='12 20 20 20'
            color={type === AddonPromotionType.Pay ? 'midnight20' : 'mint50'}
            radius='xxsmall'
        >
            <Flex direction='column' spacing='8'>
                <Flex justifyContent='end' alignItems='center'>
                    <Button icon color='transparent' noPadding onClick={onClose}>
                        <CloseIcon size={20} />
                    </Button>
                </Flex>

                <Flex direction='column' alignItems='center'>
                    {type === AddonPromotionType.Pay ? <AmPayLargeImage /> : <AmCaptureImage />}

                    <Spacing height={4} />

                    <Text font='headline' fontSize='xxsmall' fontWeight='medium' textAlign='center'>
                        {type === AddonPromotionType.Pay ? messages.payHeader : messages.captureHeader}
                    </Text>

                    <Button color='mint80' onClick={onTry} block>
                        {type === AddonPromotionType.Pay
                            ? messages.tryNowPayButtonText
                            : messages.tryNowCaptureButtonText}
                    </Button>

                    {type === AddonPromotionType.Capture && (
                        <Text font='label' fontSize='small'>
                            {messages.captureDescription}
                        </Text>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
});

PayAndCapturePromotion.displayName = 'PayAndCapturePromotion';
