import { useCallback, useState } from 'react';
import {
    useDisableTwoFaWithBackupCode,
    useDisableTwoFaWithEmailCode,
    useDisableTwoFaWithOneTimePassword,
    useSendTwoFaDisablingEmailCode,
} from 'shared/data/v1';

import { WizardProps } from './Wizard.types';

export const useWizard = (props: WizardProps) => {
    const { onFinish: onSuccess } = props;

    const [stateId, setStateId] = useState('');

    const { mutate: requestEmailCode } = useSendTwoFaDisablingEmailCode({
        onSuccess: (data) => {
            setStateId(data.stateId);
        },
    });
    const { mutate: verifyEmailCode } = useDisableTwoFaWithEmailCode({ onSuccess });
    const { mutate: verifyAppCode } = useDisableTwoFaWithOneTimePassword({ onSuccess });
    const { mutate: verifyBackupCode } = useDisableTwoFaWithBackupCode({ onSuccess });

    const handleEnterAppCode = useCallback(
        (oneTimePassword: string) => {
            verifyAppCode({ body: { oneTimePassword } });
        },
        [verifyAppCode]
    );

    const handleEnterBackupCode = useCallback(
        (backupCode: string) => {
            verifyBackupCode({ body: { backupCode } });
        },
        [verifyBackupCode]
    );

    const handleRequestEmailCode = useCallback(() => {
        requestEmailCode({});
    }, [requestEmailCode]);

    const handleEnterEmailCode = useCallback(
        (code: string) => {
            verifyEmailCode({ body: { stateId, code } });
        },
        [stateId, verifyEmailCode]
    );

    return {
        handleEnterAppCode,
        handleEnterBackupCode,
        handleEnterEmailCode,
        handleRequestEmailCode,
    };
};
