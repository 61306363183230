import { ComponentProps } from 'react';
import { CloseButton as ToastifyCloseButton } from 'react-toastify';

import { CloseIcon } from '../../../../icons';
import { Button } from '../../../Button/Button';

export const CloseButton = (props: ComponentProps<typeof ToastifyCloseButton>) => {
    const { closeToast } = props;

    return (
        <Button onClick={closeToast} icon size='small' color='white100'>
            <CloseIcon size={20} />
        </Button>
    );
};

CloseButton.displayName = 'CloseButton';
