import { messages } from './Content.messages';

export enum FormFieldId {
    file = 'file',
}

export const accept = {
    'text/csv': ['.csv'],
};

export const maxFileSize = 10000000;

export const learnMoreLink =
    'https://support.approvalmax.com/portal/en/kb/articles/how-to-upload-manual-journal-to-approvalmax';
export const downloadTemplateLink =
    'https://workdrive.zohoexternal.com/external/8015f3815f68b753324a6bda1a704c15609904790dc32d00bbb3f7e2bedd48d3';

export const rules = [
    messages.rulesItemText1,
    messages.rulesItemText2,
    messages.rulesItemText3,
    messages.rulesItemText4,
];
