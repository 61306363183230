import { mathService, numberHelpers } from '@approvalmax/utils';

import { ValueOrNumber } from './amounts.types';

export const setCurrencyPrecision = <Value extends number | any>(
    value: Value,
    precision: number
): ValueOrNumber<Value> =>
    numberHelpers.isNumber(value)
        ? (mathService.round(value, precision) as ValueOrNumber<Value>)
        : (null as ValueOrNumber<Value>);
