import { errorHelpers } from '@approvalmax/utils';
import { useCallback, useState } from 'react';
import { notificationService } from 'services/notification';
import { useCreateFido2Credential, useLazyGetFido2CredentialOptions } from 'shared/data/v2';

import {
    createPublicKey,
    getAttestationResponseTransfer,
    mapErrorTextsToHumanReadable,
} from './PasskeysSettings.helpers';

export const useCreatePasskey = (options: { onSuccess: VoidFunction }) => {
    const { onSuccess } = options;

    const [isCreatingPasskey, setIsCreatingPasskey] = useState(false);

    const [triggerGetCredentialOptions] = useLazyGetFido2CredentialOptions();

    const { mutate: saveCredential } = useCreateFido2Credential({
        onSuccess,
    });

    const createPasskey = useCallback(
        async (label: string) => {
            setIsCreatingPasskey(true);

            try {
                const credentialOptions = await triggerGetCredentialOptions({});

                const credential = await createPublicKey(credentialOptions);

                saveCredential({
                    body: {
                        attestationResponse: getAttestationResponseTransfer(credential, credentialOptions),
                        label,
                    },
                });
            } catch (err) {
                if (!errorHelpers.isApiError(err)) {
                    console.error('[Credentials create error]', err);

                    const errorText = mapErrorTextsToHumanReadable(err.message);

                    notificationService.showErrorToast(errorText);
                }
            }

            setIsCreatingPasskey(false);
        },
        [triggerGetCredentialOptions, saveCredential]
    );

    return {
        isCreatingPasskey,
        createPasskey,
    };
};
