import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { DisableTwoFaWithBackupCode } from './useDisableTwoFaWithBackupCode.types';

export const useDisableTwoFaWithBackupCode = (mutationOptions?: RequestMutationOptions<DisableTwoFaWithBackupCode>) => {
    return useMutate(authApiPaths.disableTwoFaWithBackupCode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
