import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'components.ProfileEditPopup.SectionSecurity.BackupEmailUpdatePopup.StepEmailConfirm',
    {
        changeEmailButton: 'Change the address',
        description: 'Enter the verification code that was sent to your alternative email address.',
        codeFieldTitle: 'Verification code',
        wrongCode: 'Wrong code',
        subTitle: 'We emailed a verification code to the following address:',
        emailCodePlaceholder: 'Enter the verification code...',
        resendCodeButton: 'Resend the code',
        nextButton: 'Confirm the code',
        onSuccess: 'Alternative email was updated',
    }
);
