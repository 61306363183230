import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetTwoFaEnforcementInformation } from './useGetTwoFaEnforcementInformation.types';

export const useGetTwoFaEnforcementInformation = (
    params?: RequestParams<GetTwoFaEnforcementInformation>,
    queryOptions?: RequestQueryOptions<GetTwoFaEnforcementInformation>
) => {
    return useGet(authApiPaths.getTwoFaEnforcementInformation, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
