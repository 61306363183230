import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';

import { getActiveTemplate } from '../selectors/pageSelectors';

export const useIsGetGroupOfValuesAvailable = () => {
    const template = useSelector(getActiveTemplate);
    const company = useSelector((state) => selectors.company.findCompanyById(state, template?.companyId || ''));

    if (!template || !company) return false;

    const hasBetaFeature = company?.betaFeatures.includes(domain.CompanyBetaFeature.XeroGroupOfAccounts);
    const hasLicenseFeature = company?.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroGroupOfAccounts);
    const isXero = domain.getIntegrationTypeByCode(template.integrationCode) === domain.IntegrationType.Xero;

    return isXero && (hasBetaFeature || hasLicenseFeature);
};
