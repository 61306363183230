import { createContext, useContext } from 'react';

import { PopupContextValue } from './Popup.types';

export const PopupContext = createContext<PopupContextValue>({
    setToggle: () => undefined,
    root: null,
});

export const usePopupContext = () => {
    const context = useContext(PopupContext);

    if (!context) {
        throw new Error('Popup components must be wrapped in <Popup />');
    }

    return context;
};
