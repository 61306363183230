import { BackupCodes, Button, Checkbox, Flex, Form, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { fieldNames } from './BackupCodesAddPopup.constants';
import { useBackupCodesAddPopup } from './BackupCodesAddPopup.hooks';
import { messages } from './BackupCodesAddPopup.messages';
import { BackupCodesAddPopupProps } from './BackupCodesAddPopup.types';

export const BackupCodesAddPopupContent = memo<BackupCodesAddPopupProps>((props) => {
    const { form, formSubmit, progress, backupCodes, canSubmit } = useBackupCodesAddPopup(props);

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body>
                <Form form={form} onSubmit={formSubmit}>
                    <Flex direction='column' spacing='24'>
                        <Spacing height={16} />

                        <Flex direction='column' spacing='40'>
                            <BackupCodes isLoading={progress} backupCodes={backupCodes} />

                            <Checkbox disabled={progress} name={fieldNames.codesAreSaved}>
                                <Text font='label' fontSize='medium' fontWeight='regular'>
                                    {messages.checkboxText}
                                </Text>
                            </Checkbox>
                        </Flex>

                        <Flex direction='column' spacing='16'>
                            <Button type='submit' color='blue80' size='large' progress={progress} disabled={!canSubmit}>
                                {messages.buttonDone}
                            </Button>
                        </Flex>
                    </Flex>
                </Form>
            </Popup.Body>
        </>
    );
});

BackupCodesAddPopupContent.displayName = 'BackupCodesAddPopupContent';
