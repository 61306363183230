import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import Box from '../Box/Box';
import { Grid } from '../Grid/Grid';

export const Root = styled(Grid)`
    position: relative;
`;

export const Zone = styled(Box)<StyledMods<Mods<'active' | 'drag' | 'progress' | 'disabled' | 'rounded' | 'invalid'>>>`
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${theme.color.blue10};
    border-radius: 50%;

    ${mods.rounded.false`
        border-radius: ${theme.radius.small};
    `}

    img {
        min-width: 100%;
        min-height: 100%;
    }

    &:hover {
        ${mods.progress.false`
            cursor: pointer;

            &:before {
                ${mods.disabled.false`
                    ${mods.invalid.false`
                        border-color: ${theme.color.blue100};
                    `}
                `}
            }
        `}
    }

    ${mods.drag.true`
        background-color: ${theme.color.midnight20};
    `}

    ${mods.drag.false`
        ${mods.invalid.true`
            background-color: ${theme.color.red20};
        `}
    `}

    &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid ${theme.color.blue30};
        border-radius: 50%;
        transition:
            border-width ${theme.duration.fast}ms,
            border-color ${theme.duration.fast}ms;

        ${mods.rounded.false`
            border-radius: ${theme.radius.small};
        `}

        ${mods.drag.true`
            border: none;

            // powered by https://kovart.github.io/dashed-border-generator/
            // stroke of theme.color.blue100 is %232C4DF9
            ${mods.rounded.false`
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%232C4DF9' stroke-width='1.5' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
            `}

            ${mods.rounded.true`
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%232C4DF9' stroke-width='1.5' stroke-dasharray='6%2c 10' stroke-dashoffset='4' vector-effect='non-scaling-stroke' stroke-linecap='square'/%3e%3c/svg%3e");
            `}
        `};

        ${mods.drag.false`
            ${mods.invalid.true`
                border-color: ${theme.color.red100};
            `}
        `}

        ${mods.active.true`
            background-image: none;
        `};

        ${mods.disabled.true`
            border-color: ${theme.color.midnight50};
        `}
    }

    ${mods.active.true`
        background-color: ${theme.color.midnight30};

        &:before {
            border: 2px solid ${theme.color.blue100};
        }
    `};

    ${mods.disabled.true`
        &,
        &:hover {
            cursor: default;
            background-color: ${theme.color.midnight20};
            user-select: none;
        }
    `}
`;
