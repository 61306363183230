import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCallback, useEffect, useState } from 'react';
import { useGetMatchingInfoV2 } from 'shared/data/v1';

import { mapMatchingInfo } from './QBooksMatchingInfo.helpers';
import {
    MatchingInfoNormalizer,
    MatchingInfoNormalizerValue,
    QBooksMatchingInfoProps,
} from './QBooksMatchingInfo.types';

export const useQBooksMatchingInfoDropdown = (isOpenedDefault: boolean = false) => {
    const [isOpened, setIsOpened] = useState(isOpenedDefault);

    const onDropdownOpen = useCallback(() => setIsOpened(true), []);
    const onDropdownClose = useCallback(() => setIsOpened(false), []);

    return {
        isOpened,
        onDropdownOpen,
        onDropdownClose,
    };
};

export const useGetQBooksMatching = <IsNewLineItem extends boolean>({
    integrationCode,
    companyId,
    lineItemId,
    enabled,
    isNewLineItem,
    matchingInfoNormalizer,
}: {
    integrationCode: selectors.types.ExpandedRequest['integrationCode'];
    companyId: string;
    lineItemId: QBooksMatchingInfoProps['lineItemId'];
    enabled: boolean;
    /**
     * Form a new line item on an edit page we don't have a lineItemId yet, which means,
     * we can't do requests, because they required GUID id
     */
    isNewLineItem: IsNewLineItem;
    matchingInfoNormalizer: MatchingInfoNormalizer<IsNewLineItem>;
}) => {
    const [normalizedData, setNormalizedData] = useState<Array<ReturnType<typeof mapMatchingInfo>> | undefined>();
    const [normalizeLoading, setNormalizeLoading] = useState(false);

    const isBill = integrationCode === domain.IntegrationCode.QBooksBill;
    const isExpense = integrationCode === domain.IntegrationCode.QBooksExpense;

    let query;

    if (isBill) {
        query = {
            billInvoiceLineItemId: lineItemId,
            companyId,
        };
    } else if (isExpense) {
        query = {
            expenseInvoiceLineItemId: lineItemId,
            companyId,
        };
    } else {
        query = {
            purchaseOrderLineItemId: lineItemId,
            companyId,
        };
    }

    const { data: matchingInfoV2, isFetching: isFetchingGetMatchingInfoV2 } = useGetMatchingInfoV2(
        { query },
        { enabled: enabled && !isNewLineItem, staleTime: 5 * 1000 }
    );

    useEffect(() => {
        if (!enabled) {
            return;
        }

        if (isNewLineItem) {
            setNormalizeLoading(true);
            matchingInfoNormalizer(undefined as IsNewLineItem extends true ? undefined : never, lineItemId)
                .then((data) => {
                    setNormalizedData([data]);
                })
                .finally(() => {
                    setNormalizeLoading(false);
                });
        }
    }, [enabled, isNewLineItem, lineItemId, matchingInfoNormalizer]);

    useEffect(() => {
        if (!enabled || isNewLineItem) {
            return;
        }

        setNormalizeLoading(true);
        Promise.all(
            (matchingInfoV2?.data ?? [])?.map(
                async (purchaseOrder) =>
                    await matchingInfoNormalizer(
                        mapMatchingInfo(purchaseOrder) as IsNewLineItem extends true
                            ? never
                            : MatchingInfoNormalizerValue,
                        lineItemId
                    )
            )
        )
            .then((data) => {
                setNormalizedData(data);
            })
            .finally(() => {
                setNormalizeLoading(false);
            });
    }, [matchingInfoV2?.data, lineItemId, matchingInfoNormalizer, enabled, isNewLineItem]);

    return {
        data: normalizedData,
        isLoading: isFetchingGetMatchingInfoV2 || normalizeLoading,
    };
};
