import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

export const useNoCompanies = () => {
    const companies = useSelector(selectors.company.companiesSelector);
    const noCompanies = companies.length === 0;

    if (noCompanies) {
        routingService.push(getPath(Path.noCompanies));
    }

    return noCompanies;
};
