import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { FinishAddingTwoFaAlternativeEmail } from './useFinishAddingTwoFaAlternativeEmail.types';

export const useFinishAddingTwoFaAlternativeEmail = (
    mutationOptions?: RequestMutationOptions<FinishAddingTwoFaAlternativeEmail>
) => {
    return useMutate(authApiPaths.finishAddingTwoFaAlternativeEmail, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
