import './noFilterButton.scss';

import { Children, cloneElement, Component, createRef, ReactElement } from 'react';
import bemFactory from 'react-bem-factory';

import { InputDropdownIcon } from '../../../icons';
import { DropdownButtonElement, DropdownMode, NoFilterButtonProps } from './types';

const qa = bemFactory.qa('form-dropdown-editor-button');

class NoFilterButton extends Component<NoFilterButtonProps> implements DropdownButtonElement {
    rootRef = createRef<HTMLDivElement>();

    public select() {
        // noop, no real input
    }

    public focus() {
        this.rootRef.current?.focus();
    }

    public blur() {
        this.rootRef.current?.blur();
    }

    public render() {
        const { loading, children, theme, mode, disabled, displayTextSelector, placeholder, value, warning } =
            this.props;

        const active = mode === DropdownMode.Active;
        const bem = bemFactory.block('form-dropdown-editor-no-filter-button').themed(theme);

        let child: ReactElement<NoFilterButtonProps>;

        if (children && Children.count(children) > 0) {
            child = Children.only(children);

            if (child && typeof child.type !== 'string') {
                child = cloneElement<NoFilterButtonProps>(child, {
                    ...this.props,
                });
            }
        } else {
            // default button w/o filter
            const displayText = displayTextSelector(value);

            child = (
                <div className={bem('default-no-filter-content', { active, disabled, warning })}>
                    <div className={bem('text', { placeholder: !displayText })} title={displayText}>
                        {displayText || placeholder}
                    </div>

                    {!disabled && (
                        <InputDropdownIcon width={8} height={4} className={bem('dropdown-icon', { visible: active })} />
                    )}
                </div>
            );
        }

        return (
            <div className={bem(null, { loading })} ref={this.rootRef} data-qa={qa()} tabIndex={0}>
                {child}
            </div>
        );
    }
}

export default NoFilterButton;
