export const firstNameMinLength = 1;
export const firstNameMaxLength = 500;
export const lastNameMinLength = 1;
export const lastNameMaxLength = 500;

export const fieldNamesSectionGeneral = <const>{
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    email: 'email',
    timeZoneForEmail: 'timeZoneForEmail',
};
