import { Popup } from '@approvalmax/ui';
import ImageAttention from 'modules/components/ImageIcons/ImageAttention';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './TwoFaDisableSuccessPopup.messages';
import { ButtonFinish, Root, Title } from './TwoFaDisableSuccessPopup.styles';
import { TwoFaDisableSuccessPopupProps } from './TwoFaDisableSuccessPopup.types';

const qa = bemFactory.qa('pro-two-fa-disabling-success-popup');

/**
 * @deprecated Redesigned
 */
const TwoFaDisableSuccessPopup: FC<TwoFaDisableSuccessPopupProps> = memo((props) => {
    const { onClose, isOpen } = props;

    return (
        <Popup isOpen={isOpen} onRequestClose={onClose}>
            <Root qa={qa()}>
                <ImageAttention />

                <Title>{messages.title}</Title>

                <ButtonFinish execute={onClose}>{messages.button}</ButtonFinish>
            </Root>
        </Popup>
    );
});

export default TwoFaDisableSuccessPopup;
