import { GetGroupsOfXeroAccounts } from 'shared/data/v2';

import { ExactGroupValue } from './ExactAsyncCondition.types';

export const mapGroupOfXeroAccountsToExactGroupValue = (
    item: GetGroupsOfXeroAccounts['response']['items'][number]
): ExactGroupValue => {
    return {
        id: item.id,
        versionNumber: item.latestVersion?.versionNumber || 0,
        versionId: item.latestVersion?.id || '',
        name: item.name,
        text: item.name,
        group: true,
        groupValues: item.latestVersion?.values.map(({ fieldValueId, value }) => ({
            id: fieldValueId || '',
            text: value || '',
        })),
    };
};
