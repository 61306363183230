import { Popup } from '@approvalmax/ui/src/components';
import { useDispatch, useSelector } from 'modules/react-redux';
import { ActivePopup, closeActivePopup, getActivePopup } from 'modules/request';
import { memo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { PopupContent } from './components/index';
import { newRequestPopupSizeState } from './NewRequestPopup.states';

export const NewRequestPopup = memo(() => {
    const dispatch = useDispatch();
    const activePopup = useSelector(getActivePopup);

    const size = useRecoilValue(newRequestPopupSizeState);

    const isOpen = activePopup === ActivePopup.NewRequest;

    const handleCancelActivePopup = useCallback(
        (isOpen: boolean) => {
            if (!isOpen) {
                dispatch(closeActivePopup());
            }
        },
        [dispatch]
    );

    return (
        <Popup open={isOpen} onToggle={handleCancelActivePopup} size={size}>
            <PopupContent />
        </Popup>
    );
});

NewRequestPopup.displayName = 'NewRequestPopup';
