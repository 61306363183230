import range from 'lodash/range';
import moment from 'moment';
import { RefObject } from 'react';

import { minutesStep, partToDateMethod, timeBlockSize } from './DigitalClock.constants';
import type { Ampm } from './DigitalClock.types';

export const browserAmpm = Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hour12;

const getDigitsList = (count: number, start = 0) => range(start, count).map((hour) => hour.toString().padStart(2, '0'));

export const hours24Values = getDigitsList(24);

const hours12Dirty = getDigitsList(13, 1);

hours12Dirty.unshift(hours12Dirty.pop() || '');
export const hours12Values = [...hours12Dirty];

export const minutesValues = getDigitsList(60).filter((value) => !(+value % minutesStep));

export const getMinutesIndex = (value: string) => +value / minutesStep;

export const getHourCycle = (ampm: boolean | undefined) => (typeof ampm === 'boolean' ? ampm : Boolean(browserAmpm));

export const getAmOrPm = (hours: number) => (hours >= 12 ? 'PM' : 'AM');

export const getTimeBlockTop = (value: string | number) => {
    return +value * timeBlockSize;
};

export const convertToLabel = (value: number) => value.toString().padStart(2, '0');

/**
 * i.e.
 * '00' => 0
 * '15 PM' => 15
 * '12 AM' => 0
 */
export const convertHoursLabelToHours = (hours: string, hoursCycle: Ampm | undefined) => {
    const numericHours = +hours;

    if (!hoursCycle) {
        return numericHours;
    }

    if (hoursCycle === 'PM') {
        return numericHours < 12 ? numericHours + 12 : numericHours;
    } else {
        return numericHours === 12 ? 0 : numericHours;
    }
};

const applyMidDayMidNight = (hours: number) => (hours === 0 ? 12 : hours);

export const convertAmPmHoursToHoursLabel = (hours: number) =>
    convertToLabel(applyMidDayMidNight(hours - (getAmOrPm(hours) === 'PM' ? 12 : 0)));

export const extractHoursToValue = <D extends Date | undefined>(
    date: D,
    ampm: boolean
): D extends Date ? string : undefined => {
    if (!date) {
        // cast in any because TS has a bug atm
        return undefined as any;
    }

    const hours = date.getHours();

    return (ampm ? convertAmPmHoursToHoursLabel(hours) : convertToLabel(hours)) as any;
};

export const extractMinutesToValue = (date: Date | undefined) => date?.getMinutes().toString().padStart(2, '0');

export const scrollIntoView = (
    value: Parameters<typeof getTimeBlockTop>[0],
    listRef: RefObject<HTMLUListElement | null>
) => {
    listRef.current?.scrollTo({ behavior: 'smooth', top: getTimeBlockTop(value) });
};

/**
 * Set 0 to some of the date parts
 */
export const flushDate = (date: Date | undefined, parts: Array<'h' | 'm' | 's' | 'ms'> = ['m', 's', 'ms']) => {
    if (date) {
        parts.forEach((part) => {
            date[partToDateMethod[part]](0);
        });
    }

    return date;
};

export const checkReadyForAdjustment = (
    date: Date,
    compareDate: Date,
    partsToFlush?: Parameters<typeof flushDate>[1]
) => {
    const adjustedDate = moment(flushDate(new Date(date), partsToFlush));
    const adjustedCompareDate = moment(flushDate(new Date(compareDate), partsToFlush));

    return adjustedCompareDate.isSame(adjustedDate);
};

export const maybeAdjustMinutesToCompareDate = (date: Date, compareDate: Date | undefined, isMin = false) => {
    if (!compareDate) {
        return;
    }

    if (!checkReadyForAdjustment(date, compareDate)) {
        return;
    }

    const minutes = date.getMinutes();
    const compareDateMinutes = compareDate.getMinutes();

    if (isMin ? compareDateMinutes <= minutes : compareDateMinutes >= minutes) {
        return;
    }

    const approximateStep = compareDateMinutes / minutesStep;

    return (
        (Number.isInteger(approximateStep)
            ? approximateStep
            : isMin
              ? Math.ceil(approximateStep)
              : Math.floor(approximateStep)) * minutesStep
    );
};

export const maybeAdjustHoursToCompareDate = (date: Date, compareDate: Date | undefined, isMin = false) => {
    if (!compareDate) {
        return;
    }

    if (!checkReadyForAdjustment(date, compareDate, ['h', 'm', 's', 'ms'])) {
        return;
    }

    const hours = date.getHours();
    const compareDateHours = compareDate.getHours();

    if (isMin ? compareDateHours <= hours : compareDateHours >= hours) {
        return;
    }

    return compareDateHours;
};
