import { ExtractComponentProp, Reference } from '@approvalmax/types';
import { Flex, Select, Spacing } from '@approvalmax/ui/src/components';
import { HTMLTextFieldElement } from '@approvalmax/ui/src/components/TextField/TextField.types';
import { browserHelpers } from '@approvalmax/utils';
import { dataProviders, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { useDebounce, useMountedState } from 'react-use';
import { SelectUserMenuItem } from 'shared/components';
import { useGetGroupsOfXeroAccounts } from 'shared/data/v2';
import { useFieldData } from 'shared/hooks/useFieldData';

import { useIsGetGroupOfValuesAvailable } from '../../../../hooks/useIsGetGroupOfValuesAvailable';
import { mapExactValuesForRequesterSystemPurpose } from '../../../../utils/helpers';
import { ExactAsyncConditionSelectMenuItem } from '../ExactAsyncConditionSelectMenuItem/ExactAsyncConditionSelectMenuItem';
import { ExactAsyncConditionTag } from '../ExactAsyncConditionTag/ExactAsyncConditionTag';
import { mapGroupOfXeroAccountsToExactGroupValue } from './ExactAsyncCondition.helpers';
import { messages } from './ExactAsyncCondition.messages';
import { ChangeButton, Root } from './ExactAsyncCondition.styles';
import { ExactAsyncConditionProps, ExactGroupValue, ExactValuesConditionType } from './ExactAsyncCondition.types';

export const ExactAsyncCondition: FC<ExactAsyncConditionProps> = memo((props) => {
    const {
        lineId,
        rule,
        field,
        integrationCode,
        condition,
        readonly,
        templateSubmitters = [],
        requiredFieldIds = [],
        matrixType,
        onConditionTypeChange,
        conditionTypeItems,
        onConditionChange,
        conditionTypeValue,
        dataQa = '',
        noEmptyValue,
        conditionTypeAnyValue,
        isNegativeCondition,
    } = props;

    const qa = bemFactory.qa(dataQa);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const selectRef = useRef<HTMLTextFieldElement | null>(null);
    const [inEdit, setInEdit] = useState(false);
    const users = useSelector(selectors.user.getUsers);
    const fieldName = selectors.field.getFieldNameBySystemPurpose(field.systemPurpose, integrationCode, field.name);
    const [query, setQuery] = useState('');
    const [queryValue, setQueryValue] = useState<string>('');
    const isMounted = useMountedState();
    const isGetGroupOfValuesAvailable = useIsGetGroupOfValuesAvailable();

    useDebounce(() => setQuery(queryValue), 600, [queryValue]);

    const isExactValueCondition =
        condition.conditionType === domain.ConditionType.ExactValuesCondition ||
        condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;

    const submittersWithoutSelected = useMemo(() => {
        const exactValues = isExactValueCondition && condition.exactValues ? condition.exactValues : [];

        const data = templateSubmitters.map((submitter) => ({
            ...submitter,
            id: submitter.userEmail,
        }));

        return data.filter((s) => !exactValues.some((exactValue) => exactValue.id === s.databaseId));
    }, [condition, isExactValueCondition, templateSubmitters]);

    const invalid = !selectors.matrix.isValidCondition(condition);
    const exactValuesPlaceholder = messages.selectFieldName({
        fieldName,
    });

    const isOptionalField = !requiredFieldIds.includes(field.id);

    const staticValues: Reference[] = useMemo(
        () =>
            !query && selectors.field.allowsEmptyValue(field, matrixType, isOptionalField) && !noEmptyValue
                ? [dataProviders.FieldDataProvider.EmptyValue]
                : [],
        [field, isOptionalField, matrixType, noEmptyValue, query]
    );

    const exactValues = useMemo(() => {
        let result = isExactValueCondition ? condition.exactValues : [];

        if (condition.fieldSystemPurpose === domain.FieldSystemPurpose.Requester) {
            result = mapExactValuesForRequesterSystemPurpose(result, users);
        }

        return result;
    }, [condition, isExactValueCondition, users]);

    const { data: groupsOfXeroAccounts, isFetching: isFetchingGroupsOfXeroAccounts } = useGetGroupsOfXeroAccounts(
        { path: { companyId: field.companyId } },
        { enabled: isGetGroupOfValuesAvailable && field.systemPurpose === domain.FieldSystemPurpose.XeroAccount }
    );

    const groupItems = useMemo<ExactGroupValue[]>(() => {
        if (field.systemPurpose !== domain.FieldSystemPurpose.XeroAccount) return [];

        return (groupsOfXeroAccounts?.items || []).map(mapGroupOfXeroAccountsToExactGroupValue);
    }, [field.systemPurpose, groupsOfXeroAccounts?.items]);

    const exactGroupValues = useMemo<ExactGroupValue[]>(() => {
        const groupOfXeroAccounts = isExactValueCondition ? condition.groupOfXeroAccounts || [] : [];

        return groupItems.filter((groupItem) => groupOfXeroAccounts.some((a) => a.id === groupItem.id));
    }, [condition, groupItems, isExactValueCondition]);

    const onOpen = useCallback(
        (open: boolean) => {
            if (!open) {
                const delay = browserHelpers.isSafari() ? 150 : 1;

                setTimeout(() => {
                    if (!isMounted()) return;

                    if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                        setInEdit(isExactValueCondition);
                        setQueryValue('');
                        setQuery('');
                    }
                }, delay);
            }
        },
        [isExactValueCondition, isMounted]
    );

    const value = useMemo(
        () => [...exactGroupValues, ...exactValues].map(({ id }) => id),
        [exactValues, exactGroupValues]
    );

    const requesterValue = useMemo(() => {
        return templateSubmitters
            .filter(({ databaseId, id }) => (databaseId && value.includes(databaseId)) || value.includes(id))
            .map(({ id }) => id);
    }, [templateSubmitters, value]);

    const params = useMemo(
        () => ({
            field,
            templateSubmitters: submittersWithoutSelected,
            integrationCode,
            staticValues,
            query: query.trim(),
        }),
        [field, integrationCode, staticValues, submittersWithoutSelected, query]
    );

    const { trigger, items, isFetching } = useFieldData(params);

    const onChangeExactValues = useCallback(
        (
            exactValues: Reference[],
            exactGroupValues: ExactGroupValue[] | undefined,
            conditionType: ExactValuesConditionType
        ) => {
            const newCondition = {
                fieldId: condition.fieldId,
                fieldName: condition.fieldName,
                fieldSystemPurpose: condition.fieldSystemPurpose,
                conditionType,
                exactValues,
                groupOfXeroAccounts: exactGroupValues,
                allowCreation: condition.allowCreation,
                allowEditing: condition.allowEditing,
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [
            condition.allowCreation,
            condition.allowEditing,
            condition.fieldId,
            condition.fieldName,
            condition.fieldSystemPurpose,
            field,
            lineId,
            onConditionChange,
            rule,
        ]
    );

    const onClick = useCallback(() => {
        setInEdit(true);
        selectRef.current?.focus();
    }, []);

    const changeValues = useCallback(
        (conditionType: ExactValuesConditionType) => (values: string[], items: ExactGroupValue[]) => {
            const newExactValues = items.filter((item) => values.includes(item.id) && !item.group);
            const newGroupExactValues = items.filter((item) => values.includes(item.id) && item.group);

            onChangeExactValues(newExactValues, newGroupExactValues, conditionType);
        },
        [onChangeExactValues]
    );

    const changeRequesters = useCallback(
        (conditionType: ExactValuesConditionType) =>
            (values: string[], items: selectors.types.ExpandedCompanyUser[]) => {
                const newExactValues = items
                    .filter((item) => values.includes(item.id))
                    .map((item) => ({ id: item.id, text: item.displayName }));

                onChangeExactValues(newExactValues, undefined, conditionType);
            },
        [onChangeExactValues]
    );

    const handleRemove = useCallback(
        (item: ExactGroupValue, conditionType: ExactValuesConditionType) => {
            onChangeExactValues(
                exactValues.filter((v) => v.id !== item.id),
                exactGroupValues.filter(({ id }) => id !== item.id),
                conditionType
            );
        },
        [onChangeExactValues, exactValues, exactGroupValues]
    );

    const handleBlur = useCallback(() => {
        const delay = browserHelpers.isSafari() ? 150 : 1;

        setTimeout(() => {
            if (!isMounted()) return;

            if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                setInEdit(false);
            }
        }, delay);
    }, [isMounted]);

    const allItems = useMemo(
        () => [...exactGroupValues, ...groupItems, ...items, ...exactValues],
        [exactValues, exactGroupValues, items, groupItems]
    );

    const changeConditionType = useCallback(
        (conditionType: string) => {
            if (conditionType !== conditionTypeAnyValue) {
                setInEdit(true);
                selectRef.current?.focus();
            }

            onConditionTypeChange(conditionType, exactValues, exactGroupValues);
        },
        [conditionTypeAnyValue, onConditionTypeChange, exactValues, exactGroupValues]
    );

    const handleConditionTypeBlur = useCallback(() => {
        setInEdit(false);
    }, []);

    const tagColor = useMemo(() => (isNegativeCondition ? 'midnight70' : 'midnight40'), [isNegativeCondition]);

    const customSelectMenuItem = useCallback<ExtractComponentProp<typeof Select<ExactGroupValue>, 'customMenuItem'>>(
        (item) => <ExactAsyncConditionSelectMenuItem item={item} key={item.key} />,
        []
    );

    return (
        <Root ref={containerRef} data-qa={qa()} data-qa-id={field.id} data-qa-name={field.name}>
            <Select
                items={conditionTypeItems}
                value={conditionTypeValue}
                onChange={changeConditionType}
                textActivatorColor='midnight70'
                textActivatorHoverColor='blue90'
                openIconOnHover
                size='small'
                noInput
                dropdownWidth='max-content'
                onBlur={handleConditionTypeBlur}
                data-qa={qa('condition-type-dropdown')}
                disabled={readonly}
            />

            <Spacing height={4} />

            {inEdit && isExactValueCondition && field.systemPurpose !== domain.FieldSystemPurpose.Requester && (
                <Select
                    items={allItems}
                    onChange={changeValues(condition.conditionType)}
                    value={value}
                    placeholder={exactValuesPlaceholder}
                    onInputChange={setQueryValue}
                    textActivatorColor='blue90'
                    itemNameKey='text'
                    size='xsmall'
                    onOpen={onOpen}
                    autocomplete
                    multiple
                    progress={isFetching || isFetchingGroupsOfXeroAccounts}
                    invalid={invalid}
                    onBlur={handleBlur}
                    onFocus={trigger}
                    activatorRef={selectRef}
                    initFocus
                    preventSearch
                    disabled={readonly}
                    data-qa={qa('values-editor')}
                    tagColor={tagColor}
                    customMenuItem={customSelectMenuItem}
                    customTagItem={(item: ExactGroupValue) => (
                        <ExactAsyncConditionTag
                            key={item.id}
                            item={item}
                            tagColor={tagColor}
                            isNegativeCondition={isNegativeCondition}
                            readonly={readonly}
                            conditionType={condition.conditionType}
                            onRemove={handleRemove}
                        />
                    )}
                />
            )}

            {inEdit && isExactValueCondition && field.systemPurpose === domain.FieldSystemPurpose.Requester && (
                <Select
                    items={templateSubmitters}
                    customMenuItem={({ key, ...item }) => <SelectUserMenuItem key={key} {...item} />}
                    onChange={changeRequesters(condition.conditionType)}
                    value={requesterValue}
                    placeholder={exactValuesPlaceholder}
                    textActivatorColor='blue90'
                    itemNameKey='displayName'
                    size='xsmall'
                    onOpen={onOpen}
                    autocomplete
                    multiple
                    invalid={invalid}
                    onBlur={handleBlur}
                    activatorRef={selectRef}
                    initFocus
                    disabled={readonly}
                    data-qa={qa('values-editor')}
                    tagColor={tagColor}
                />
            )}

            {!inEdit && isExactValueCondition && (
                <>
                    <Flex spacing='4'>
                        {[...exactGroupValues, ...exactValues].map((item: ExactGroupValue, index) => (
                            <ExactAsyncConditionTag
                                key={`${item.id}-${index}`}
                                item={item}
                                tagColor={tagColor}
                                isNegativeCondition={isNegativeCondition}
                                readonly={readonly}
                                conditionType={condition.conditionType}
                                onRemove={handleRemove}
                            />
                        ))}
                    </Flex>

                    <Spacing height={4} />

                    {!readonly && (
                        <ChangeButton
                            onClick={onClick}
                            size='xsmall'
                            color={invalid ? 'red40' : 'blue10'}
                            $active={invalid}
                        >
                            {messages.selectFieldName({ fieldName: field.name })}
                        </ChangeButton>
                    )}
                </>
            )}
        </Root>
    );
});

ExactAsyncCondition.displayName = 'ExactAsyncCondition';
