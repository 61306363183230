import { PasskeysFilledIcon } from '@approvalmax/ui';
import { Button, Link, Text, toast } from '@approvalmax/ui/src/components';
import { finishLoginFlow } from 'pages/auth/actions';
import { memo, useCallback, useRef, useState } from 'react';
import { Id as ToastifyId, toast as toastify } from 'react-toastify';
import { useUnmount } from 'react-use';
import { useLazyGetFido2AssertionOptions, useSetFido2Assertion } from 'shared/data/v2';

import { getAssertionTranfer, getCredentials } from './PasskeyAuthButton.helpers';
import { messages } from './PasskeyAuthButton.messages';
import { PasskeyAuthButtonProps } from './PasskeyAuthButton.types';

export const PasskeyAuthButton = memo<PasskeyAuthButtonProps>((props) => {
    const { className, postAction, block, uppercase, outline, shouldReturnToCurrentPage, size } = props;

    const [isLoading, setIsLoading] = useState(false);
    const passkeyToastId = useRef<ToastifyId>();

    const [triggerGetFido2AssertionOptions] = useLazyGetFido2AssertionOptions();

    const { mutateAsync: loginWithFido2 } = useSetFido2Assertion();

    const handleLogin = useCallback(async () => {
        setIsLoading(true);

        try {
            const assertionOptions = await triggerGetFido2AssertionOptions({});

            const credentials = await getCredentials(assertionOptions);

            await loginWithFido2({
                body: getAssertionTranfer(credentials, assertionOptions),
            });

            finishLoginFlow({
                postAction: shouldReturnToCurrentPage ? window.location.href : postAction,
            });
        } catch {
            const toastContent = (
                <>
                    <Text font='body'>{messages.errorText}</Text>

                    <Link
                        external
                        font='label'
                        href='https://support.approvalmax.com/portal/en/kb/articles/what-is-a-passkey'
                    >
                        {messages.whatIsPasskey}
                    </Link>

                    <br />

                    <Link
                        external
                        font='label'
                        href='https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-a-passkey'
                    >
                        {messages.howToPasskey}
                    </Link>
                </>
            );

            if (!passkeyToastId.current) {
                passkeyToastId.current = toast.error(toastContent, {
                    autoClose: false,
                    onClose: () => {
                        passkeyToastId.current = undefined;
                    },
                });
            }
        }

        setIsLoading(false);
    }, [triggerGetFido2AssertionOptions, loginWithFido2, postAction, shouldReturnToCurrentPage]);

    useUnmount(() => passkeyToastId.current && toastify.dismiss(passkeyToastId.current));

    return (
        <Button
            className={className}
            color='midnight100'
            startIcon={<PasskeysFilledIcon />}
            progress={isLoading}
            block={block}
            uppercase={uppercase}
            outline={outline}
            onClick={handleLogin}
            size={size}
        >
            {messages.title}
        </Button>
    );
});

PasskeyAuthButton.displayName = 'PasskeyAuthButton';
