/**
 * Developer: Stepan Burguchev
 * Date: 6/3/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Identifiable } from '@approvalmax/types';
import { ComponentType, ReactElement } from 'react';

export const ALL_DROPDOWN_THEMES = ['form', 'matrix', 'transparent'] as const;
export type DropdownTheme = (typeof ALL_DROPDOWN_THEMES)[number];

export enum DropdownBoxItemTheme {
    Green = 'green',
    Grey = 'grey',
}

export enum DropdownMode {
    Active = 'active',
    Passive = 'passive',
    Unfocused = 'unfocused',
}

export enum DropdownDataMode {
    Async = 'async',
    Sync = 'sync',
}

export type DropdownSingleValueType = Identifiable | null;
export type DropdownMultipleValueType = Identifiable[] | null;
export type DropdownValueType = DropdownSingleValueType | DropdownMultipleValueType;

export interface DropdownButtonElement {
    select(): void;
    focus(): void;
    blur(): void;
}

export type NoFilterButtonProps = DropdownButtonProps<DropdownSingleValueType>;

export interface DropdownButtonProps<TValue> {
    value: TValue;
    theme: DropdownTheme;
    mode: DropdownMode;
    inputText: string;
    onTextChange: (text: string) => void;
    displayTextSelector: (value: Identifiable | null) => string;
    buttonTitleSelector?: (value: Identifiable | null) => string;
    boxItem: ComponentType<DropdownBoxItemProps>;
    loading: boolean;
    disabled: boolean;
    invalid: boolean;
    warning: boolean;
    placeholder?: string;
    filterPlaceholder?: string;
    children?: ReactElement<NoFilterButtonProps>[];
    onRemove: (item: Identifiable) => void;
    title?: string;
}

export interface DropdownBoxItemProps {
    item: Identifiable;
    displayTextSelector: (item: Identifiable) => string;
    onRemove: (item: Identifiable) => void;
    disabled: boolean;
    className: string;
    theme?: DropdownBoxItemTheme;
    strikeThrough?: boolean;
}

export interface DropdownListItemProps<TValue extends Identifiable> {
    item: TValue;
    displayTextSelector: (item: TValue) => string;
    displaySubTextSelector?: (item: TValue) => string;
    rootRef: (ref: HTMLLIElement) => void;
    active: boolean;
    isSelected?: boolean;
    highlightedText: string | null;
    onSelect: (item: TValue) => void;
    onHover: (item: TValue) => void;
    className?: string;
    nestingLevel?: number;
    singleLinePerItem?: boolean;
}
