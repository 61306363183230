import { Reference } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { defineMessages as amDefineMessages } from '@approvalmax/utils';
import * as Sentry from '@sentry/browser';
import map from 'lodash/map';
import { immutable } from 'modules/immutable';
import { defineMessages } from 'react-intl';

export const defaultCurrency = 'USD';

// hack with typings to make findCurrencyNameByCode working correctly
export const currencyFullTextWithoutCodeMessages: Record<string, string> = amDefineMessages(
    'statics.currency.currencyFullTextWithoutCode',
    {
        usd: 'United States Dollar',
        aud: 'Australian Dollar',
        cad: 'Canadian Dollar',
        eur: 'Euro',
        gbp: 'British Pound',
        inr: 'Indian Rupee',
        jpy: 'Japanese Yen',
        nzd: 'New Zealand Dollar',
        aed: 'United Arab Emirates Dirham',
        afn: 'Afghan Afghani',
        all: 'Albanian Lek',
        amd: 'Armenian Dram',
        ang: 'Netherlands Antillean Guilder',
        aoa: 'Angolan Kwanza',
        ars: 'Argentine Peso',
        awg: 'Aruban Guilder',
        azn: 'Azerbaijani Manat',
        bam: 'Bosnia and Herzegovina Convertible Mark',
        bbd: 'Barbadian Dollar',
        bdt: 'Bangladeshi Taka',
        bgn: 'Bulgarian Lev',
        bhd: 'Bahraini Dinar',
        bif: 'Burundian Franc',
        bmd: 'Bermudian Dollar',
        bnd: 'Brunei Dollar',
        bob: 'Bolivian Boliviano',
        brl: 'Brazilian Real',
        bsd: 'Bahamian Dollar',
        btn: 'Bhutanese Ngultrum',
        bwp: 'Botswana Pula',
        byr: 'Belarusian Ruble',
        bzd: 'Belize Dollar',
        cdf: 'Congolese Franc',
        chf: 'Swiss Franc',
        clp: 'Chilean Peso',
        cny: 'Chinese Yuan',
        cop: 'Colombian Peso',
        crc: 'Costa Rican Colón',
        cuc: 'Cuban Convertible Peso',
        cve: 'Cape Verdean Escudo',
        czk: 'Czech Koruna',
        djf: 'Djiboutian Franc',
        dkk: 'Danish Krone',
        dop: 'Dominican Peso',
        dzd: 'Algerian Dinar',
        eek: 'Estonian Kroon',
        egp: 'Egyptian Pound',
        ern: 'Eritrean Nakfa',
        etb: 'Ethiopian Birr',
        fjd: 'Fijian Dollar',
        fkp: 'Falkland Island Pound',
        gel: 'Georgian Lari',
        ghs: 'Ghanaian Cedi',
        gip: 'Gibraltar Pound',
        gmd: 'Gambian Dalasi',
        gnf: 'Guinean Franc',
        gtq: 'Guatemalan Quetzal',
        gyd: 'Guyanese Dollar',
        hkd: 'Hong Kong Dollar',
        hnl: 'Honduran Lempira',
        hrk: 'Croatian Kuna',
        htg: 'Haitian Gourde',
        huf: 'Hungarian Forint',
        idr: 'Indonesian Rupiah',
        ils: 'Israeli New Sheqel',
        iqd: 'Iraqi Dinar',
        irr: 'Iranian Rial',
        isk: 'Icelandic Króna',
        jmd: 'Jamaican Dollar',
        jod: 'Jordanian Dinar',
        kes: 'Kenyan Shilling',
        kgs: 'Kyrgyzstani Som',
        khr: 'Cambodian Riel',
        kmf: 'Comorian Franc',
        kpw: 'North Korean Won',
        krw: 'South Korean Won',
        kwd: 'Kuwaiti Dinar',
        kyd: 'Cayman Islands Dollar',
        kzt: 'Kazakhstani Tenge',
        lak: 'Lao Kip',
        lbp: 'Lebanese Pound',
        lkr: 'Sri Lankan Rupee',
        lrd: 'Liberian Dollar',
        lsl: 'Lesotho Loti',
        lyd: 'Libyan Dinar',
        mad: 'Moroccan Dirham',
        mdl: 'Moldovan Leu',
        mga: 'Malagasy Ariary',
        mkd: 'Macedonian Denar',
        mmk: 'Myanmar Kyat',
        mnt: 'Mongolian Tugrik',
        mop: 'Macanese Pataca',
        mro: 'Mauritanian Ouguiya',
        mur: 'Mauritian Rupee',
        mvr: 'Maldivian Rufiyaa',
        mwk: 'Malawian Kwacha',
        mxn: 'Mexican Peso',
        myr: 'Malaysian Ringgit',
        mzn: 'Mozambican Metical',
        nad: 'Namibian Dollar',
        ngn: 'Nigerian Naira',
        nio: 'Nicaraguan Córdoba',
        nok: 'Norwegian Krone',
        npr: 'Nepalese Rupee',
        omr: 'Omani Rial',
        pab: 'Panamanian Balboa',
        pen: 'Peruvian Nuevo Sol',
        pgk: 'Papua New Guinean Kina',
        php: 'Philippine Peso',
        pkr: 'Pakistani Rupee',
        pln: 'Polish Zloty',
        pyg: 'Paraguayan Guaraní',
        qar: 'Qatari Riyal',
        ron: 'Romanian Leu',
        rsd: 'Serbian Dinar',
        rub: 'Russian Ruble',
        rwf: 'Rwandan Franc',
        sar: 'Saudi Riyal',
        sbd: 'Solomon Islands Dollar',
        scr: 'Seychellois Rupee',
        sdg: 'Sudanese Pound',
        sek: 'Swedish Krona',
        sgd: 'Singapore Dollar',
        shp: 'Saint Helenian Pound',
        sll: 'Sierra Leonean Leone',
        sos: 'Somali Shilling',
        srd: 'Surinamese Dollar',
        std: 'São Tomé and Príncipe Dobra',
        svc: 'Salvadoran Colón',
        syp: 'Syrian Pound',
        szl: 'Swazi Lilangeni',
        thb: 'Thai Baht',
        tjs: 'Tajikistani Somoni',
        tmt: 'Turkmenistani Manat',
        tnd: 'Tunisian Dinar',
        top: "Tongan Pa'anga",
        try: 'Turkish Lira',
        ttd: 'Trinidad and Tobago Dollar',
        twd: 'Taiwanese New Dollar',
        tzs: 'Tanzanian Shilling',
        uah: 'Ukrainian Hryvnia',
        ugx: 'Ugandan Shilling',
        uyu: 'Uruguayan Peso',
        uzs: 'Uzbekistani Som',
        vef: 'Venezuelan Bolívar Fuertes',
        vnd: 'Vietnamese Dong',
        vuv: 'Vanuatu Vatu',
        wst: 'Samoan Tala',
        xaf: 'Central African CFA Franc',
        xcd: 'East Caribbean Dollar',
        xof: 'West African CFA Franc',
        xpf: 'CFP Franc',
        yer: 'Yemeni Rial',
        zar: 'South African Rand',
        zmk: 'Zambian Kwacha (expiring)',
        zmw: 'Zambian Kwacha',
        byn: 'Belarussian Ruble',
        clf: 'Unidad de Fomento',
        cou: 'Unidad de Valor Real',
        cup: 'Cuban Peso',
        ktd: 'Cayman Islands Dollar',
        mru: 'Ouguiya',
        mxv: 'Mexican Unidad de Inversion',
        skk: 'Slovak Koruna',
        sle: 'Leone',
        ssp: 'South Sudanese Pound',
        stn: 'Dobra',
        ves: 'Venezualan Bolivar',
        zwl: 'Zimbabwe Dollar',
    }
);

const currencyFullTextMessages = defineMessages({
    usd: {
        id: 'currency.fullText.usd',
        defaultMessage: 'USD United States Dollar',
    },
    aud: {
        id: 'currency.fullText.aud',
        defaultMessage: 'AUD Australian Dollar',
    },
    cad: {
        id: 'currency.fullText.cad',
        defaultMessage: 'CAD Canadian Dollar',
    },
    eur: {
        id: 'currency.fullText.eur',
        defaultMessage: 'EUR Euro',
    },
    gbp: {
        id: 'currency.fullText.gbp',
        defaultMessage: 'GBP British Pound',
    },
    inr: {
        id: 'currency.fullText.inr',
        defaultMessage: 'INR Indian Rupee',
    },
    jpy: {
        id: 'currency.fullText.jpy',
        defaultMessage: 'JPY Japanese Yen',
    },
    nzd: {
        id: 'currency.fullText.nzd',
        defaultMessage: 'NZD New Zealand Dollar',
    },
    aed: {
        id: 'currency.fullText.aed',
        defaultMessage: 'AED United Arab Emirates Dirham',
    },
    afn: {
        id: 'currency.fullText.afn',
        defaultMessage: 'AFN Afghan Afghani',
    },
    all: {
        id: 'currency.fullText.all',
        defaultMessage: 'ALL Albanian Lek',
    },
    amd: {
        id: 'currency.fullText.amd',
        defaultMessage: 'AMD Armenian Dram',
    },
    ang: {
        id: 'currency.fullText.ang',
        defaultMessage: 'ANG Netherlands Antillean Guilder',
    },
    aoa: {
        id: 'currency.fullText.aoa',
        defaultMessage: 'AOA Angolan Kwanza',
    },
    ars: {
        id: 'currency.fullText.ars',
        defaultMessage: 'ARS Argentine Peso',
    },
    awg: {
        id: 'currency.fullText.awg',
        defaultMessage: 'AWG Aruban Guilder',
    },
    azn: {
        id: 'currency.fullText.azn',
        defaultMessage: 'AZN Azerbaijani Manat',
    },
    bam: {
        id: 'currency.fullText.bam',
        defaultMessage: 'BAM Bosnia and Herzegovina Convertible Mark',
    },
    bbd: {
        id: 'currency.fullText.bbd',
        defaultMessage: 'BBD Barbadian Dollar',
    },
    bdt: {
        id: 'currency.fullText.bdt',
        defaultMessage: 'BDT Bangladeshi Taka',
    },
    bgn: {
        id: 'currency.fullText.bgn',
        defaultMessage: 'BGN Bulgarian Lev',
    },
    bhd: {
        id: 'currency.fullText.bhd',
        defaultMessage: 'BHD Bahraini Dinar',
    },
    bif: {
        id: 'currency.fullText.bif',
        defaultMessage: 'BIF Burundian Franc',
    },
    bmd: {
        id: 'currency.fullText.bmd',
        defaultMessage: 'BMD Bermudian Dollar',
    },
    bnd: {
        id: 'currency.fullText.bnd',
        defaultMessage: 'BND Brunei Dollar',
    },
    bob: {
        id: 'currency.fullText.bob',
        defaultMessage: 'BOB Bolivian Boliviano',
    },
    brl: {
        id: 'currency.fullText.brl',
        defaultMessage: 'BRL Brazilian Real',
    },
    bsd: {
        id: 'currency.fullText.bsd',
        defaultMessage: 'BSD Bahamian Dollar',
    },
    btn: {
        id: 'currency.fullText.btn',
        defaultMessage: 'BTN Bhutanese Ngultrum',
    },
    bwp: {
        id: 'currency.fullText.bwp',
        defaultMessage: 'BWP Botswana Pula',
    },
    byr: {
        id: 'currency.fullText.byr',
        defaultMessage: 'BYR Belarusian Ruble',
    },
    bzd: {
        id: 'currency.fullText.bzd',
        defaultMessage: 'BZD Belize Dollar',
    },
    cdf: {
        id: 'currency.fullText.cdf',
        defaultMessage: 'CDF Congolese Franc',
    },
    chf: {
        id: 'currency.fullText.chf',
        defaultMessage: 'CHF Swiss Franc',
    },
    clp: {
        id: 'currency.fullText.clp',
        defaultMessage: 'CLP Chilean Peso',
    },
    cny: {
        id: 'currency.fullText.cny',
        defaultMessage: 'CNY Chinese Yuan',
    },
    cop: {
        id: 'currency.fullText.cop',
        defaultMessage: 'COP Colombian Peso',
    },
    crc: {
        id: 'currency.fullText.crc',
        defaultMessage: 'CRC Costa Rican Colón',
    },
    cuc: {
        id: 'currency.fullText.cuc',
        defaultMessage: 'CUC Cuban Convertible Peso',
    },
    cve: {
        id: 'currency.fullText.cve',
        defaultMessage: 'CVE Cape Verdean Escudo',
    },
    czk: {
        id: 'currency.fullText.czk',
        defaultMessage: 'CZK Czech Koruna',
    },
    djf: {
        id: 'currency.fullText.djf',
        defaultMessage: 'DJF Djiboutian Franc',
    },
    dkk: {
        id: 'currency.fullText.dkk',
        defaultMessage: 'DKK Danish Krone',
    },
    dop: {
        id: 'currency.fullText.dop',
        defaultMessage: 'DOP Dominican Peso',
    },
    dzd: {
        id: 'currency.fullText.dzd',
        defaultMessage: 'DZD Algerian Dinar',
    },
    eek: {
        id: 'currency.fullText.eek',
        defaultMessage: 'EEK Estonian Kroon',
    },
    egp: {
        id: 'currency.fullText.egp',
        defaultMessage: 'EGP Egyptian Pound',
    },
    ern: {
        id: 'currency.fullText.ern',
        defaultMessage: 'ERN Eritrean Nakfa',
    },
    etb: {
        id: 'currency.fullText.etb',
        defaultMessage: 'ETB Ethiopian Birr',
    },
    fjd: {
        id: 'currency.fullText.fjd',
        defaultMessage: 'FJD Fijian Dollar',
    },
    fkp: {
        id: 'currency.fullText.fkp',
        defaultMessage: 'FKP Falkland Island Pound',
    },
    gel: {
        id: 'currency.fullText.gel',
        defaultMessage: 'GEL Georgian Lari',
    },
    ghs: {
        id: 'currency.fullText.ghs',
        defaultMessage: 'GHS Ghanaian Cedi',
    },
    gip: {
        id: 'currency.fullText.gip',
        defaultMessage: 'GIP Gibraltar Pound',
    },
    gmd: {
        id: 'currency.fullText.gmd',
        defaultMessage: 'GMD Gambian Dalasi',
    },
    gnf: {
        id: 'currency.fullText.gnf',
        defaultMessage: 'GNF Guinean Franc',
    },
    gtq: {
        id: 'currency.fullText.gtq',
        defaultMessage: 'GTQ Guatemalan Quetzal',
    },
    gyd: {
        id: 'currency.fullText.gyd',
        defaultMessage: 'GYD Guyanese Dollar',
    },
    hkd: {
        id: 'currency.fullText.hkd',
        defaultMessage: 'HKD Hong Kong Dollar',
    },
    hnl: {
        id: 'currency.fullText.hnl',
        defaultMessage: 'HNL Honduran Lempira',
    },
    hrk: {
        id: 'currency.fullText.hrk',
        defaultMessage: 'HRK Croatian Kuna',
    },
    htg: {
        id: 'currency.fullText.htg',
        defaultMessage: 'HTG Haitian Gourde',
    },
    huf: {
        id: 'currency.fullText.huf',
        defaultMessage: 'HUF Hungarian Forint',
    },
    idr: {
        id: 'currency.fullText.idr',
        defaultMessage: 'IDR Indonesian Rupiah',
    },
    ils: {
        id: 'currency.fullText.ils',
        defaultMessage: 'ILS Israeli New Sheqel',
    },
    iqd: {
        id: 'currency.fullText.iqd',
        defaultMessage: 'IQD Iraqi Dinar',
    },
    irr: {
        id: 'currency.fullText.irr',
        defaultMessage: 'IRR Iranian Rial',
    },
    isk: {
        id: 'currency.fullText.isk',
        defaultMessage: 'ISK Icelandic Króna',
    },
    jmd: {
        id: 'currency.fullText.jmd',
        defaultMessage: 'JMD Jamaican Dollar',
    },
    jod: {
        id: 'currency.fullText.jod',
        defaultMessage: 'JOD Jordanian Dinar',
    },
    kes: {
        id: 'currency.fullText.kes',
        defaultMessage: 'KES Kenyan Shilling',
    },
    kgs: {
        id: 'currency.fullText.kgs',
        defaultMessage: 'KGS Kyrgyzstani Som',
    },
    khr: {
        id: 'currency.fullText.khr',
        defaultMessage: 'KHR Cambodian Riel',
    },
    kmf: {
        id: 'currency.fullText.kmf',
        defaultMessage: 'KMF Comorian Franc',
    },
    kpw: {
        id: 'currency.fullText.kpw',
        defaultMessage: 'KPW North Korean Won',
    },
    krw: {
        id: 'currency.fullText.krw',
        defaultMessage: 'KRW South Korean Won',
    },
    kwd: {
        id: 'currency.fullText.kwd',
        defaultMessage: 'KWD Kuwaiti Dinar',
    },
    kyd: {
        id: 'currency.fullText.kyd',
        defaultMessage: 'KYD Cayman Islands Dollar',
    },
    kzt: {
        id: 'currency.fullText.kzt',
        defaultMessage: 'KZT Kazakhstani Tenge',
    },
    lak: {
        id: 'currency.fullText.lak',
        defaultMessage: 'LAK Lao Kip',
    },
    lbp: {
        id: 'currency.fullText.lbp',
        defaultMessage: 'LBP Lebanese Pound',
    },
    lkr: {
        id: 'currency.fullText.lkr',
        defaultMessage: 'LKR Sri Lankan Rupee',
    },
    lrd: {
        id: 'currency.fullText.lrd',
        defaultMessage: 'LRD Liberian Dollar',
    },
    lsl: {
        id: 'currency.fullText.lsl',
        defaultMessage: 'LSL Lesotho Loti',
    },
    lyd: {
        id: 'currency.fullText.lyd',
        defaultMessage: 'LYD Libyan Dinar',
    },
    mad: {
        id: 'currency.fullText.mad',
        defaultMessage: 'MAD Moroccan Dirham',
    },
    mdl: {
        id: 'currency.fullText.mdl',
        defaultMessage: 'MDL Moldovan Leu',
    },
    mga: {
        id: 'currency.fullText.mga',
        defaultMessage: 'MGA Malagasy Ariary',
    },
    mkd: {
        id: 'currency.fullText.mkd',
        defaultMessage: 'MKD Macedonian Denar',
    },
    mmk: {
        id: 'currency.fullText.mmk',
        defaultMessage: 'MMK Myanmar Kyat',
    },
    mnt: {
        id: 'currency.fullText.mnt',
        defaultMessage: 'MNT Mongolian Tugrik',
    },
    mop: {
        id: 'currency.fullText.mop',
        defaultMessage: 'MOP Macanese Pataca',
    },
    mro: {
        id: 'currency.fullText.mro',
        defaultMessage: 'MRO Mauritanian Ouguiya',
    },
    mur: {
        id: 'currency.fullText.mur',
        defaultMessage: 'MUR Mauritian Rupee',
    },
    mvr: {
        id: 'currency.fullText.mvr',
        defaultMessage: 'MVR Maldivian Rufiyaa',
    },
    mwk: {
        id: 'currency.fullText.mwk',
        defaultMessage: 'MWK Malawian Kwacha',
    },
    mxn: {
        id: 'currency.fullText.mxn',
        defaultMessage: 'MXN Mexican Peso',
    },
    myr: {
        id: 'currency.fullText.myr',
        defaultMessage: 'MYR Malaysian Ringgit',
    },
    mzn: {
        id: 'currency.fullText.mzn',
        defaultMessage: 'MZN Mozambican Metical',
    },
    nad: {
        id: 'currency.fullText.nad',
        defaultMessage: 'NAD Namibian Dollar',
    },
    ngn: {
        id: 'currency.fullText.ngn',
        defaultMessage: 'NGN Nigerian Naira',
    },
    nio: {
        id: 'currency.fullText.nio',
        defaultMessage: 'NIO Nicaraguan Córdoba',
    },
    nok: {
        id: 'currency.fullText.nok',
        defaultMessage: 'NOK Norwegian Krone',
    },
    npr: {
        id: 'currency.fullText.npr',
        defaultMessage: 'NPR Nepalese Rupee',
    },
    omr: {
        id: 'currency.fullText.omr',
        defaultMessage: 'OMR Omani Rial',
    },
    pab: {
        id: 'currency.fullText.pab',
        defaultMessage: 'PAB Panamanian Balboa',
    },
    pen: {
        id: 'currency.fullText.pen',
        defaultMessage: 'PEN Peruvian Nuevo Sol',
    },
    pgk: {
        id: 'currency.fullText.pgk',
        defaultMessage: 'PGK Papua New Guinean Kina',
    },
    php: {
        id: 'currency.fullText.php',
        defaultMessage: 'PHP Philippine Peso',
    },
    pkr: {
        id: 'currency.fullText.pkr',
        defaultMessage: 'PKR Pakistani Rupee',
    },
    pln: {
        id: 'currency.fullText.pln',
        defaultMessage: 'PLN Polish Zloty',
    },
    pyg: {
        id: 'currency.fullText.pyg',
        defaultMessage: 'PYG Paraguayan Guaraní',
    },
    qar: {
        id: 'currency.fullText.qar',
        defaultMessage: 'QAR Qatari Riyal',
    },
    ron: {
        id: 'currency.fullText.ron',
        defaultMessage: 'RON Romanian Leu',
    },
    rsd: {
        id: 'currency.fullText.rsd',
        defaultMessage: 'RSD Serbian Dinar',
    },
    rub: {
        id: 'currency.fullText.rub',
        defaultMessage: 'RUB Russian Ruble',
    },
    rwf: {
        id: 'currency.fullText.rwf',
        defaultMessage: 'RWF Rwandan Franc',
    },
    sar: {
        id: 'currency.fullText.sar',
        defaultMessage: 'SAR Saudi Riyal',
    },
    sbd: {
        id: 'currency.fullText.sbd',
        defaultMessage: 'SBD Solomon Islands Dollar',
    },
    scr: {
        id: 'currency.fullText.scr',
        defaultMessage: 'SCR Seychellois Rupee',
    },
    sdg: {
        id: 'currency.fullText.sdg',
        defaultMessage: 'SDG Sudanese Pound',
    },
    sek: {
        id: 'currency.fullText.sek',
        defaultMessage: 'SEK Swedish Krona',
    },
    sgd: {
        id: 'currency.fullText.sgd',
        defaultMessage: 'SGD Singapore Dollar',
    },
    shp: {
        id: 'currency.fullText.shp',
        defaultMessage: 'SHP Saint Helenian Pound',
    },
    sll: {
        id: 'currency.fullText.sll',
        defaultMessage: 'SLL Sierra Leonean Leone',
    },
    sos: {
        id: 'currency.fullText.sos',
        defaultMessage: 'SOS Somali Shilling',
    },
    srd: {
        id: 'currency.fullText.srd',
        defaultMessage: 'SRD Surinamese Dollar',
    },
    std: {
        id: 'currency.fullText.std',
        defaultMessage: 'STD São Tomé and Príncipe Dobra',
    },
    svc: {
        id: 'currency.fullText.svc',
        defaultMessage: 'SVC Salvadoran Colón',
    },
    syp: {
        id: 'currency.fullText.syp',
        defaultMessage: 'SYP Syrian Pound',
    },
    szl: {
        id: 'currency.fullText.szl',
        defaultMessage: 'SZL Swazi Lilangeni',
    },
    thb: {
        id: 'currency.fullText.thb',
        defaultMessage: 'THB Thai Baht',
    },
    tjs: {
        id: 'currency.fullText.tjs',
        defaultMessage: 'TJS Tajikistani Somoni',
    },
    tmt: {
        id: 'currency.fullText.tmt',
        defaultMessage: 'TMT Turkmenistani Manat',
    },
    tnd: {
        id: 'currency.fullText.tnd',
        defaultMessage: 'TND Tunisian Dinar',
    },
    top: {
        id: 'currency.fullText.top',
        defaultMessage: "TOP Tongan Pa'anga",
    },
    try: {
        id: 'currency.fullText.try',
        defaultMessage: 'TRY Turkish Lira',
    },
    ttd: {
        id: 'currency.fullText.ttd',
        defaultMessage: 'TTD Trinidad and Tobago Dollar',
    },
    twd: {
        id: 'currency.fullText.twd',
        defaultMessage: 'TWD Taiwanese New Dollar',
    },
    tzs: {
        id: 'currency.fullText.tzs',
        defaultMessage: 'TZS Tanzanian Shilling',
    },
    uah: {
        id: 'currency.fullText.uah',
        defaultMessage: 'UAH Ukrainian Hryvnia',
    },
    ugx: {
        id: 'currency.fullText.ugx',
        defaultMessage: 'UGX Ugandan Shilling',
    },
    uyu: {
        id: 'currency.fullText.uyu',
        defaultMessage: 'UYU Uruguayan Peso',
    },
    uzs: {
        id: 'currency.fullText.uzs',
        defaultMessage: 'UZS Uzbekistani Som',
    },
    vef: {
        id: 'currency.fullText.vef',
        defaultMessage: 'VEF Venezuelan Bolívar Fuertes',
    },
    vnd: {
        id: 'currency.fullText.vnd',
        defaultMessage: 'VND Vietnamese Dong',
    },
    vuv: {
        id: 'currency.fullText.vuv',
        defaultMessage: 'VUV Vanuatu Vatu',
    },
    wst: {
        id: 'currency.fullText.wst',
        defaultMessage: 'WST Samoan Tala',
    },
    xaf: {
        id: 'currency.fullText.xaf',
        defaultMessage: 'XAF Central African CFA Franc',
    },
    xcd: {
        id: 'currency.fullText.xcd',
        defaultMessage: 'XCD East Caribbean Dollar',
    },
    xof: {
        id: 'currency.fullText.xof',
        defaultMessage: 'XOF West African CFA Franc',
    },
    xpf: {
        id: 'currency.fullText.xpf',
        defaultMessage: 'XPF CFP Franc',
    },
    yer: {
        id: 'currency.fullText.yer',
        defaultMessage: 'YER Yemeni Rial',
    },
    zar: {
        id: 'currency.fullText.zar',
        defaultMessage: 'ZAR South African Rand',
    },
    zmk: {
        id: 'currency.fullText.zmk',
        defaultMessage: 'ZMK Zambian Kwacha (expiring)',
    },
    zmw: {
        id: 'currency.fullText.zmw',
        defaultMessage: 'ZMW Zambian Kwacha',
    },
    byn: {
        id: 'currency.fullText.byn',
        defaultMessage: 'BYN Belarussian Ruble',
    },
    clf: {
        id: 'currency.fullText.clf',
        defaultMessage: 'CLF Unidad de Fomento',
    },
    cou: {
        id: 'currency.fullText.cou',
        defaultMessage: 'COU Unidad de Valor Real',
    },
    cup: {
        id: 'currency.fullText.cup',
        defaultMessage: 'CUP Cuban Peso',
    },
    ktd: {
        id: 'currency.fullText.ktd',
        defaultMessage: 'KTD Cayman Islands Dollar',
    },
    mru: {
        id: 'currency.fullText.mru',
        defaultMessage: 'MRU Ouguiya',
    },
    mxv: {
        id: 'currency.fullText.mxv',
        defaultMessage: 'MXV Mexican Unidad de Inversion',
    },
    skk: {
        id: 'currency.fullText.skk',
        defaultMessage: 'SKK - Slovak Koruna',
    },
    sle: {
        id: 'currency.fullText.sle',
        defaultMessage: 'SLE Leone',
    },
    ssp: {
        id: 'currency.fullText.ssp',
        defaultMessage: 'SSP South Sudanese Pound',
    },
    stn: {
        id: 'currency.fullText.stn',
        defaultMessage: 'STN Dobra',
    },
    ves: {
        id: 'currency.fullText.ves',
        defaultMessage: 'VES Venezualan Bolivar',
    },
    zwl: {
        id: 'currency.fullText.zwl',
        defaultMessage: 'ZWL Zimbabwe Dollar',
    },
});

const currencyTextMessages = defineMessages({
    usd: {
        id: 'currency.text.usd',
        defaultMessage: 'USD',
    },
    aud: {
        id: 'currency.text.aud',
        defaultMessage: 'AUD',
    },
    cad: {
        id: 'currency.text.cad',
        defaultMessage: 'CAD',
    },
    eur: {
        id: 'currency.text.eur',
        defaultMessage: 'EUR',
    },
    gbp: {
        id: 'currency.text.gbp',
        defaultMessage: 'GBP',
    },
    inr: {
        id: 'currency.text.inr',
        defaultMessage: 'INR',
    },
    jpy: {
        id: 'currency.text.jpy',
        defaultMessage: 'JPY',
    },
    nzd: {
        id: 'currency.text.nzd',
        defaultMessage: 'NZD',
    },
    aed: {
        id: 'currency.text.aed',
        defaultMessage: 'AED',
    },
    afn: {
        id: 'currency.text.afn',
        defaultMessage: 'AFN',
    },
    all: {
        id: 'currency.text.all',
        defaultMessage: 'ALL',
    },
    amd: {
        id: 'currency.text.amd',
        defaultMessage: 'AMD',
    },
    ang: {
        id: 'currency.text.ang',
        defaultMessage: 'ANG',
    },
    aoa: {
        id: 'currency.text.aoa',
        defaultMessage: 'AOA',
    },
    ars: {
        id: 'currency.text.ars',
        defaultMessage: 'ARS',
    },
    awg: {
        id: 'currency.text.awg',
        defaultMessage: 'AWG',
    },
    azn: {
        id: 'currency.text.azn',
        defaultMessage: 'AZN',
    },
    bam: {
        id: 'currency.text.bam',
        defaultMessage: 'BAM',
    },
    bbd: {
        id: 'currency.text.bbd',
        defaultMessage: 'BBD',
    },
    bdt: {
        id: 'currency.text.bdt',
        defaultMessage: 'BDT',
    },
    bgn: {
        id: 'currency.text.bgn',
        defaultMessage: 'BGN',
    },
    bhd: {
        id: 'currency.text.bhd',
        defaultMessage: 'BHD',
    },
    bif: {
        id: 'currency.text.bif',
        defaultMessage: 'BIF',
    },
    bmd: {
        id: 'currency.text.bmd',
        defaultMessage: 'BMD',
    },
    bnd: {
        id: 'currency.text.bnd',
        defaultMessage: 'BND',
    },
    bob: {
        id: 'currency.text.bob',
        defaultMessage: 'BOB',
    },
    brl: {
        id: 'currency.text.brl',
        defaultMessage: 'BRL',
    },
    bsd: {
        id: 'currency.text.bsd',
        defaultMessage: 'BSD',
    },
    btn: {
        id: 'currency.text.btn',
        defaultMessage: 'BTN',
    },
    bwp: {
        id: 'currency.text.bwp',
        defaultMessage: 'BWP',
    },
    byr: {
        id: 'currency.text.byr',
        defaultMessage: 'BYR',
    },
    bzd: {
        id: 'currency.text.bzd',
        defaultMessage: 'BZD',
    },
    cdf: {
        id: 'currency.text.cdf',
        defaultMessage: 'CDF',
    },
    chf: {
        id: 'currency.text.chf',
        defaultMessage: 'CHF',
    },
    clp: {
        id: 'currency.text.clp',
        defaultMessage: 'CLP',
    },
    cny: {
        id: 'currency.text.cny',
        defaultMessage: 'CNY',
    },
    cop: {
        id: 'currency.text.cop',
        defaultMessage: 'COP',
    },
    crc: {
        id: 'currency.text.crc',
        defaultMessage: 'CRC',
    },
    cuc: {
        id: 'currency.text.cuc',
        defaultMessage: 'CUC',
    },
    cve: {
        id: 'currency.text.cve',
        defaultMessage: 'CVE',
    },
    czk: {
        id: 'currency.text.czk',
        defaultMessage: 'CZK',
    },
    djf: {
        id: 'currency.text.djf',
        defaultMessage: 'DJF',
    },
    dkk: {
        id: 'currency.text.dkk',
        defaultMessage: 'DKK',
    },
    dop: {
        id: 'currency.text.dop',
        defaultMessage: 'DOP',
    },
    dzd: {
        id: 'currency.text.dzd',
        defaultMessage: 'DZD',
    },
    eek: {
        id: 'currency.text.eek',
        defaultMessage: 'EEK',
    },
    egp: {
        id: 'currency.text.egp',
        defaultMessage: 'EGP',
    },
    ern: {
        id: 'currency.text.ern',
        defaultMessage: 'ERN',
    },
    etb: {
        id: 'currency.text.etb',
        defaultMessage: 'ETB',
    },
    fjd: {
        id: 'currency.text.fjd',
        defaultMessage: 'FJD',
    },
    fkp: {
        id: 'currency.text.fkp',
        defaultMessage: 'FKP',
    },
    gel: {
        id: 'currency.text.gel',
        defaultMessage: 'GEL',
    },
    ghs: {
        id: 'currency.text.ghs',
        defaultMessage: 'GHS',
    },
    gip: {
        id: 'currency.text.gip',
        defaultMessage: 'GIP',
    },
    gmd: {
        id: 'currency.text.gmd',
        defaultMessage: 'GMD',
    },
    gnf: {
        id: 'currency.text.gnf',
        defaultMessage: 'GNF',
    },
    gtq: {
        id: 'currency.text.gtq',
        defaultMessage: 'GTQ',
    },
    gyd: {
        id: 'currency.text.gyd',
        defaultMessage: 'GYD',
    },
    hkd: {
        id: 'currency.text.hkd',
        defaultMessage: 'HKD',
    },
    hnl: {
        id: 'currency.text.hnl',
        defaultMessage: 'HNL',
    },
    hrk: {
        id: 'currency.text.hrk',
        defaultMessage: 'HRK',
    },
    htg: {
        id: 'currency.text.htg',
        defaultMessage: 'HTG',
    },
    huf: {
        id: 'currency.text.huf',
        defaultMessage: 'HUF',
    },
    idr: {
        id: 'currency.text.idr',
        defaultMessage: 'IDR',
    },
    ils: {
        id: 'currency.text.ils',
        defaultMessage: 'ILS',
    },
    iqd: {
        id: 'currency.text.iqd',
        defaultMessage: 'IQD',
    },
    irr: {
        id: 'currency.text.irr',
        defaultMessage: 'IRR',
    },
    isk: {
        id: 'currency.text.isk',
        defaultMessage: 'ISK',
    },
    jmd: {
        id: 'currency.text.jmd',
        defaultMessage: 'JMD',
    },
    jod: {
        id: 'currency.text.jod',
        defaultMessage: 'JOD',
    },
    kes: {
        id: 'currency.text.kes',
        defaultMessage: 'KES',
    },
    kgs: {
        id: 'currency.text.kgs',
        defaultMessage: 'KGS',
    },
    khr: {
        id: 'currency.text.khr',
        defaultMessage: 'KHR',
    },
    kmf: {
        id: 'currency.text.kmf',
        defaultMessage: 'KMF',
    },
    kpw: {
        id: 'currency.text.kpw',
        defaultMessage: 'KPW',
    },
    krw: {
        id: 'currency.text.krw',
        defaultMessage: 'KRW',
    },
    kwd: {
        id: 'currency.text.kwd',
        defaultMessage: 'KWD',
    },
    kyd: {
        id: 'currency.text.kyd',
        defaultMessage: 'KYD',
    },
    kzt: {
        id: 'currency.text.kzt',
        defaultMessage: 'KZT',
    },
    lak: {
        id: 'currency.text.lak',
        defaultMessage: 'LAK',
    },
    lbp: {
        id: 'currency.text.lbp',
        defaultMessage: 'LBP',
    },
    lkr: {
        id: 'currency.text.lkr',
        defaultMessage: 'LKR',
    },
    lrd: {
        id: 'currency.text.lrd',
        defaultMessage: 'LRD',
    },
    lsl: {
        id: 'currency.text.lsl',
        defaultMessage: 'LSL',
    },
    lyd: {
        id: 'currency.text.lyd',
        defaultMessage: 'LYD',
    },
    mad: {
        id: 'currency.text.mad',
        defaultMessage: 'MAD',
    },
    mdl: {
        id: 'currency.text.mdl',
        defaultMessage: 'MDL',
    },
    mga: {
        id: 'currency.text.mga',
        defaultMessage: 'MGA',
    },
    mkd: {
        id: 'currency.text.mkd',
        defaultMessage: 'MKD',
    },
    mmk: {
        id: 'currency.text.mmk',
        defaultMessage: 'MMK',
    },
    mnt: {
        id: 'currency.text.mnt',
        defaultMessage: 'MNT',
    },
    mop: {
        id: 'currency.text.mop',
        defaultMessage: 'MOP',
    },
    mro: {
        id: 'currency.text.mro',
        defaultMessage: 'MRO',
    },
    mur: {
        id: 'currency.text.mur',
        defaultMessage: 'MUR',
    },
    mvr: {
        id: 'currency.text.mvr',
        defaultMessage: 'MVR',
    },
    mwk: {
        id: 'currency.text.mwk',
        defaultMessage: 'MWK',
    },
    mxn: {
        id: 'currency.text.mxn',
        defaultMessage: 'MXN',
    },
    myr: {
        id: 'currency.text.myr',
        defaultMessage: 'MYR',
    },
    mzn: {
        id: 'currency.text.mzn',
        defaultMessage: 'MZN',
    },
    nad: {
        id: 'currency.text.nad',
        defaultMessage: 'NAD',
    },
    ngn: {
        id: 'currency.text.ngn',
        defaultMessage: 'NGN',
    },
    nio: {
        id: 'currency.text.nio',
        defaultMessage: 'NIO',
    },
    nok: {
        id: 'currency.text.nok',
        defaultMessage: 'NOK',
    },
    npr: {
        id: 'currency.text.npr',
        defaultMessage: 'NPR',
    },
    omr: {
        id: 'currency.text.omr',
        defaultMessage: 'OMR',
    },
    pab: {
        id: 'currency.text.pab',
        defaultMessage: 'PAB',
    },
    pen: {
        id: 'currency.text.pen',
        defaultMessage: 'PEN',
    },
    pgk: {
        id: 'currency.text.pgk',
        defaultMessage: 'PGK',
    },
    php: {
        id: 'currency.text.php',
        defaultMessage: 'PHP',
    },
    pkr: {
        id: 'currency.text.pkr',
        defaultMessage: 'PKR',
    },
    pln: {
        id: 'currency.text.pln',
        defaultMessage: 'PLN',
    },
    pyg: {
        id: 'currency.text.pyg',
        defaultMessage: 'PYG',
    },
    qar: {
        id: 'currency.text.qar',
        defaultMessage: 'QAR',
    },
    ron: {
        id: 'currency.text.ron',
        defaultMessage: 'RON',
    },
    rsd: {
        id: 'currency.text.rsd',
        defaultMessage: 'RSD',
    },
    rub: {
        id: 'currency.text.rub',
        defaultMessage: 'RUB',
    },
    rwf: {
        id: 'currency.text.rwf',
        defaultMessage: 'RWF',
    },
    sar: {
        id: 'currency.text.sar',
        defaultMessage: 'SAR',
    },
    sbd: {
        id: 'currency.text.sbd',
        defaultMessage: 'SBD',
    },
    scr: {
        id: 'currency.text.scr',
        defaultMessage: 'SCR',
    },
    sdg: {
        id: 'currency.text.sdg',
        defaultMessage: 'SDG',
    },
    sek: {
        id: 'currency.text.sek',
        defaultMessage: 'SEK',
    },
    sgd: {
        id: 'currency.text.sgd',
        defaultMessage: 'SGD',
    },
    shp: {
        id: 'currency.text.shp',
        defaultMessage: 'SHP',
    },
    sll: {
        id: 'currency.text.sll',
        defaultMessage: 'SLL',
    },
    sos: {
        id: 'currency.text.sos',
        defaultMessage: 'SOS',
    },
    srd: {
        id: 'currency.text.srd',
        defaultMessage: 'SRD',
    },
    std: {
        id: 'currency.text.std',
        defaultMessage: 'STD',
    },
    svc: {
        id: 'currency.text.svc',
        defaultMessage: 'SVC',
    },
    syp: {
        id: 'currency.text.syp',
        defaultMessage: 'SYP',
    },
    szl: {
        id: 'currency.text.szl',
        defaultMessage: 'SZL',
    },
    thb: {
        id: 'currency.text.thb',
        defaultMessage: 'THB',
    },
    tjs: {
        id: 'currency.text.tjs',
        defaultMessage: 'TJS',
    },
    tmt: {
        id: 'currency.text.tmt',
        defaultMessage: 'TMT',
    },
    tnd: {
        id: 'currency.text.tnd',
        defaultMessage: 'TND',
    },
    top: {
        id: 'currency.text.top',
        defaultMessage: 'TOP',
    },
    try: {
        id: 'currency.text.try',
        defaultMessage: 'TRY',
    },
    ttd: {
        id: 'currency.text.ttd',
        defaultMessage: 'TTD',
    },
    twd: {
        id: 'currency.text.twd',
        defaultMessage: 'TWD',
    },
    tzs: {
        id: 'currency.text.tzs',
        defaultMessage: 'TZS',
    },
    uah: {
        id: 'currency.text.uah',
        defaultMessage: 'UAH',
    },
    ugx: {
        id: 'currency.text.ugx',
        defaultMessage: 'UGX',
    },
    uyu: {
        id: 'currency.text.uyu',
        defaultMessage: 'UYU',
    },
    uzs: {
        id: 'currency.text.uzs',
        defaultMessage: 'UZS',
    },
    vef: {
        id: 'currency.text.vef',
        defaultMessage: 'VEF',
    },
    vnd: {
        id: 'currency.text.vnd',
        defaultMessage: 'VND',
    },
    vuv: {
        id: 'currency.text.vuv',
        defaultMessage: 'VUV',
    },
    wst: {
        id: 'currency.text.wst',
        defaultMessage: 'WST',
    },
    xaf: {
        id: 'currency.text.xaf',
        defaultMessage: 'XAF',
    },
    xcd: {
        id: 'currency.text.xcd',
        defaultMessage: 'XCD',
    },
    xof: {
        id: 'currency.text.xof',
        defaultMessage: 'XOF',
    },
    xpf: {
        id: 'currency.text.xpf',
        defaultMessage: 'XPF',
    },
    yer: {
        id: 'currency.text.yer',
        defaultMessage: 'YER',
    },
    zar: {
        id: 'currency.text.zar',
        defaultMessage: 'ZAR',
    },
    zmk: {
        id: 'currency.text.zmk',
        defaultMessage: 'ZMK',
    },
    zmw: {
        id: 'currency.text.zmw',
        defaultMessage: 'ZMW',
    },
    byn: {
        id: 'currency.text.byn',
        defaultMessage: 'BYN',
    },
    clf: {
        id: 'currency.text.clf',
        defaultMessage: 'CLF',
    },
    cou: {
        id: 'currency.text.cou',
        defaultMessage: 'COU',
    },
    cup: {
        id: 'currency.text.cup',
        defaultMessage: 'CUP',
    },
    ktd: {
        id: 'currency.text.ktd',
        defaultMessage: 'KTD',
    },
    mru: {
        id: 'currency.text.mru',
        defaultMessage: 'MRU',
    },
    mxv: {
        id: 'currency.text.mxv',
        defaultMessage: 'MXV',
    },
    skk: {
        id: 'currency.text.skk',
        defaultMessage: 'SKK',
    },
    sle: {
        id: 'currency.text.sle',
        defaultMessage: 'SLE',
    },
    ssp: {
        id: 'currency.text.ssp',
        defaultMessage: 'SSP',
    },
    stn: {
        id: 'currency.text.stn',
        defaultMessage: 'STN',
    },
    ves: {
        id: 'currency.text.ves',
        defaultMessage: 'VES',
    },
    zwl: {
        id: 'currency.text.zwl',
        defaultMessage: 'ZWL',
    },
    btc: {
        id: 'currency.text.btc',
        defaultMessage: 'BTC',
    },
    eth: {
        id: 'currency.text.eth',
        defaultMessage: 'ETH',
    },
    ltc: {
        id: 'currency.text.ltc',
        defaultMessage: 'LTC',
    },
    usdt: {
        id: 'currency.text.usdt',
        defaultMessage: 'USDT',
    },
    bnb: {
        id: 'currency.text.bnb',
        defaultMessage: 'BNB',
    },
    xrp: {
        id: 'currency.text.xrp',
        defaultMessage: 'XRP',
    },
    doge: {
        id: 'currency.text.doge',
        defaultMessage: 'DOGE',
    },
    ada: {
        id: 'currency.text.ada',
        defaultMessage: 'ADA',
    },
    trx: {
        id: 'currency.text.trx',
        defaultMessage: 'TRX',
    },
    bch: {
        id: 'currency.text.bch',
        defaultMessage: 'BCH',
    },
    leo: {
        id: 'currency.text.leo',
        defaultMessage: 'LEO',
    },
    xlm: {
        id: 'currency.text.xlm',
        defaultMessage: 'XLM',
    },
    link: {
        id: 'currency.text.link',
        defaultMessage: 'LINK',
    },
    xmr: {
        id: 'currency.text.xmr',
        defaultMessage: 'XMR',
    },
    etc: {
        id: 'currency.text.etc',
        defaultMessage: 'ETC',
    },
});

export const cryptoCurrencyFullTextMessages = defineMessages({
    btc: {
        id: 'currency.fullText.btc',
        defaultMessage: 'BTC Bitcoin',
    },
    eth: {
        id: 'currency.fullText.eth',
        defaultMessage: 'ETH Ethereum',
    },
    ltc: {
        id: 'currency.fullText.ltc',
        defaultMessage: 'LTC Litecoin',
    },
    usdt: {
        id: 'currency.fullText.usdt',
        defaultMessage: 'USDT Tether',
    },
    bnb: {
        id: 'currency.fullText.bnb',
        defaultMessage: 'BNB Binance Coin',
    },
    xrp: {
        id: 'currency.fullText.xrp',
        defaultMessage: 'XRP Ripple',
    },
    doge: {
        id: 'currency.fullText.doge',
        defaultMessage: 'DOGE Dogecoin',
    },
    ada: {
        id: 'currency.fullText.ada',
        defaultMessage: 'ADA Cardano',
    },
    trx: {
        id: 'currency.fullText.trx',
        defaultMessage: 'TRX Tron',
    },
    bch: {
        id: 'currency.fullText.bch',
        defaultMessage: 'BCH Bitcoin Cash',
    },
    leo: {
        id: 'currency.fullText.leo',
        defaultMessage: 'LEO UNUS SED LEO',
    },
    xlm: {
        id: 'currency.fullText.xlm',
        defaultMessage: 'XLM Stellar',
    },
    link: {
        id: 'currency.fullText.link',
        defaultMessage: 'LINK Chainlink',
    },
    xmr: {
        id: 'currency.fullText.xmr',
        defaultMessage: 'XMR Monero',
    },
    etc: {
        id: 'currency.fullText.etc',
        defaultMessage: 'ETC Ethereum Classic',
    },
});

export const currencyReferences = immutable<Reference>(
    map(
        currencyFullTextMessages,
        (message, key): Reference => ({
            id: key.toUpperCase(),
            text: intl.formatMessage(message),
        })
    )
);

export const currencyShortReferences = immutable<Reference>(
    map(
        currencyTextMessages,
        (message, key): Reference => ({
            id: key.toUpperCase(),
            text: intl.formatMessage(message),
        })
    )
);

export const cryptoCurrencyReferences = immutable<Reference>(
    map(
        cryptoCurrencyFullTextMessages,
        (message, key): Reference => ({
            id: key.toUpperCase(),
            text: intl.formatMessage(message),
        })
    )
);

export const findCurrencyNameByCode = (code: string) => {
    const normalizedCode = code.toLowerCase();

    return currencyFullTextWithoutCodeMessages[normalizedCode] ?? null;
};

export function findCurrencyByCode(code: string): Reference | undefined {
    const normalizedCode = code.toUpperCase();

    return currencyReferences.find((x) => x.id === normalizedCode);
}

export const findCryptoCurrencyByCode = (code: string): Reference | undefined => {
    const normalizedCode = code.toUpperCase();

    return cryptoCurrencyReferences.find((x) => x.id === normalizedCode);
};

export function findShortCurrencyByCode(code: string): Reference | undefined {
    const normalizedCode = code.toUpperCase();

    return currencyShortReferences.find((x) => x.id === normalizedCode);
}

export function findCurrencyShortText(code: string): string | undefined {
    const message = (currencyTextMessages as any)[code.toLowerCase()];

    return message ? intl.formatMessage(message) : undefined;
}

export function getCurrencyShortText(code: string): string {
    const result = findCurrencyShortText(code);

    if (!result) {
        Sentry.captureMessage(`Currency not found. Currency code: ${code}.`, 'warning');

        return findCurrencyShortText(defaultCurrency)!;
    }

    return result;
}

export function getCurrencyFullText(code: string): string {
    const result = findCurrencyByCode(code);

    if (!result) {
        Sentry.captureMessage(`Currency not found. Currency code: ${code}.`, 'warning');

        return getCurrencyFullText(defaultCurrency);
    }

    return result.text;
}
