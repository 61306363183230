export const twoFaApiPaths = {
    addTFAEnablingBackupCodes: 'auth/addTFAEnablingBackupCodes',
    defaultTwoFaEnforcementType: 'integration/:integrationType/default-tfa-enforcement-type',
    disableTFAWithBackupCode: 'auth/disableTFAWithBackupCode',
    disablingRequestEmailCode: 'auth/sendTFADisablingEmailCode',
    disablingVerifyAppCode: 'auth/disableTFAWithOneTimePassword',
    disablingVerifyEmailCode: 'auth/disableTFAWithEmailCode',
    enablingAppCodeVerify: 'auth/verifyTFAEnablingCode',
    enablingEmailCodeVerify: 'auth/finishTFAEnablingWithEmailCode',
    enablingEmailSet: 'auth/addTFAEnablingAlternativeEmail',
    enablingStart: 'auth/startTFAEnabling',
    finishTFAEnablingWithBackupCodes: 'auth/finishTFAEnablingWithBackupCodes',
};
