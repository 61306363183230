import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.BackupCodesAddPopup', {
    title: 'Add backup codes',
    subtitle: 'Backup codes',
    downloadButton: 'Download codes (txt file)',
    checkboxText: 'Yes, I have saved the backup codes',
    buttonDone: 'Done',
    onSuccess: 'New backup codes were generated',
});
