import { TwoFaActionWizard } from 'modules/components';
import { memo, useCallback } from 'react';

import { Step } from '../CompleteAirwallexPaymentPopup.types';
import { messages } from './TwoFaStep.messages';
import { TwoFaStepProps } from './TwoFaStep.types';

const TwoFaStep = memo<TwoFaStepProps>((props) => {
    const { payApiCall, request, setCurrentStep, onRequestClose } = props;

    const { companyId, id: requestId } = request;

    const onEnterCode = useCallback(
        async (appCode: string) => {
            setCurrentStep(Step.Processing);
            payApiCall({
                companyId,
                requestId,
                oneTimePassword: appCode,
                requestVersion: request.version,
            });
        },
        [companyId, payApiCall, request.version, requestId, setCurrentStep]
    );

    return (
        <TwoFaActionWizard
            title={messages.title}
            open
            onEnterAppCode={onEnterCode}
            onClose={onRequestClose}
            disableAutoClose
        />
    );
});

export default TwoFaStep;
