import { toast, useConfirmation } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    useCreateFido2Credential,
    useDeleteFido2Credential,
    useLazyGetFido2CredentialOptions,
    useUpdateFido2CredentialLabel,
} from 'shared/data/v2';

import { fieldNamePasskeyForm } from './PasskeyItem.constants';
import {
    createPublicKey,
    generatePasskeyLabel,
    getAttestationResponseTransfer,
    mapErrorTextsToHumanReadable,
} from './PasskeyItem.helpers';
import { messages } from './PasskeyItem.messages';
import { PasskeyItemFormState, PasskeyItemProps } from './PasskeyItem.types';

export const usePasskeyItemForm = (props: PasskeyItemProps) => {
    const { credential, onChange, onCancel, onDelete } = props;

    const [isEditable, setIsEditable] = useState(!credential);

    const { confirmation } = useConfirmation({
        confirmMessage: messages.areYouSure,
        confirmButtonMessage: messages.yes,
    });

    const form = useForm<PasskeyItemFormState>({
        values: {
            [fieldNamePasskeyForm.label]: credential?.label ?? generatePasskeyLabel() ?? '',
            [fieldNamePasskeyForm.newKey]: 'none',
        },
    });

    const { deleteCredential, isDeletingCredential } = useDeleteCredentials({
        onSuccess: onDelete,
    });

    const { updateCredential, isUpdatingCredential } = useUpdateCredentials({
        onSuccess: () => {
            onChange();
            setIsEditable(false);
        },
    });

    const { createCredential, isCreatingCredential } = useCreateCredentials({
        onSuccess: () => {
            setIsEditable(false);
            onCancel?.();

            form.reset();
        },
    });

    const handleSubmitForm = form.handleSubmit((data) => {
        if (credential?.credentialId === undefined) {
            void createCredential(data[fieldNamePasskeyForm.label]);
        } else {
            updateCredential({
                body: {
                    credentialId: credential.credentialId,
                    newLabel: data[fieldNamePasskeyForm.label],
                },
            });
        }
    });

    const handleEdit: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
        event.preventDefault();

        setIsEditable(true);
    }, []);

    const handleEditCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            event.preventDefault();

            setIsEditable(false);
            onCancel?.();
        },
        [onCancel]
    );

    const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            event.preventDefault();

            confirmation()
                .then(() => {
                    credential?.credentialId && deleteCredential({ body: { credentialId: credential.credentialId } });
                })
                .catch(() => {});
        },
        [deleteCredential, credential?.credentialId, confirmation]
    );

    return {
        form,
        handleSubmitForm,
        handleEdit,
        handleEditCancel,
        handleDelete,
        isEditable,
        isDeletingCredential,
        isUpdatingCredential,
        isCreatingCredential,
    };
};

const useUpdateCredentials = (options?: { onSuccess?: VoidFunction }) => {
    const { mutate, isLoading } = useUpdateFido2CredentialLabel({
        onSuccess: options?.onSuccess,
    });

    return {
        updateCredential: mutate,
        isUpdatingCredential: isLoading,
    };
};

const useDeleteCredentials = (options?: { onSuccess?: VoidFunction }) => {
    const { mutate, isLoading } = useDeleteFido2Credential({
        onSuccess: options?.onSuccess,
    });

    return {
        deleteCredential: mutate,
        isDeletingCredential: isLoading,
    };
};

export const useCreateCredentials = (options?: { onSuccess?: VoidFunction }) => {
    const [isCreatingCredential, setIsCreatingCredential] = useState(false);

    const [triggerGetCredentialOptions] = useLazyGetFido2CredentialOptions();

    const { mutate: create } = useCreateFido2Credential({
        onSuccess: options?.onSuccess,
    });

    const createCredential = useCallback(
        async (label: string) => {
            setIsCreatingCredential(true);

            try {
                const credentialOptions = await triggerGetCredentialOptions({});
                const credential = await createPublicKey(credentialOptions);

                const attestationResponse = getAttestationResponseTransfer(credential, credentialOptions);

                create({ body: { label, attestationResponse } });
            } catch (err) {
                if (!errorHelpers.isApiError(err)) {
                    /**
                     * Do not remove this line coz it writes errors
                     * which are generated by Passkeys, and it helps during a debugg    ing
                     */
                    console.error('[Credentials create error]', err);

                    const errorText = mapErrorTextsToHumanReadable(err.message);

                    toast.error(errorText);
                }
            }

            setIsCreatingCredential(false);
        },
        [triggerGetCredentialOptions, create]
    );

    return {
        isCreatingCredential,
        createCredential,
    };
};
