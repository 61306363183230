import { CertificateIcon, DownloadIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Grid, Text } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo } from 'react';

import { useSectionCertification } from './SectionCertification.hooks';
import { messages } from './SectionCertification.messages';
import { SectionCertificationProps } from './SectionCertification.types';

export const SectionCertification = memo<SectionCertificationProps>((props) => {
    const { profile } = props;

    const { handleDownload } = useSectionCertification(props);

    if (!profile.lastCertification) {
        return null;
    }

    return (
        <Flex direction='column' spacing='24'>
            <Text font='headline' fontSize='small' fontWeight='medium'>
                {messages.title}
            </Text>

            <Box color='midnight20' spacing='24' radius='small'>
                <Flex direction='column' spacing='8'>
                    <Grid
                        gridTemplateColumns='max-content auto max-content'
                        gap={12}
                        alignItems='center'
                        minHeight={28}
                    >
                        <CertificateIcon size={20} />

                        <Text color='black100' font='label' fontSize='medium'>
                            {messages.description}
                        </Text>

                        <Flex spacing='16'>
                            <Button
                                color='transparent'
                                onClick={handleDownload}
                                noPadding
                                startIcon={<DownloadIcon size={16} />}
                            >
                                {messages.buttonDownload}
                            </Button>
                        </Flex>
                    </Grid>

                    <Text color='midnight60' font='body' fontSize='small' fontWeight='regular'>
                        {moment(profile.lastCertification.date).format('ll')}
                    </Text>
                </Flex>
            </Box>
        </Flex>
    );
});

SectionCertification.displayName = 'SectionCertification';
