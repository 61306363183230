import { Button, Field, PasswordEditor, TextButton, validators } from '@approvalmax/ui';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import { changePassword } from '../../../../actions';
import { useProfileContext } from '../../ProfileContext';
import { messages } from './SetPasswordSection.messages';
import {
    Actions,
    ActionsText,
    CollapsedSection,
    ExpandedSection,
    Fields,
    ValidationSection,
} from './SetPasswordSection.styles';

const qa = bemFactory.qa('profile-popup-change-password-section');

/**
 * @deprecated Redesigned
 */
const SetPasswordSection: FC = () => {
    const dispatch = useDispatch();
    const {
        profile: { isPasswordSet },
    } = useProfileContext();
    const [expanded, setExpanded] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const disableChangePassword = (isPasswordSet && !oldPassword) || Boolean(validators.password(newPassword));

    const handleExpandSection = () => {
        setExpanded(true);
        setOldPassword('');
        setNewPassword('');
    };

    const handleCollapseSection = () => {
        setExpanded(false);
    };

    const handleSetPassword = () => {
        dispatch(changePassword(isPasswordSet, newPassword, oldPassword));
        setExpanded(false);
    };

    return (
        <>
            {!expanded ? (
                <CollapsedSection>
                    <TextButton qa='change-password-button' execute={handleExpandSection}>
                        {messages.setNewPassword}
                    </TextButton>
                </CollapsedSection>
            ) : (
                <ExpandedSection>
                    <Actions>
                        <ActionsText>{messages.setNewPassword}</ActionsText>

                        <TextButton
                            qa={qa('cancel-change-password-button')}
                            colorTheme='red'
                            execute={handleCollapseSection}
                        >
                            {messages.cancel}
                        </TextButton>

                        <Button
                            qa={qa('do-change-password-button')}
                            execute={handleSetPassword}
                            disabled={disableChangePassword}
                        >
                            {messages.setNewPassword}
                        </Button>
                    </Actions>

                    <Fields>
                        {isPasswordSet && (
                            <Field title={messages.oldPassword} required>
                                <PasswordEditor
                                    qa={qa('old-password-editor')}
                                    value={oldPassword}
                                    onChange={setOldPassword}
                                    focusOnMount
                                />
                            </Field>
                        )}

                        <Field title={messages.newPassword} required>
                            <PasswordEditor
                                qa={qa('new-password-editor')}
                                value={newPassword}
                                onChange={setNewPassword}
                                autoComplete='new-password'
                            />
                        </Field>
                    </Fields>

                    <ValidationSection password={newPassword} highlightInvalidCriteria={Boolean(newPassword)} />
                </ExpandedSection>
            )}
        </>
    );
};

export default memo(SetPasswordSection);
