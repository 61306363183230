import { theme } from '@approvalmax/theme';
import { Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { useMedia } from 'react-use';
import { TwoFaEnablingWizard, useTwoFaWizardController } from 'shared/components';

import { Actions } from '../Actions/Actions';
import { WizardProps } from './Wizard.types';

export const Wizard = memo<WizardProps>((props) => {
    const { onClose, onFinish } = props;

    const { wizardController } = useTwoFaWizardController();

    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    return (
        <Popup open onClose={onClose} size={isMobileScreen ? 'small' : 'medium'} closable>
            <Popup.Header title={wizardController.title} actions={<Actions />} />

            <Popup.Body>
                <TwoFaEnablingWizard onFinish={onFinish} />
            </Popup.Body>
        </Popup>
    );
});

Wizard.displayName = 'Wizard';
