import { Form, Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { useBackupEmailUpdateForm } from './BackupEmailUpdatePopup.hooks';
import { BackupEmailUpdatePopupProps } from './BackupEmailUpdatePopup.types';
import { StepEmailConfirm } from './components/StepEmailConfirm/StepEmailConfirm';
import { StepEmailEnter } from './components/StepEmailEnter/StepEmailEnter';

export const BackupEmailUpdatePopup = memo<BackupEmailUpdatePopupProps>((props) => {
    const { onClose, title } = props;

    const { form, formSubmit, stage, progress, resendCode, gotoEmailEnter } = useBackupEmailUpdateForm(props);

    return (
        <Popup open onClose={onClose} size='medium'>
            <Popup.Header title={title} />

            <Popup.Body>
                <Form form={form} onSubmit={formSubmit}>
                    {stage === 'emailEnter' && <StepEmailEnter progress={progress} />}

                    {stage === 'emailVerify' && (
                        <StepEmailConfirm progress={progress} onPrev={gotoEmailEnter} onResendCode={resendCode} />
                    )}
                </Form>
            </Popup.Body>
        </Popup>
    );
});

BackupEmailUpdatePopup.displayName = 'BackupEmailUpdatePopup';
