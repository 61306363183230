import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { WizardProps } from './components/Wizard/Wizard.types';
import { twoFaSoftEnforcementState } from './TwoFaSoftEnforcement.states';
import { TwoFaSoftEnforcementFinishHandler } from './TwoFaSoftEnforcement.types';

export const useTwoFaSoftEnforcement = () => {
    const [popup, setPopup] = useRecoilState(twoFaSoftEnforcementState);

    const handleOpen = useCallback(
        (options: { onFinish?: TwoFaSoftEnforcementFinishHandler }) => {
            setPopup((state) => ({
                ...state,
                stage: 'welcome',
                selectedBackupMethod: null,
                onFinish: options.onFinish,
            }));
        },
        [setPopup]
    );

    const handleClose = useCallback(() => {
        setPopup(null);
    }, [setPopup]);

    return {
        handleOpen,
        handleClose,
        stage: popup?.stage ?? null,
    };
};

export const useCheckTwoFaSoftEnforcementIsOpen = () => {
    const popup = useRecoilValue(twoFaSoftEnforcementState);

    return popup !== null;
};

export const useGoToTwoFaSoftEnforcementStage = () => {
    const [popup, setPopup] = useRecoilState(twoFaSoftEnforcementState);

    const onFinish = popup?.onFinish;
    const selectedBackupMethod = popup?.selectedBackupMethod ?? null;

    const gotoWelcome = useCallback(() => {
        setPopup((state) => ({ ...state, stage: 'welcome', selectedBackupMethod: null }));
    }, [setPopup]);

    const gotoWizard = useCallback(() => {
        setPopup((state) => ({ ...state, stage: 'wizard', selectedBackupMethod: null }));
    }, [setPopup]);

    const gotoSuccess: WizardProps['onFinish'] = useCallback(
        (selectedBackupMethod) => {
            setPopup((state) => ({ ...state, stage: 'success', selectedBackupMethod }));
        },
        [setPopup]
    );

    const handleFinish = useCallback(() => {
        onFinish?.(selectedBackupMethod);
        setPopup(null);
    }, [onFinish, selectedBackupMethod, setPopup]);

    return {
        gotoWelcome,
        gotoWizard,
        gotoSuccess,
        handleFinish,
    };
};
