import { Box, Flex, Form, Spacing, Switch, Text } from '@approvalmax/ui/src/components';
import { FC } from 'react';

import { PropertyRowWithSwitchProps } from './PropertyRowWithSwitch.types';

export const PropertyRowWithSwitch: FC<PropertyRowWithSwitchProps> = (props) => {
    const { name, title, label, children } = props;

    return (
        <Form.Part>
            <Box spacing='16 0'>
                <Flex height='100%' justifyContent='space-between' alignItems='center' wrap={false}>
                    <Text font='label' fontSize='medium' fontWeight='medium'>
                        {title}
                    </Text>

                    <Flex alignItems='center' shrink={0} maxWidth='30%' wrap={false} overflow='hidden'>
                        {label && (
                            <Text font='label' fontSize='medium' fontWeight='regular' color='midnight80' ellipsis={1}>
                                {label}
                            </Text>
                        )}

                        <Switch name={name} />
                    </Flex>
                </Flex>

                {children && (
                    <>
                        <Spacing height={16} />

                        {children}
                    </>
                )}
            </Box>
        </Form.Part>
    );
};

PropertyRowWithSwitch.displayName = 'PropertyRowWithSwitch';
