import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { FinishAddingTwoFaBackupCodes } from './useFinishAddingTwoFaBackupCodes.types';

export const useFinishAddingTwoFaBackupCodes = (
    mutationOptions?: RequestMutationOptions<FinishAddingTwoFaBackupCodes>
) => {
    return useMutate(authApiPaths.finishAddingTwoFaBackupCodes, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
