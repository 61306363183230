import { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import { PasswordField } from '../../PasswordField';
import { ControllerProps } from './Controller.types';

/**
 * The `PasswordField.Controller` allows you to use the `PasswordField` with the `react-hook-form` library.
 * They take the same properties as the `PasswordField` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
export const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, defaultValue, name, rules, ...restProps } = props;

        const { field, fieldState } = useController({
            control,
            name: name || 'name',
            rules: { required: restProps.required },
            defaultValue,
        });

        return (
            <PasswordField
                {...field}
                {...fieldState}
                {...restProps}
                ref={ref}
                hint={(fieldState.error?.message || restProps.hint) ?? <span>&nbsp;</span>}
            />
        );
    })
);

Controller.displayName = 'Controller';
