import { useMemo } from 'react';
import { useToggle } from 'react-use';

import { messages } from './BackupMethodsList.messages';
import { BackupMethodData, BackupMethodsListProps } from './BackupMethodsList.types';

export const useBackupMethodsList = (props: BackupMethodsListProps) => {
    const { profile } = props;

    const [isBackupEmailOpened, toggleIsBackupEmailOpened] = useToggle(false);
    const [isAddBackupCodesOpened, toggleIsAddBackupCodesOpened] = useToggle(false);
    const [isUpdateBackupCodesOpened, toggleIsUpdateBackupCodesOpened] = useToggle(false);

    const backupCodes = useMemo<BackupMethodData>(
        () => ({
            color: profile.isTFABackupCodesEnabled ? 'midnight100' : 'midnight70',
            button: profile.isTFABackupCodesEnabled ? messages.buttonEdit : messages.buttonAdd,
            onClick: () => {
                profile.isTFABackupCodesEnabled
                    ? toggleIsUpdateBackupCodesOpened(true)
                    : toggleIsAddBackupCodesOpened(true);
            },
        }),
        [profile.isTFABackupCodesEnabled, toggleIsAddBackupCodesOpened, toggleIsUpdateBackupCodesOpened]
    );

    const backupEmail = useMemo<BackupMethodData>(
        () => ({
            color: profile.isTFAAlternativeEmailEnabled ? 'midnight100' : 'midnight70',
            button: profile.isTFAAlternativeEmailEnabled ? messages.buttonEdit : messages.buttonAdd,
            onClick: () => {
                toggleIsBackupEmailOpened(true);
            },
        }),
        [profile.isTFAAlternativeEmailEnabled, toggleIsBackupEmailOpened]
    );

    return {
        backupCodes,
        backupEmail,
        isBackupEmailOpened,
        isAddBackupCodesOpened,
        isUpdateBackupCodesOpened,
        toggleIsBackupEmailOpened,
        toggleIsAddBackupCodesOpened,
        toggleIsUpdateBackupCodesOpened,
    };
};
