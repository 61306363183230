import { zodResolver } from '@hookform/resolvers/zod';
import { selectors } from 'modules/common';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useUpdateProfileForm } from '../../hooks/useUpdateProfileForm';
import { fieldNameNotificationsSection } from './SectionNotifications.constants';
import { schemaSectionNotifications } from './SectionNotifications.schema';

export const useSectionNotificationsForm = (profile: selectors.types.ExpandedProfile) => {
    const form = useForm<z.infer<typeof schemaSectionNotifications>>({
        mode: 'onChange',
        values: {
            [fieldNameNotificationsSection.newRequests]: profile.sendNewRequestsNotifications ?? true,
            [fieldNameNotificationsSection.comments]: profile.sendCommentsNotifications ?? true,
            [fieldNameNotificationsSection.other]: profile.sendApprovalChangeNotifications ?? true,
            [fieldNameNotificationsSection.workflow]: profile.sendWorkflowNotifications ?? true,
            [fieldNameNotificationsSection.summary]: profile.sendSummaryNotifications ?? false,
            [fieldNameNotificationsSection.summaryDay]: profile.summaryNotificationsSendOnDays ?? [],
            [fieldNameNotificationsSection.summaryTime]: profile.summaryNotificationsSendAtTime ?? '',
        },
        resolver: zodResolver(schemaSectionNotifications),
    });

    useUpdateProfileForm('notifications', form);

    return {
        form,
    };
};
