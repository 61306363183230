import { domain } from 'modules/data';
import { atom, selector } from 'recoil';

type CompanyBetaFeatureStatus = {
    [K in domain.CompanyBetaFeature as `is${Capitalize<K>}`]: boolean;
};

export const companiesBetaFeaturesState = atom<Map<string, domain.CompanyBetaFeature[]>>({
    key: 'companiesBetaFeaturesState',
    default: new Map(),
});

/**
 * @deprecated Direct usage is prohibited. Please use a {@link useCompanyBetaFeatures} hook instead
 */
export const companyBetaFeaturesState = selector({
    key: 'companyBetaFeaturesState',
    get: ({ get }) => {
        const companiesBetaFeatures = get(companiesBetaFeaturesState);

        return (companyId: string | undefined) => {
            const companyBetaFeatures = companyId ? companiesBetaFeatures.get(companyId) : [];

            // Do not move `allCompanyBetaFeatures` or `initialStatus` to the global scope.
            // Otherwise, errors occur in tests or runtime.
            const allCompanyBetaFeatures = Object.values(domain.CompanyBetaFeature);
            const initialStatus = Object.fromEntries(allCompanyBetaFeatures.map((feature) => [`is${feature}`, false]));

            companyBetaFeatures?.forEach((feature) => {
                const key = `is${feature}`;

                if (key in initialStatus) {
                    initialStatus[key] = true;
                }
            });

            return initialStatus as CompanyBetaFeatureStatus;
        };
    },
});
