import { theme } from '@approvalmax/theme';
import { Button, Flex, Link, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { useMedia } from 'react-use';
import { useGetTwoFaEnforcementInformation } from 'shared/data/v1';

import { messages } from './Welcome.messages';
import { WelcomeSkeleton } from './Welcome.skeleton';
import { WelcomeProps } from './Welcome.types';

export const Welcome = memo<WelcomeProps>((props) => {
    const { onStart, onClose } = props;

    const { data, isLoading } = useGetTwoFaEnforcementInformation();
    const isDefaultEnforcement = Boolean(data?.tfaEnforcementIntegrationTypes.length);

    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    if (isLoading) {
        return;
    }

    return (
        <Popup open onToggle={onClose} size={isMobileScreen ? 'small' : 'medium'} closable>
            <Popup.Header title={messages.title} />

            <Popup.Body>
                {isLoading ? (
                    <WelcomeSkeleton />
                ) : (
                    <Flex direction='column'>
                        <Text font='body' fontSize='large' fontWeight='regular'>
                            {isDefaultEnforcement
                                ? messages.descriptionAdministratorEnforcement
                                : messages.descriptionCompanyEnforcement}
                        </Text>

                        <Text font='body' fontSize='large' fontWeight='regular'>
                            {messages.moreInformation({
                                supportlink: (chunks) => (
                                    <Link
                                        href='https://support.approvalmax.com/portal/en/newticket'
                                        font='body'
                                        fontSize='large'
                                        fontWeight='regular'
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            })}
                        </Text>

                        <Spacing height={8} />

                        <Button onClick={onStart} color='blue80' size='large'>
                            {messages.button}
                        </Button>
                    </Flex>
                )}
            </Popup.Body>
        </Popup>
    );
});

Welcome.displayName = 'Welcome';
