import { domHelpers } from '@approvalmax/utils';

import Checkbox from '../../../Checkbox/Checkbox';
import { checkboxColumnId, typedMemo } from '../../Table.helpers';
import { BaseItem, TableCheckType } from '../../Table.types';
import { ColumnHeader } from '..';
import { HeaderCell } from '../HeaderCell/HeaderCell';
import { checkboxColumnDefinition } from './HeaderRow.constants';
import { useCheckedColumn } from './HeaderRow.hooks';
import { DragHandler, DragHandlerTh, Root } from './HeaderRow.styles';
import { HeaderRowProps } from './HeaderRow.types';

export const HeaderRow = typedMemo(<Item extends BaseItem>(props: HeaderRowProps<Item>) => {
    const {
        columns,
        getRowId,
        checkedItems,
        onCheckedItemsChange,
        bordered,
        hideHeaderBottomBorder,
        data,
        onSort,
        headerColor,
        stickyHeader,
        checkboxColumnCover = checkboxColumnDefinition,
        isReorderableColumns,
        isReorderableRows,
        checkType,
        qa,
        ...otherProps
    } = props;

    const { handleColumnCheck } = useCheckedColumn(data, checkedItems, getRowId, onCheckedItemsChange);

    return (
        <Root
            $bordered={bordered}
            $hideHeaderBottomBorder={hideHeaderBottomBorder}
            data-qa={domHelpers.generateDataQa(qa, 'table-header')}
            {...otherProps}
        >
            {isReorderableRows && (
                <DragHandlerTh data-column-id='row-drag-handler'>
                    <DragHandler $hide={!isReorderableRows} />
                </DragHandlerTh>
            )}

            {checkedItems && (
                <HeaderCell
                    data-column-id='checked-items'
                    key={checkboxColumnId}
                    id={checkboxColumnCover.id}
                    columnDefinition={checkboxColumnCover}
                    color={headerColor}
                    bordered={bordered}
                    stickyHeader={stickyHeader}
                    hideHeaderBottomBorder={hideHeaderBottomBorder}
                >
                    <ColumnHeader
                        columnDefinition={checkboxColumnCover}
                        color={headerColor}
                        bordered={bordered}
                        onSort={onSort}
                    >
                        {checkType === TableCheckType.checkbox && (
                            <Checkbox
                                size='small'
                                indeterminate={(checkedItems?.length ?? 0) < data.length}
                                checked={(checkedItems?.length ?? 0) > 0}
                                onChange={handleColumnCheck}
                            />
                        )}
                    </ColumnHeader>
                </HeaderCell>
            )}

            {columns.map((columnDefinition) => {
                const ChildrenComponent = columnDefinition.columnComponent || ColumnHeader;

                return (
                    <HeaderCell
                        id={columnDefinition.id}
                        columnDefinition={columnDefinition}
                        color={headerColor}
                        onSort={onSort}
                        reorderable={isReorderableColumns}
                        bordered={bordered}
                        stickyHeader={stickyHeader}
                        hideHeaderBottomBorder={hideHeaderBottomBorder}
                        key={columnDefinition.id}
                    >
                        <ChildrenComponent
                            columnDefinition={columnDefinition}
                            color={headerColor}
                            bordered={bordered}
                            onSort={onSort}
                            reorderable={isReorderableColumns}
                        />
                    </HeaderCell>
                );
            })}
        </Root>
    );
});
