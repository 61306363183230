import { Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { BackupCodesUpdatePopupProps } from './BackupCodesUpdatePopup.types';
import { BackupCodesUpdatePopupContent } from './BackupCodesUpdatePopupContent';

export const BackupCodesUpdatePopup = memo<BackupCodesUpdatePopupProps>((props) => {
    const { open, onClose } = props;

    return (
        <Popup open={open} onClose={onClose} size='medium'>
            <BackupCodesUpdatePopupContent {...props} />
        </Popup>
    );
});

BackupCodesUpdatePopup.displayName = 'BackupCodesUpdatePopup';
