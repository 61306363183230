import { Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers, intl } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { domain, State } from 'modules/data';
import createCachedSelector from 're-reselect';

import { getMatrixDefinition } from '../config/matrixDefinitions';
import { MatrixDefinition } from '../config/matrixDefinitions.shared';
import { MatrixType } from '../types/matrix';
import { ExpandedTemplateUser } from '../types/selectors';
import { mapExactValuesForRequesterSystemPurpose } from '../utils/helpers';
import { messages } from './stepSelectors.messages';

const qbooksPayeeFields = [
    domain.FieldSystemPurpose.QBooksPayeeCustomer,
    domain.FieldSystemPurpose.QBooksPayeeEmployee,
    domain.FieldSystemPurpose.QBooksPayeeVendor,
];

const getExactValueText = (value: Reference | domain.ExactValuesGroup) => {
    if ('text' in value) {
        return intl.formatMessage(messages.participant_rules_preview_field_value_pattern, {
            value: value.text,
        });
    }

    return intl.formatMessage(messages.participant_rules_preview_field_group_value_pattern, {
        name: value.name,
        versionNumber: value.versionNumber,
    });
};

function getRulesPreviewText(params: {
    integrationCode: domain.IntegrationCode | null;
    company: domain.Company;
    fields: domain.Field[];
    rules: domain.MatrixRule[];
    users: selectors.types.ExpandedUser[];
    matrixDefinition?: MatrixDefinition;
    matrixType?: MatrixType.Approval | MatrixType.Reviewer;
}) {
    const {
        integrationCode,
        company,
        fields,
        rules,
        users,
        matrixDefinition,
        matrixType = MatrixType.Approval,
    } = params;

    const currency = company.defaultCurrency || statics.currency.defaultCurrency;

    let conditionText = (c: domain.MatrixCondition) => {
        const isNetSuiteCustomField = c.fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteCustom;

        if (
            c.fieldSystemPurpose === domain.FieldSystemPurpose.Amount ||
            (isNetSuiteCustomField && c.conditionType === domain.ConditionType.NumericRangeCondition)
        ) {
            const amountCondition = c as domain.NumericRangeCondition;
            const less = intl.formatNumber(amountCondition.numericRangeLess);
            const greaterEq = intl.formatNumber(amountCondition.numericRangeGreaterEquals);

            const currencyText = isNetSuiteCustomField ? '' : ` ${currency}`;

            let amountValueText;

            switch (amountCondition.numericRangeConditionType) {
                case domain.NumericRangeConditionType.Above:
                    amountValueText = intl.formatMessage(messages.participant_rules_preview_amount_pattern_above, {
                        greaterEq,
                        currency: currencyText,
                    });
                    break;

                case domain.NumericRangeConditionType.Below:
                    amountValueText = intl.formatMessage(messages.participant_rules_preview_amount_pattern_below, {
                        less,
                        currency: currencyText,
                    });
                    break;

                case domain.NumericRangeConditionType.Within:
                    amountValueText = intl.formatMessage(messages.participant_rules_preview_amount_pattern_within, {
                        less,
                        greaterEq,
                        currency: currencyText,
                    });
                    break;

                case domain.NumericRangeConditionType.Any:
                    throw errorHelpers.invalidOperationError('Numeric type cannot be ANY.');

                default:
                    throw errorHelpers.assertNever(amountCondition.numericRangeConditionType);
            }

            const templateHasAmountType = integrationCode !== null && !isNetSuiteCustomField;

            if (templateHasAmountType) {
                return intl.formatMessage(
                    (messages as any)[
                        `participant_rules_preview_amount_pattern${
                            amountCondition.amountType === domain.AmountType.Net ? '_net' : '_gross'
                        }`
                    ],
                    {
                        amount: amountValueText,
                    }
                );
            } else {
                return isNetSuiteCustomField
                    ? intl.formatMessage(messages.participantRulesPreviewNumberFieldPattern, {
                          fieldName: c.fieldName,
                          amount: amountValueText,
                      })
                    : intl.formatMessage(messages.participant_rules_preview_amount_pattern, {
                          amount: amountValueText,
                      });
            }
        }

        if (c.conditionType === domain.ConditionType.BoolCondition) {
            if (c.exactConstraintBool === null) {
                return '';
            }

            const field = fields.find((x) => x.id === c.fieldId);
            const fieldName = selectors.field.getFieldNameBySystemPurpose(
                c.fieldSystemPurpose,
                integrationCode,
                field?.name ?? c.fieldName
            );

            return c.exactConstraintBool
                ? intl.formatMessage(messages.participantBooleanRuleEnabled, {
                      fieldName,
                  })
                : intl.formatMessage(messages.participantBooleanRuleDisabled, {
                      fieldName,
                  });
        }

        const isNegativeExactConstraint = c.conditionType === domain.ConditionType.NegativeExactValuesCondition;

        const exactValues: Reference[] =
            c.conditionType === domain.ConditionType.NegativeExactValuesCondition ||
            c.conditionType === domain.ConditionType.ExactValuesCondition
                ? c.exactValues || []
                : [];
        const groupOfXeroAccounts =
            c.conditionType === domain.ConditionType.NegativeExactValuesCondition ||
            c.conditionType === domain.ConditionType.ExactValuesCondition
                ? c.groupOfXeroAccounts || []
                : [];

        let values = [...exactValues, ...groupOfXeroAccounts];

        if (c.fieldSystemPurpose === domain.FieldSystemPurpose.Requester) {
            values = mapExactValuesForRequesterSystemPurpose(exactValues, users);
        }

        let valuesText: string;

        if (values.length === 0) {
            return '';
        }

        if (values.length < 3) {
            valuesText = values.map(getExactValueText).join(', ');
        } else {
            const v1 = getExactValueText(values[0]);
            const v2 = getExactValueText(values[1]);

            valuesText = `${v1}, ${v2} ...`;
        }

        const field = fields.find((x) => x.id === c.fieldId);
        const fieldName = selectors.field.getFieldNameBySystemPurpose(
            c.fieldSystemPurpose,
            integrationCode,
            field?.name ?? c.fieldName
        );

        return isNegativeExactConstraint
            ? intl.formatMessage(messages.participant_rules_preview_not_field_pattern, {
                  fieldName,
                  values: valuesText,
              })
            : intl.formatMessage(messages.participant_rules_preview_field_pattern, {
                  fieldName,
                  values: valuesText,
              });
    };

    if (!rules || !rules.length) {
        switch (matrixType) {
            case MatrixType.Approval:
                return intl.formatMessage(messages.participant_rules_preview_always_approve);

            case MatrixType.Reviewer:
                return intl.formatMessage(messages.participant_rules_preview_always_review);
        }
    }

    let res = '';

    rules.forEach((rule) => {
        let rtext: string | null = null;

        const conditionList = sortConditions(rule.conditions, matrixDefinition);

        conditionList.forEach((condition, index) => {
            let ctext = conditionText(condition);

            let connectionMessage = messages.participant_rules_preview_and_text;

            if (
                integrationCode === domain.IntegrationCode.QBooksExpense ||
                integrationCode === domain.IntegrationCode.QBooksJournalEntry
            ) {
                const prevCondition = index > 0 ? conditionList[index - 1] : undefined;

                if (
                    prevCondition &&
                    qbooksPayeeFields.includes(condition.fieldSystemPurpose) &&
                    qbooksPayeeFields.includes(prevCondition.fieldSystemPurpose)
                ) {
                    connectionMessage = messages.participant_rules_preview_or_text;
                }
            }

            if (ctext) {
                rtext = rtext
                    ? intl.formatMessage(connectionMessage, {
                          left: rtext,
                          right: ctext,
                      })
                    : ctext;
            }
        });

        if (rtext) {
            res = res
                ? intl.formatMessage(messages.participant_rules_preview_or_text, {
                      left: res,
                      right: rtext,
                  })
                : rtext;
        }
    });

    if (matrixType === MatrixType.Approval) {
        res = res
            ? intl.formatMessage(messages.participant_rules_preview_approves_when, {
                  rules: res,
              })
            : intl.formatMessage(messages.participant_rules_preview_always_approve);
    }

    if (matrixType === MatrixType.Reviewer) {
        res = res
            ? intl.formatMessage(messages.participant_rules_preview_review_when, {
                  rules: res,
              })
            : intl.formatMessage(messages.participant_rules_preview_always_review);
    }

    return res;
}

function getStepEditorRulesPreviewText(
    integrationCode: domain.IntegrationCode | null,
    rules: domain.MatrixRule[],
    users: selectors.types.ExpandedUser[],
    matrixDefinition?: MatrixDefinition
) {
    let conditionText = (c: domain.MatrixCondition) => {
        const isNegativeExactConstraint = c.conditionType === domain.ConditionType.NegativeExactValuesCondition;

        let values: Reference[] = (c as any).exactValues || [];
        let valuesText: string;

        if (c.fieldSystemPurpose === domain.FieldSystemPurpose.Requester) {
            values = mapExactValuesForRequesterSystemPurpose(values, users);
        }

        if (values.length <= 2) {
            valuesText = values.map((fcv) => `"${fcv.text}"`).join(', ');
        } else {
            valuesText = `"${values[0].text}", "${values[1].text}" ...`;
        }

        return isNegativeExactConstraint
            ? intl.formatMessage(messages.editor_rules_preview_not_field_pattern, {
                  fieldName: c.fieldName,
                  values: valuesText,
              })
            : intl.formatMessage(messages.editor_rules_preview_field_pattern, {
                  fieldName: c.fieldName,
                  values: valuesText,
              });
    };

    if (!rules || rules.length === 0) {
        return intl.formatMessage(messages.editor_rules_preview_always_approve);
    }

    let res = '';

    rules.forEach((rule) => {
        let rtext: string | null = null;

        const conditionList = sortConditions(rule.conditions, matrixDefinition);

        conditionList.forEach((condition, index) => {
            let ctext = conditionText(condition);

            let connectionMessage = messages.participant_rules_preview_and_text;

            if (integrationCode === domain.IntegrationCode.QBooksExpense) {
                const prevCondition = index > 0 ? conditionList[index - 1] : undefined;

                if (
                    prevCondition &&
                    qbooksPayeeFields.includes(condition.fieldSystemPurpose) &&
                    qbooksPayeeFields.includes(prevCondition.fieldSystemPurpose)
                ) {
                    connectionMessage = messages.participant_rules_preview_or_text;
                }
            }

            if (ctext) {
                rtext = rtext
                    ? intl.formatMessage(connectionMessage, {
                          left: rtext,
                          right: ctext,
                      })
                    : ctext;
            }
        });

        if (rtext) {
            res = res
                ? intl.formatMessage(messages.editor_rules_preview_or_text, {
                      left: res,
                      right: rtext,
                  })
                : rtext;
        }
    });

    res = res
        ? intl.formatMessage(messages.editor_rules_preview_can_set, {
              rules: res,
          })
        : intl.formatMessage(messages.editor_rules_preview_always_approve);

    return res;
}

export const getStepApprovers: (
    state: State,
    template: domain.Template,
    step: domain.TemplateStep
) => ExpandedTemplateUser[] = createCachedSelector(
    (state: State, template: domain.Template) => template.integrationCode,
    selectors.user.getUsers,
    (state: State, template: domain.Template) => selectors.company.getCompanyById(state, template.companyId),
    (state: State, template: domain.Template) => selectors.field.getFieldsByCompanyId(state, template.companyId),
    (state: State, template: domain.Template, step: domain.TemplateStep) => step.participantMatrix,
    (integrationCode, users, company, fields, participantMatrix) => {
        const matrixDefinition = getMatrixDefinition({
            integrationCode,
            betaFeatures: company.betaFeatures,
            licenseFeatures: company.licenseFeatures,
            matrixType: MatrixType.Approval,
        });

        return arrayHelpers.arraySort(
            participantMatrix
                .filter((x) => !x.isBackup)
                .map((x) => {
                    const user = users.find((u) => u.id === x.lineId);

                    if (!user) {
                        throw errorHelpers.notFoundError();
                    }

                    return {
                        ...user,
                        rulesPreviewText: getRulesPreviewText({
                            integrationCode,
                            company,
                            fields,
                            rules: x.rules,
                            users,
                            matrixDefinition,
                        }),
                    };
                }),
            (u1, u2) => compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
)((state, template, stepIndex) => template.id + stepIndex);

export const getReviewers: (state: State, template: domain.Template) => ExpandedTemplateUser[] = createCachedSelector(
    (state: State, template: domain.Template) => template.reviewStep.reviewers,
    (state: State, template: domain.Template) => selectors.company.getCompanyById(state, template.companyId),
    (state: State, template: domain.Template) => template.integrationCode,
    selectors.user.getUsers,
    (state: State, template: domain.Template) => selectors.field.getFieldsByCompanyId(state, template.companyId),
    (reviewers: domain.MatrixLine[], company, integrationCode, users: selectors.types.ExpandedUser[], fields) => {
        const matrixDefinition = getMatrixDefinition({
            integrationCode,
            betaFeatures: company.betaFeatures,
            licenseFeatures: company.licenseFeatures,
            matrixType: MatrixType.Reviewer,
        });

        return arrayHelpers.arraySort(
            reviewers
                .filter((reviewer) => !reviewer.isBackup)
                .map((reviewer) => {
                    const user = users.find((u) => u.id === reviewer.lineId);

                    if (!user) {
                        throw errorHelpers.notFoundError();
                    }

                    return {
                        ...user,
                        rulesPreviewText: getRulesPreviewText({
                            integrationCode,
                            company,
                            fields,
                            rules: reviewer.rules,
                            users,
                            matrixDefinition,
                            matrixType: MatrixType.Reviewer,
                        }),
                    };
                }),
            (u1, u2) => compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
)((state, template) => template.id);

export const getStepEditors: (
    state: State,
    step: domain.TemplateStep,
    template: domain.Template
) => ExpandedTemplateUser[] = createCachedSelector(
    (state: State, step: domain.TemplateStep) => step.editorMatrix,
    (state: State, step: domain.TemplateStep, template: domain.Template) =>
        selectors.company.getCompanyById(state, template.companyId),
    (state: State, step: domain.TemplateStep, template: domain.Template) => template.integrationCode,
    selectors.user.getUsers,
    (editorMatrix: domain.MatrixLine[], company, integrationCode, users: selectors.types.ExpandedUser[]) => {
        const matrixDefinition = getMatrixDefinition({
            integrationCode,
            betaFeatures: company.betaFeatures,
            licenseFeatures: company.licenseFeatures,
            matrixType: MatrixType.Editor,
        });

        return arrayHelpers.arraySort(
            editorMatrix.map((x) => {
                const user = users.find((u) => u.id === x.lineId);

                if (!user) {
                    throw errorHelpers.notFoundError();
                }

                return {
                    ...user,
                    rulesPreviewText: getStepEditorRulesPreviewText(integrationCode, x.rules, users, matrixDefinition),
                };
            }),
            (u1, u2) => compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
)((state, step, template) => template.id);

export function sortConditions(
    conditions: domain.MatrixCondition[],
    matrixDefinition?: MatrixDefinition
): domain.MatrixCondition[] {
    if (!matrixDefinition || matrixDefinition.columns.length === 0) {
        return conditions;
    }

    const sortedConditions: domain.MatrixCondition[] = [...conditions];

    sortedConditions.sort((condition1, condition2) => {
        const relatedColumnOneIndex = matrixDefinition.columns.findIndex(
            (column) => column.systemPurpose === condition1.fieldSystemPurpose
        );

        const relatedColumnTwoIndex = matrixDefinition.columns.findIndex(
            (column) => column.systemPurpose === condition2.fieldSystemPurpose
        );

        return relatedColumnOneIndex - relatedColumnTwoIndex;
    });

    return sortedConditions;
}
