import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.SubsectionTwoFa', {
    optionTFA: 'Two-factor authentication',
    optionTFAUnavailable: 'As you registered via SSO and no password is set, 2FA is not available.',

    blockVerifyDescription:
        'Backup options can be used during Two-factor authentication when you have no access to the Authenticator app',

    buttonEdit: 'Edit',
    buttonEnable: 'Enable',
    buttonDisable: 'Disable',
});
