import { DataIcon } from '@approvalmax/ui';
import { List, Menu, Tooltip } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { ExactAsyncConditionSelectMenuItemProps } from './ExactAsyncConditionSelectMenuItem.types';

export const ExactAsyncConditionSelectMenuItem: FC<ExactAsyncConditionSelectMenuItemProps> = memo((props) => {
    const { item } = props;

    return (
        <Tooltip
            width='fit-content'
            display='list-item'
            placement='left'
            disabled={!item.group}
            safePolygon
            activator={<Menu.Item {...item} endIcon={item.group ? <DataIcon size={16} /> : undefined} />}
        >
            <List listStyle='inside'>
                {(item.groupValues || []).map(({ id, text }) => (
                    <List.Item key={id}>{text}</List.Item>
                ))}
            </List>
        </Tooltip>
    );
});

ExactAsyncConditionSelectMenuItem.displayName = 'ExactAsyncConditionSelectMenuItem';
