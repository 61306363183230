import {
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useId,
    useImperativeHandle,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDrop } from 'react-use';

import { AddFileIcon, AlertIcon, CameraIcon } from '../../icons';
import { Avatar } from '../Avatar/Avatar';
import Box from '../Box/Box';
import { Flex } from '../Flex/Flex';
import { Grid } from '../Grid/Grid';
import Label from '../Label/Label';
import { Progress } from '../Progress/Progress';
import { Text } from '../Text/Text';
import { useAvatarDropzone } from './AvatarDropzone.hooks';
import { messages } from './AvatarDropzone.messages';
import { Root, Zone } from './AvatarDropzone.styles';
import { AvatarDropzoneProps, ChildrenComponents } from './AvatarDropzone.types';
import { Controller } from './components';

/*
 * Component that abstracts the logic and UI for a drag and drop file upload avatar zone using react-dropzone
 * https://react-dropzone.js.org/
 */
export const AvatarDropzone = memo(
    forwardRef<HTMLInputElement, AvatarDropzoneProps>((props, ref) => {
        const {
            width = 100,
            disabled,
            accept,
            maxSize,
            minSize = 0,
            rounded = true,
            avatarUrl,
            progress,
            label,
            message,
            hint,
            invalid,
            required,
            dragFileIcon,
            dragFileHint,
            dropFileHint,
            value,
            onDropAccepted,
            onDropRejected,
            ...restProps
        } = props;

        const id = useId();
        const hintId = `${id}-hint`;

        const { over: isDragOnPageActive } = useDrop();
        const { onDropAcceptedFiles, onDropRejectedFiles } = useAvatarDropzone(props);

        const { getRootProps, getInputProps, isDragActive, open, inputRef } = useDropzone({
            accept,
            maxSize,
            minSize,
            disabled,
            maxFiles: 1,
            noClick: true,
            multiple: false,
            noKeyboard: true,
            onDropAccepted: onDropAcceptedFiles,
            onDropRejected: onDropRejectedFiles,
            ...restProps,
        });

        useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current, [inputRef]);

        const getInternalColor = () => {
            if (isDrag || isDragActive || disabled) {
                return 'midnight60';
            }

            if (invalid) {
                return 'red100';
            }

            return 'blue100';
        };

        const isDrag = isDragOnPageActive && !disabled;
        const internalColor = getInternalColor();
        const Icon = rounded ? CameraIcon : AddFileIcon;

        return (
            <Root {...getRootProps()} {...restProps} $drag={isDragOnPageActive} gap={8}>
                <input {...getInputProps({ id })} />

                {label && (
                    <Label htmlFor={id} size='small' required={required}>
                        {label}
                    </Label>
                )}

                {message && (
                    <Grid gridTemplateColumns='max-content auto' gap={8}>
                        <AlertIcon size={16} />

                        <Text font='body' fontSize='small' fontWeight='regular'>
                            {message}
                        </Text>
                    </Grid>
                )}

                <Box width={width}>
                    <Zone
                        $active={isDragActive}
                        $progress={progress}
                        $disabled={disabled}
                        $rounded={rounded}
                        $invalid={invalid}
                        $drag={isDrag}
                    >
                        {progress && <Progress rounded size='xsmall' shape='circle' />}

                        {!progress && !avatarUrl && (
                            <Flex
                                direction='column'
                                spacing='4'
                                alignItems='center'
                                justifyContent='center'
                                width='100%'
                                height='100%'
                                onClick={!disabled ? open : undefined}
                            >
                                {dragFileIcon || isDragActive || <Icon size={24} color={internalColor} />}

                                <Text
                                    font='label'
                                    textAlign='center'
                                    fontSize='xsmall'
                                    fontWeight='regular'
                                    color={internalColor}
                                >
                                    {isDragActive
                                        ? dropFileHint || messages.dropFileHint
                                        : dragFileHint || (rounded ? messages.dragPhotoHint : messages.dragFileHint)}
                                </Text>
                            </Flex>
                        )}

                        {!progress && avatarUrl && (
                            <Flex
                                direction='column'
                                spacing='4'
                                alignItems='center'
                                justifyContent='center'
                                width='100%'
                                height='100%'
                                onClick={!disabled ? open : undefined}
                            >
                                <Avatar
                                    size={100}
                                    src={avatarUrl}
                                    alt={messages.avatarAlt}
                                    shape={rounded ? 'circle' : 'square'}
                                />
                            </Flex>
                        )}
                    </Zone>
                </Box>

                {hint && (
                    <Text
                        id={hintId}
                        font='body'
                        fontSize='xsmall'
                        fontWeight='regular'
                        color={invalid ? 'red100' : 'midnight70'}
                    >
                        {hint}
                    </Text>
                )}
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<AvatarDropzoneProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

AvatarDropzone.displayName = 'AvatarDropzone';
AvatarDropzone.Controller = Controller;
