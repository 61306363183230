import { fileHelpers } from '@approvalmax/utils';
import isError from 'lodash/isError';
import {
    ComponentProps,
    forwardRef,
    ForwardRefExoticComponent,
    MouseEvent,
    RefAttributes,
    useCallback,
    useId,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { DropzoneProps as DropzoneExternalProps, useDropzone } from 'react-dropzone';
import { useDrop } from 'react-use';

import { AlertIcon, AttachmentIcon, DragFileIcon } from '../../icons';
import Attachment from '../Attachment/Attachment';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { Grid } from '../Grid/Grid';
import Label from '../Label/Label';
import List from '../List/List';
import { Spacing } from '../Spacing/Spacing';
import { Text } from '../Text/Text';
import { toast } from '../Toast/Toast.helpers';
import { Controller } from './components';
import { getErrorMessage, handleDuplicateNames, prepareFiles } from './Dropzone.helpers';
import { errorMessages, messages } from './Dropzone.messages';
import { Root, Zone } from './Dropzone.styles';
import { ChildrenComponents, DropzoneProps, FileRecord } from './Dropzone.types';

/*
 * Component that abstracts the logic and UI for a drag and drop file upload zone using react-dropzone
 * https://react-dropzone.js.org/
 */
const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>((props, ref) => {
    const {
        hideZone,
        size = 'medium',
        disabled,
        multiple = false,
        hideErrorToast = false,
        noDuplicateNames = false,
        accept,
        children,
        maxSize,
        minSize = 0,
        maxFilesInList,
        value: valueProp,
        progress,
        invalid,
        width,
        height,
        hideButton,
        hideAcceptedExtensions,
        dragFileMessage,
        dragFileIcon,
        onUpload,
        filterFiles,
        onDropAccepted,
        onDropRejected,
        onChange,
        onRemove,
        onShow,
        onFileDialogCancel,
        additionalUploadButtonProps,
        readOnly,
        label,
        hint,
        message,
        required,
        ...restProps
    } = props;

    const id = useId();
    const hintId = `${id}-hint`;

    const { over: isDragOnPageActive } = useDrop();
    const value = useMemo(
        () =>
            (valueProp || []).map((file) => ({
                ...file,
                name: file.name || file.source?.name || '',
            })),
        [valueProp]
    );
    const acceptedExtensions = useMemo(
        () =>
            accept
                ? Object.values(accept)
                      .map((ext) => ext.join(', '))
                      .join(', ')
                : '',
        [accept]
    );

    const [newFiles, setNewFiles] = useState<FileRecord[]>([]);
    const [isUploadFromAdditionalButton, setIsUploadFromAdditionalButton] = useState(false);
    const hasFiles = Boolean(value.length);
    const hasNewFiles = Boolean(newFiles.length);
    const hasMultipleFiles = multiple && (hasFiles || hasNewFiles);
    const fileWordMessageValue = { file: multiple ? messages.files : messages.file };
    const isShowActionsUnderFiles =
        ((hideZone && !hasFiles && !hasNewFiles) || hasMultipleFiles) && !hideButton && !readOnly;
    const isShowAdditionalUploadButton =
        additionalUploadButtonProps && Object.keys(additionalUploadButtonProps).length > 0;

    const handleChange = useCallback(
        (files: Required<DropzoneProps>['value']) => {
            onChange?.(files);
        },
        [onChange]
    );

    const onDropAcceptedFiles = useCallback<Required<DropzoneExternalProps>['onDropAccepted']>(
        async (acceptedFiles, event) => {
            let preparedFiles = prepareFiles(filterFiles ? filterFiles(acceptedFiles) : acceptedFiles);

            if (maxFilesInList && preparedFiles.length + value.length > maxFilesInList) {
                toast.error(errorMessages.tooManyFilesInList({ maxFilesInList }));

                return;
            }

            if (noDuplicateNames) {
                preparedFiles = handleDuplicateNames(preparedFiles, value);
            }

            if (onUpload) {
                setNewFiles((newFiles) => [...newFiles, ...preparedFiles]);

                const uploadResults = await Promise.all(
                    preparedFiles.map(async (file) => {
                        try {
                            await onUpload(file, isUploadFromAdditionalButton);

                            return { id: file.id, success: true };
                        } catch (error) {
                            if (isError(error)) {
                                toast.error(error.message);
                            }

                            return { id: file.id, success: false };
                        }
                    })
                );

                const uploadResultsMap = new Map(uploadResults.map((result) => [result.id, result.success]));

                setNewFiles((prevFiles) => prevFiles.filter((file) => !uploadResultsMap.has(file.id)));

                preparedFiles = preparedFiles.filter((file) => uploadResultsMap.get(file.id));
            }

            if (preparedFiles.length) {
                handleChange(multiple ? [...(value || []), ...preparedFiles] : preparedFiles);
                onDropAccepted?.(preparedFiles, event);
            }
        },
        [
            filterFiles,
            handleChange,
            isUploadFromAdditionalButton,
            maxFilesInList,
            multiple,
            noDuplicateNames,
            onDropAccepted,
            onUpload,
            value,
        ]
    );

    const onDropRejectedFiles = useCallback<Required<DropzoneExternalProps>['onDropRejected']>(
        (rejectedFiles, event) => {
            if (!hideErrorToast) {
                rejectedFiles.forEach(({ file, errors }) =>
                    errors.map((error) => {
                        toast.error(
                            <>
                                <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                                    {file.name}
                                </Text>

                                {getErrorMessage(error, { maxSize: maxSize || 100000, minSize })}
                            </>
                        );
                    })
                );
            }

            onDropRejected?.(rejectedFiles, event);
        },
        [hideErrorToast, maxSize, minSize, onDropRejected]
    );

    const onRemoveFile = useCallback<Required<ComponentProps<typeof Attachment>>['onRemove']>(
        ({ id, source }) => {
            if (!id) return;
            handleChange(value.filter((file) => id !== file.id));
            onRemove?.({ id, source });
        },
        [handleChange, onRemove, value]
    );

    const { getRootProps, getInputProps, isDragActive, open, inputRef } = useDropzone({
        accept,
        maxSize,
        minSize,
        multiple,
        disabled: disabled || readOnly,
        noClick: true,
        noKeyboard: true,
        onDropAccepted: onDropAcceptedFiles,
        onDropRejected: onDropRejectedFiles,
        onFileDialogCancel,
        ...restProps,
    });

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current, [inputRef]);

    const fadedOrInvalidColor =
        isDragOnPageActive || isDragActive || disabled ? 'midnight60' : invalid ? 'red100' : undefined;

    const handleOpen = (isAdditionalButton?: boolean) => (e: MouseEvent<HTMLButtonElement>) => {
        isAdditionalButton && additionalUploadButtonProps?.onClick?.(e);
        setIsUploadFromAdditionalButton(Boolean(isAdditionalButton));
        open();
    };

    return (
        <Grid gap={8} height={label || message ? undefined : height} width={label || message ? undefined : width}>
            {label && (
                <Label htmlFor={id} size='small' required={required}>
                    {label}
                </Label>
            )}

            {message && (
                <Grid gridTemplateColumns='max-content auto' gap={8}>
                    <AlertIcon size={16} />

                    <Text font='body' fontSize='small' fontWeight='regular'>
                        {message}
                    </Text>
                </Grid>
            )}

            <Root
                {...getRootProps()}
                {...restProps}
                $size={size}
                $hide={hideZone || hasFiles || hasNewFiles}
                $drag={isDragOnPageActive}
                $height={height}
                $width={width}
            >
                <input {...getInputProps({ id })} />

                {children}

                {(hasFiles || hasNewFiles) && (
                    <List spacing='4 0' removeLastSpacing>
                        {value.map((file) => (
                            <List.Item key={file.id}>
                                <Attachment onRemove={onRemoveFile} onShow={onShow} progress={progress} {...file} />
                            </List.Item>
                        ))}

                        {newFiles.map((file) => (
                            <List.Item key={file.id}>
                                <Attachment onShow={onShow} progress {...file} />
                            </List.Item>
                        ))}
                    </List>
                )}

                {hasMultipleFiles && !hideButton && <Spacing height={12} />}

                {isShowActionsUnderFiles && (
                    <Flex spacing='8'>
                        <Button
                            onClick={handleOpen()}
                            color={invalid ? 'red40' : 'blue80'}
                            size={size === 'medium' ? 'small' : 'xsmall'}
                            startIcon={<AttachmentIcon />}
                            disabled={disabled}
                        >
                            {messages.selectFileButton(fileWordMessageValue)}
                        </Button>

                        {isShowAdditionalUploadButton && (
                            <Button
                                {...additionalUploadButtonProps}
                                onClick={handleOpen(true)}
                                size={size === 'medium' ? 'small' : 'xsmall'}
                                disabled={disabled}
                            />
                        )}
                    </Flex>
                )}

                {!hideZone && !readOnly && (
                    <Zone
                        $hide={hasFiles || hasNewFiles}
                        $active={isDragActive}
                        $invalid={invalid}
                        $drag={isDragOnPageActive && !disabled}
                    >
                        {dragFileIcon || (
                            <DragFileIcon
                                size={size === 'medium' ? 32 : 24}
                                color={fadedOrInvalidColor || 'midnight80'}
                            />
                        )}

                        <Box spacing={size === 'medium' ? '16 0' : '8 0'}>
                            <Text
                                font='label'
                                textAlign='center'
                                fontSize={size}
                                color={fadedOrInvalidColor || 'midnight100'}
                                fontWeight='medium'
                            >
                                {isDragActive
                                    ? messages.hint(fileWordMessageValue)
                                    : dragFileMessage || messages.dragFileMessage}
                            </Text>

                            {(acceptedExtensions || maxSize) && (
                                <Text
                                    font='body'
                                    fontSize='small'
                                    textAlign='center'
                                    color='midnight70'
                                    spacing='8 0 0'
                                >
                                    {`${!hideAcceptedExtensions ? acceptedExtensions.toUpperCase() : ''} ${maxSize ? messages.maxSizeText({ maxSize: fileHelpers.formatBytes(maxSize, 0) }) : ''}`}
                                </Text>
                            )}
                        </Box>

                        <Flex spacing='8'>
                            <Button
                                onClick={handleOpen()}
                                size={size === 'medium' ? 'small' : 'xsmall'}
                                color='midnight80'
                                startIcon={<AttachmentIcon />}
                                disabled={isDragOnPageActive || disabled}
                            >
                                {messages.selectFileButton(fileWordMessageValue)}
                            </Button>

                            {isShowAdditionalUploadButton && (
                                <Button
                                    {...additionalUploadButtonProps}
                                    onClick={handleOpen(true)}
                                    size={size === 'medium' ? 'small' : 'xsmall'}
                                    disabled={isDragOnPageActive || disabled}
                                />
                            )}
                        </Flex>
                    </Zone>
                )}
            </Root>

            {hint && (
                <Text
                    id={hintId}
                    font='body'
                    fontSize='xsmall'
                    fontWeight='regular'
                    color={invalid ? 'red100' : 'midnight70'}
                >
                    {hint}
                </Text>
            )}
        </Grid>
    );
}) as ForwardRefExoticComponent<DropzoneProps & RefAttributes<HTMLInputElement>> & ChildrenComponents;

Dropzone.displayName = 'Dropzone';
Dropzone.Controller = Controller;

export default Dropzone;
