import { domHelpers } from '@approvalmax/utils';
import { useCallback } from 'react';
import { api } from 'services/api';

import { SectionCertificationProps } from './SectionCertification.types';

export const useSectionCertification = (props: SectionCertificationProps) => {
    const { profile } = props;

    const handleDownload = useCallback(() => {
        if (profile.lastCertification) {
            domHelpers.downloadUrl(
                api.expandUrl(
                    `certifications/getCertification?certificationId=${profile.lastCertification.id}`,
                    'myAccount'
                )
            );
        }
    }, [profile.lastCertification]);

    return {
        handleDownload,
    };
};
