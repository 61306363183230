import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteDiscountItems } from './useGetNetSuiteDiscountItems.types';

export const useLazyGetNetSuiteDiscountItems = (queryOptions?: RequestQueryOptions<GetNetSuiteDiscountItems>) => {
    return useLazyGet<
        LazyGetDataParams<{}, GetNetSuiteDiscountItems['params']['query']>,
        GetNetSuiteDiscountItems['response']
    >(netSuiteApiPaths.getDiscountItems, {
        queryOptions,
        apiVersion: 'v2',
    });
};
