import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { PascalCaseToCamelCase } from '@approvalmax/types';
import { useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteAccounts } from './useLazyGetNetSuiteAccounts.types';

export const useLazyGetNetSuiteAccounts = (queryOptions?: RequestQueryOptions<GetNetSuiteAccounts>) => {
    return useLazyGet<
        LazyGetDataParams<{}, GetNetSuiteAccounts['params']['query']>,
        PascalCaseToCamelCase<GetNetSuiteAccounts['response']>
    >(integrationApiPaths.getNetSuiteAccounts, {
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
        mapToCamelCase: true,
    });
};
