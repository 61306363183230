import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { usePrevious, useUnmount } from 'react-use';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApi from '../rootApi';
import rootApiLegacy from '../rootApiLegacy';
import { ApiSource, LazyGetDataParams, UseGetOptions } from '../types';

export const createUseLazyGet = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    /**
     * The concept of this hook is partially taken from the following sources:
     *
     * @see https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useLazyQuery.ts and
     * @see https://github.com/TanStack/query/discussions/1205
     */
    return <RequestBody extends LazyGetDataParams, ResponseData, MappedResponseData = ResponseData>(
        path: string,
        options: Omit<UseGetOptions<ResponseData, MappedResponseData>, 'params' | 'pathParams' | 'queryParams'> = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method,
            queryOptions,
            responseType,
            mapData,
            mapToCamelCase,
        } = options;

        const handleApiCall = useHandleApiCall(mapData, mapToCamelCase);
        const queryClient = useQueryClient();
        const queryKey = useMemo(() => ['lazy', path], [path]);

        const queryResult = useQuery<MappedResponseData, ApiError>(queryKey, {
            // enabled is always false, no cache, no refetch, we just need to get results
            enabled: false,
            retry: false,
            cacheTime: 0,
            staleTime: 0,
        });

        const { data: queryResultData, error, status: queryStatus, isFetching } = queryResult;
        const status = isFetching ? 'fetching' : queryStatus;
        const prevStatus = usePrevious(status);

        useEffect(() => {
            if (prevStatus === status) return;

            switch (queryStatus) {
                case 'success':
                    queryOptions?.onSuccess?.(queryResultData);
                    queryOptions?.onSettled?.(queryResultData, null);
                    break;

                case 'error':
                    queryOptions?.onError?.(error);
                    queryOptions?.onSettled?.(undefined, error);
                    break;
            }
        }, [error, queryOptions, queryResultData, status, prevStatus, queryStatus]);

        useUnmount(() => {
            // reset useQuery flags, remove cache
            queryClient.removeQueries({ queryKey });
        });

        const execute = useCallback(
            ({ queryParams, pathParams, params }: RequestBody) => {
                const requestParams = params || {
                    path: pathParams,
                    query: queryParams,
                };

                const apiFn = apiVersion === 'v1' ? rootApiLegacy : rootApi;
                const apiMethod = apiVersion === 'v1' && !method ? 'post' : method;
                const data = method === 'get' || !requestParams.query ? {} : requestParams.query;

                const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
                const url = routerHelpers.testPathByParams(path, requestParams.path)
                    ? routerHelpers.pathToUrl(path, requestParams.path)
                    : '';
                const Authorization = getAuthorization(defaultApiSource, defaultEnv);

                return queryClient.fetchQuery(
                    queryKey,
                    () =>
                        handleApiCall(
                            apiFn({
                                baseURL,
                                url,
                                method: apiMethod,
                                responseType,
                                headers: { ...(Authorization && { Authorization }) },
                                params: requestParams.query,
                                data,
                            })
                        ),
                    {
                        meta: {
                            meta: queryOptions?.meta,
                            path,
                            queryParams,
                            pathParams,
                            params,
                            data,
                        },
                    }
                );
            },
            // exclude queryClient
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [apiSource, apiVersion, path, queryKey]
        );

        return <const>[execute, queryResult];
    };
};
