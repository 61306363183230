import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { VerifyTwoFaWithEmailCode } from './useVerifyTwoFaWithEmailCode.types';

export const useVerifyTwoFaWithEmailCode = (mutationOptions?: RequestMutationOptions<VerifyTwoFaWithEmailCode>) => {
    return useMutate(authApiPaths.verifyTwoFaWithEmailCode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
