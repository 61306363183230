import { Reference } from '@approvalmax/types';
import { backend, domain, du } from 'modules/data';

export interface Vendor extends Reference {
    address?: domain.BillingAddress[] | null;
    taxNumber?: string | null;
    currencyIds?: string[] | null;
}

export interface NetSuiteItem extends Reference {
    vendorName: string | null;
    unitList: domain.NetSuiteUnitItem[] | null;
    priceLevelList: domain.NetSuitePriceLevelItem[] | null;
    rate: number | null;
    purchaseDescription: string | null;
}

export interface NetSuiteExpenseCategory extends Reference {
    accountId: string | null;
    rates: {
        currency: string;
        defaultRate: number | null;
    }[];
}

export interface NetSuiteEmployee extends Reference {
    defaultCurrencyId: string;
    currencyIds: string[];
}

export interface NetSuiteSalesTaxItem extends Reference {
    rate: number | null;
}

export interface NetSuiteTerms extends Reference {
    daysUntilExpiry: number | null;
    daysUntilNetDue: number | null;
    discountPercent: number | null;
}

export interface NetSuiteContext {
    accounts: Reference[];
    departments: Reference[];
    classes: Reference[];
    locations: Reference[];
    currencies: domain.NetSuiteCurrency[];
    terms: NetSuiteTerms[];
    accountingPeriods: Reference[];
    salesTaxItems: NetSuiteSalesTaxItem[];
    employees: NetSuiteEmployee[];
    items: NetSuiteItem[];
    expenseCategories: NetSuiteExpenseCategory[];
    availableLineItemType: domain.NetSuiteAvailableLineItemType;
    customFields: domain.Field[];
    supplierEmailSettings?: backend.SupplierEmailSettings;
    documentFields?: domain.DocumentField[];
    currentSubsidiary?: Reference & {
        edition: string;
    };
}

export function parseNetSuiteContext(value: backend.RequestsNetSuiteEditingContext): NetSuiteContext {
    return {
        accounts: (value.accounts || []).map(du.parseServerReference),
        departments: (value.departments || []).map(du.parseServerReference),
        classes: (value.classes || []).map(du.parseServerReference),
        locations: (value.locations || []).map(du.parseServerReference),
        currencies: (value.currencies || []).map((currency) => ({
            ...du.parseServerReference(currency),
            ISOCode: currency.ISOCode || null,
            rateToCompanyCurrency: currency.RateToCompanyCurrency || null,
            precision: currency.Precision === backend.NetSuiteCurrencyPrecisionType.Zero ? 0 : 2,
        })),
        terms: (value.terms || []).map((term) => ({
            ...du.parseServerReference(term),
            daysUntilExpiry: term.DaysUntilExpiry,
            daysUntilNetDue: term.DaysUntilNetDue,
            discountPercent: term.DiscountPercent,
        })),
        accountingPeriods: (value.accountingPeriods || []).map(du.parseServerReference),
        salesTaxItems: (value.salesTaxItems || []).map((taxItem) => ({
            ...du.parseServerReference(taxItem),
            rate: taxItem.Rate || null,
        })),
        employees: (value.employees || []).map((employee) => ({
            ...du.parseServerReference(employee),
            currencyIds: employee.CurrencyIds,
            defaultCurrencyId: employee.DefaultCurrencyId,
        })),
        items: (value.items || []).map((item) => ({
            ...du.parseServerReference(item),
            vendorName: item.VendorName || null,
            unitList: (item.Units || []).map((unit) => ({
                id: unit.Id,
                text: unit.Name,
                catalogUnitPrice: unit.CatalogUnitPrice,
                isItemPrimaryPurchaseUnit: unit.IsItemPrimaryPurchaseUnit,
            })),
            priceLevelList: (item.PriceLevels || []).map((priceLevel) => ({
                ...du.parseServerReference(priceLevel),
                isItemPrimaryPriceLevel: priceLevel.IsItemPrimaryPriceLevel,
            })),
            rate: item.PurchasePrices || null,
            purchaseDescription: item.PurchaseDescription || null,
        })),
        expenseCategories: (value.expenseCategories || []).map((category) => ({
            ...du.parseServerReference(category),
            accountId: category.AccountId || null,
            rates: (category.Rates || []).map((rate) => ({
                currency: rate.CurrencyInternalId,
                defaultRate: rate.DefaultRate,
            })),
        })),
        availableLineItemType: value.availableLineItemType || domain.NetSuiteAvailableLineItemType.All,
        currentSubsidiary: value.currentSubsidiary && {
            ...du.parseServerReference(value.currentSubsidiary),
            edition: value.currentSubsidiary.Edition,
        },
        customFields: (value.customFields || []).map(domain.schemas.field.mapField),
    };
}
