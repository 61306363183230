import { toast } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { commonConstants } from 'modules/common/constants';
import { domain } from 'modules/data';
import { getTemplateSubmitterType, isOcrEnabledForTemplate, TemplateSubmitter } from 'shared/helpers';

import { messages } from './handleTemplateSubmitterErrors.messages';

export const handleTemplateSubmitterErrors = (template: domain.Template, company: selectors.types.ExpandedCompany) => {
    const integrationCode = template.integrationCode;
    const hasRegularSubmitter = template.submitterMatrix.length > 0;
    const hasExternalSubmitter = Boolean(template.externalSubmitter);
    const hasReceiptBankSubmitter = Boolean(template.receiptBankExternalSubmitter);
    const receiptBankSubmitterRequired = company.receiptBankIntegration?.isConnected;

    const externalEmailSubmitterRequired = Boolean(template.ocrEmailAddress);
    const hasExternalEmailSubmitter = Boolean(template.emailExternalSubmitter);

    const isOcrEnabled = isOcrEnabledForTemplate(company, template.integrationCode);

    if (
        template.integrationCode &&
        commonConstants.ocrAvailableIntegrationCodes.includes(template.integrationCode) &&
        isOcrEnabled &&
        externalEmailSubmitterRequired &&
        !hasExternalEmailSubmitter
    ) {
        toast.error(messages.emailSubmitterRequiredError);
    }

    if (
        template.integrationCode &&
        commonConstants.dextAvailableIntegrationCodes.includes(template.integrationCode) &&
        !hasReceiptBankSubmitter &&
        receiptBankSubmitterRequired
    ) {
        toast.error(messages.dextSubmitterRequiredError);
    }

    const submitterType = getTemplateSubmitterType(integrationCode);

    switch (submitterType) {
        case TemplateSubmitter.Regular:
            if (!hasRegularSubmitter) {
                toast.error(messages.regularSubmitterRequiredError);
            }

            break;

        case TemplateSubmitter.External:
            if (!hasExternalSubmitter) {
                toast.error(messages.externalSubmitterRequiredError);
            }

            break;

        case TemplateSubmitter.RegularOrExternal:
            if (!hasRegularSubmitter && !hasExternalSubmitter) {
                toast.error(messages.reqularOrExternalSubmitterRequiredError);
            }

            break;

        default:
            throw errorHelpers.assertNever(integrationCode ?? '');
    }
};
