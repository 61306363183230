import { Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { BackupCodesAddPopupProps } from './BackupCodesAddPopup.types';
import { BackupCodesAddPopupContent } from './BackupCodesAddPopupContent';

export const BackupCodesAddPopup = memo<BackupCodesAddPopupProps>((props) => {
    const { open, onClose } = props;

    return (
        <Popup open={open} onClose={onClose} size='medium'>
            <BackupCodesAddPopupContent {...props} />
        </Popup>
    );
});

BackupCodesAddPopup.displayName = 'BackupCodesAddPopup';
