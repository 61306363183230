import { Guid } from '@approvalmax/types';
import { Flex } from '@approvalmax/ui/src/components';
import { intl, mathService, numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import moment from 'moment/moment';
import FileItem from 'pages/requestForm/components/filesSection/FileItem/FileItem';
import { useExpenseLinesFieldSettings } from 'pages/requestForm/containers/netsuite/NetSuiteExpenseReportRequestForm/hooks/useExpenseLinesFieldSettings/useExpenseLinesFieldSettings';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';

import { messages } from './RequestCardExpenseReport.messages';
import { AttachmentWrap } from './RequestCardExpenseReport.styles';

type ColumnDefinitionExpenseLine = ColumnDefinition<domain.NetSuiteExpenseLineExpenseReport> & { isHidden?: boolean };

export const getColumnDefs = (
    onPreviewAttachment: (attachmentId: Guid) => void,
    fieldsSettingsExpenseLines: ReturnType<typeof useExpenseLinesFieldSettings>,
    exchangeRate?: number | null
): ColumnDefinitionExpenseLine[] => {
    const cols: ColumnDefinitionExpenseLine[] = [
        {
            id: 'refLineNumber',
            name: messages.columnRefLineNumber,
            value: (li) => li.refLineNumber?.toString() || null,
            minWidth: 50,
            isHidden: fieldsSettingsExpenseLines.expensesRefLineNumber === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'expenseDate',
            name: messages.columnExpenseDate,
            value: (li) => moment.utc(li.expenseDate).format('ll'),
            minWidth: 90,
            isHidden: fieldsSettingsExpenseLines.expensesDate === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'category',
            name: messages.columnCategory,
            value: (li) => (li.category ? li.category.text : null),
            minWidth: 180,
            isHidden: fieldsSettingsExpenseLines.expensesCategory === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'expenseAccount',
            name: messages.columnExpenseAccount,
            value: (li) => (li.expenseAccount ? li.expenseAccount.text : null),
            minWidth: 150,
            isHidden: fieldsSettingsExpenseLines.expensesAccount === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'foreignAmount',
            name: messages.columnForeignAmount,
            value: (li) => (li.foreignAmount ? intl.formatNumber(li.foreignAmount || 0, 'auto') : null),
            alignRight: true,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesForeignAmount === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'currency',
            name: messages.columnCurrency,
            value: (li) => li.currency?.text ?? null,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesCurrency === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'exchangeRate',
            name: messages.columnExchangeRate,
            value: (li) =>
                numberHelpers.isNumber(li.exchangeRate)
                    ? intl.formatNumber(li.exchangeRate || 0, 8, true)
                    : li.exchangeRate,
            alignRight: true,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesExchangeRate === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'amount',
            name: messages.columnAmount,
            value: (li) => {
                let amount = li.amount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesAmount === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'taxCode',
            name: messages.columnTaxCode,
            value: (li) => li.taxCode?.text ?? null,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesTaxCode === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'taxRate',
            name: messages.columnTaxRate,
            value: (li) => (numberHelpers.isNumber(li.taxRate) ? `${intl.formatNumber(li.taxRate, 'auto')}%` : null),
            alignRight: true,
            minWidth: 60,
            isHidden: fieldsSettingsExpenseLines.expensesTaxRate === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'taxAmount',
            name: messages.columnTaxAmount,
            value: (li) => {
                let taxAmount = li.taxAmount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    taxAmount = mathService.multiply(taxAmount, exchangeRate);
                    taxAmount = mathService.round(taxAmount, 2);
                }

                return intl.formatNumber(taxAmount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesTaxAmount === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'memo',
            name: messages.columnMemo,
            value: (li) => li.memo,
            minWidth: 115,
            isHidden: fieldsSettingsExpenseLines.expensesMemo === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'department',
            name: messages.columnDepartment,
            value: (li) => li.department?.text ?? null,
            minWidth: 150,
            isHidden: fieldsSettingsExpenseLines.expensesDepartment === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'class',
            name: messages.columnClass,
            value: (li) => li.class?.text ?? null,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesClass === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'location',
            name: messages.columnLocation,
            value: (li) => li.location?.text ?? null,
            minWidth: 100,
            isHidden: fieldsSettingsExpenseLines.expensesLocation === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'customer',
            name: messages.columnCustomer,
            value: (li) => li.customer?.text ?? null,
            minWidth: 80,
            isHidden: fieldsSettingsExpenseLines.expensesCustomer === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'attachment',
            name: messages.columnLineAttachment,
            value: (li) => li.attachment?.id || null,
            cell: (value, li) => (
                <AttachmentWrap>
                    {value && li.attachment && (
                        <FileItem
                            readonly
                            fileId={value}
                            fileName={li.attachment.name}
                            onRemove={() => undefined}
                            onOpen={() => onPreviewAttachment(value)}
                        />
                    )}
                </AttachmentWrap>
            ),
            isHidden: fieldsSettingsExpenseLines.expensesAttachment === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'isBillable',
            name: messages.columnIsBillable,
            value: (li) => (li.isBillable ? messages.columnIsBillableTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: fieldsSettingsExpenseLines.expensesIsBillable === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'isNonReimbursable',
            name: messages.columnIsNonReimbursable,
            value: (li) => (li.isNonReimbursable ? messages.columnIsNonReimbursableTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: fieldsSettingsExpenseLines.expensesIsNonReimbursable === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'isReceipt',
            name: messages.columnIsReceipt,
            value: (li) => (li.isReceipt ? messages.columnIsReceiptTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: fieldsSettingsExpenseLines.expensesIsReceipt === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'isCorporateCreditCard',
            name: messages.columnIsCorporateCreditCard,
            value: (li) => (li.isCorporateCreditCard ? messages.columnIsCorporateCreditCardTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: fieldsSettingsExpenseLines.expensesIsCorporateCreditCard === domain.DocumentFieldState.Hidden,
        },
    ];

    return cols.filter((col) => !col.isHidden);
};
