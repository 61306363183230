import { defineMessages } from 'react-intl';

const i18nPrefix = 'app.WorkflowId.selectors.stepSelectors';

export const messages = defineMessages({
    participant_rules_preview_amount_pattern: {
        id: `${i18nPrefix}.participant_rules_preview_amount_pattern`,
        defaultMessage: 'Amount is {amount}',
    },

    participantRulesPreviewNumberFieldPattern: {
        id: `${i18nPrefix}.participant_rules_preview_number_pattern`,
        defaultMessage: '{fieldName} is {amount}',
    },

    participant_rules_preview_amount_pattern_net: {
        id: `${i18nPrefix}.participant_rules_preview_amount_pattern_net`,
        defaultMessage: 'Net amount is {amount}',
    },

    participant_rules_preview_amount_pattern_gross: {
        id: `${i18nPrefix}.participant_rules_preview_amount_pattern_gross`,
        defaultMessage: 'Total amount is {amount}',
    },

    participant_rules_preview_amount_pattern_above: {
        id: `${i18nPrefix}.participant_rules_preview_amount_pattern_above`,
        defaultMessage: 'over or equal to {greaterEq} {currency}',
    },

    participant_rules_preview_amount_pattern_below: {
        id: `${i18nPrefix}.participant_rules_preview_amount_pattern_below`,
        defaultMessage: 'under {less} {currency}',
    },

    participant_rules_preview_amount_pattern_within: {
        id: `${i18nPrefix}.participant_rules_preview_amount_pattern_within`,
        defaultMessage: 'over or equal to {greaterEq} and under {less} {currency}',
    },

    participant_rules_preview_field_value_pattern: {
        id: `${i18nPrefix}.participant_rules_preview_field_value_pattern`,
        defaultMessage: '"{value}"',
    },

    participant_rules_preview_field_group_value_pattern: {
        id: `${i18nPrefix}.participant_rules_preview_field_group_value_pattern`,
        defaultMessage: 'Group "{name}"(#{versionNumber})',
    },

    participant_rules_preview_not_field_pattern: {
        id: `${i18nPrefix}.participant_rules_preview_not_field_pattern`,
        defaultMessage: '{fieldName} does not match {values}',
    },

    participant_rules_preview_field_pattern: {
        id: `${i18nPrefix}.participant_rules_preview_field_pattern`,
        defaultMessage: '{fieldName} matches {values}',
    },

    participantBooleanRuleEnabled: {
        id: `${i18nPrefix}.participantBooleanRuleEnabled`,
        defaultMessage: '{fieldName} enabled',
    },

    participantBooleanRuleDisabled: {
        id: `${i18nPrefix}.participantBooleanRuleDisabled`,
        defaultMessage: '{fieldName} disabled',
    },

    participant_rules_preview_and_text: {
        id: `${i18nPrefix}.participant_rules_preview_and_text`,
        defaultMessage: '{left} and {right}',
    },

    participant_rules_preview_or_text: {
        id: `${i18nPrefix}.participant_rules_preview_or_text`,
        defaultMessage: '{left} or {right}',
    },

    editor_rules_preview_not_field_pattern: {
        id: `${i18nPrefix}.editor_rules_preview_not_field_pattern`,
        defaultMessage: '{fieldName} to any value except {values}',
    },

    editor_rules_preview_field_pattern: {
        id: `${i18nPrefix}.editor_rules_preview_field_pattern`,
        defaultMessage: '{fieldName} to {values}',
    },

    editor_rules_preview_always_approve: {
        id: `${i18nPrefix}.editor_rules_preview_always_approve`,
        defaultMessage: 'can set any values',
    },

    editor_rules_preview_and_text: {
        id: `${i18nPrefix}.editor_rules_preview_and_text`,
        defaultMessage: '{left} and {right}',
    },

    editor_rules_preview_or_text: {
        id: `${i18nPrefix}.editor_rules_preview_or_text`,
        defaultMessage: '{left} or {right}',
    },

    editor_rules_preview_can_set: {
        id: `${i18nPrefix}.editor_rules_preview_can_set`,
        defaultMessage: 'can set {rules}',
    },

    participant_rules_preview_approves_when: {
        id: `${i18nPrefix}.participant_rules_preview_approves_when`,
        defaultMessage: 'should approve if {rules}',
    },

    participant_rules_preview_always_approve: {
        id: `${i18nPrefix}.participant_rules_preview_always_approve`,
        defaultMessage: 'should approve everything',
    },

    participant_rules_preview_review_when: {
        id: `${i18nPrefix}.participant_rules_preview_review_when`,
        defaultMessage: 'should review if {rules}',
    },

    participant_rules_preview_always_review: {
        id: `${i18nPrefix}.participant_rules_preview_always_review`,
        defaultMessage: 'should review everything',
    },
});
