import { ApiTypes } from 'shared/data/v2';

import * as backend from '../../backend';
import * as domain from '../../domain';

export const mapBeneficiary = (
    value: backend.AirwallexBeneficiary | ApiTypes['IntegrationsAirwallexAirwallexBeneficiaryVersionCamelCased']
): domain.AirwallexBeneficiary => {
    return {
        id: value.airwallexBeneficiaryId,
        text: value.name || '',
        paymentMethod: value.paymentMethod ? { id: value.paymentMethod, text: value.paymentMethod } : null,
        paymentFee: 'paymentFee' in value ? { id: value.paymentFee || '', text: value.paymentFee || '' } : null,
        version: value.version,
        accountCurrency: value.accountCurrency || '',
        bankDetails: {
            ...value.bankDetails,
            accountName: value.bankDetails?.accountName || '',
            bankCountryCode: value.bankDetails?.bankCountryCode || '',
            bankName: value.bankDetails?.bankName || '',
            iban: value.bankDetails?.iban || '',
            swiftCode: value.bankDetails?.swiftCode || '',
            accountNumber: value.bankDetails?.accountNumber || '',
            bankBranch: value.bankDetails?.bankBranch || '',
            localClearingSystem: value.bankDetails?.localClearingSystem || '',
            accountingRoutingType1:
                value.bankDetails?.accountingRoutingType1 ||
                (value.bankDetails && 'accountRoutingType1' in value.bankDetails
                    ? value.bankDetails?.accountRoutingType1
                    : '') ||
                '',
            accountingRoutingType2:
                value.bankDetails?.accountingRoutingType2 ||
                (value.bankDetails && 'accountRoutingType2' in value.bankDetails
                    ? value.bankDetails?.accountRoutingType2
                    : '') ||
                '',
            accountingRoutingValue1:
                value.bankDetails?.accountingRoutingValue1 ||
                (value.bankDetails && 'accountRoutingValue1' in value.bankDetails
                    ? value.bankDetails?.accountRoutingValue1
                    : '') ||
                '',
            accountingRoutingValue2:
                value.bankDetails?.accountingRoutingValue2 ||
                (value.bankDetails && 'accountRoutingValue2' in value.bankDetails
                    ? value.bankDetails?.accountRoutingValue2
                    : '') ||
                '',
        },
    };
};
