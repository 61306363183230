import { dateTimeHelpers, miscHelpers } from '@approvalmax/utils';
import { constants, selectors, useSortedCompanies } from 'modules/common';
import { useCurrentUser } from 'modules/utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { messages } from './UserMenuActivator.messages';

export const useDelegates = () => {
    const me = useCurrentUser();
    const users = useSelector(selectors.user.getUsers);
    const companies = useSortedCompanies();

    const delegates = useMemo(() => {
        return companies
            .map((company) => {
                const delegatePair = company.delegates.find((delegate) => {
                    const { DATE_FORMAT_ONLY_DATE } = constants.commonConstants;

                    const isDatesNotExist = !delegate.delegateFrom && !delegate.delegateTo;

                    const isDatesInRange = dateTimeHelpers.isBetweenDates(
                        delegate.delegateFrom,
                        delegate.delegateTo ? moment(delegate.delegateTo).hour(23).minute(59).second(59) : undefined,
                        moment().hour(0).minute(0).second(0),
                        true
                    );

                    const isSameDate =
                        delegate.delegateFrom === delegate.delegateTo &&
                        moment(delegate.delegateFrom).format(DATE_FORMAT_ONLY_DATE) ===
                            moment().format(DATE_FORMAT_ONLY_DATE);

                    return delegate.userId === me.id && (isDatesNotExist || isDatesInRange || isSameDate);
                });

                return delegatePair && !company.isReadonly
                    ? users.find(({ id }) => id === delegatePair.delegateUserId)
                    : null;
            })
            .filter(miscHelpers.notEmptyFilter);
    }, [companies, me.id, users]);

    const delegatesText = delegates.length
        ? delegates.length === 1
            ? delegates[0].displayName
            : messages.manyDelegates({ count: delegates.length })
        : '';

    return {
        delegatesText,
    };
};
