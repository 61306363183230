import { Box, Flex, Form, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './PropertyRowWithButton.messages';
import { PropertyRowWithSwitchProps } from './PropertyRowWithButton.types';

export const PropertyRowWithButton = memo<PropertyRowWithSwitchProps>((props) => {
    const {
        title,
        description,
        active = false,
        renderAction,
        statusEnabledText = messages.statusEnabled,
        statusDisabledText = messages.statusDisabled,
    } = props;

    return (
        <Form.Part>
            <Box spacing='16 0'>
                <Flex height='100%' justifyContent='space-between' alignItems='center' wrap={false}>
                    <Flex alignItems='center' grow={1} wrap={false} overflow='hidden' spacing='8'>
                        <Text font='headline' fontSize='xsmall' fontWeight='regular'>
                            {title}
                        </Text>

                        <Text
                            font='headline'
                            fontSize='xsmall'
                            fontWeight='regular'
                            color={active ? 'green100' : 'midnight60'}
                            ellipsis={1}
                        >
                            {active ? statusEnabledText : statusDisabledText}
                        </Text>
                    </Flex>

                    {renderAction(active)}
                </Flex>

                {description && (
                    <>
                        <Spacing height={8} />

                        <Text font='body' fontSize='small' fontWeight='regular' color='midnight70'>
                            {description}
                        </Text>
                    </>
                )}
            </Box>
        </Form.Part>
    );
});

PropertyRowWithButton.displayName = 'PropertyRowWithButton';
