import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteEmployees } from './useGetNetSuiteEmployees.types';

export const useLazyGetNetSuiteEmployees = (queryOptions?: RequestQueryOptions<GetNetSuiteEmployees>) => {
    return useLazyGet<LazyGetDataParams<{}, GetNetSuiteEmployees['params']['query']>, GetNetSuiteEmployees['response']>(
        netSuiteApiPaths.getEmployees,
        {
            queryOptions,
            apiVersion: 'v2',
        }
    );
};
