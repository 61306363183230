import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { matchingApiPaths } from '../paths';
import { GetAccountLineItemMatchingSuggestions } from './useGetAccountLineItemMatchingSuggestions.types';

export const useGetAccountLineItemMatchingSuggestions = (
    params?: RequestParams<GetAccountLineItemMatchingSuggestions>,
    queryOptions?: RequestQueryOptions<GetAccountLineItemMatchingSuggestions>
) =>
    useGet(matchingApiPaths.getQBooksAccountLineItemMatchingSuggestions, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetAccountLineItemMatchingSuggestions['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    vendorId: params?.query?.vendorId,
                    billDate: params?.query?.billDate,
                    accountItemId: params?.query?.accountItemId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });

export const useLazyGetAccountLineItemMatchingSuggestions = (
    queryOptions?: RequestQueryOptions<GetAccountLineItemMatchingSuggestions>
) =>
    useLazyGet(matchingApiPaths.getQBooksAccountLineItemMatchingSuggestions, {
        queryOptions,
        apiVersion: 'v2',
    });
