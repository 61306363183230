import { selectors } from 'modules/common';
import { actions as actionsProfile } from 'modules/profile';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import {
    useProfileEditPopupCloseConfirmation,
    useTwoFaProfileDisabling,
    useTwoFaProfileEnabling,
    useTwoFaProfileVerifying,
} from 'shared/components';

import { useCheckIsModifiedProfileForm } from '../../../../hooks/useCheckIsModifiedProfileForm';
import { useResetProfileForm } from '../../../../hooks/useResetProfileForm';

export const useSubsectionTwoFa = (profile: selectors.types.ExpandedProfile) => {
    const dispatch = useDispatch();
    const isModifiedProfileForm = useCheckIsModifiedProfileForm();
    const resetProfileForm = useResetProfileForm();

    const [isVerified, setIsVerified] = useToggle(false);

    const { handleOpen: openTwoFaEnabling } = useTwoFaProfileEnabling();
    const { handleOpen: openTwoFaDisabling } = useTwoFaProfileDisabling();
    const { handleOpen: openTwoFaVerifying } = useTwoFaProfileVerifying();

    const { confirmation } = useProfileEditPopupCloseConfirmation();

    useEffect(() => {
        if (!profile.is2faEnabled) {
            setIsVerified(false);
        }
    }, [profile.is2faEnabled, setIsVerified]);

    const startEnablingTwoFa = useCallback(() => {
        openTwoFaEnabling({
            onFinish: (backupMethod) => {
                switch (backupMethod) {
                    case 'backupCodes':
                        dispatch(actionsProfile.changeTwoFaBackupCodesIsEnabled(true));
                        break;

                    case 'backupEmailAddress':
                        dispatch(actionsProfile.changeTwoFaAlternativeEmailIsEnabled(true));
                        break;
                }

                dispatch(actionsProfile.changeTwoFaIsEnabled(true, profile.email));
            },
        });
    }, [dispatch, openTwoFaEnabling, profile.email]);

    const startDisablingTwoFa = useCallback(() => {
        openTwoFaDisabling({
            onFinish: () => {
                dispatch(actionsProfile.changeTwoFaAlternativeEmailIsEnabled(false));
                dispatch(actionsProfile.changeTwoFaBackupCodesIsEnabled(false));
                dispatch(actionsProfile.changeTwoFaIsEnabled(false, profile.email));
            },
        });
    }, [dispatch, openTwoFaDisabling, profile.email]);

    const handleVerificationStart = useCallback(() => {
        openTwoFaVerifying({
            onFinish: () => {
                setIsVerified(true);
            },
        });
    }, [openTwoFaVerifying, setIsVerified]);

    const handleEnableStart = useCallback(() => {
        if (isModifiedProfileForm) {
            confirmation()
                .then(() => resetProfileForm())
                .then(() => startEnablingTwoFa())
                .catch(() => {});
        } else {
            startEnablingTwoFa();
        }
    }, [confirmation, isModifiedProfileForm, resetProfileForm, startEnablingTwoFa]);

    const handleDisableStart = useCallback(() => {
        if (isModifiedProfileForm) {
            confirmation()
                .then(() => resetProfileForm())
                .then(() => startDisablingTwoFa())
                .catch(() => {});
        } else {
            startDisablingTwoFa();
        }
    }, [confirmation, isModifiedProfileForm, resetProfileForm, startDisablingTwoFa]);

    return {
        isVerified,
        handleEnableStart,
        handleDisableStart,
        handleVerificationStart,
        isEnabled: profile.is2faEnabled,
        isAvailable: profile.isPasswordSet,
    };
};
