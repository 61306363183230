import { EditIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import React, { memo, useCallback } from 'react';
import {
    TwoFaProfileDisabling,
    TwoFaProfileEnabling,
    TwoFaProfileVerifying,
    useCheckTwoFaProfileDisablingIsOpen,
    useCheckTwoFaProfileEnablingIsOpen,
    useCheckTwoFaProfileVerifyingIsOpen,
} from 'shared/components';

import { PropertyRowWithButton } from '../../../PropertyRowWithButton/PropertyRowWithButton';
import { BackupMethodsList } from '../BackupMethodsList/BackupMethodsList';
import { useSubsectionTwoFa } from './SubsectionTwoFa.hooks';
import { messages } from './SubsectionTwoFa.messages';
import { SubsectionTwoFaProps } from './SubsectionTwoFa.types';

export const SubsectionTwoFa = memo<SubsectionTwoFaProps>((props) => {
    const { profile, qa } = props;

    const { isAvailable, isVerified, isEnabled, handleEnableStart, handleDisableStart, handleVerificationStart } =
        useSubsectionTwoFa(profile);
    const isTwoFaEnablingOpen = useCheckTwoFaProfileEnablingIsOpen();
    const isTwoFaDisablingOpen = useCheckTwoFaProfileDisablingIsOpen();
    const isTwoFaVerifyingOpen = useCheckTwoFaProfileVerifyingIsOpen();

    const isActive = isEnabled || isVerified;

    const renderAction = useCallback(() => {
        return isActive ? (
            <Button size='small' color='red40' disabled={!isAvailable} onClick={handleDisableStart}>
                {messages.buttonDisable}
            </Button>
        ) : (
            <Button size='small' color='blue80' disabled={!isAvailable} onClick={handleEnableStart}>
                {messages.buttonEnable}
            </Button>
        );
    }, [handleDisableStart, handleEnableStart, isActive, isAvailable]);

    return (
        <>
            <PropertyRowWithButton
                title={messages.optionTFA}
                description={!isActive ? messages.optionTFAUnavailable : undefined}
                active={isActive}
                renderAction={renderAction}
                qa={qa}
            />

            {isEnabled && !isVerified && (
                <Box color='midnight20' spacing='24' radius='xsmall'>
                    <Flex direction='column' spacing='16' alignItems='center' justifyContent='center'>
                        <Text font='body' fontSize='small' fontWeight='regular' color='midnight70'>
                            {messages.blockVerifyDescription}
                        </Text>

                        <Button
                            size='medium'
                            color='blue80'
                            startIcon={<EditIcon size={16} />}
                            onClick={handleVerificationStart}
                        >
                            {messages.buttonEdit}
                        </Button>
                    </Flex>
                </Box>
            )}

            {isEnabled && isVerified && <BackupMethodsList profile={profile} />}

            {isTwoFaEnablingOpen && <TwoFaProfileEnabling />}

            {isTwoFaDisablingOpen && <TwoFaProfileDisabling />}

            {isTwoFaVerifyingOpen && <TwoFaProfileVerifying />}
        </>
    );
});

SubsectionTwoFa.displayName = 'SubsectionTwoFa';
