import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.BackupMethodsList', {
    optionBackupCodes: 'Backup codes',
    optionBackupEmail: 'Backup email',
    buttonEdit: 'Edit',
    buttonAdd: 'Add',
    addBackupEmailTitle: 'Add backup email address',
    editBackupEmailTitle: 'Update backup email address',
});
