import { SearchAccountImage } from '@approvalmax/ui';
import { Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { memo, useMemo } from 'react';

import { useCompanies } from '../../NewRequestPopup.hooks';
import { ManagersList } from '../ManagersList/ManagersList';
import { WorkflowsList } from '../WorkflowsList/WorkflowsList';
import { messages } from './EmptyContent.messages';

export const EmptyContent = memo(() => {
    const { allCompanies, activeCompany } = useCompanies();
    const me = useSelector(selectors.profile.getProfileUser);

    const isAdmin = useMemo(
        () =>
            activeCompany
                ? activeCompany.managers.includes(me.id)
                : allCompanies.some((company) => company.managers.includes(me.id)),
        [activeCompany, allCompanies, me.id]
    );

    return (
        <>
            <Popup.Header />

            <Popup.Body>
                <Flex spacing='24' alignItems='center' direction='column'>
                    <SearchAccountImage width={100} height={100} />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center'>
                        {messages.title({ br: <br /> })}
                    </Text>

                    {isAdmin ? <WorkflowsList /> : <ManagersList />}
                </Flex>
            </Popup.Body>
        </>
    );
});

EmptyContent.displayName = 'EmptyContent';
