import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.PasskeyItem', {
    created: 'Created: {createdDate}',
    areYouSure: 'Are you sure you want to delete this passkey?',
    yes: 'Yes, Delete it',

    placeholder: 'Enter passkey label...',
    buttonSave: 'Save',
    buttonEdit: 'Edit',
    buttonDelete: 'Delete',

    errorText: 'Failed to add a passkey.',
    alreadyAddedError: 'This passkey is already added to your account',
});
