import { PasswordCriteria } from './PasswordValidation.types';

export const getCriteriaColor = (criteria: PasswordCriteria, value: string, invalid?: boolean) => {
    if (criteria.test(value)) {
        return 'green100';
    } else if (invalid) {
        return 'red100';
    } else {
        return 'midnight70';
    }
};
