import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { SendTwoFaVerifyingEmailCode } from './useSendTwoFaVerifyingEmailCode.types';

export const useSendTwoFaVerifyingEmailCode = (
    mutationOptions?: RequestMutationOptions<SendTwoFaVerifyingEmailCode>
) => {
    return useMutate(authApiPaths.sendTwoFaVerifyingEmailCode, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
