import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';

export const useCompanies = () => {
    const activeCompanyId = useSelector(selectors.navigation.getActiveCompanyId);
    const allCompanies = useSelector(selectors.company.companiesSelector);
    const activeCompany = useSelector((state) =>
        activeCompanyId ? selectors.company.getCompanyById(state, activeCompanyId) : null
    );

    return {
        activeCompany,
        allCompanies,
    };
};
