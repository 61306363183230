import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { profileFormModifiedState } from '../ProfileEditPopup.states';

export const useCheckIsModifiedProfileForm = () => {
    const modificators = useRecoilValue(profileFormModifiedState);

    return useMemo(() => Object.values(modificators).some((el) => el), [modificators]);
};
