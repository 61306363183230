import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.NetSuiteStartStep', {
    vraAbbreviatedPluralName: 'VRAs',
    regularSubmitterBubbleText: 'Creation of {pluralTemplateName}',
    externalSubmitterBubbleText: 'Pulling of {pluralTemplateName}',
    regularOfExternalSubmitterBubbleText: 'Creation/Pulling of {pluralTemplateName}',
    submitterListDescriptionEmpty:
        'Add at least one user authorised to create {templateName} and submit them for approval in ApprovalMax.',
    submitterListDescriptionNonEmpty:
        'Add users authorised to create {templateName} and submit them for approval in ApprovalMax.',
    createInAmBubbleText: 'Create in ApprovalMax',
    emailExternalSubmitterTitle:
        'ApprovalMax will create a new bill for each email attachment sent to the email address below.',
    emailExternaSubmitterDescriptionNonEmpty: `
        {pluralTemplateName} will be created in ApprovalMax on behalf of the default requester. 
        Only the default requestor can see the incoming requests and submit them for approval.`,
    emailExternalSubmitterDescriptionEmpty: `
        Enter the user who will be notified about {templateName} status changes in ApprovalMax.\n
        A generic email address, e.g. finance@mycompany.com, can also be used.`,
});
