import { useRef } from 'react';

/**
 * Get the previous value (that was used during the previous render).
 * Use it when useEffect dependencies list is not enough.
 *
 * @deprecated Please, use the similar hook, imported from the `react-use` package
 */
export const usePrevious = <T>(value: T) => {
    // TODO: replace the line after installing react@19
    // const ref = useRef<T>(undefined);
    const ref = useRef<T>();
    const result = ref.current;

    ref.current = value;

    return result;
};
