import { ExtractComponentProp } from '@approvalmax/types';
import { Dropzone, Spacing, Text, toast, useExtensions } from '@approvalmax/ui/src/components';
import { constants } from 'modules/common';
import { FC, memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { commonErrorMessages } from 'services/error/messages';
import { useUploadAttachment } from 'shared/data';
import { OcrFileContentType } from 'shared/data/v2';
import { handleFileUploadLimits, isPdfFileProtected, isValidOcrImage } from 'shared/helpers';

import { FormValues } from '../BulkCreationPopupContent.types';
import { documentPerFileMaxSize, documentPerPageMaxSize } from './UploadFiles.constants';
import { messages } from './UploadFiles.messages';
import { UploadFilesProps } from './UploadFiles.types';

const { commonConstants } = constants;

export const UploadFiles: FC<UploadFilesProps> = memo((props) => {
    const { attachments, setAttachments, disabled } = props;

    const extensions = useExtensions(commonConstants.ocrAllowedFileExtensions);
    const pdfExtension = useExtensions(['pdf']);

    const form = useFormContext<FormValues>();

    const fileContentType = form.watch('fileContentType');

    const { mutateAsync } = useUploadAttachment();

    const onUpload = useCallback<ExtractComponentProp<typeof Dropzone, 'onUpload'>>(
        async (file) => {
            if (file.source.type === 'application/pdf') {
                const isProtected = await isPdfFileProtected(file.source);

                if (isProtected) {
                    toast.info(commonErrorMessages.pdfFileProtectedError({ fileName: file.source.name }));
                }
            }

            if (file.source.type.startsWith('image/')) {
                const url = URL.createObjectURL(file.source);
                const isValidImage = await isValidOcrImage(url);

                if (!isValidImage) {
                    throw new Error(commonErrorMessages.ocrImageResolutionError({ fileName: file.source.name }));
                }

                URL.revokeObjectURL(url);
            }

            return mutateAsync({ file: file.source }).then((value) => {
                setAttachments((attachments) => [...attachments, { innerId: file.id, ...value.data[0] }]);
            });
        },
        [mutateAsync, setAttachments]
    );

    const onRemove = useCallback<ExtractComponentProp<typeof Dropzone, 'onRemove'>>(
        (file) => {
            setAttachments((attachments) => attachments.filter((attachment) => attachment.id !== file.id));
        },
        [setAttachments]
    );

    return (
        <>
            <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                {messages.uploadFilesTitle}
            </Text>

            <Spacing height={16} />

            <Dropzone
                accept={fileContentType === OcrFileContentType.DocumentPerFile ? extensions : pdfExtension}
                multiple
                disabled={disabled}
                onRemove={onRemove}
                onUpload={onUpload}
                filterFiles={handleFileUploadLimits}
                value={attachments}
                maxFilesInList={30}
                noDuplicateNames
                maxSize={
                    fileContentType === OcrFileContentType.DocumentPerFile
                        ? documentPerFileMaxSize
                        : documentPerPageMaxSize
                }
            />
        </>
    );
});

UploadFiles.displayName = 'UploadFiles';
