import { Link } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { getPath, Path } from 'urlBuilder';

import { messages } from './QBooksMatchingPopup.messages';
import { QBooksMatchingLineItem } from './QBooksMatchingPopup.types';

export const getColumnDefinitions = (
    request:
        | selectors.types.QBooksBillExpandedRequest
        | selectors.types.QBooksPoExpandedRequest
        | selectors.types.QBooksExpenseExpandedRequest,
    isAccountLineItem: boolean,
    hideRemaining = false
) => {
    const details = request.details;
    const hasTaxes = details.lineAmountType !== domain.LineAmountType.NoTax;
    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
    const isPO = request.integrationCode === domain.IntegrationCode.QBooksPo;
    const grossRate = details.lineAmountType === domain.LineAmountType.TaxInclusive;
    const currency = request.currency;

    let columnDefinitions: ColumnDefinition<QBooksMatchingLineItem>[] = [];

    if (isPO) {
        columnDefinitions.push(
            {
                id: 'bill',
                name: messages.billColumnName,
                value: (li) => li.name,
                cell: (value, li) => (
                    <Link
                        targetBlank
                        title={`${value}\n${messages.totalAmount({ totalAmount: li.totalAmount })}`}
                        href={getPath(Path.request, li.lineItemId, request.companyId)}
                    >
                        {value}
                    </Link>
                ),
                hideTitle: true,
            },
            {
                id: 'date',
                name: messages.dateColumnName,
                value: (li) => li.date,
            }
        );
    }

    if (isAccountLineItem) {
        columnDefinitions.push(
            {
                id: 'account',
                name: messages.accountColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksAccountLineItem>) =>
                    li.account ? li.account.text : null,
            },
            {
                id: 'description',
                name: messages.descriptionColumnName,
                value: (li) => li.description,
            }
        );

        if (hasTaxes) {
            columnDefinitions.push({
                id: 'taxCodeName',
                name: messages.taxColumnName,
                value: (li) => (li.taxCode ? li.taxCode.text : null),
            });
        }

        if (isBill) {
            columnDefinitions.push({
                id: 'billable',
                name: messages.billableColumnName,
                value: (li) => {
                    return li.isBillable ? messages.billableColumnTooltip : null;
                },
                alignRight: false,
                width: 50,
                cell: (val) => val && <DoneIcon width={13} height={10} />,
            });
            columnDefinitions.push({
                id: 'taxable',
                name: messages.taxableColumnName,
                value: (li) => {
                    return li.isTaxable ? messages.taxableColumnTooltip : null;
                },
                alignRight: false,
                width: 30,
                cell: (val) => val && <DoneIcon width={13} height={10} />,
            });
        }

        columnDefinitions.push(
            {
                id: 'customerName',
                name: messages.customerColumnName,
                value: (li) => (li.customer ? li.customer.text : null),
            },
            {
                id: 'className',
                name: messages.classColumnName,
                value: (li) => (li.class ? li.class.text : null),
            },
            {
                id: 'amount',
                name: messages.amountColumnName({
                    currency,
                }),
                value: (li) => intl.formatNumber(li.amount || 0),
            }
        );
    } else {
        columnDefinitions.push(
            {
                id: 'item',
                name: messages.itemColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksLineItem>) => (li.item ? li.item.text : null),
            },
            {
                id: 'description',
                name: messages.descriptionColumnName,
                value: (li) => li.description,
            },
            {
                id: 'qty',
                name: messages.qtyColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksLineItem>) => intl.formatNumber(li.qty || 0, 'auto'),
                alignRight: true,
            },
            {
                id: 'unitPrice',
                name: messages.unitPriceColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksLineItem>) => {
                    const value = grossRate ? li.unitPriceGross : li.unitPrice;

                    return intl.formatNumber(value || 0, 'auto');
                },
                alignRight: true,
            }
        );

        if (isBill) {
            columnDefinitions.push({
                id: 'billable',
                name: messages.billableColumnName,
                value: (li) => {
                    return li.isBillable ? messages.billableColumnTooltip : null;
                },
                alignRight: false,
                width: 50,
                cell: (val) => val && <DoneIcon width={13} height={10} />,
            });
            columnDefinitions.push({
                id: 'taxable',
                name: messages.taxableColumnName,
                value: (li) => {
                    return li.isTaxable ? messages.taxableColumnTooltip : null;
                },
                alignRight: false,
                width: 30,
                cell: (val) => val && <DoneIcon width={13} height={10} />,
            });
        }

        if (hasTaxes) {
            columnDefinitions.push({
                id: 'taxCodeName',
                name: messages.taxColumnName,
                value: (li) => (li.taxCode ? li.taxCode.text : null),
            });
        }

        columnDefinitions.push(
            {
                id: 'customerName',
                name: messages.customerColumnName,
                value: (li) => (li.customer ? li.customer.text : null),
            },
            {
                id: 'className',
                name: messages.classColumnName,
                value: (li) => (li.class ? li.class.text : null),
            },
            {
                id: 'amount',
                name: messages.amountColumnName({
                    currency,
                }),
                value: (li) => intl.formatNumber(li.amount || 0),
                alwaysVisible: true,
                width: 90,
            }
        );
    }

    if (isBill && !hideRemaining) {
        columnDefinitions.push({
            id: 'remaining',
            name: messages.remainingColumnName({
                currency,
            }),
            value: (li) => intl.formatNumber(li.remainingBalance || 0),
            alwaysVisible: true,
        });
    }

    return columnDefinitions;
};
