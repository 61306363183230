import { selectors } from 'modules/common';
import { UpdateProfile } from 'shared/data/v1';

import { fieldNameSecuritySection } from './SectionSecurity.constants';

export const getSectionSecuritySaveToProfileValues = (
    profile: selectors.types.ExpandedProfile,
    data: UpdateProfile['body']
) => {
    return {
        useSession: data[fieldNameSecuritySection.session] ?? profile.useSession,
    };
};
