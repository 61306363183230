/**
 * Developer: Stepan Burguchev
 * Date: 6/16/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Guid, ValueOf } from '@approvalmax/types';

import { backend } from '..';
import { AccountType } from '../backend';
import { TwoFaEnforcementType } from './TwoFa';

export enum AccountMemberRole {
    Manager = 'Manager',
    Member = 'Member',
    Administrator = 'Administrator',
    UserManager = 'UserManager',
}

export interface ProfileEmail {
    email: string;
    verified: boolean;
    defaultEmail: boolean;
    expirationDate?: string;
}

export enum ProfilePartnerStatus {
    Undefined = 'Undefined',
    Applied = 'Applied',
    StatusVerified = 'StatusVerified',
    DocsSubmitted = 'DocsSubmitted',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Ignored = 'Ignored',
}

export enum ProfileBetaFeature {
    Refiner = 'Refiner',
    PublicAPI = 'PublicAPI',
    BelgradeUINewOrg = 'BelgradeUINewOrg',
    BelgradeUINewRequest = 'BelgradeUINewRequest',
    DoNotShowMarketingPopups = 'DoNotShowMarketingPopups',
    CaptureAddonBilling = 'CaptureAddonBilling',
    BelgradeUINoCompanies = 'BelgradeUINoCompanies',
    DisableWorkflowWizard = 'DisableWorkflowWizard',
    DisablePromoPopups = 'DisablePromoPopups',
    BelgradeUISync = 'BelgradeUISync',
    BelgradeUISubPopup = 'BelgradeUISubPopup',
    BelgradeUIResubmitRequestPopup = 'BelgradeUIResubmitRequestPopup',
    BelgradeUIWatchers = 'BelgradeUIWatchers',
    BelgradeUIEmailToSupplier = 'BelgradeUIEmailToSupplier',
    BelgradeUISlackAuth = 'BelgradeUISlackAuth',
    BelgradeUIXeroBillForm = 'BelgradeUIXeroBillForm',
    BelgradeUIXeroCreateContactPopup = 'BelgradeUIXeroCreateContactPopup',
    BelgradeUISaveAndApprovePopup = 'BelgradeUISaveAndApprovePopup',
    BelgradeUINoteForApproversSection = 'BelgradeUINoteForApproversSection',
    BelgradeUICustomFields = 'BelgradeUICustomFields',
    BelgradeUIFilesUploadSection = 'BelgradeUIFilesUploadSection',
    BelgradeUIProfileEdit = 'BelgradeUIProfileEdit',
    BelgradeUIVendorMessageSection = 'BelgradeUIVendorMessageSection',
    BelgradeUIManualJournalImportPopup = 'BelgradeUIManualJournalImportPopup',
}

export interface CreateAccountData {
    systemName: string;
    accountId: string;
    type: backend.AccountType;
    ownerInfo: {
        id: string;
    };
}

export interface CreateAccountResponse {
    data: CreateAccountData;
}

export interface ProfileAccount {
    accountId: Guid;
    ownerUserProfileId: Guid;
    accountType: AccountType;
    userRole?: AccountMemberRole;
    practiceName?: string;
    isPartnerAccount: boolean;
    partnerStatus: ProfilePartnerStatus;
}

export enum DayOfWeek {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export enum SignUpSource {
    Undefined = 'undefined',
    App = 'app',
    MyAccount = 'my-account',
    PartnerPortal = 'partner-portal',
    Transfer = 'transfer',
    CompanyParticipant = 'company-participant',
    WorkflowEdit = 'workflow-edit',
    PracticeStaffMember = 'practice-staff-member',
    CreatedByAMAdmin = 'created-by-am-admin',
}

export interface Delegate {
    companyId: string;
    delegateFrom: string;
    delegateTo?: string;
    delegateUserId: string | null;
}

export interface Profile {
    id: Guid;
    databaseId: Guid;
    email: string;
    createdDate: string;
    isSubscribedToAd: boolean;
    phone: string;
    skype: string;
    betaFeatures: ProfileBetaFeature[];
    certifications: Array<{
        id: string;
        name: string;
        date: string;
    }>;
    sendApprovalChangeNotifications: boolean;
    sendCommentsNotifications: boolean;
    sendWorkflowNotifications: boolean;
    sendNewRequestsNotifications: boolean;
    sendSummaryNotifications: boolean;
    summaryNotificationsSendOnDays: ValueOf<DayOfWeek>[];
    summaryNotificationsSendAtTime: string;
    timeZoneForEmail: string;
    canCreateNewCompanies: boolean;
    enableXeroSurvey: boolean;
    enableQBooksSurvey: boolean;
    account?: ProfileAccount;
    is2faEnabled: boolean;
    useSession: boolean;
    firstName?: string;
    lastName?: string;
    name?: string;
    delegates: Delegate[];
    isPasswordSet: boolean;
    isSsoLogin: boolean;
    modified: boolean;
    requiredEnforcementTfaType: TwoFaEnforcementType;
    isTFAAlternativeEmailEnabled: boolean;
    isTFABackupCodesEnabled: boolean;
    signUpSource: SignUpSource;
    avatar?: string;
}
