import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.SubsectionPassword', {
    optionPassword: 'Password',
    statusEnabled: '(Enabled)',
    statusDisabled: '(Not set)',
    buttonSetup: 'Setup',
    buttonChange: 'Change',
    buttonCancel: 'Cancel',

    fieldOldPassword: 'Old Password',
    fieldNewPassword: 'New password',

    headlineSetPassword: 'Set your password',
    headlineChangePassword: 'Change your password',

    successText: 'Your password has been successfully updated.',
});
