import { enabledByParams, LazyGetDataParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { PascalCaseToCamelCase } from '@approvalmax/types';
import { useGet, useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteTaxCodes } from './useGetNetSuiteTaxCodes.types';

export const useGetNetSuiteTaxCodes = (
    params?: RequestParams<GetNetSuiteTaxCodes>,
    queryOptions?: RequestQueryOptions<GetNetSuiteTaxCodes>
) => {
    return useGet(integrationApiPaths.getNetSuiteTaxCodes, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetNetSuiteTaxCodes['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetNetSuiteTaxCodes = (queryOptions?: RequestQueryOptions<GetNetSuiteTaxCodes>) => {
    return useLazyGet<
        LazyGetDataParams<{}, GetNetSuiteTaxCodes['params']['query']>,
        PascalCaseToCamelCase<GetNetSuiteTaxCodes['response']>
    >(integrationApiPaths.getNetSuiteTaxCodes, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
