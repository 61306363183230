import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { profileFormInvalidState } from '../ProfileEditPopup.states';

export const useCheckIsInvalidProfileForm = () => {
    const errors = useRecoilValue(profileFormInvalidState);

    return useMemo(() => Object.values(errors).some((el) => el), [errors]);
};
