import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import {
    profileFormDataState,
    profileFormInvalidState,
    profileFormModifiedState,
    profileFormResetState,
} from '../ProfileEditPopup.states';

// TODO: Try to find a way to get rid of <any>
export const useUpdateProfileForm = (formId: string, form: UseFormReturn<any>) => {
    const setProfileFormData = useSetRecoilState(profileFormDataState);
    const setProfileFormInvalid = useSetRecoilState(profileFormInvalidState);
    const setProfileFormModified = useSetRecoilState(profileFormModifiedState);
    const setProfileFormResets = useSetRecoilState(profileFormResetState);

    const values = form.watch();
    const errors = Object.keys(form.formState.errors).length;

    useEffect(() => {
        setProfileFormData((prev) => ({ ...prev, ...values }));
    }, [setProfileFormData, values]);

    useEffect(() => {
        setProfileFormModified((prev) => ({ ...prev, [formId]: form.formState.isDirty }));
    }, [form.formState.isDirty, formId, setProfileFormModified]);

    useEffect(() => {
        setProfileFormInvalid((prev) => ({ ...prev, [formId]: errors > 0 }));
    }, [formId, errors, setProfileFormInvalid]);

    useEffect(() => {
        setProfileFormResets((prev) => ({ ...prev, [formId]: form.reset }));
    }, [form.reset, formId, setProfileFormResets]);
};
