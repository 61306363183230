import { arrayHelpers, compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common/index';
import { ExpandedCompany } from 'modules/common/selectors/types';
import { useSelector } from 'modules/react-redux';
import { useMemo } from 'react';

export const useSortedCompanies = () => {
    const unsortedCompanies = useSelector(selectors.company.companiesSelector);

    return useMemo(
        () =>
            arrayHelpers.arraySort(
                unsortedCompanies,
                compareHelpers.comparatorFor<ExpandedCompany>(compareHelpers.stringComparator2AscI, 'displayName')
            ),
        [unsortedCompanies]
    );
};
