import { validators } from '@approvalmax/ui';
import { z } from 'zod';

import { fieldNamePasswordForm } from './SubsectionPassword.constants';

/**
 * Empty messages used on purpose, they are just hiding hints under the field.
 * To display errors PasswordValidation component is rendered below the form.
 */
export const schemaPasswordForm = (isPasswordSet: boolean) => {
    const validator = z
        .string()
        .trim()
        .min(8, { message: '' })
        .refine((value) => !validators.password(value), { message: '' });

    return z.object({
        [fieldNamePasswordForm.oldPassword]: isPasswordSet ? validator : z.null(),
        [fieldNamePasswordForm.newPassword]: validator,
    });
};
