import { PartyIcon } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './Success.messages';
import { SuccessProps } from './Success.types';

const qa = bemFactory.qa('pro-two-fa-setup-success-popup');

export const Success = memo<SuccessProps>((props) => {
    const { onFinish } = props;

    return (
        <Popup open onToggle={onFinish} size='xsmall' closable>
            <Popup.Body>
                <Flex direction='column' alignItems='center' spacing='24' data-qa={qa()}>
                    <PartyIcon />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center'>
                        {messages.title}
                    </Text>

                    <Text font='body' fontSize='medium' fontWeight='regular' textAlign='center'>
                        {messages.description}
                    </Text>

                    <Button color='blue80' size='large' onClick={onFinish}>
                        {messages.button}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

Success.displayName = 'Success';
