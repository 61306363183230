import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { matchingApiPaths } from '../paths';
import { GetLineItemMatchingSuggestions } from './useGetLineItemMatchingSuggestions.types';

export const useGetLineItemMatchingSuggestions = (
    params?: RequestParams<GetLineItemMatchingSuggestions>,
    queryOptions?: RequestQueryOptions<GetLineItemMatchingSuggestions>
) =>
    useGet(matchingApiPaths.getQBooksLineItemMatchingSuggestions, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetLineItemMatchingSuggestions['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    vendorId: params?.query?.vendorId,
                    billDate: params?.query?.billDate,
                    itemId: params?.query?.itemId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });

export const useLazyGetLineItemMatchingSuggestions = (
    queryOptions?: RequestQueryOptions<GetLineItemMatchingSuggestions>
) =>
    useLazyGet(matchingApiPaths.getQBooksLineItemMatchingSuggestions, {
        queryOptions,
        apiVersion: 'v2',
    });
