import { useCallback, useState } from 'react';
import {
    useSendTwoFaVerifyingEmailCode,
    useVerifyTwoFaWithBackupCode,
    useVerifyTwoFaWithEmailCode,
    useVerifyTwoFaWithOneTimePassword,
} from 'shared/data/v1';

import { WizardProps } from './Wizard.types';

export const useWizard = (props: WizardProps) => {
    const { onFinish: onSuccess } = props;

    const [stateId, setStateId] = useState('');

    const { mutate: requestEmailCode } = useSendTwoFaVerifyingEmailCode({
        onSuccess: (data) => {
            setStateId(data.stateId);
        },
    });
    const { mutate: verifyTwoFaWithEmailCode } = useVerifyTwoFaWithEmailCode({ onSuccess });
    const { mutate: verifyTwoFaWithOneTimePassword } = useVerifyTwoFaWithOneTimePassword({ onSuccess });
    const { mutate: verifyTwoFaWithBackupCode } = useVerifyTwoFaWithBackupCode({ onSuccess });

    const handleEnterAppCode = useCallback(
        (oneTimePassword: string) => {
            verifyTwoFaWithOneTimePassword({ body: { oneTimePassword } });
        },
        [verifyTwoFaWithOneTimePassword]
    );

    const handleEnterBackupCode = useCallback(
        (backupCode: string) => {
            verifyTwoFaWithBackupCode({ body: { backupCode } });
        },
        [verifyTwoFaWithBackupCode]
    );

    const handleRequestEmailCode = useCallback(() => {
        requestEmailCode({});
    }, [requestEmailCode]);

    const handleEnterEmailCode = useCallback(
        (emailCode: string) => {
            verifyTwoFaWithEmailCode({ body: { stateId, emailCode } });
        },
        [stateId, verifyTwoFaWithEmailCode]
    );

    return {
        handleEnterAppCode,
        handleEnterBackupCode,
        handleEnterEmailCode,
        handleRequestEmailCode,
    };
};
