/**
 * Developer: Stepan Burguchev
 * Date: 6/1/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */
import { Identifiable } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { clsx } from 'clsx';
import bindAll from 'lodash/bindAll';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import { ComponentProps, ComponentType, createRef, PropsWithChildren, ReactElement, ReactNode } from 'react';

import { Dropdown, DropdownElement } from '../../../drop';
import GlobalHotKeys from '../../../ui/hotkeys/GlobalHotKeys';
import EditorBase, { BaseEditorProps, BaseEditorState } from '../EditorBase';
import Button from './Button';
import { messages } from './DropdownEditor.messages';
import ListItem from './ListItem';
import MultiValueBoxItem from './MultiValueBoxItem';
import MultiValueButton from './MultiValueButton';
import NoFilterButton from './NoFilterButton';
import Panel from './Panel';
import {
    DropdownBoxItemTheme,
    DropdownButtonElement,
    DropdownButtonProps,
    DropdownDataMode,
    DropdownListItemProps,
    DropdownMode,
    DropdownMultipleValueType,
    DropdownSingleValueType,
    DropdownTheme,
    DropdownValueType,
} from './types';

interface OwnPropsWithDefaults extends PropsWithChildren {
    theme: DropdownTheme;
    className: string;
    displayAttribute: string;
    displaySubAttribute: string;
    multiple: boolean;
    placeholder: string;
    emptyListPlaceholder:
        | ReactElement
        | string
        | number
        | ((filterText: string | null) => ReactElement | string | number);
    buttonComponent: ComponentType<any>;
    listItem: ComponentType<DropdownListItemProps<Identifiable>>;
    boxItem: any;
    dataMode: DropdownDataMode;
    loading: boolean;
    hasMore: boolean;
    warning: boolean;
    items: Identifiable[];

    onLoadItems(filterText: string | null, excludedIds: string[]): void;
    createItemDisplayTextFn(text: string | null): ReactElement | string | number;
    canCreateItemFn(
        text: string | null,
        options: {
            loading: boolean;
            items: Identifiable[];
        }
    ): boolean;
    onCreateItem(text: string | null): Promise<Identifiable | null>;
    error?: boolean;
}

interface OwnPropsWithoutDefaults {
    filterPlaceholder?: string;
    buttonTextSelector?(item: Identifiable | null): string;
    buttonTitleSelector?(item: Identifiable | null): string;
    buttonSubTextSelector?(item: Identifiable | null): string;
    panelMinWidth?: ComponentProps<typeof Dropdown>['panelMinWidth'];
    panelMaxWidth?: ComponentProps<typeof Dropdown>['panelMaxWidth'];
    singleLinePerItem?: boolean;
    buttonTitle?: string;
    leaveSelectedInPanelWhenMultiple?: boolean;
    noLimitHeightPanel?: boolean;
    targetElementId?: string;
    portalClassName?: string;
}

export type PublicOwnProps = BaseEditorProps<DropdownValueType> &
    Partial<OwnPropsWithDefaults> &
    OwnPropsWithoutDefaults;

type DropdownEditorProps = BaseEditorProps<DropdownValueType> & OwnPropsWithDefaults & OwnPropsWithoutDefaults;

interface OwnState extends BaseEditorState {
    mode: DropdownMode;
    activeItem: Identifiable | null;
    isOpen: boolean;
    inputText: string;
    filterText: string | null;
}

class DropdownEditor extends EditorBase<DropdownValueType, PublicOwnProps, OwnState> {
    public static defaultProps = {
        dataMode: DropdownDataMode.Async,
        placeholder: messages.defaultPlaceholder,
        emptyListPlaceholder: messages.defaultEmptyListPlaceholder,
        theme: 'form',
        buttonComponent: null,
        invalid: false,
        warning: false,
        multiple: false,
        displayAttribute: 'text',
        displaySubAttribute: 'subText',
        items: [],
        loading: false,
        hasMore: false,
        panelMaxWidth: 350,
        onLoadItems: () =>
            errorHelpers.throwArgumentError('DropdownEditor must be wrapped in one of the data providers.'),
        listItem: ListItem,
        error: false,
        boxItem: MultiValueBoxItem,
        canCreateItemFn: () => false,
        createItemDisplayTextFn: (text: string | null) => {
            if (!text) return messages.defaultEmptyAddNew;

            return messages.defaultAddNew({
                text: <b>{text}</b>,
            });
        },
    };

    public static NoFilterButton = NoFilterButton;
    public static ListItem = ListItem;
    public static BoxItem = MultiValueBoxItem;

    public static BoxItemTheme = DropdownBoxItemTheme;

    public state: OwnState = {
        mode: DropdownMode.Unfocused,
        activeItem: null,
        isOpen: false,
        inputText: '',
        filterText: null,
        focused: false,
    };

    panelRef = createRef<Panel>();
    private _button: DropdownButtonElement | undefined;
    private _mode = DropdownMode.Unfocused;
    private _activeItem: Identifiable | null = null;
    private _focusWhenUpdated: boolean = false;
    private _commandLoadItemsThrottle: () => void;
    private _commandLoadItemsDebounce: () => void;

    private _handlers = {
        up: () => this._commandMoveActiveItem(-1),
        down: () => this._commandMoveActiveItem(+1),
        enter: () => {
            const { activeItem } = this.state;

            if (activeItem) {
                this._commandSelectItem(activeItem);
            } else {
                // collection is empty and we can create => trigger addNew
                this._commandCreateItem();
            }
        },
        escape: () => this._commandGoFocusPassive(),
    };

    private _dropdownRef = createRef<DropdownElement>();

    constructor(props: DropdownEditorProps) {
        super(props);
        this._commandLoadItemsDebounce = debounce(this._commandLoadItems.bind(this), 500);
        this._commandLoadItemsThrottle = throttle(this._commandLoadItems.bind(this), 50);
        bindAll(
            this,
            ...Object.getOwnPropertyNames(this.constructor.prototype).filter((x) => x.startsWith('_command'))
        );
    }

    public componentDidMount() {
        if (this.props.focusOnMount) {
            this.focus();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: DropdownEditorProps) {
        if (this.props.items !== nextProps.items) {
            let activeItem = this._activeItem;

            const { items, value, multiple } = nextProps;

            // Attempt to fix invalid or empty active item
            if (!activeItem || !items.find((x) => x.id === (activeItem as Identifiable).id)) {
                if (multiple) {
                    activeItem = items[0] || null;
                } else {
                    const singleValue = value as DropdownSingleValueType;

                    activeItem = singleValue
                        ? items.find((x) => x.id === singleValue.id) || items[0] || null
                        : items[0] || null;
                }

                this.setState({
                    activeItem,
                });
            }
        }

        // Note: important to use this._mode here as this.state.mode might be incorrect in componentWillReceiveProps
        if (
            this.props.value !== nextProps.value &&
            this._mode === DropdownMode.Active &&
            !this.props.leaveSelectedInPanelWhenMultiple
        ) {
            // Apply new value into the active editor
            this._commandGoFocusActive(nextProps, true);
        }
    }

    public componentDidUpdate(prevProps: DropdownEditorProps, prevState: OwnState) {
        const prevIsOpen = this._getComputedIsOpen(prevProps, prevState);
        const isOpen = this._getComputedIsOpen(this.props as DropdownEditorProps, this.state);

        if (!prevIsOpen && isOpen) {
            this._scrollActiveItemIntoView();
        }

        if (prevProps.items !== this.props.items && prevIsOpen && isOpen) {
            this._dropdownRef.current!.adjustPanel();
        }

        if (this._focusWhenUpdated) {
            this._button?.focus();
            this._focusWhenUpdated = false;
        }

        if (this.props.error && this.props.error !== prevProps.error) {
            this._onRequestClose();
        }
    }

    public focus() {
        this._button?.focus();
    }

    public blur() {
        this._button?.blur();
    }

    public render() {
        const {
            placeholder,
            filterPlaceholder,
            value,
            theme,
            buttonComponent: ButtonComponent,
            listItem,
            singleLinePerItem,
            boxItem,
            className = '',
            invalid,
            warning,
            multiple,
            items,
            loading,
            hasMore,
            createItemDisplayTextFn,
            qa,
            children,
            panelMaxWidth,
            buttonTextSelector,
            buttonSubTextSelector,
            buttonTitleSelector,
            buttonTitle,
            panelMinWidth,
            noLimitHeightPanel,
            targetElementId,
            portalClassName,
        } = this.props as DropdownEditorProps;
        const { mode, activeItem, filterText, inputText } = this.state;
        const isOpen = this._getComputedIsOpen(this.props as DropdownEditorProps, this.state);
        const canCreateItem = this._getCanCreateItem(this.state);
        const emptyListPlaceholder = this._getEmptyListPlaceholder(this.state);
        const createItemDisplayText = createItemDisplayTextFn(this._getSuggestedNewItemText(this.state));
        const disabled = this._isDisabled();

        const buttonProps = {
            ref: (ref: any) => (this._button = ref),
            theme: theme as any,
            placeholder,
            filterPlaceholder,
            value,
            displayTextSelector: buttonTextSelector || this._getDisplayText,
            displaySubTextSelector: buttonSubTextSelector || this._getDisplaySubText,
            buttonTitleSelector,
            onTextChange: this._commandUpdateFilterText,
            onRemove: this._commandRemoveItem,
            title: buttonTitle,
            mode,
            boxItem,
            disabled,
            invalid,
            warning,
            loading,
            inputText,
        };

        let button: ReactNode;

        if (ButtonComponent) {
            button = <ButtonComponent {...buttonProps}>{children}</ButtonComponent>;
        } else {
            if (multiple) {
                button = <MultiValueButton {...(buttonProps as DropdownButtonProps<DropdownMultipleValueType>)} />;
            } else {
                button = <Button {...(buttonProps as DropdownButtonProps<DropdownSingleValueType>)} />;
            }
        }

        return (
            <Dropdown
                ref={this._dropdownRef}
                isOpen={isOpen}
                onRequestClose={this._onRequestClose}
                panelMinWidth={panelMinWidth}
                panelMaxWidth={panelMaxWidth}
                targetElementId={targetElementId}
                portalClassName={portalClassName}
                button={(ref) => (
                    <GlobalHotKeys handlers={this._handlers} disabled={!isOpen}>
                        <div
                            ref={ref}
                            data-qa={qa}
                            className={clsx(className, 'fs-mask')}
                            onMouseDown={this._onMouseDown}
                            onTouchEnd={this._onTouchEnd}
                            onFocus={!disabled ? this._onFocus : undefined}
                            onBlur={!disabled ? this._onBlur : undefined}
                            role='combobox'
                            aria-expanded={isOpen}
                            aria-haspopup='listbox'
                        >
                            {button}
                        </div>
                    </GlobalHotKeys>
                )}
            >
                <Panel
                    ref={this.panelRef}
                    items={items}
                    value={value}
                    activeItem={activeItem}
                    filterText={filterText}
                    hasMore={hasMore}
                    emptyListPlaceholder={emptyListPlaceholder}
                    displayTextSelector={this._getDisplayText}
                    displaySubTextSelector={this._getDisplaySubText}
                    itemComponent={listItem}
                    singleLinePerItem={singleLinePerItem}
                    onItemSelect={this._commandSelectItem}
                    onChangeActiveItem={this._commandChangeActiveItem}
                    canCreateItem={canCreateItem}
                    createItemDisplayText={createItemDisplayText}
                    onCreateItem={this._commandCreateItem}
                    panelMaxWidth={this.props.panelMaxWidth}
                    noLimitHeight={noLimitHeightPanel}
                />
            </Dropdown>
        );
    }

    protected _onFocus = () => {
        this._commandGoFocusActive(this.props);

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    private _onBlur = () => {
        this._commandGoUnfocused();

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    private _onTouchEnd = () => {
        this._onMouseDown();
    };

    private _onMouseDown = () => {
        if (this.state.mode === DropdownMode.Passive) {
            this._commandGoFocusActive(this.props);
        }
    };

    private _scrollActiveItemIntoView = () => {
        this.panelRef.current?.scrollActiveItemIntoView();
    };

    private _onRequestClose = () => {
        // we definitely know that it's focused.
        // Otherwise, the blur event would close the panel by changing isOpen property and this event wouldn't happen.
        this._button?.blur();
    };

    private _getDisplayText = (item: Identifiable | null): string => {
        return item ? get(item, this.props.displayAttribute as string) : '';
    };

    private _getDisplaySubText = (item: Identifiable | null): string => {
        return item ? (item as any)[this.props.displaySubAttribute as string] : '';
    };

    private _getSuggestedNewItemText = (state: OwnState): string | null => {
        // if filter is null => the user hasn't started typing yet, force the text to be null
        // otherwise => show immediate input from the user, not the filter
        return state.filterText !== null ? state.inputText : null;
    };

    private _getCanCreateItem = (state: OwnState): boolean => {
        return Boolean(
            this.props.canCreateItemFn &&
                this.props.canCreateItemFn(this._getSuggestedNewItemText(state), {
                    loading: Boolean(this.props.loading),
                    items: this.props.items || [],
                })
        );
    };

    private _getEmptyListPlaceholder = (state: OwnState) => {
        const { emptyListPlaceholder } = this.props as DropdownEditorProps;

        if (!isFunction(emptyListPlaceholder)) {
            return emptyListPlaceholder;
        }

        return emptyListPlaceholder(this._getSuggestedNewItemText(state));
    };

    private _getAdjacentItem(items: Identifiable[], item: Identifiable): Identifiable | undefined {
        let idx = items.indexOf(item);
        let nextItem;

        if (idx + 1 < items.length) {
            nextItem = items[idx + 1];
        } else {
            if (idx > 1) {
                nextItem = items[idx - 1];
            }
        }

        return nextItem;
    }

    private _getComputedIsOpen(props: DropdownEditorProps, state: OwnState) {
        return state.isOpen && (props.items.length > 0 || !props.loading);
    }

    private async _commandGoFocusActive(props: PublicOwnProps, forceReapply: boolean = false) {
        if (this.state.mode !== DropdownMode.Active || forceReapply) {
            const { value, items } = props as DropdownEditorProps;

            let activeItem;
            let inputText;

            if (this.props.multiple) {
                activeItem = items[0] || null;
                inputText = '';
            } else {
                const singleValue = value as DropdownSingleValueType;

                activeItem = singleValue
                    ? items.find((x) => x.id === singleValue.id) || items[0] || null
                    : items[0] || null;

                const buttonTextSelector = props.buttonTextSelector || this._getDisplayText;

                inputText = buttonTextSelector(singleValue) || '';
            }

            this._setModeValue(DropdownMode.Active);
            this.setState({
                isOpen: true,
                focused: true,
                inputText,
                filterText: null,
            });
            this._commandChangeActiveItem(activeItem, !props.multiple);
            this.setState({}, () => {
                this._commandLoadItems();
            });
            this._focusWhenUpdated = true;
        }
    }

    private _commandGoFocusPassive() {
        if (this.state.mode !== DropdownMode.Passive) {
            if (this.props.multiple) {
                this.setState({
                    inputText: '',
                });
            }

            this._setModeValue(DropdownMode.Passive);
            this.setState({
                focused: true,
                isOpen: false,
            });
        }
    }

    private _commandGoUnfocused() {
        if (this.state.mode !== DropdownMode.Unfocused) {
            if (this.props.multiple) {
                this._setModeValue(DropdownMode.Unfocused);
                this.setState({
                    inputText: '',
                    focused: false,
                    isOpen: false,
                });
            } else {
                const userLeftEmptyInput = !this.state.inputText && this.props.value !== null;

                this._setModeValue(DropdownMode.Unfocused);
                this.setState({
                    focused: false,
                    isOpen: false,
                });

                if (userLeftEmptyInput) {
                    this.props.onChange(null);
                }
            }
        }
    }

    private _commandLoadItems(updatedValue?: DropdownValueType) {
        const { onLoadItems, multiple, value, leaveSelectedInPanelWhenMultiple } = this.props as DropdownEditorProps;
        const { filterText } = this.state;
        const excludedIds =
            multiple && !leaveSelectedInPanelWhenMultiple
                ? ((updatedValue as DropdownMultipleValueType) || (value as DropdownMultipleValueType) || []).map(
                      (x) => x.id
                  )
                : [];

        onLoadItems(filterText, excludedIds);
    }

    private _commandUpdateFilterText(text: string) {
        const props = this.props as DropdownEditorProps;

        this._commandGoFocusActive(props);
        this.setState({
            filterText: text.trim(),
            inputText: text,
        });

        const normalizedText = text.trim().toLowerCase();
        const foundItem = props.items.find((x) => this._getDisplayText(x).toLowerCase().includes(normalizedText));

        if (foundItem) {
            this._commandChangeActiveItem(foundItem, true);
        }

        this.setState({}, () => {
            if (this.props.dataMode === DropdownDataMode.Async) {
                this._commandLoadItemsDebounce();
            } else {
                this._commandLoadItemsThrottle();
            }
        });
    }

    private async _commandMoveActiveItem(diff: number) {
        const { activeItem } = this.state;
        const { items } = this.props as DropdownEditorProps;

        let nextItem = activeItem;

        if (activeItem) {
            let idx = items.indexOf(activeItem);

            idx += diff;

            if (idx >= 0 && idx < items.length) {
                nextItem = items[idx];
            }
        } else {
            if (items.length > 0) {
                nextItem = items[0];
            }
        }

        this._commandChangeActiveItem(nextItem, true);
    }

    private _commandChangeActiveItem(item: Identifiable | null, scrollIntoView?: boolean) {
        // It's imperative that we set the activeItem property exclusively through this command.
        // The private property this._activeItem is required since setState() doesn't guarantee the update of the state
        // before componentDidUpdate() is called and we use it in componentWillReceiveProps()

        if (this._activeItem === item) {
            return;
        }

        this._activeItem = item;

        if (scrollIntoView) {
            this.setState(
                {
                    activeItem: item,
                },
                this._scrollActiveItemIntoView
            );
        } else {
            this.setState({
                activeItem: item,
            });
        }
    }

    private async _commandCreateItem() {
        if (!this._getCanCreateItem(this.state)) {
            return;
        }

        if (!this.props.onCreateItem) {
            throw errorHelpers.invalidOperationError(
                'The property `onCreateItem` is required if the create feature is on.'
            );
        }

        const suggestedNewItemText = this._getSuggestedNewItemText(this.state);

        if (!this.props.multiple) {
            this._commandGoFocusPassive();
        }

        const newItem = await this.props.onCreateItem(suggestedNewItemText);

        if (newItem) {
            this._commandSelectItem(newItem);
        }
    }

    private _commandSelectItem(item: Identifiable) {
        if (this.props.multiple && this.props.leaveSelectedInPanelWhenMultiple) {
            const value = (this.props.value as DropdownMultipleValueType) || [];

            const filtered = value.filter((x) => x.id !== item.id);

            if (filtered.length !== value.length) {
                // item already selected, deselect
                this.props.onChange(filtered);
            } else {
                this.props.onChange(value.concat(item));
            }
        } else if (this.props.multiple) {
            // Multiple value => add item, empty the input, keep the panel open
            const { items } = this.props as DropdownEditorProps;
            const nextItem = this._getAdjacentItem(items, item);

            if (nextItem) {
                this._commandChangeActiveItem(nextItem, false);
            }

            this.setState({
                inputText: '',
                filterText: null,
            });

            const value = this.props.value as DropdownMultipleValueType;
            const newValue = (value || []).filter((x) => x.id !== item.id).concat(item);

            this.props.onChange(newValue);
            this.setState({}, () => {
                this._commandLoadItems(newValue);
            });
        } else {
            // Single value => replace item, go to passive
            this._commandGoFocusPassive();

            if (this.props.value !== item) {
                this.setState({
                    inputText: this._getDisplayText(item) || '',
                });
                this.props.onChange(item);
            }
        }
    }

    private _commandRemoveItem(item: Identifiable) {
        if (!this.props.multiple) {
            errorHelpers.throwInvalidOperationError();
        }

        const value = this.props.value as DropdownMultipleValueType;
        const newValue = (value || []).filter((x) => x.id !== item.id);

        this.props.onChange(newValue);
        this._commandLoadItems(newValue);
    }

    private _setModeValue(mode: DropdownMode) {
        this._mode = mode;
        this.setState({
            mode,
        });
    }
}

export default DropdownEditor;
