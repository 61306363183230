import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes, useCallback } from 'react';

import { Flex } from '../Flex/Flex';
import { Controller, Option, OptionProps } from './components';
import { labels, options } from './WeekDayPicker.constants';
import { ChildrenComponents, WeekDayPickerProps, WeekDayPickerValue } from './WeekDayPicker.types';

export const WeekDayPicker = memo(
    forwardRef<HTMLInputElement, WeekDayPickerProps>((props, ref) => {
        const { className, qa, onChange, name, invalid, required, disabled, value: selected = [] } = props;

        const checkIsDisabled = useCallback(
            (option: WeekDayPickerValue) => {
                const isDisabledAll = disabled === true;
                const isDisabledCurrent = Array.isArray(disabled) && disabled.includes(option);

                return isDisabledAll || isDisabledCurrent;
            },
            [disabled]
        );

        const handleChange: OptionProps['onChange'] = useCallback(
            (value, event) => {
                const selectedValuesNext = selected.includes(value)
                    ? selected.filter((optionExisted) => optionExisted !== value)
                    : [...selected, value];

                onChange?.(selectedValuesNext, event);
            },
            [onChange, selected]
        );

        return (
            <Flex ref={ref} className={className} alignItems='center' justifyContent='center' spacing='8' wrap={false}>
                {options.map((option) => (
                    <Option
                        key={option}
                        name={name}
                        value={option}
                        text={labels[option]}
                        disabled={checkIsDisabled(option)}
                        checked={selected.includes(option)}
                        invalid={invalid}
                        required={required}
                        onChange={handleChange}
                        groupRef={ref}
                        qa={qa}
                    />
                ))}
            </Flex>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<WeekDayPickerProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

WeekDayPicker.Controller = Controller;

WeekDayPicker.displayName = 'WeekDayPicker';
