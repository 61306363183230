import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useMemo } from 'react';
import { getTemplateSubmitterType, TemplateSubmitter } from 'shared/helpers';

import { messages } from './NetSuiteStartStep.messages';

export const useBubbleText = (integrationCode: domain.IntegrationCode | null) => {
    const pluralTemplateName = useMemo(() => {
        if (integrationCode === domain.IntegrationCode.NetSuiteVRA) {
            return messages.vraAbbreviatedPluralName;
        }

        return selectors.template.getTemplateDisplayNameByCode(integrationCode ?? null, true);
    }, [integrationCode]);

    return useMemo(() => {
        const submitterType = getTemplateSubmitterType(integrationCode);

        switch (submitterType) {
            case TemplateSubmitter.Regular:
                return messages.regularSubmitterBubbleText({ pluralTemplateName });

            case TemplateSubmitter.External:
                return messages.externalSubmitterBubbleText({ pluralTemplateName });

            case TemplateSubmitter.RegularOrExternal:
                return messages.regularOfExternalSubmitterBubbleText({ pluralTemplateName });

            default:
                throw errorHelpers.assertNever(integrationCode ?? '');
        }
    }, [integrationCode, pluralTemplateName]);
};
