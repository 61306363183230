import { Accept } from 'react-dropzone';

export const avatarMaxFileSize = 10000000;
export const avatarMaxFileSizeLabel = `${avatarMaxFileSize / 1000 / 1000} MB`;

export const avatarAllowedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
};

export const acceptedExtensions = Object.values(avatarAllowedFileTypes)
    .map((ext) => ext.map((el) => el.replaceAll('.', '')).join(', '))
    .join(', ');
