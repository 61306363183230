import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { DisableTwoFaWithOneTimePassword } from './useDisableTwoFaWithOneTimePassword.types';

export const useDisableTwoFaWithOneTimePassword = (
    mutationOptions?: RequestMutationOptions<DisableTwoFaWithOneTimePassword>
) => {
    return useMutate(authApiPaths.disableTwoFaWithOneTimePassword, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
