import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

import { TemplateSubmitter } from './getTemplateSubmitterType.types';

export const getTemplateSubmitterType = (integrationCode: domain.IntegrationCode | null) => {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
        case domain.IntegrationCode.QBooksExpense:
        case domain.IntegrationCode.QBooksInvoice:
        case domain.IntegrationCode.QBooksJournalEntry:
        case domain.IntegrationCode.QBooksPo:
        case domain.IntegrationCode.QBooksBill:
        case domain.IntegrationCode.QBooksVendor:
        case domain.IntegrationCode.XeroAirwallexBatchPayment:
        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroContact:
        case domain.IntegrationCode.XeroManualJournal:
        case domain.IntegrationCode.NetSuiteVendor:
        case null:
            return TemplateSubmitter.Regular;

        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.NetSuiteSalesOrder:
        case domain.IntegrationCode.NetSuiteInvoice:
        case domain.IntegrationCode.NetSuiteVRA:
        case domain.IntegrationCode.NetSuiteRA:
        case domain.IntegrationCode.DearPo:
            return TemplateSubmitter.External;

        case domain.IntegrationCode.XeroPo:
        case domain.IntegrationCode.XeroBill:
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.XeroQuote:
        case domain.IntegrationCode.NetSuiteBill:
        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuitePO:
        case domain.IntegrationCode.NetSuiteJournalEntry:
        case domain.IntegrationCode.NetSuiteBillPayment:
            return TemplateSubmitter.RegularOrExternal;

        default:
            throw errorHelpers.assertNever(integrationCode);
    }
};
