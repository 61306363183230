import { List, Tag, Tooltip } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';

import { ExactAsyncConditionTagProps } from './ExactAsyncConditionTag.types';

export const ExactAsyncConditionTag: FC<ExactAsyncConditionTagProps> = memo((props) => {
    const { item, onRemove, tagColor, isNegativeCondition, readonly, conditionType } = props;

    const isGroup = 'group' in item && item.group;
    const groupTagColor = isNegativeCondition ? 'green20' : 'green100';

    const handleRemove = useCallback(() => {
        onRemove(item, conditionType);
    }, [item, onRemove, conditionType]);

    return (
        <Tooltip
            width='fit-content'
            disabled={!isGroup || readonly}
            safePolygon
            activator={
                <Tag
                    closable={!readonly}
                    size='xsmall'
                    onClose={handleRemove}
                    color={isGroup ? groupTagColor : tagColor}
                >
                    {item.text}
                </Tag>
            }
        >
            <List listStyle='inside'>
                {(item.groupValues || []).map(({ id, text }) => (
                    <List.Item key={id}>{text}</List.Item>
                ))}
            </List>
        </Tooltip>
    );
});

ExactAsyncConditionTag.displayName = 'ExactAsyncConditionTag';
