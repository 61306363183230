import { AvatarDropzone, Grid, Text } from '@approvalmax/ui/src/components';
import { fileHelpers } from '@approvalmax/utils';
import { memo } from 'react';

import { acceptedExtensions, avatarAllowedFileTypes, avatarMaxFileSize } from './ProfileAvatar.constants';
import { useUploadAvatar } from './ProfileAvatar.hooks';
import { messages } from './ProfileAvatar.messages';
import { ProfileAvatarProps } from './ProfileAvatar.types';

export const ProfileAvatar = memo<ProfileAvatarProps>((props) => {
    const { disabled = false, avatarUrl } = props;

    const { isLoading, handleUploadAvatar } = useUploadAvatar();

    return (
        <Grid gap={8} gridAutoRows='max-content'>
            <AvatarDropzone
                width='inherit'
                avatarUrl={avatarUrl}
                maxSize={avatarMaxFileSize}
                accept={avatarAllowedFileTypes}
                progress={isLoading}
                disabled={disabled}
                onDropAccepted={handleUploadAvatar}
            />

            <Text font='body' fontSize='xsmall' textAlign='center' color='midnight70'>
                {acceptedExtensions && acceptedExtensions.toUpperCase()}

                <br />

                {messages.maxSizeText({ maxSize: fileHelpers.formatBytes(avatarMaxFileSize, 0) })}
            </Text>
        </Grid>
    );
});

ProfileAvatar.displayName = 'ProfileAvatar';
