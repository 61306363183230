import addOnCaptureImg from '!!file-loader!./assets/AddOnCapture.png';
import addonPayImg from '!!file-loader!./assets/AddonPay.png';
import advancedHoldOnStatus from '!!file-loader!./assets/AdvancedHoldOnStatus.png';
import qboAdvancedBillToPOMatching from '!!file-loader!./assets/QboAdvancedBillToPOMatching.png';
import qboAdvancedGoodsReceivedNotes from '!!file-loader!./assets/QboAdvancedGoodsReceivedNotes.png';
import qboAdvancedWorkflowVersionHistory from '!!file-loader!./assets/QboAdvancedWorkflowVersionHistory.png';
import xeroAdvancedBillToPOMatching from '!!file-loader!./assets/XeroAdvancedBillToPOMatching.png';
import xeroAdvancedBudgetChecking from '!!file-loader!./assets/XeroAdvancedBudgetChecking.png';
import xeroAdvancedGoodsReceivedNotes from '!!file-loader!./assets/XeroAdvancedGoodsReceivedNotes.png';
import xeroAdvancedWorkflowVersionHistory from '!!file-loader!./assets/XeroAdvancedWorkflowVersionHistory.png';
import xeroPremiumBatchPaymentApprovals from '!!file-loader!./assets/XeroPremiumBatchPaymentApprovals.png';
import xeroPremiumPaymentsWithAirwallex from '!!file-loader!./assets/XeroPremiumPaymentsWithAirwallex.png';

import { TrialFeature } from '../../AllFeatureTrialPaywallPopup.types';
import { messages } from './FeatureDescription.messages';
import { FeatureDescription } from './FeatureDescription.types';

export const descriptions: Record<TrialFeature, FeatureDescription> = {
    [TrialFeature.XeroAdvancedBillToPOMatching]: {
        title: messages.xeroAdvancedBillToPOMatchingTitle,
        subtitle: messages.xeroAdvancedBillToPOMatchingSubtitle,
        list: [
            messages.xeroAdvancedBillToPOMatchingList1,
            messages.xeroAdvancedBillToPOMatchingList2,
            messages.xeroAdvancedBillToPOMatchingList3,
            messages.xeroAdvancedBillToPOMatchingList4,
        ],
        image: xeroAdvancedBillToPOMatching,
    },
    [TrialFeature.XeroAdvancedBudgetChecking]: {
        title: messages.xeroAdvancedBudgetCheckingTitle,
        subtitle: messages.xeroAdvancedBudgetCheckingSubtitle,
        list: [
            messages.xeroAdvancedBudgetCheckingList1,
            messages.xeroAdvancedBudgetCheckingList2,
            messages.xeroAdvancedBudgetCheckingList3,
            messages.xeroAdvancedBudgetCheckingList4,
        ],
        image: xeroAdvancedBudgetChecking,
    },
    [TrialFeature.XeroPremiumBatchPaymentApprovals]: {
        title: messages.xeroPremiumBatchPaymentApprovalsTitle,
        subtitle: messages.xeroPremiumBatchPaymentApprovalsSubtitle,
        list: [
            messages.xeroPremiumBatchPaymentApprovalsList1,
            messages.xeroPremiumBatchPaymentApprovalsList2,
            messages.xeroPremiumBatchPaymentApprovalsList3,
            messages.xeroPremiumBatchPaymentApprovalsList4,
        ],
        image: xeroPremiumBatchPaymentApprovals,
    },
    [TrialFeature.XeroAdvancedSupplierOnboarding]: {
        title: messages.xeroAdvancedContactApprovalsTitle,
        subtitle: messages.xeroAdvancedContactApprovalsSubtitle,
        list: [
            messages.xeroAdvancedContactApprovalsList1,
            messages.xeroAdvancedContactApprovalsList2,
            messages.xeroAdvancedContactApprovalsList3,
            messages.xeroAdvancedContactApprovalsList4,
            messages.xeroAdvancedContactApprovalsList5,
        ],
    },
    [TrialFeature.XeroPremiumPaymentsWithAirwallex]: {
        title: messages.xeroPremiumPaymentsWithAirwallexTitle,
        subtitle: messages.xeroPremiumPaymentsWithAirwallexSubtitle,
        list: [
            messages.xeroPremiumPaymentsWithAirwallexList1,
            messages.xeroPremiumPaymentsWithAirwallexList2,
            messages.xeroPremiumPaymentsWithAirwallexList3,
            messages.xeroPremiumPaymentsWithAirwallexList4,
        ],
        endnote: messages.xeroPremiumPaymentsWithAirwallexEndnote,
        image: xeroPremiumPaymentsWithAirwallex,
    },
    [TrialFeature.XeroAdvancedAutoApprovals]: {
        title: messages.xeroAdvancedAutoApprovalsTitle,
        subtitle: messages.xeroAdvancedAutoApprovalsSubtitle,
        list: [
            messages.xeroAdvancedAutoApprovalsList1,
            messages.xeroAdvancedAutoApprovalsList2,
            messages.xeroAdvancedAutoApprovalsList3,
            messages.xeroAdvancedAutoApprovalsList4,
        ],
    },
    [TrialFeature.XeroAdvancedSalesQuoteApprovals]: {
        title: messages.xeroAdvancedSalesQuoteApprovalsTitle,
        subtitle: messages.xeroAdvancedSalesQuoteApprovalsSubtitle,
        list: [
            messages.xeroAdvancedSalesQuoteApprovalsList1,
            messages.xeroAdvancedSalesQuoteApprovalsList2,
            messages.xeroAdvancedSalesQuoteApprovalsList3,
            messages.xeroAdvancedSalesQuoteApprovalsList4,
        ],
    },
    [TrialFeature.XeroAdvancedManualJournalApprovals]: {
        title: messages.xeroAdvancedManualJournalApprovalsTitle,
        subtitle: messages.xeroAdvancedManualJournalApprovalsSubtitle,
        list: [
            messages.xeroAdvancedManualJournalApprovalsList1,
            messages.xeroAdvancedManualJournalApprovalsList2,
            messages.xeroAdvancedManualJournalApprovalsList3,
            messages.xeroAdvancedManualJournalApprovalsList4,
        ],
    },
    [TrialFeature.XeroPremiumStandAloneWorkflows]: {
        title: messages.xeroPremiumStandAloneWorkflowsTitle,
        subtitle: messages.xeroPremiumStandAloneWorkflowsSubtitle,
        list: [
            messages.xeroPremiumStandAloneWorkflowsList1,
            messages.xeroPremiumStandAloneWorkflowsList2,
            messages.xeroPremiumStandAloneWorkflowsList3,
            messages.xeroPremiumStandAloneWorkflowsList4,
        ],
    },
    [TrialFeature.XeroPremiumPublicAPI]: {
        title: messages.xeroPremiumPublicAPITitle,
        subtitle: messages.xeroPremiumPublicAPISubtitle,
        list: [
            messages.xeroPremiumPublicAPIList1,
            messages.xeroPremiumPublicAPIList2,
            messages.xeroPremiumPublicAPIList3,
        ],
    },
    [TrialFeature.XeroAdvancedGoodsReceivedNotes]: {
        title: messages.xeroAdvancedGoodsReceivedNotesTitle,
        subtitle: messages.xeroAdvancedGoodsReceivedNotesSubtitle,
        list: [
            messages.xeroAdvancedGoodsReceivedNotesList1,
            messages.xeroAdvancedGoodsReceivedNotesList2,
            messages.xeroAdvancedGoodsReceivedNotesList3,
            messages.xeroAdvancedGoodsReceivedNotesList4,
        ],
        image: xeroAdvancedGoodsReceivedNotes,
    },
    [TrialFeature.XeroAdvancedWorkflowVersionHistory]: {
        title: messages.xeroAdvancedWorkflowVersionHistoryTitle,
        subtitle: messages.xeroAdvancedWorkflowVersionHistorySubtitle,
        list: [
            messages.xeroAdvancedWorkflowVersionHistoryList1,
            messages.xeroAdvancedWorkflowVersionHistoryList2,
            messages.xeroAdvancedWorkflowVersionHistoryList3,
            messages.xeroAdvancedWorkflowVersionHistoryList4,
            messages.xeroAdvancedWorkflowVersionHistoryList5,
        ],
        image: xeroAdvancedWorkflowVersionHistory,
    },
    [TrialFeature.XeroAdvancedRequestReviewStep]: {
        title: messages.xeroAdvancedRequestReviewStepTitle,
        subtitle: messages.xeroAdvancedRequestReviewStepSubtitle,
        list: [
            messages.xeroAdvancedRequestReviewStepList1,
            messages.xeroAdvancedRequestReviewStepList2,
            messages.xeroAdvancedRequestReviewStepList3,
            messages.xeroAdvancedRequestReviewStepList4,
        ],
    },
    [TrialFeature.XeroAdvancedOnHoldStatus]: {
        title: messages.xeroAdvancedOnHoldStatusTitle,
        subtitle: messages.xeroAdvancedOnHoldStatusSubtitle,
        list: [messages.xeroAdvancedOnHoldStatusList1],
        image: advancedHoldOnStatus,
    },
    [TrialFeature.XeroPremiumPrioritySupport]: {
        title: messages.xeroPremiumPrioritySupportTitle,
        subtitle: messages.xeroPremiumPrioritySupportSubtitle,
        list: [
            messages.xeroPremiumPrioritySupportList1,
            messages.xeroPremiumPrioritySupportList2,
            messages.xeroPremiumPrioritySupportList3,
        ],
    },
    [TrialFeature.QboAdvancedBillToPOMatching]: {
        title: messages.qboAdvancedBillToPOMatchingTitle,
        subtitle: messages.qboAdvancedBillToPOMatchingSubtitle,
        list: [
            messages.qboAdvancedBillToPOMatchingList1,
            messages.qboAdvancedBillToPOMatchingList2,
            messages.qboAdvancedBillToPOMatchingList3,
            messages.qboAdvancedBillToPOMatchingList4,
        ],
        image: qboAdvancedBillToPOMatching,
    },
    [TrialFeature.QboAdvancedVendorOnboarding]: {
        title: messages.qboAdvancedVendorApprovalsTitle,
        subtitle: messages.qboAdvancedVendorApprovalsSubtitle,
        list: [
            messages.qboAdvancedVendorApprovalsList1,
            messages.qboAdvancedVendorApprovalsList2,
            messages.qboAdvancedVendorApprovalsList3,
            messages.qboAdvancedVendorApprovalsList4,
        ],
    },
    [TrialFeature.QboAdvancedAutoApprovals]: {
        title: messages.qboAdvancedAutoApprovalsTitle,
        subtitle: messages.qboAdvancedAutoApprovalsSubtitle,
        list: [
            messages.qboAdvancedAutoApprovalsList1,
            messages.qboAdvancedAutoApprovalsList2,
            messages.qboAdvancedAutoApprovalsList3,
            messages.qboAdvancedAutoApprovalsList4,
        ],
    },
    [TrialFeature.QboAdvancedJournalEntryApprovals]: {
        title: messages.qboAdvancedJournalEntryApprovalsTitle,
        subtitle: messages.qboAdvancedJournalEntryApprovalsSubtitle,
        list: [
            messages.qboAdvancedJournalEntryApprovalsList1,
            messages.qboAdvancedJournalEntryApprovalsList2,
            messages.qboAdvancedJournalEntryApprovalsList3,
            messages.qboAdvancedJournalEntryApprovalsList4,
        ],
    },
    [TrialFeature.QboPremiumStandAloneWorkflows]: {
        title: messages.qboPremiumStandAloneWorkflowsTitle,
        subtitle: messages.qboPremiumStandAloneWorkflowsSubtitle,
        list: [
            messages.qboPremiumStandAloneWorkflowsList1,
            messages.qboPremiumStandAloneWorkflowsList2,
            messages.qboPremiumStandAloneWorkflowsList3,
            messages.qboPremiumStandAloneWorkflowsList4,
        ],
    },
    [TrialFeature.QboAdvancedGoodsReceivedNotes]: {
        title: messages.qboAdvancedGoodsReceivedNotesTitle,
        subtitle: messages.qboAdvancedGoodsReceivedNotesSubtitle,
        list: [
            messages.qboAdvancedGoodsReceivedNotesList1,
            messages.qboAdvancedGoodsReceivedNotesList2,
            messages.qboAdvancedGoodsReceivedNotesList3,
            messages.qboAdvancedGoodsReceivedNotesList4,
        ],
        image: qboAdvancedGoodsReceivedNotes,
    },
    [TrialFeature.QboAdvancedWorkflowVersionHistory]: {
        title: messages.qboAdvancedWorkflowVersionHistoryTitle,
        subtitle: messages.qboAdvancedWorkflowVersionHistorySubtitle,
        list: [
            messages.qboAdvancedWorkflowVersionHistoryList1,
            messages.qboAdvancedWorkflowVersionHistoryList2,
            messages.qboAdvancedWorkflowVersionHistoryList3,
            messages.qboAdvancedWorkflowVersionHistoryList4,
            messages.qboAdvancedWorkflowVersionHistoryList5,
        ],
        image: qboAdvancedWorkflowVersionHistory,
    },
    [TrialFeature.QboAdvancedRequestReviewStep]: {
        title: messages.qboAdvancedRequestReviewStepTitle,
        list: [messages.qboAdvancedRequestReviewStepList1, messages.qboAdvancedRequestReviewStepList2],
    },
    [TrialFeature.QboAdvancedOnHoldStatus]: {
        title: messages.qboAdvancedOnHoldStatusTitle,
        subtitle: messages.qboAdvancedOnHoldStatusSubtitle,
        list: [messages.qboAdvancedOnHoldStatusList1],
        image: advancedHoldOnStatus,
    },
    [TrialFeature.QboPremiumPrioritySupport]: {
        title: messages.qboPremiumPrioritySupportTitle,
        subtitle: messages.qboPremiumPrioritySupportSubtitle,
        list: [
            messages.qboPremiumPrioritySupportList1,
            messages.qboPremiumPrioritySupportList2,
            messages.qboPremiumPrioritySupportList3,
        ],
    },
    [TrialFeature.QboPremiumPublicAPI]: {
        title: messages.qboPremiumPublicAPITitle,
        subtitle: messages.qboPremiumPublicAPISubtitle,
        list: [messages.qboPremiumPublicAPIList1, messages.qboPremiumPublicAPIList2, messages.qboPremiumPublicAPIList3],
    },
    [TrialFeature.AddonsCapture]: {
        title: messages.addOnsCaptureTitle,
        subtitle: messages.addOnsCaptureSubTitle,
        list: [
            messages.addOnCaptureList1,
            messages.addOnCaptureList2,
            messages.addOnCaptureList3,
            messages.addOnCaptureList4,
        ],
        endnoteLarge: messages.addOnCaptureEndNote,
        image: addOnCaptureImg,
    },
    [TrialFeature.AddonsPay]: {
        title: messages.addOnsPayTitle,
        subtitle: messages.addOnsPaySubTitle,
        list: [
            messages.addOnPayList1,
            messages.addOnPayList2,
            messages.addOnPayList3,
            messages.addOnPayList4,
            messages.addOnPayList5,
        ],
        image: addonPayImg,
    },
    [TrialFeature.XeroAdvancedAccrualReports]: {
        title: messages.xeroAdvancedAccrualReportsTitle,
        list: [
            messages.xeroAdvancedAccrualReportsList1,
            messages.xeroAdvancedAccrualReportsList2,
            messages.xeroAdvancedAccrualReportsList3,
            messages.xeroAdvancedAccrualReportsList4,
        ],
    },
    [TrialFeature.XeroAdvancedEditingOnApproval]: {
        title: messages.xeroAdvancedEditingOnApprovalTitle,
        subtitle: messages.xeroAdvancedEditingOnApprovalSubtitle,
        list: [
            messages.xeroAdvancedEditingOnApprovalList1,
            messages.xeroAdvancedEditingOnApprovalList2,
            messages.xeroAdvancedEditingOnApprovalList3,
        ],
    },
    [TrialFeature.XeroAdvancedRequestVersionHistory]: {
        title: messages.xeroAdvancedRequestVersionHistoryTitle,
        subtitle: messages.xeroAdvancedRequestVersionHistorySubtitle,
        list: [
            messages.xeroAdvancedRequestVersionHistoryList1,
            messages.xeroAdvancedRequestVersionHistoryList2,
            messages.xeroAdvancedRequestVersionHistoryList3,
            messages.xeroAdvancedRequestVersionHistoryList4,
        ],
    },
    [TrialFeature.QboAdvancedRequestVersionHistory]: {
        title: messages.qboAdvancedRequestVersionHistoryTitle,
        subtitle: messages.qboAdvancedRequestVersionHistorySubtitle,
        list: [
            messages.qboAdvancedRequestVersionHistoryList1,
            messages.qboAdvancedRequestVersionHistoryList2,
            messages.qboAdvancedRequestVersionHistoryList3,
            messages.qboAdvancedRequestVersionHistoryList4,
        ],
    },
};
