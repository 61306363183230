import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AvatarDropzone', {
    avatarAlt: 'Your photo',
    selectFileButton: 'Upload {file}',
    dragPhotoHint: 'Add photo',
    dragFileHint: 'Upload image',
    dropFileHint: 'Drop the file here',
});

export const errorMessages = defineMessages('components.AvatarDropzone.errorMessages', {
    fileInvalidType: 'Invalid file type',
    fileTooLarge: 'File size more than {maxSize}',
    fileTooSmall: 'File size less than {maxSize}',
    tooManyFiles: 'Only one file can be uploaded',
    tooManyFilesInList: 'Sorry, only {maxFilesInList} files can be uploaded at a time',
    duplicateFile:
        'Please re-upload the file with a different name, as a file with "{fileName}" name is already added.',
    unknownError: 'Unknown error',
});
