import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';
import { FormProvider } from 'react-hook-form';

import { Part } from './components';
import { prepareChildren } from './Form.helpers';
import { ChildrenComponents, FormProps } from './Form.types';

/**
 * The Form component allows you to provide a form context for nested controls.
 * Also change all the components with subcomponent `Controller` to `Component.Controller`.
 */
const Form = memo(
    forwardRef<HTMLFormElement, FormProps>((props, ref) => {
        const { onSubmit, children, form, ...restProps } = props;

        return (
            <FormProvider {...form}>
                <form onSubmit={onSubmit} {...restProps} ref={ref}>
                    {prepareChildren(children, form)}
                </form>
            </FormProvider>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<FormProps & RefAttributes<HTMLFormElement>>> & ChildrenComponents;

Form.displayName = 'Form';
Form.Part = Part;

export default Form;
