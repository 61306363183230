import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useCallback } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { splitViewGoToBarSettingsState } from 'shared/components';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

export const useResetSplitView = (params: { views: ContentSplitViewMode[] }) => {
    const { views } = params;

    const openSplitView = useRecoilValue(openSplitViewState);
    const contentSplitView = useRecoilValue(contentSplitViewState);
    const resetContentSplitView = useResetRecoilState(contentSplitViewState);
    const resetSplitViewGoToBarSettings = useResetRecoilState(splitViewGoToBarSettingsState);

    return useCallback(() => {
        if (!openSplitView || !views.includes(contentSplitView.mode)) {
            return;
        }

        resetSplitViewGoToBarSettings();
        resetContentSplitView();
    }, [openSplitView, views, contentSplitView, resetContentSplitView, resetSplitViewGoToBarSettings]);
};
