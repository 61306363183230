import { z } from 'zod';

import { FormFieldId } from './Content.constants';
import { messages } from './Content.messages';

export const schema = z.object({
    [FormFieldId.file]: z.array(z.object({}).passthrough()).nonempty({
        message: messages.fileRequired,
    }),
});
