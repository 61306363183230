import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { routingService } from 'services/routing';
import { getDefaultPath } from 'urlBuilder';

import { loadPageData } from '../actions';

const usePageData = () => {
    const dispatch = useDispatch();
    const companies = useSelector(selectors.company.companiesSelector);
    const someCompanies = companies.length > 0;

    useMount(() => {
        if (someCompanies) {
            routingService.push(getDefaultPath());
        } else {
            dispatch(loadPageData());
        }
    });
};

export default usePageData;
