import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteLeadSources } from './useGetNetSuiteLeadSources.types';

export const useLazyGetNetSuiteLeadSources = (queryOptions?: RequestQueryOptions<GetNetSuiteLeadSources>) => {
    return useLazyGet<
        LazyGetDataParams<{}, GetNetSuiteLeadSources['params']['query']>,
        GetNetSuiteLeadSources['response']
    >(netSuiteApiPaths.getLeadSources, {
        queryOptions,
        apiVersion: 'v2',
    });
};
