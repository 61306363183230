import { BellIcon } from '@approvalmax/ui';
import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { useNudgeAction } from './NudgeAction.hooks';
import { messages } from './NudgeAction.messages';
import { NudgeActionProps } from './NudgeAction.types';

export const NudgeAction = memo<NudgeActionProps>((props) => {
    const { request, readonly } = props;

    const { approversToNudge, handleNudge, timer, isLocked, isLoading } = useNudgeAction(request);

    if (!approversToNudge.length) {
        return null;
    }

    return (
        <Flex alignItems='end' grow={1}>
            <Flex alignItems='center' spacing='8'>
                <Text font='label' fontSize='small' fontWeight='regular' color='midnight70'>
                    {isLocked
                        ? messages.labelNudged({ approversCount: approversToNudge.length })
                        : messages.labelNonNudged({ approversCount: approversToNudge.length })}
                </Text>

                <Button
                    size='xsmall'
                    color='midnight80'
                    outline
                    startIcon={isLocked ? undefined : <BellIcon size={12} />}
                    disabled={isLocked || readonly}
                    onClick={handleNudge}
                    progress={isLoading}
                >
                    {isLocked ? messages.buttonNudged({ timer }) : messages.buttonNonNudged}
                </Button>
            </Flex>
        </Flex>
    );
});

NudgeAction.displayName = 'NudgeAction';
