import { errorHelpers, numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

export function isAlwaysTrueCondition(condition: domain.MatrixCondition): boolean {
    switch (condition.conditionType) {
        case null:
            return true;

        case domain.ConditionType.NumericRangeCondition:
            return condition.numericRangeConditionType === domain.NumericRangeConditionType.Any;

        case domain.ConditionType.ExactValuesCondition:
        case domain.ConditionType.NegativeExactValuesCondition:
            return (
                (condition.exactValues === null || condition.exactValues.length === 0) &&
                !condition.groupOfXeroAccounts?.length &&
                !condition.allowEditing
            );

        case domain.ConditionType.ServerCondition:
        case domain.ConditionType.BoolCondition:
            return false;

        default:
            return errorHelpers.throwInvalidOperationError();
    }
}

export const filterBrokenConditions = (condition: domain.MatrixCondition): boolean => {
    // INFO:
    // we filter broken conditions from backend, task https://approvalmax.atlassian.net/browse/AM-16906
    // this code will be deleted after we make sure that there are no broken conditions anymore
    if (
        condition.conditionType === domain.ConditionType.NumericRangeCondition &&
        condition.numericRangeConditionType === domain.NumericRangeConditionType.Any
    ) {
        return false;
    }

    return true;
};

export function isValidCondition(condition: domain.MatrixCondition): boolean {
    const allowEditing = Boolean(condition.allowEditing);

    switch (condition.conditionType) {
        case null:
            return true;

        case domain.ConditionType.NumericRangeCondition: {
            const l = condition.numericRangeLess;
            const g = condition.numericRangeGreaterEquals;

            switch (condition.numericRangeConditionType) {
                case domain.NumericRangeConditionType.Above:
                    return numberHelpers.isNumber(g);

                case domain.NumericRangeConditionType.Below:
                    return numberHelpers.isNumber(l);

                case domain.NumericRangeConditionType.Within:
                    return numberHelpers.isNumber(l) && numberHelpers.isNumber(g) && g <= l;

                case domain.NumericRangeConditionType.Any:
                    return true;

                default:
                    return errorHelpers.throwInvalidOperationError();
            }
        }

        case domain.ConditionType.ExactValuesCondition:
        case domain.ConditionType.NegativeExactValuesCondition:
            return (
                (condition.exactValues !== null && condition.exactValues.length > 0) ||
                (!!condition.groupOfXeroAccounts && condition.groupOfXeroAccounts.length > 0) ||
                allowEditing
            );

        case domain.ConditionType.ServerCondition:
            return condition.serverConditionType !== null;

        case domain.ConditionType.BoolCondition:
            return condition.exactConstraintBool !== null;

        default:
            return errorHelpers.throwInvalidOperationError();
    }
}

export function isValidMatrix(matrix: domain.MatrixLine[]) {
    return matrix.every((line) => line.rules.every((r) => r.conditions.every((c) => isValidCondition(c))));
}
