import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { PascalCaseToCamelCase } from '@approvalmax/types';
import { useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteEmployee } from './useLazyGetNetSuiteEmployee.types';

export const useLazyGetNetSuiteEmployee = (queryOptions?: RequestQueryOptions<GetNetSuiteEmployee<true>>) => {
    return useLazyGet<
        LazyGetDataParams<{}, GetNetSuiteEmployee['params']['query']>,
        PascalCaseToCamelCase<GetNetSuiteEmployee['response']>
    >(integrationApiPaths.getNetSuiteEmployee, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
