import { BackupCodes, Button, Checkbox, Flex, Form, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { fieldNames } from './BackupCodesUpdatePopup.constants';
import { useBackupCodesUpdatePopup } from './BackupCodesUpdatePopup.hooks';
import { messages } from './BackupCodesUpdatePopup.messages';
import { BackupCodesUpdatePopupProps } from './BackupCodesUpdatePopup.types';

export const BackupCodesUpdatePopupContent = memo<BackupCodesUpdatePopupProps>((props) => {
    const { form, formSubmit, progress, backupCodes, canSubmit, generateCodes } = useBackupCodesUpdatePopup(props);

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body>
                <Form form={form} onSubmit={formSubmit}>
                    <Flex direction='column' spacing='24'>
                        <Spacing height={16} />

                        <Flex direction='column' spacing='40'>
                            <Flex direction='column' spacing='16'>
                                <BackupCodes isLoading={progress} backupCodes={backupCodes} />

                                <Button color='blue10' size='large' progress={progress} onClick={generateCodes}>
                                    {messages.buttonGenerate}
                                </Button>
                            </Flex>

                            <Checkbox disabled={progress} name={fieldNames.codesAreSaved}>
                                <Text font='label' fontSize='medium' fontWeight='regular'>
                                    {messages.checkboxText}
                                </Text>
                            </Checkbox>
                        </Flex>

                        <Flex direction='column' spacing='16'>
                            <Button type='submit' color='blue80' size='large' progress={progress} disabled={!canSubmit}>
                                {messages.buttonDone}
                            </Button>
                        </Flex>
                    </Flex>
                </Form>
            </Popup.Body>
        </>
    );
});

BackupCodesUpdatePopupContent.displayName = 'BackupCodesUpdatePopupContent';
