import { messages } from './TryPayOrCapturePopupContent.messages';

export const payItemLabels = [
    messages.payListItem1,
    messages.payListItem2,
    messages.payListItem3,
    messages.payListItem4,
    messages.payListItem5,
];

export const captureItemLabels = [
    messages.captureListItem1,
    messages.captureListItem2,
    messages.captureListItem3,
    messages.captureListItem4,
    messages.captureListItem5,
];

export const payVideoSrc = 'https://www.youtube.com/watch?v=WeKhVV5RRGY';
export const captureVideoSrc = 'https://www.youtube.com/watch?v=mGoRPopvdJE';
