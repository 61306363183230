import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { twoFaProfileEnablingState } from './TwoFaProfileEnabling.states';
import { TwoFaProfileEnablingFinishHandler } from './TwoFaProfileEnabling.types';

export const useTwoFaProfileEnabling = () => {
    const [popup, setPopup] = useRecoilState(twoFaProfileEnablingState);

    const handleOpen = useCallback(
        (options: { onFinish?: TwoFaProfileEnablingFinishHandler }) => {
            setPopup((state) => ({
                ...state,
                stage: 'wizard',
                selectedBackupMethod: null,
                onFinish: options.onFinish,
            }));
        },
        [setPopup]
    );

    const handleClose = useCallback(() => {
        setPopup(null);
    }, [setPopup]);

    return {
        handleOpen,
        handleClose,
        stage: popup?.stage ?? null,
    };
};

export const useCheckTwoFaProfileEnablingIsOpen = () => {
    const popup = useRecoilValue(twoFaProfileEnablingState);

    return popup !== null;
};

export const useGoToTwoFaProfileEnablingStage = () => {
    const [popup, setPopup] = useRecoilState(twoFaProfileEnablingState);

    const onFinish = popup?.onFinish;
    const selectedBackupMethod = popup?.selectedBackupMethod ?? null;

    const gotoWizard = useCallback(() => {
        setPopup((state) => ({ ...state, stage: 'wizard', selectedBackupMethod: null }));
    }, [setPopup]);

    const gotoSuccess: TwoFaProfileEnablingFinishHandler = useCallback(
        (selectedBackupMethod) => {
            setPopup((state) => ({ ...state, stage: 'success', selectedBackupMethod }));
        },
        [setPopup]
    );

    const handleFinish = useCallback(() => {
        onFinish?.(selectedBackupMethod);
        setPopup(null);
    }, [onFinish, selectedBackupMethod, setPopup]);

    return {
        gotoWizard,
        gotoSuccess,
        handleFinish,
    };
};
