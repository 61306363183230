import { Children, cloneElement, isValidElement, memo, NamedExoticComponent, useMemo } from 'react';

import { Item } from './components';
import { Root } from './List.styles';
import { ChildrenComponents, ListProps } from './List.types';

/**
 * List is used to display list items.
 */
const List = memo((props) => {
    const { children, spacing, divider, items, removeLastDivider, removeLastSpacing, ...restProps } = props;

    const itemProps = useMemo(() => ({ spacing, divider }), [divider, spacing]);

    const enrichedItems = useMemo(() => {
        return Children.map(children, (child) =>
            isValidElement(child)
                ? cloneElement(child, {
                      ...itemProps,
                      ...(child.props || {}),
                  })
                : child
        );
    }, [children, itemProps]);

    return (
        <Root $removeLastDivider={removeLastDivider} $removeLastSpacing={removeLastSpacing} {...restProps}>
            {items?.map((item, index) => (
                <Item {...itemProps} key={index}>
                    {item}
                </Item>
            )) || enrichedItems}
        </Root>
    );
}) as NamedExoticComponent<ListProps> & ChildrenComponents;

List.Item = Item;

export default List;
