import { dateTimeHelpers } from '@approvalmax/utils';
import { useCallback, useRef, useState } from 'react';

import { StartTimerOptions } from './useTimer.types';

/**
 * This hook provides countdown functionality with some methods to control it.
 * It cnn be useful in situations where you need to display a countdown timer or lock some actions for any period of time.
 *
 * This hook provides:
 *  - raw timer data: how many seconds left
 *  - formatted timer data: time left hh:mm:ss
 *  - is timer active or not
 *  - handler to start countdown
 *  - handler to stop countdown before it finished
 */
export const useTimer = () => {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const [timer, setTimer] = useState(0);

    const handleClearInterval = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, []);

    const handleStartTimer = useCallback(
        (options: StartTimerOptions) => {
            const { seconds, onStart, onFinish } = options;

            handleClearInterval();

            setTimer(seconds);
            onStart?.(seconds);

            intervalRef.current = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer > 1) {
                        return prevTimer - 1;
                    }

                    handleClearInterval();
                    onFinish?.();

                    return 0;
                });
            }, 1000);
        },
        [handleClearInterval]
    );

    const handleStopTimer = useCallback(() => {
        handleClearInterval();
        setTimer(0);
    }, [handleClearInterval]);

    return {
        timer,
        timerFormatted: dateTimeHelpers.formatTime(timer * 1000),
        stopTimer: handleStopTimer,
        startTimer: handleStartTimer,
        isTimerActive: timer > 0,
    };
};
