import { ExtractComponentProp } from '@approvalmax/types';
import { Box, Dropzone, Flex, useExtensions } from '@approvalmax/ui/src/components';
import { constants, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { getAllowedFileExtenstions, isPayOrCapturePromotionsAllowed } from 'shared/helpers';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { netSuiteSendToSupplierState } from '../../../../containers/netsuite/NetSuitePurchaseOrderRequestForm/NetSuitePurchaseOrderRequestForm.states';
import { getContext } from '../../../../selectors/pageSelectors';
import { getFieldsApprovalPermissionBySystemPurpose } from '../../../../selectors/requestSelectors';
import { RequestCapturePromotion } from '../RequestCapturePromotion/RequestCapturePromotion';
import { UploadType } from '../UploadPlaceholder/UploadPlaceholder.types';
import { messages } from './RequestUploadPlaceholder.messages';
import { RequestUploadPlaceholderProps } from './RequestUploadPlaceholder.types';

const { commonConstants } = constants;

export const RequestUploadPlaceholder: FC<RequestUploadPlaceholderProps> = memo((props) => {
    const { request, files, setFiles, onDrop, isLoadingNewSupplierAttachments, isLoadingNewAttachments, filterFiles } =
        props;

    const context = useSelector(getContext);
    const company = useSelector((state) => selectors.company.getCompanyById(state, request.companyId));
    const netSuiteSendToSupplierIsEnabled = useRecoilValue(netSuiteSendToSupplierState);
    const [attachmentFieldPermission] = useSelector((state) =>
        getFieldsApprovalPermissionBySystemPurpose(state, request, [domain.FieldSystemPurpose.XeroAttachment])
    );
    const extensions = useExtensions(getAllowedFileExtenstions(request.integrationType));
    const { canShowPromotion } = useAddonPromotions();

    const isSendToSupplierEnabled =
        ('sendToSupplier' in request.details && request.details.sendToSupplier) || netSuiteSendToSupplierIsEnabled;

    const onDropFiles = useCallback<ExtractComponentProp<typeof Dropzone, 'onDropAccepted'>>(
        (files) => {
            setFiles(files);
            onDrop(files, UploadType.General);
        },
        [onDrop, setFiles]
    );

    const onDropFilesToSupplier = useCallback<ExtractComponentProp<typeof Dropzone, 'onDropAccepted'>>(
        (files) => {
            setFiles(files);
            onDrop(files, UploadType.EmailToSupplier);
        },
        [onDrop, setFiles]
    );

    return (
        <>
            {request.integrationCode &&
                commonConstants.addonPromotionIntegrationCodes.includes(request.integrationCode) &&
                isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Capture) &&
                canShowPromotion(AddonPromotionType.Capture) && <RequestCapturePromotion />}

            {(!isSendToSupplierEnabled || !isLoadingNewSupplierAttachments) && (
                <Box spacing='24' height={isSendToSupplierEnabled ? '50%' : '100%'} width='100%'>
                    <Flex height='100%' justifyContent='center' alignItems='center' minWidth={300}>
                        <Dropzone
                            onDropAccepted={onDropFiles}
                            multiple
                            height='100%'
                            value={files}
                            width={isLoadingNewAttachments ? '400px' : '100%'}
                            progress={isLoadingNewAttachments}
                            hideButton
                            filterFiles={filterFiles}
                            accept={extensions}
                            hideAcceptedExtensions
                            maxSize={context.attachmentMaxSize}
                            maxFilesInList={context.attachmentMaxCount}
                            dragFileMessage={messages.filesDragMessage}
                            disabled={attachmentFieldPermission.disabled}
                        />
                    </Flex>
                </Box>
            )}

            {isSendToSupplierEnabled && !isLoadingNewAttachments && (
                <Box spacing='24' height='50%' width='100%'>
                    <Flex height='100%' justifyContent='center' alignItems='center' minWidth={300}>
                        <Dropzone
                            onDropAccepted={onDropFilesToSupplier}
                            multiple
                            height='100%'
                            value={files}
                            width={isLoadingNewSupplierAttachments ? '400px' : '100%'}
                            progress={isLoadingNewSupplierAttachments}
                            hideButton
                            accept={extensions}
                            hideAcceptedExtensions
                            filterFiles={filterFiles}
                            maxSize={context.attachmentMaxSize}
                            maxFilesInList={context.attachmentMaxCount}
                            dragFileMessage={messages.filesToSupplieDragMessage}
                            disabled={attachmentFieldPermission.disabled}
                        />
                    </Flex>
                </Box>
            )}
        </>
    );
});

RequestUploadPlaceholder.displayName = 'RequestUploadPlaceholder';
