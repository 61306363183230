import { FC, memo } from 'react';

import { CheckCircleFilledIcon, CheckCircleIcon, CloseCircleFilledIcon } from '../../icons';
import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { passwordCriteria } from './PasswordValidation.constants';
import { getCriteriaColor } from './PasswordValidation.helpers';
import { PasswordValidationProps } from './PasswordValidation.types';

export const PasswordValidation: FC<PasswordValidationProps> = memo((props) => {
    const { value = '', invalid } = props;

    return (
        <Flex container spacing='12 16'>
            {passwordCriteria.map((criteria, index) => {
                const color = getCriteriaColor(criteria, value, invalid);

                return (
                    <Flex size={12} key={index} spacing='8' alignItems='center' wrap={false}>
                        {invalid ? (
                            <CloseCircleFilledIcon color={color} size={16} />
                        ) : criteria.test(value) ? (
                            <CheckCircleFilledIcon color={color} size={16} />
                        ) : (
                            <CheckCircleIcon color={color} size={16} />
                        )}

                        <Text font='label' fontSize='small' color={color}>
                            {criteria.message}
                        </Text>
                    </Flex>
                );
            })}
        </Flex>
    );
});

PasswordValidation.displayName = 'PasswordValidation';
