import { AlertIcon, PasskeysQrIcon } from '@approvalmax/ui';
import { Alert, Button, Flex, Spacing } from '@approvalmax/ui/src/components';
import React, { memo, useCallback } from 'react';

import { PropertyRowWithButton } from '../../../PropertyRowWithButton/PropertyRowWithButton';
import { PasskeyItem } from '../PasskeyItem/PasskeyItem';
import { useSubsectionPasskeys } from './SubsectionPasskeys.hooks';
import { messages } from './SubsectionPasskeys.messages';
import { SubsectionPasskeysProps } from './SubsectionPasskeys.types';

export const SubsectionPasskeys = memo<SubsectionPasskeysProps>((props) => {
    const { profile } = props;

    const {
        credentials,
        refetchCredentials,
        isLoadingCredentials,
        showSetup,
        newPasskeyType,
        handleShowPasskeyCreate,
        handleCancelCreate,
    } = useSubsectionPasskeys();

    const renderAction = useCallback(() => {
        if (newPasskeyType !== null) {
            return null;
        }

        return showSetup ? (
            <Button size='small' color='blue80' disabled={isLoadingCredentials} onClick={handleShowPasskeyCreate}>
                {messages.buttonSetup}
            </Button>
        ) : (
            <Flex spacing='16'>
                <Button
                    size='small'
                    color='blue80'
                    outline
                    startIcon={<PasskeysQrIcon size='12' />}
                    disabled={isLoadingCredentials}
                    onClick={handleShowPasskeyCreate}
                >
                    {messages.buttonAddPasskey}
                </Button>
            </Flex>
        );
    }, [handleShowPasskeyCreate, isLoadingCredentials, newPasskeyType, showSetup]);

    return (
        <>
            <PropertyRowWithButton
                title={messages.optionPasskeys}
                description={showSetup ? messages.optionPasskeysUnavailable : undefined}
                active={!showSetup}
                renderAction={renderAction}
            />

            {!profile.isPasswordSet && !profile.isSsoLogin && credentials && credentials.length > 0 && (
                <Alert size='small' color='midnight20' invalid startIcon={<AlertIcon size={16} />}>
                    {messages.alertRemovePasskey}
                </Alert>
            )}

            <Flex direction='column' spacing='12 0'>
                {newPasskeyType && (
                    <PasskeyItem type={newPasskeyType} onChange={refetchCredentials} onCancel={handleCancelCreate} />
                )}

                {credentials?.map((item) => (
                    <PasskeyItem
                        type='passkey'
                        credential={item}
                        onChange={refetchCredentials}
                        key={item.credentialId}
                    />
                ))}
            </Flex>

            {(newPasskeyType || (credentials && credentials.length > 0)) && <Spacing height={16} />}
        </>
    );
});

SubsectionPasskeys.displayName = 'SubsectionPasskeys';
