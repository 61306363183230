import { ChevronRightIcon } from '@approvalmax/ui';
import { Box, Grid, Select } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useCallback } from 'react';

import { useWorkflows } from '../../CopyWorkflowPopup.hooks';
import WarningList from '../WarningList/WarningList';
import { messages } from './SingleForm.messages';
import { SingleFormProps } from './SingleForm.types';

const SingleForm: FC<SingleFormProps> = memo((props) => {
    const { activeWorkflow, availableCompanies, showErrors, selectedCompany, selectedWorkflow, onStateChange } = props;

    const { workflows, isFetching: isFetchingWorkflows } = useWorkflows(selectedCompany, activeWorkflow);

    const onCompanyChange = useCallback(
        (value: string, items: selectors.types.ExpandedCompany[]) => {
            const company = items.find(({ id }) => id === value);

            onStateChange({
                selectedCompany: company || null,
                selectedWorkflow: null,
            });
        },
        [onStateChange]
    );

    const onWorkflowChange = useCallback(
        (value: string, items: selectors.types.ExpandedTemplate[]) => {
            const selectedWorkflow = items.find(({ id }) => id === value);

            onStateChange({
                selectedCompany,
                selectedWorkflow: selectedWorkflow || null,
            });
        },
        [selectedCompany, onStateChange]
    );

    return (
        <>
            <Grid gridTemplateColumns='1fr max-content 1fr' alignItems='center'>
                <Select
                    label={messages.toCompanyFieldLabel}
                    placeholder={messages.selectOrganisation}
                    items={availableCompanies}
                    itemNameKey='displayName'
                    invalid={showErrors && !selectedCompany}
                    value={selectedCompany?.id}
                    onChange={onCompanyChange}
                    autocomplete
                    required
                />

                <Box spacing='0 16'>
                    <ChevronRightIcon size={16} />
                </Box>

                <Select
                    label={messages.workflowFieldLabel}
                    placeholder={messages.selectWorkflow}
                    items={workflows}
                    itemNameKey='displayName'
                    invalid={showErrors && !selectedWorkflow}
                    value={selectedWorkflow?.id}
                    onChange={onWorkflowChange}
                    progress={isFetchingWorkflows}
                    autocomplete
                    required
                />
            </Grid>

            {selectedWorkflow && <WarningList activeWorkflow={activeWorkflow} selectedWorkflows={[selectedWorkflow]} />}
        </>
    );
});

export default SingleForm;
