import { memo } from 'react';

import { Success } from './components/Success/Success';
import { Welcome } from './components/Welcome/Welcome';
import { Wizard } from './components/Wizard/Wizard';
import { useGoToTwoFaSoftEnforcementStage, useTwoFaSoftEnforcement } from './TwoFaSoftEnforcement.hooks';

export const TwoFaSoftEnforcement = memo(() => {
    const { stage, handleClose } = useTwoFaSoftEnforcement();
    const { gotoWelcome, gotoWizard, gotoSuccess, handleFinish } = useGoToTwoFaSoftEnforcementStage();

    switch (stage) {
        case 'welcome':
            return <Welcome onStart={gotoWizard} onClose={handleClose} />;

        case 'wizard':
            return <Wizard onClose={gotoWelcome} onFinish={gotoSuccess} />;

        case 'success':
            return <Success onFinish={handleFinish} />;

        default:
            return null;
    }
});

TwoFaSoftEnforcement.displayName = 'TwoFaSoftEnforcement';
