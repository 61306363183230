import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.GroupOfValuesPopup.EditingPopupContent', {
    popupTitle: 'Edit "{groupName}"',
    save: 'Save',
    fieldType: 'Field type:',
    clear: 'Clear',
    successMessage: '"{groupName}" has been updated.',
    account: 'Account',
    edit: 'Edit',
    noChanges: 'There were no changes in the group {groupName}.',
});
