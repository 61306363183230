import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteCustomers } from './useGetNetSuiteCustomers.types';

export const useLazyGetNetSuiteCustomers = (queryOptions?: RequestQueryOptions<GetNetSuiteCustomers>) => {
    return useLazyGet<LazyGetDataParams<{}, GetNetSuiteCustomers['params']['query']>, GetNetSuiteCustomers['response']>(
        netSuiteApiPaths.getCustomers,
        {
            queryOptions,
            apiVersion: 'v2',
        }
    );
};
