import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ManualJournalImportPopup.Content', {
    title: 'Import Manual Journal',
    button: 'Import',
    warningText: 'Important: Uploading a file will replace your existing data.',
    fileRequired: 'File is required',
    downloadLinkText: 'Download Template File',
    learnMoreText: 'Learn more',
    description: 'ApprovalMax allows you to upload a manual journal as a .csv file.',
    rulesTitle: 'To import your manual journal successfully:',
    rulesItemText1: 'Do not alter the column headings in the template.',
    rulesItemText2: 'Include the date and narration in your first line only.',
    rulesItemText3: 'Date format must be 25/12/2023 or 25 Dec 2023.',
    rulesItemText4: 'Do not import more than 500 lines per file.',
    dropzoneTitle: 'Upload CSV file',
});
