import { Button } from '@approvalmax/ui';
import { Box, Flex, Form, Grid, Text } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useGetFido2Credentials } from 'shared/data/v2';
import { getBrowserName } from 'shared/helpers';

import { PasskeyEdit } from '../PasskeyEdit/PasskeyEdit';
import { PasskeyItem } from '../PasskeyItem/PasskeyItem';
import { useCreatePasskey } from './PasskeysSettings.hooks';
import { Loading } from './PasskeysSettings.loading';
import { messages } from './PasskeysSettings.messages';
import { Title } from './PasskeysSettings.styles';
import { FormState } from './PasskeysSettings.types';

/**
 * @deprecated Redesigned
 */
export const PasskeysSettings = memo(() => {
    const form = useForm<FormState>({
        defaultValues: { label: '', newKey: 'none' },
    });

    const {
        data: credentials,
        refetch: refetchCredentials,
        isLoading: isLoadingCredentials,
    } = useGetFido2Credentials({});

    const handleCancelForm = useCallback(() => {
        form.reset();
    }, [form]);

    const { isCreatingPasskey, createPasskey } = useCreatePasskey({
        onSuccess: handleCancelForm,
    });

    const handleAddPassKey = useCallback(async () => {
        const browserName = getBrowserName();
        const now = moment().format('L HH:mm');

        const passkeyLabel = `${browserName} ${now}`;

        await createPasskey(passkeyLabel);
    }, [createPasskey]);

    const handleAddKeySubmit = form.handleSubmit(async (data) => {
        const { label } = data;

        await createPasskey(label);
    });

    const newKeyForm = form.watch('newKey');

    const hasCredentials = Boolean(credentials?.length);

    const showNewKeyForm = newKeyForm !== 'none';

    const showSetup = !hasCredentials && !showNewKeyForm;

    if (isLoadingCredentials) {
        return <Loading />;
    }

    return (
        <Box width='100%' spacing='12 0'>
            <Flex alignItems='center' justifyContent='space-between' width='100%'>
                <Title>{messages.title}</Title>

                {hasCredentials && (
                    <Button theme='secondary' preset='compact' execute={handleAddPassKey}>
                        {messages.addPassKey}
                    </Button>
                )}

                {showSetup && (
                    <Button preset='compact' execute={handleAddPassKey}>
                        {messages.setup}
                    </Button>
                )}
            </Flex>

            <Grid gap={12} padding='12 0'>
                {showNewKeyForm && (
                    <Form form={form} onSubmit={handleAddKeySubmit}>
                        <Box color='midnight20' spacing='12 16'>
                            <PasskeyEdit isLoading={isCreatingPasskey} onCancel={handleCancelForm} />
                        </Box>
                    </Form>
                )}

                {credentials?.map((item) => {
                    return (
                        <PasskeyItem
                            key={item.credentialId}
                            id={item.credentialId}
                            label={item.label}
                            createdDate={item.createdDate}
                            onItemChange={refetchCredentials}
                        />
                    );
                })}

                {showSetup && (
                    <Text color='black100' font='body' fontSize='small'>
                        {messages.description}
                    </Text>
                )}
            </Grid>
        </Box>
    );
});

PasskeysSettings.displayName = 'PasskeysSettings';
