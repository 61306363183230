import { memo } from 'react';

import { Wizard } from './components/Wizard/Wizard';
import { useGoToTwoFaProfileVerifyingStage, useTwoFaProfileVerifying } from './TwoFaProfileVerifying.hooks';

export const TwoFaProfileVerifying = memo(() => {
    const { stage, handleClose } = useTwoFaProfileVerifying();
    const { handleFinish } = useGoToTwoFaProfileVerifyingStage();

    switch (stage) {
        case 'wizard':
            return <Wizard onClose={handleClose} onFinish={handleFinish} />;

        default:
            return null;
    }
});

TwoFaProfileVerifying.displayName = 'TwoFaProfileVerifying';
