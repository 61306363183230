import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import NetSuiteExternalSubmitterSection from '../NetSuiteExternalSubmitterSection/NetSuiteExternalSubmitterSection';
import { ReceiveOverEmailSection } from '../ReceiveOverEmailSection/ReceiveOverEmailSection';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import { useBubbleText } from './NetSuiteStartStep.hooks';
import { messages } from './NetSuiteStartStep.messages';
import { NetSuiteStartStepProps } from './NetSuiteStartStep.types';

export const NetSuiteStartStep = memo<NetSuiteStartStepProps>((props) => {
    const { showCreateInAMAX = false } = props;

    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state) => selectors.company.getCompanyById(state, template.companyId));
    const pluralTemplateName = template.displayNamePlural;
    const [collapsedPullingSection, setCollapsedPullingSection] = useState<boolean>();
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedReceiveOverEmailSection, setCollapsedReceiveOverEmailSection] = useState<boolean>();

    const bubbleText = useBubbleText(template.integrationCode);

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedPullingSection(collapsed);
        setCollapsedSubmitterSection(collapsed);
    }, []);

    const allCollapsed = showCreateInAMAX
        ? collapsedPullingSection && collapsedSubmitterSection
        : collapsedPullingSection;

    const receiveOverEmailSection = isOcrEnabledForTemplate(company, template.integrationCode);

    const showExternalSubmitterSection = template.integrationCode !== domain.IntegrationCode.NetSuiteVendor;

    return (
        <FixedStepContainer bubbleText={bubbleText} color='brandNetSuite100'>
            {showCreateInAMAX && <CollapseAllButton collapsed={Boolean(allCollapsed)} onCollapse={onCollapse} />}

            {showCreateInAMAX && (
                <StepSubmitterSection
                    cacheId='NetSuiteStartStepStepSubmitter'
                    required
                    headerText={messages.createInAmBubbleText}
                    description={messages.submitterListDescriptionNonEmpty({
                        templateName: template.displayNamePlural,
                    })}
                    descriptionEmpty={messages.submitterListDescriptionEmpty({
                        templateName: template.displayNamePlural,
                    })}
                    collapsed={collapsedSubmitterSection}
                    onCollapse={setCollapsedSubmitterSection}
                />
            )}

            {showExternalSubmitterSection && (
                <NetSuiteExternalSubmitterSection
                    cacheId='NetSuiteStartStepExternalSubmitter'
                    collapsed={collapsedPullingSection}
                    onCollapse={setCollapsedPullingSection}
                />
            )}

            {receiveOverEmailSection && (
                <ReceiveOverEmailSection
                    collapsed={collapsedReceiveOverEmailSection}
                    onCollapse={setCollapsedReceiveOverEmailSection}
                    cacheId='NetSuiteBillStartStepReceiveOverEmail'
                    description={messages.emailExternaSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                    descriptionEmpty={messages.emailExternalSubmitterDescriptionEmpty({
                        templateName: template.displayName,
                    })}
                    title={messages.emailExternalSubmitterTitle}
                />
            )}
        </FixedStepContainer>
    );
});

NetSuiteStartStep.displayName = 'NetSuiteStartStep';
