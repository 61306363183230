import moment from 'moment';
import { useCallback } from 'react';

/**
 * This hook manages data storage in localStorage to track information about nudged requests and their time limits.
 * It organizes the data in key-value pairs, where the format is:
 * [<companyId>:<requestId>]: <date of nudge block end>
 *
 * Additionally, the hook provides utility functions for easier management of nudge-related data in localStorage:
 *  - getLockingTime,
 *  - setLockingTime,
 *  - clearLockingTIme,
 */
export const useNudgeLocalStorage = (companyId: string) => {
    const key = 'nudge';

    const getRecordKey = useCallback(
        (requestId: string) => {
            return `${companyId}:${requestId}`;
        },
        [companyId]
    );

    const getData = useCallback((): Record<string, string> => {
        const dataString = localStorage.getItem(key);

        return dataString ? JSON.parse(dataString) : {};
    }, []);

    const setData = useCallback((data: Record<string, string>) => {
        localStorage.setItem(key, JSON.stringify(data));
    }, []);

    const getLockingTime = useCallback(
        (requestId: string) => {
            const data = getData();

            const recordKey = getRecordKey(requestId);

            if (data[recordKey]) {
                const now = moment();
                const until = moment(data[recordKey]);
                const seconds = until.diff(now, 'seconds');

                return Math.max(seconds, 0);
            }

            return 0;
        },
        [getData, getRecordKey]
    );

    const updateLockingTime = useCallback(
        (requestId: string, seconds: number) => {
            const data = getData();
            const recordKey = getRecordKey(requestId);

            const now = moment();
            const until = now.add(seconds, 'seconds');

            data[recordKey] = until.toISOString();

            setData(data);
        },
        [getData, getRecordKey, setData]
    );

    const clearLockingTIme = useCallback(
        (requestId: string) => {
            const data = getData();
            const recordKey = getRecordKey(requestId);

            delete data[recordKey];

            setData(data);
        },
        [getData, getRecordKey, setData]
    );

    const setLockingTime = useCallback(
        (requestId: string, seconds: number) => {
            if (seconds <= 0) {
                clearLockingTIme(requestId);
            } else {
                updateLockingTime(requestId, seconds);
            }
        },
        [clearLockingTIme, updateLockingTime]
    );

    return {
        getLockingTime,
        setLockingTime,
        clearLockingTIme,
    };
};
