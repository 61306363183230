import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { StartAddingTwoFaAlternativeEmail } from './useStartAddingTwoFaAlternativeEmail.types';

export const useStartAddingTwoFaAlternativeEmail = (
    mutationOptions?: RequestMutationOptions<StartAddingTwoFaAlternativeEmail>
) => {
    return useMutate(authApiPaths.startAddingTwoFaAlternativeEmail, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
