import { TextButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import AddBackupCodesPopup from '../AddBackupCodesPopup/AddBackupCodesPopup';
import UpdateBackupCodesPopup from '../UpdateBackupCodesPopup/UpdateBackupCodesPopup';
import UpdateBackupEmailPopup from '../UpdateBackupEmailPopup/UpdateBackupEmailPopup';
import { messages } from './BackupOptionsSection.messages';
import { List, ListItem, ListItemText } from './BackupOptionsSection.styles';

/**
 * @deprecated Redesigned
 */
const BackupOptionsSection: FC = memo(() => {
    const profile = useSelector(selectors.profile.getProfile);
    const [isBackupEmailOpened, toggleIsBackupEmailOpened] = useToggle(false);
    const [isAddBackupCodesOpened, toggleIsAddBackupCodesOpened] = useToggle(false);
    const [isUpdateBackupCodesOpened, toggleIsUpdateBackupCodesOpened] = useToggle(false);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>{messages.backupCodes}</ListItemText>

                    {profile.isTFABackupCodesEnabled ? (
                        <TextButton execute={toggleIsUpdateBackupCodesOpened}>{messages.edit}</TextButton>
                    ) : (
                        <TextButton execute={toggleIsAddBackupCodesOpened}>{messages.add}</TextButton>
                    )}
                </ListItem>

                <ListItem>
                    <ListItemText>{messages.backupEmail}</ListItemText>

                    <TextButton execute={toggleIsBackupEmailOpened}>
                        {profile.isTFAAlternativeEmailEnabled ? messages.edit : messages.add}
                    </TextButton>
                </ListItem>
            </List>

            {isBackupEmailOpened && (
                <UpdateBackupEmailPopup
                    onClose={toggleIsBackupEmailOpened}
                    title={
                        profile.isTFAAlternativeEmailEnabled
                            ? messages.editBackupEmailTitle
                            : messages.addBackupEmailTitle
                    }
                />
            )}

            {isAddBackupCodesOpened && <AddBackupCodesPopup onClose={toggleIsAddBackupCodesOpened} />}

            {isUpdateBackupCodesOpened && <UpdateBackupCodesPopup onClose={toggleIsUpdateBackupCodesOpened} />}
        </>
    );
});

export default BackupOptionsSection;
