export const authApiPaths = <const>{
    extendSession: 'Auth/extendSession',
    cannyIoToken: 'Auth/getCannyIoToken',
    xeroOAuthUrl: 'Auth/getXeroOAuthUrl',
    qBooksOAuthUrl: 'Auth/getQBooksOAuthUrl',
    googleOAuthUrl: 'Auth/getGoogleOAuthUrl',
    microsoftOAuthUrl: 'Auth/getMicrosoftOAuthUrl',
    logout: 'Auth/logout',
    register: 'Auth/register',
    resetPassword: 'Auth/resetPwd',
    registerViaGoogle: 'Auth/registerViaGoogleOAuth',
    registerViaMicrosoft: 'Auth/registerViaMicrosoftOAuth',
    registerViaQBooks: 'Auth/registerViaQBooksOAuth',
    registerViaXero: 'Auth/registerViaXeroOAuth',
    loginViaGoogle: 'Auth/loginViaGoogleOAuth',
    loginViaMicrosoft: 'Auth/loginViaMicrosoftOAuth',
    loginViaQBooks: 'Auth/loginViaQBooksOAuth',
    loginViaXero: 'Auth/loginViaXeroOAuth',
    disableTwoFaWithOneTimePassword: 'Auth/disableTFAWithOneTimePassword',
    disableTwoFaWithBackupCode: 'Auth/disableTFAWithBackupCode',
    sendTwoFaDisablingEmailCode: 'Auth/sendTFADisablingEmailCode',
    disableTwoFaWithEmailCode: 'Auth/disableTFAWithEmailCode',
    sendTwoFaVerifyingEmailCode: 'Auth/sendTFAVerifyingEmailCode',
    verifyTwoFaWithEmailCode: 'Auth/verifyTFAWithEmailCode',
    verifyTwoFaWithOneTimePassword: 'Auth/verifyTFAWithOneTimePassword',
    verifyTwoFaWithBackupCode: 'Auth/verifyTFAWithBackupCode',
    startAddingTwoFaAlternativeEmail: 'Auth/startAddingTFAAlternativeEmail',
    finishAddingTwoFaAlternativeEmail: 'Auth/finishAddingTFAAlternativeEmail',
    startAddingTwoFaBackupCodes: 'Auth/startAddingTFABackupCodes',
    finishAddingTwoFaBackupCodes: 'Auth/finishAddingTFABackupCodes',
    getTwoFaAlternativeMethods: 'Auth/getTFAAlternativeMethods',
    generateTwoFaBackupCodes: 'Auth/generateTFABackupCodes',
    getTwoFaEnforcementInformation: 'Auth/tfaEnforcementInformation',
};
