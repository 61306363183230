import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { VerifyTwoFaWithBackupCode } from './useVerifyTwoFaWithBackupCode.types';

export const useVerifyTwoFaWithBackupCode = (mutationOptions?: RequestMutationOptions<VerifyTwoFaWithBackupCode>) => {
    return useMutate(authApiPaths.verifyTwoFaWithBackupCode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
