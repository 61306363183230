import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuitePartners } from './useGetNetSuitePartners.types';

export const useLazyGetNetSuitePartners = (queryOptions?: RequestQueryOptions<GetNetSuitePartners>) => {
    return useLazyGet<LazyGetDataParams<{}, GetNetSuitePartners['params']['query']>, GetNetSuitePartners['response']>(
        netSuiteApiPaths.getPartners,
        {
            queryOptions,
            apiVersion: 'v2',
        }
    );
};
