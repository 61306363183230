import { CloseIcon } from '@approvalmax/ui';
import { Button, Form, Grid, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { type FormState } from '../PasskeysSettings/PasskeysSettings.types';
import { messages } from './PasskeyEdit.messages';
import { PasskeyEditProps } from './PasskeyEdit.types';

/**
 * @deprecated Redesigned
 */
export const PasskeyEdit = memo<PasskeyEditProps>((props) => {
    const { isLoading, onCancel } = props;

    const formContext = useFormContext<FormState>();

    const label = formContext.watch('label');

    return (
        <Form.Part>
            <Grid gridTemplateColumns='auto max-content max-content' gap={16} alignItems='center'>
                <TextField name='label' size='small' placeholder={messages.labelPlaceholder} initFocus />

                <Button type='submit' color='green80' size='small' progress={isLoading} disabled={!label}>
                    {messages.save}
                </Button>

                <Button icon onClick={onCancel}>
                    <CloseIcon size={16} />
                </Button>
            </Grid>
        </Form.Part>
    );
});

PasskeyEdit.displayName = 'PasskeyEdit';
