import { Button, Grid, List, Text } from '@approvalmax/ui/src/components';
import { useSortedCompanies } from 'modules/common';
import { useDispatch } from 'modules/react-redux';
import { closeActivePopup } from 'modules/request/actions';
import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { getWorkflowsLink } from './WorkflowsList.helpers';
import { messages } from './WorkflowsList.messages';

export const WorkflowsList = memo(() => {
    const dispatch = useDispatch();
    const sortedCompanies = useSortedCompanies();

    const handleClick = useCallback(() => {
        dispatch(closeActivePopup());
    }, [dispatch]);

    return (
        <Grid gap={24}>
            <Text font='body' fontSize='medium' textAlign='center'>
                {messages.description}
            </Text>

            {sortedCompanies.length > 0 && (
                <div>
                    <Text spacing='16 0 24' font='headline' fontSize='xxsmall' fontWeight='medium'>
                        {messages.organisations}
                    </Text>

                    <List divider spacing='8 0'>
                        {sortedCompanies.map((company) => (
                            <List.Item key={company.id}>
                                <Grid gap={12} gridTemplateColumns='1fr auto' alignItems='center'>
                                    <Text font='label' fontSize='medium'>
                                        {company.displayName}
                                    </Text>

                                    <Button
                                        as={Link}
                                        to={getWorkflowsLink(company.id)}
                                        size='small'
                                        color='midnight40'
                                        onClick={handleClick}
                                    >
                                        {messages.buttonText}
                                    </Button>
                                </Grid>
                            </List.Item>
                        ))}
                    </List>
                </div>
            )}
        </Grid>
    );
});

WorkflowsList.displayName = 'WorkflowsList';
