import { Button, Flex, Form, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { fieldNames } from '../../BackupEmailUpdatePopup.constants';
import { useBackupMethodsUpdateEmailFormContext } from '../../BackupEmailUpdatePopup.hooks';
import { messages } from './StepEmailEnter.messages';
import { StepEmailEnterProps } from './StepEmailEnter.types';

const qa = bemFactory.qa('pro-two-fa-setup-email-enter');

export const StepEmailEnter = memo<StepEmailEnterProps>((props) => {
    const { progress } = props;

    const form = useBackupMethodsUpdateEmailFormContext();

    const fieldEmail = form.watch(fieldNames.email);

    return (
        <Form.Part>
            <Spacing height={16} />

            <Flex direction='column' spacing='24' data-qa={qa()} container>
                <Flex size={16} direction='column' spacing='16'>
                    <Text font='label' fontSize='medium' fontWeight='regular'>
                        {messages.instruction}
                    </Text>
                </Flex>

                <Flex size={10} direction='column' spacing='16'>
                    <TextField
                        type='email'
                        name={fieldNames.email}
                        required
                        initFocus
                        disabled={progress}
                        label={messages.emailLabel}
                        placeholder={messages.emailPlaceholder}
                    />
                </Flex>

                <Flex size={24} direction='column' spacing='16'>
                    <Button
                        type='submit'
                        size='large'
                        color='blue80'
                        progress={progress}
                        disabled={!fieldEmail || progress}
                    >
                        {messages.nextButton}
                    </Button>
                </Flex>
            </Flex>
        </Form.Part>
    );
});

StepEmailEnter.displayName = 'StepEmailEnter';
