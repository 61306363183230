import { type MutableRefObject, useCallback, useRef } from 'react';

/**
 * When using React.forwardRef() you cannot use the ref you forward, only assign it to an element.
 *
 * This hook gives you an element ref that you CAN use for HTML measurements etc, as well as
 * the onMount function that has to be passed into an elements `ref={}` prop.
 */
export const useForwardedRef = <THTMLElement>(
    // TODO: replace the line after installing react@19
    ref: ((instance: THTMLElement) => void) | /* RefObject */ MutableRefObject<THTMLElement> | null
) => {
    // TODO: replace the line after installing react@19
    // const elRef = useRef<THTMLElement>(null);
    const elRef = useRef<THTMLElement | null>(null);
    const onMount = useCallback(
        (el: THTMLElement) => {
            if (typeof ref === 'function') {
                ref(el);
            } else if (ref) {
                ref.current = el;
            }

            elRef.current = el;
        },
        [ref]
    );

    return <const>[onMount, elRef];
};
