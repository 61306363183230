import { forwardRef, memo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { AvatarDropzone } from '../../AvatarDropzone';
import { AvatarDropzoneProps } from '../../AvatarDropzone.types';
import { ControllerProps } from './Controller.types';

/**
 * The `AvatarDropzone.Controller` allows you to use the `AvatarDropzone` with the `react-hook-form` library.
 * They take the same properties as the `AvatarDropzone` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
export const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, defaultValue, onDropAccepted, ...restProps } = props;

        const { field, fieldState } = useController({
            control,
            name,
            defaultValue,
        });

        const { onChange, ...restField } = field;

        const handleDropAccepted = useCallback<NonNullable<AvatarDropzoneProps['onDropAccepted']>>(
            (files, event) => {
                onDropAccepted?.(files, event);
                onChange(files);
            },
            [onChange, onDropAccepted]
        );

        return (
            <AvatarDropzone
                {...restField}
                {...fieldState}
                {...restProps}
                onDropAccepted={handleDropAccepted}
                ref={ref}
                hint={fieldState.error?.message || restProps.hint}
            />
        );
    })
);

Controller.displayName = 'Controller';
