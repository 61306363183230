import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { DisableTwoFaWithEmailCode } from './useDisableTwoFaWithEmailCode.types';

export const useDisableTwoFaWithEmailCode = (mutationOptions?: RequestMutationOptions<DisableTwoFaWithEmailCode>) => {
    return useMutate(authApiPaths.disableTwoFaWithEmailCode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
