import { validators } from '@approvalmax/ui';
import { backend, domain } from 'modules/data';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { CustomFieldsState } from '../../data/types/CustomFields';
import { messages } from './customFields.messages';
import { ValidateCustomFieldsParams } from './customFields.types';

const isHttpValid = (urlString: string) => {
    try {
        const newUrl = new URL(urlString);

        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch {
        return false;
    }
};

export const checkIfCustomFieldHasValue = (field: domain.NetSuiteCustomField) => {
    if (field.fieldType === 'CheckBox') {
        return true;
    }

    const hasValue =
        field.valueBoolean !== null ||
        field.valueDate ||
        field.valueDecimal !== null ||
        field.valueLong !== null ||
        field.valueRef ||
        field.valueRefs ||
        field.valueString;

    return Boolean(hasValue);
};

export const getCustomFieldsTransfer = (
    customFields: domain.NetSuiteCustomField[] | undefined
): backend.transfers.IntegrationNetSuiteCustomField[] | null => {
    if (!customFields) {
        return null;
    }

    return customFields
        .filter((field) => checkIfCustomFieldHasValue(field))
        .map((field) => {
            if (field.valueRef) {
                return {
                    ...field,
                    valueRefId: field.valueRef.id,
                    valueRefIds: null,
                };
            }

            if (field.valueRefs) {
                return {
                    ...field,
                    valueRefId: null,
                    valueRefIds: field.valueRefs.map((valueRef) => valueRef.id),
                };
            }

            return {
                ...field,
                valueRefId: null,
                valueRefIds: null,
            };
        });
};

export const getCustomFieldIsInvalid = (
    field: domain.Field,
    fieldData?: domain.NetSuiteCustomField,
    workflowId?: string
) => {
    if (field.netSuiteField?.type === 'CheckBox') {
        return false;
    }

    const isMandatoryField = field.workflows?.some(
        (workflow) => workflow.workflowId === workflowId && workflow.isMandatory
    );

    if (!fieldData) {
        return Boolean(isMandatoryField);
    }

    if (
        field.netSuiteField?.type === 'EMailAddress' &&
        fieldData.valueString &&
        !validators.isEmail(fieldData.valueString)
    ) {
        return true;
    }

    if (
        field.netSuiteField?.type === 'PhoneNumber' &&
        fieldData.valueString &&
        !isPossiblePhoneNumber(fieldData.valueString, 'US')
    ) {
        return true;
    }

    if (field.netSuiteField?.type === 'Hyperlink' && fieldData.valueString && !isHttpValid(fieldData.valueString)) {
        return true;
    }

    if (isMandatoryField) {
        const hasValue = checkIfCustomFieldHasValue(fieldData);

        return !hasValue;
    }

    return false;
};

export const validateCustomFields = (params: ValidateCustomFieldsParams) => {
    const { customFieldsValues, customFieldsList, customFieldsLevels, workflowId, errors } = params;

    customFieldsValues.forEach((field) => {
        if (field.fieldType === 'EMailAddress' && field.valueString) {
            const isInvalidEmail = !validators.isEmail(field.valueString);

            if (isInvalidEmail) {
                errors.push(messages.emailError);
            }
        }

        if (field.fieldType === 'PhoneNumber' && field.valueString) {
            const isInvalidPhone = !isPossiblePhoneNumber(field.valueString || '', 'US');

            if (isInvalidPhone) {
                errors.push(messages.phoneError);
            }
        }

        if (field.fieldType === 'Hyperlink' && field.valueString) {
            const isInvalidLink = !isHttpValid(field.valueString);

            if (isInvalidLink) {
                errors.push(messages.hyperLinkError);
            }
        }
    });

    customFieldsList.forEach((field) => {
        if (!customFieldsLevels.includes(field.netSuiteField?.level ?? '')) {
            return;
        }

        const fieldWorkflowSettings = field.workflows?.find((workflow) => workflow.workflowId === workflowId);

        if (fieldWorkflowSettings?.isMandatory) {
            const fieldData = customFieldsValues.find((fieldValue) => fieldValue.companyFieldId === field.id);

            const hasFieldValue = fieldData
                ? checkIfCustomFieldHasValue(fieldData)
                : field.netSuiteField?.type === 'CheckBox';

            if (!hasFieldValue) {
                errors.push(messages.mandatoryFieldsError);
            }
        }
    });
};

export const getCustomFieldMaxLength = (field: domain.Field): number | undefined => {
    switch (field.netSuiteField?.type) {
        case 'PhoneNumber':
            return 32;

        case 'FreeFormText':
            return 300;

        case 'LongText':
            return 500000;

        case 'TextArea':
            return 4000;

        case 'EMailAddress':
            return 254;
    }

    return;
};

export const sortCustomFields = (customFields: domain.Field[]) => {
    return [...customFields].sort((field1, field2) => {
        if (field1.netSuiteField && field2.netSuiteField) {
            // Custom segments fields first
            if (
                field1.netSuiteField.fieldScriptId.startsWith('cseg') &&
                !field2.netSuiteField.fieldScriptId.startsWith('cseg')
            ) {
                return -1;
            }

            if (
                !field1.netSuiteField.fieldScriptId.startsWith('cseg') &&
                field2.netSuiteField.fieldScriptId.startsWith('cseg')
            ) {
                return 1;
            }

            return field1.netSuiteField.order - field2.netSuiteField.order;
        }

        return 0;
    });
};

export const convertCustomFieldsToState = (fields: domain.NetSuiteCustomField[]): CustomFieldsState => {
    return fields.reduce<CustomFieldsState>((total, current) => {
        total[current.companyFieldId] = current;

        return total;
    }, {});
};
