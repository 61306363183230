import { Popup } from '@approvalmax/ui/src/components';
import { FC, memo, useState } from 'react';

import { Content } from './components';
import { ManualJournalImportPopupProps } from './ManualJournalImportPopup.types';

export const ManualJournalImportPopup: FC<ManualJournalImportPopupProps> = memo((props) => {
    const { open, onToggle, onSuccess } = props;

    const [loading, setLoading] = useState(false);

    return (
        <Popup open={open} onToggle={onToggle} size='small' preventAutoClose closable={!loading}>
            <Content onSuccess={onSuccess} setLoading={setLoading} />
        </Popup>
    );
});

ManualJournalImportPopup.displayName = 'ManualJournalImportPopup';
