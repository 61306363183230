import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { stickyColumnZIndex, stickyHeaderColumnZIndex, stickyHeaderZIndex } from '../../Table.constants';
import { stickyHeaderCellBorder, stickyShadow } from '../../Table.mixins';
import { BaseItem, ColumnDefinition } from '../../Table.types';

export const Root = styled.th.attrs<{
    $transform?: string;
    $transition?: string;
}>((props) => ({
    style: {
        transform: props.$transform,
        transition: props.$transition,
    },
}))<
    StyledMods<
        {
            transform?: string;
            transition?: string;
            isDragging?: boolean;
            stickyHeader?: boolean;
            spacing?: string;
            withStickyShadow?: boolean;
            hideHeaderBottomBorder?: boolean;
        } & Mods<'color', 'silver80' | 'white100'> &
            Mods<'bordered'> &
            Pick<ColumnDefinition<BaseItem>, 'sortable' | 'sticky'>
    >
>`
    ${mods.color(mixins.colorBg)};

    position: relative;

    ${mods('stickyHeader', true)`
        position: sticky;
        top: 0;
        z-index: ${stickyHeaderZIndex};

        ${mods('hideHeaderBottomBorder', false)`
            ${({ $bordered }) => stickyHeaderCellBorder($bordered, !$bordered, !$bordered)}
        `};
    `};

    ${mods('sticky', 'left')`
        position: sticky;
        left: -1px; // -1 for Intersection Observer
        z-index: ${stickyColumnZIndex};

        ${mods.bordered.true`
            ${stickyHeaderCellBorder(true)}
        `};
        ${mods('withStickyShadow', true)`
            ${stickyShadow(false)}
        `};
    `}

    ${mods('sticky', 'right')`
        position: sticky;
        right: -1px; // -1 for Intersection Observer
        z-index: ${stickyColumnZIndex};

        ${mods.bordered.true`
            ${stickyHeaderCellBorder(true)}
        `};
        ${mods('withStickyShadow', true)`
            ${stickyShadow(true)}
        `};
    `}

    ${mods('stickyHeader', true)`
        ${mods('sticky', ['left', 'right'])`
            z-index: ${stickyHeaderColumnZIndex};
        `};
    `};

    ${mods('isDragging', true)`
        z-index: 2000;

        & > * {
            background-color: ${theme.color.transparent};
        }
    `}
`;

export const SortableHandleContent = styled.div<
    StyledMods<
        Mods<'hide'> & {
            isDragging?: boolean;
        }
    >
>`
    width: 100%;
    height: 12px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
    cursor: move;
    pointer-events: auto;
    z-index: 5;

    ${mods.hide.true`
        visibility: hidden;
    `}

    * {
        cursor: move;
    }

    &:hover {
        opacity: 1;
    }
`;
