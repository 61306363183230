import { enabledByParams, LazyGetDataParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetCustomerContacts } from './useGetCustomerContacts.types';

export const useGetCustomerContacts = (
    params?: RequestParams<GetCustomerContacts<true>>,
    queryOptions?: RequestQueryOptions<GetCustomerContacts<true>>
) => {
    return useGet(integrationApiPaths.getCustomerContacts, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetCustomerContacts<true>['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetCustomerContacts = (queryOptions?: RequestQueryOptions<GetCustomerContacts<true>>) => {
    return useLazyGet(integrationApiPaths.getCustomerContacts, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetCustomerContactsRaw = (queryOptions?: RequestQueryOptions<GetCustomerContacts>) => {
    return useLazyGet<LazyGetDataParams<{}, GetCustomerContacts['params']['query']>, GetCustomerContacts['response']>(
        integrationApiPaths.getCustomerContacts,
        {
            queryOptions,
            apiVersion: 'v1',
        }
    );
};
