import { zodResolver } from '@hookform/resolvers/zod';
import { selectors } from 'modules/common';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useUpdateProfileForm } from '../../hooks/useUpdateProfileForm';
import { schemaUseSession } from './SectionSecurity.schema';

export const useSectionSecuritySessionForm = (profile: selectors.types.ExpandedProfile) => {
    const form = useForm<z.infer<typeof schemaUseSession>>({
        mode: 'onChange',
        values: {
            useSession: profile.useSession ?? false,
        },
        resolver: zodResolver(schemaUseSession),
    });

    useUpdateProfileForm('session', form);

    return {
        form,
    };
};
