export enum RequestFraudulentActivity {
    PostApprovalChange = 1,
    ExternalResolving = 2,
    AutomaticResolving = 3,
    AdminEnforcedResolving = 4,
    DroppingOffApprovers = 5,
}

export enum RequestStepParticipantDecision {
    NoResponse = 0,
    Reject = 1,
    Approve = 2,
}

export enum RequestStepParticipantOrigin {
    ByRules = 0,
    IsBackup = 1,
    Manually = 2,
    ByMatching = 3,
}

export enum RequestStepState {
    NotStarted = 0,
    Active = 1,
    Done = 2,
}

export enum RequestResponseTransfer {
    Revoke = 0,
    Reject = 1,
    Approve = 2,
}

export enum RequestHistoryEventType {
    RequesterSubmitted = 0,
    ApproverApproved = 1,
    ApproverApprovedStepApproved = 2,
    ApproverApprovedRequestApproved = 3,
    ApproverRejected = 4,
    ApproverRevoked = 5,
    ApproverRevokedStepChanged = 6,
    RequesterClosed = 7,
    RequesterCancelled = 8,
    Comment = 9,
    CommentDeleted = 10,
    RequesterReopened = 11,
    RequesterReopenedWithReset = 12,
    RequesterReopenedStepChanged = 13,
    RequesterReopenedRequestApproved = 14,
    RequesterUpdated = 15,
    RequesterUpdatedWithReset = 16,
    RequesterUpdatedStepChanged = 17,
    RequesterUpdatedRequestApproved = 18,
    StepSkipped = 19,
    StepSkippedRequestApproved = 20,
    ForcedApproval = 21,
    ForcedReject = 22,
    ApprovedInXero = 23,
    CancelledInXero = 24,
    RequesterResetTemplate = 25,
    ParticipantsWereChanged = 26,
    ParticipantsWereChangedStepChanged = 27,
    ParticipantsWereChangedRequestApproved = 28,
    EmailToPartnerSent = 29,
    EditorEdited = 30,
    EditorEditedStepApproved = 31,
    EditorEditedRequestApproved = 32,
    RejectedExternally = 33,
    StepSkippedRequestRejected = 34,
    PulledFromSource = 35,
    PushedToSource = 36,
    RequestMatchedByUser = 37,
    RequestMatchedBySystem = 38,
    RequestUnmatchedByUser = 39,
    RequestUnmatchedBySystem = 40,
    MarkedAsBilled = 41,
    UnmarkedAsBilled = 42,
    MarkAsBilledSynced = 43,
    PostApprovalChangeDetected = 44,
    UpdatedExternallyResetTemplate = 45,
    XeroMatchingAdded = 46,
    XeroMatchingRemoved = 47,
    AutoMarkedAsBilled = 48,
    AutoUnmarkedAsBilled = 49,
    QBooksMatchingAdded = 50,
    QBooksMatchingRemoved = 51,
    CancelledExternally = 52,
    DocumentClosed = 53,
    DocumentOpened = 54,
    DocumentClosedStatusSynced = 55,
    DocumentOpenedStatusSynced = 56,
    AirwallexBatchPaymentRequestPaid = 57,
    AttachmentUploadFailed = 58,
    GrnChangedToNotReceived = 59,
    GrnChangedToPartiallyReceived = 60,
    GrnChangedToFullyReceived = 61,
    QBooksStatusRejected = 62,
    ParticipantsWereAddedDueToMatchingBillWithPO = 63,
    MarkAsCustomerDecisionSynced = 64,
    NetSuiteApprovedExternally = 66,
    AirwallexRequestScheduledForPayment = 68,
    AirwallexScheduledPaymentCancelled = 69,
    AirwallexScheduledPaymentFailedDueToPaymentServiceIntegrationDisconnected = 70,
    AirwallexScheduledPaymentFailedDueToExceededAmountDue = 71,
    AirwallexScheduledPaymentFailedDueToBeneficiaryDiscrepancy = 72,
    AirwallexScheduledPaymentFailedDueToInsufficientFunds = 73,
    AirwallexScheduledPaymentFailedDueToCompanyIntegrationDisabled = 74,
    ReviewersChanged = 75,
    ReviewForcedByAdmin = 76,
    ReviewCompleted = 77,
    MarkedAsSentSynced = 78,
    AttachmentsAdded = 79,
    AttachmentDeleted = 80,
    ReturnedToReview = 81,
    ReviewerEdited = 82,
    AmaxPayBatchPaymentBillPaymentCancelled = 83,
    ReviewSkipped = 84,
    ApproverEdited = 85,
    PutOnHold = 88,
    TakenOffHold = 89,
    ApproverNudged = 95,
}
