import { hooks } from '@approvalmax/utils';
import { ForwardedRef, useImperativeHandle, useRef } from 'react';

export const useOptionRef = (ref: ForwardedRef<HTMLInputElement>, groupRef: ForwardedRef<HTMLInputElement>) => {
    const innerRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    useImperativeHandle(groupRef, () => innerRef.current as HTMLInputElement);

    return hooks.useCaptureFocus(innerRef);
};
