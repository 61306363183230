import { zodResolver } from '@hookform/resolvers/zod';
import { selectors } from 'modules/common';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useUpdateProfileForm } from '../../hooks/useUpdateProfileForm';
import { fieldNamesSectionGeneral } from './SectionGeneral.constants';
import { getSchemaSectionGeneral } from './SectionGeneral.schema';

export const useSectionGeneralForm = (profile: selectors.types.ExpandedProfile) => {
    const form = useForm<z.infer<ReturnType<typeof getSchemaSectionGeneral>>>({
        mode: 'onChange',
        values: {
            [fieldNamesSectionGeneral.firstName]: profile.firstName ?? '',
            [fieldNamesSectionGeneral.lastName]: profile.lastName ?? '',
            [fieldNamesSectionGeneral.phone]: profile.phone ?? null,
            [fieldNamesSectionGeneral.email]: profile.email ?? '',
            [fieldNamesSectionGeneral.timeZoneForEmail]: profile.timeZoneForEmail ?? '',
        },
        resolver: zodResolver(getSchemaSectionGeneral(profile)),
    });

    useUpdateProfileForm('general', form);

    return {
        form,
    };
};

export const useSectionGeneral = () => {
    // TODO: Remove after replaced with PhoneField component
    const handleTransformPhone = useCallback((value: string) => {
        let result = value.replace(/[^0-9+]/g, '');

        if (result && !result.startsWith('+')) {
            result = '+' + result;
        }

        return result;
    }, []);

    return {
        handleTransformPhone,
    };
};
