import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { UpdatePassword } from './useUpdatePassword.types';

export const useUpdatePassword = (mutationOptions?: RequestMutationOptions<UpdatePassword>) => {
    return useMutate(profileApiPaths.updatePassword, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
