import { memo } from 'react';

import { ScreenAppCode } from './components/ScreenAppCode/ScreenAppCode';
import { ScreenBackupCode } from './components/ScreenBackupCode/ScreenBackupCode';
import { ScreenEmailCode } from './components/ScreenEmailCode/ScreenEmailCode';
import { ScreenMethodSelect } from './components/ScreenMethodSelect/ScreenMethodSelect';
import { useTwoFaActionWizardContext } from './TwoFaActionWizard.context';
import { TwoFaActionWizardMethods, TwoFaActionWizardProps } from './TwoFaActionWizard.types';

export const TwoFaActionWizardContent = memo<TwoFaActionWizardProps>((props) => {
    const { activeScreen } = useTwoFaActionWizardContext();

    return (
        <>
            {activeScreen === TwoFaActionWizardMethods.authenticatorAppCode && <ScreenAppCode {...props} />}

            {activeScreen === TwoFaActionWizardMethods.backupCode && <ScreenBackupCode {...props} />}

            {activeScreen === TwoFaActionWizardMethods.emailCode && <ScreenEmailCode {...props} />}

            {activeScreen === 'methodSelect' && <ScreenMethodSelect {...props} />}
        </>
    );
});

TwoFaActionWizardContent.displayName = 'TwoFaActionWizardContent';
