import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.AllFeatureTrialPaywallPopup.FeatureDescription', {
    xeroAdvancedBillToPOMatchingTitle: "Confused by invoice totals that don't match your POs?",
    xeroAdvancedBillToPOMatchingSubtitle: 'Get clarity with Bill-to-PO matching:',
    xeroAdvancedBillToPOMatchingList1: 'Track remaining balances on POs',
    xeroAdvancedBillToPOMatchingList2: 'Prevent overspending with custom controls',
    xeroAdvancedBillToPOMatchingList3: 'Align team spending with budgets',
    xeroAdvancedBillToPOMatchingList4: 'Match actual expenditure to budgets',

    xeroAdvancedBudgetCheckingTitle: 'Tired of guessing your remaining budget?',
    xeroAdvancedBudgetCheckingSubtitle: 'Approve with confidence using budget checking:',
    xeroAdvancedBudgetCheckingList1: 'Compare payables on approval against budget before approving',
    xeroAdvancedBudgetCheckingList2: 'Visualise budget status with a traffic light system',
    xeroAdvancedBudgetCheckingList3: 'See remaining budget amounts',
    xeroAdvancedBudgetCheckingList4: 'View budgets at a glance - skip the Xero detour',

    xeroPremiumBatchPaymentApprovalsTitle: 'Is payment processing eating up your time?',
    xeroPremiumBatchPaymentApprovalsSubtitle: 'Speed it up with Xero Batch Payments',
    xeroPremiumBatchPaymentApprovalsList1: 'Prepare payments easier and faster',
    xeroPremiumBatchPaymentApprovalsList2: 'Review all payments in one go',
    xeroPremiumBatchPaymentApprovalsList3: 'Say goodbye to multiple banking logins',
    xeroPremiumBatchPaymentApprovalsList4: 'Keep your cash flow running smoothly',

    xeroAdvancedContactApprovalsTitle: 'Worried about payment fraud to third-parties?',
    xeroAdvancedContactApprovalsSubtitle: 'Verify before you pay with Supplier Onboarding:',
    xeroAdvancedContactApprovalsList1: 'Approve new contacts before paying them',
    xeroAdvancedContactApprovalsList2: 'Pay legitimate contacts',
    xeroAdvancedContactApprovalsList3: 'Add and approve generic bank account details',
    xeroAdvancedContactApprovalsList4: 'Use smart rules to verify suppliers and contacts',
    xeroAdvancedContactApprovalsList5: 'Automatically generate audit trails for all contact approvals',

    xeroPremiumPaymentsWithAirwallexTitle: 'International payments giving you a headache?',
    xeroPremiumPaymentsWithAirwallexSubtitle: 'Simplify with Airwallex*',
    xeroPremiumPaymentsWithAirwallexList1: 'Transact in over 150 countries and 40 currencies',
    xeroPremiumPaymentsWithAirwallexList2: 'Make payments directly in ApprovalMax',
    xeroPremiumPaymentsWithAirwallexList3: 'Automatic report and audit trails for every payment',
    xeroPremiumPaymentsWithAirwallexList4: 'Consolidate international banking processes',
    xeroPremiumPaymentsWithAirwallexEndnote: '*Available in United Kingdom, Australia and New Zealand.',

    xeroAdvancedAutoApprovalsTitle: 'Tired of handling routine approvals?',
    xeroAdvancedAutoApprovalsSubtitle: 'Speed up with Auto-approvals:',
    xeroAdvancedAutoApprovalsList1: 'Set up automatic approvals for recurring requests',
    xeroAdvancedAutoApprovalsList2: 'Customise approval rules to fit your needs',
    xeroAdvancedAutoApprovalsList3: "Prioritise decision-makers' time for high impact tasks",
    xeroAdvancedAutoApprovalsList4: 'Accelerate your approval process',

    xeroAdvancedSalesQuoteApprovalsTitle: 'Are inconsistent quotes creating problems? ',
    xeroAdvancedSalesQuoteApprovalsSubtitle: 'Sort it out with Sales Quotation:',
    xeroAdvancedSalesQuoteApprovalsList1: 'Manage quotes directly within ApprovalMax',
    xeroAdvancedSalesQuoteApprovalsList2: 'Review quotes before sending to customers',
    xeroAdvancedSalesQuoteApprovalsList3: 'Maintain consistency across all outgoing quotes',
    xeroAdvancedSalesQuoteApprovalsList4: 'Monitor customer quote acceptance and convert to invoices',

    xeroAdvancedManualJournalApprovalsTitle: 'Stressed about errors or unauthorised entries in your accounting data?',
    xeroAdvancedManualJournalApprovalsSubtitle: 'Protect your books with Manual Journal approvals',
    xeroAdvancedManualJournalApprovalsList1: 'Implement approval processes for manual entries',
    xeroAdvancedManualJournalApprovalsList2: 'Ensure thorough review before posting changes',
    xeroAdvancedManualJournalApprovalsList3: 'Prevent errors and unauthorised entries',
    xeroAdvancedManualJournalApprovalsList4: 'Protect the integrity of your general ledger',

    xeroPremiumStandAloneWorkflowsTitle: 'Need approvals for different documents?',
    xeroPremiumStandAloneWorkflowsSubtitle: 'Approve them with Stand-alone workflows:',
    xeroPremiumStandAloneWorkflowsList1: 'Broaden control beyond financial documents',
    xeroPremiumStandAloneWorkflowsList2: 'Cover diverse business areas (HR, CapEx, Payroll)',
    xeroPremiumStandAloneWorkflowsList3: 'Tailor approvals to your specific requirements',
    xeroPremiumStandAloneWorkflowsList4: 'Enhance control across business operations',

    xeroPremiumPublicAPITitle: 'Want to integrate ApprovalMax data with other systems?',
    xeroPremiumPublicAPISubtitle: 'Get started with our Public API',
    xeroPremiumPublicAPIList1: 'Integrate with your existing software ecosystem',
    xeroPremiumPublicAPIList2: 'Expand your data analysis capabilities',
    xeroPremiumPublicAPIList3: 'Maximise the value of your ApprovalMax data',

    xeroAdvancedGoodsReceivedNotesTitle: "Paying for goods you haven't received?",
    xeroAdvancedGoodsReceivedNotesSubtitle: 'Take control with Goods Received Notes:',
    xeroAdvancedGoodsReceivedNotesList1: 'Verify deliveries before processing payments',
    xeroAdvancedGoodsReceivedNotesList2: 'Match deliveries to Purchase Orders',
    xeroAdvancedGoodsReceivedNotesList3: 'Add notes to keep your team in the loop',
    xeroAdvancedGoodsReceivedNotesList4: 'Let your finance team know when to pay bills',

    xeroAdvancedWorkflowVersionHistoryTitle: 'Lacking visibility for workflow changes?',
    xeroAdvancedWorkflowVersionHistorySubtitle: 'Catch every edit with Workflow Version History:',
    xeroAdvancedWorkflowVersionHistoryList1: 'Record all workflow modifications',
    xeroAdvancedWorkflowVersionHistoryList2: 'Access past versions of workflows',
    xeroAdvancedWorkflowVersionHistoryList3: 'Maintain comprehensive audit trails',
    xeroAdvancedWorkflowVersionHistoryList4: 'Simplify audit preparation',
    xeroAdvancedWorkflowVersionHistoryList5: 'Detect and prevent internal fraud',

    xeroAdvancedRequestReviewStepTitle: 'Are small mistakes delaying approvals?',
    xeroAdvancedRequestReviewStepSubtitle: 'Fix them and speed things up with a Review Step.',
    xeroAdvancedRequestReviewStepList1: 'Enable request editing before formal approval',
    xeroAdvancedRequestReviewStepList2: 'Reduce unnecessary communication loops',
    xeroAdvancedRequestReviewStepList3: 'Direct requests to appropriate reviewers automatically',
    xeroAdvancedRequestReviewStepList4: 'Address issues early in the process',

    xeroAdvancedAccrualReportsTitle: 'P&L accuracy keeping you awake? Sleep soundly with Accrual Reports.',
    xeroAdvancedAccrualReportsList1: 'Sync directly with your accounting software to reduce errors.',
    xeroAdvancedAccrualReportsList2: 'Use multi-level approval processes for accruals.',
    xeroAdvancedAccrualReportsList3: 'Review and edit accrual journals before posting.',
    xeroAdvancedAccrualReportsList4:
        'Automatically calculate PO balances and switch between net and gross views for detailed insights.',

    xeroAdvancedEditingOnApprovalTitle: 'Losing time rejecting and restarting workflows over minor edits?',
    xeroAdvancedEditingOnApprovalSubtitle: 'Speed up approvals with Editing on Approval:',
    xeroAdvancedEditingOnApprovalList1: 'Approvers can make changes right inside the workflow.',
    xeroAdvancedEditingOnApprovalList2: 'Modify requests without rejecting or restarting the process.',
    xeroAdvancedEditingOnApprovalList3: 'Edit Purchase Orders, Bills, Invoices, and Quotes directly in the workflow.',

    xeroAdvancedRequestVersionHistoryTitle: 'Finding it challenging to see changes in requests?',
    xeroAdvancedRequestVersionHistorySubtitle: 'Take control with Request Version History:',
    xeroAdvancedRequestVersionHistoryList1: 'View all changes made to your requests.',
    xeroAdvancedRequestVersionHistoryList2: 'Track changes with a detailed Audit Trail.',
    xeroAdvancedRequestVersionHistoryList3: 'Compare versions easily to understand changes.',
    xeroAdvancedRequestVersionHistoryList4:
        'Access version history for Purchase Orders, Bills, Invoices & Credit Notes, Quotes, Batch payments (Xero & Airwallex) and Contacts.',

    xeroAdvancedOnHoldStatusTitle: 'Hold Request on Approval',
    xeroAdvancedOnHoldStatusSubtitle: 'Pause a request without cancelling it via the On Hold status.',
    xeroAdvancedOnHoldStatusList1:
        ' Requests On Hold won’t move to Approved until their status is changed so important items aren’t overlooked.',

    xeroPremiumPrioritySupportTitle: 'Need quick answers?',
    xeroPremiumPrioritySupportSubtitle: 'Get faster help with Premium Support:',
    xeroPremiumPrioritySupportList1: 'Resolve issues quickly with dedicated support',
    xeroPremiumPrioritySupportList2: 'Receive personalised guidance from expert support staff',
    xeroPremiumPrioritySupportList3: 'Optimise your use of ApprovalMax features',

    qboAdvancedBillToPOMatchingTitle: "Confused by invoice totals that don't match your POs?",
    qboAdvancedBillToPOMatchingSubtitle: 'Get clarity with Bill-to-PO Matching:',
    qboAdvancedBillToPOMatchingList1: 'Track remaining balances on POs',
    qboAdvancedBillToPOMatchingList2: 'Prevent overspending with custom controls',
    qboAdvancedBillToPOMatchingList3: 'Align team spending with budgets',
    qboAdvancedBillToPOMatchingList4: 'Match actual expenditure to budgets',

    qboAdvancedVendorApprovalsTitle: 'Worried about payment fraud to third-parties?',
    qboAdvancedVendorApprovalsSubtitle: 'Verify before you pay with Vendor Onboarding:',
    qboAdvancedVendorApprovalsList1: 'Approve new vendors before paying them',
    qboAdvancedVendorApprovalsList2: 'Pay legitimate vendors',
    qboAdvancedVendorApprovalsList3: 'Use smart rules to vet suppliers and vendors',
    qboAdvancedVendorApprovalsList4: 'Generate automatic audit trails for all vendors',

    qboAdvancedAutoApprovalsTitle: 'Tired of handling routine approvals?',
    qboAdvancedAutoApprovalsSubtitle: 'Speed up with Auto-approvals:',
    qboAdvancedAutoApprovalsList1: 'Set up automatic approvals for recurring requests',
    qboAdvancedAutoApprovalsList2: 'Customise approval rules to fit your needs',
    qboAdvancedAutoApprovalsList3: "Prioritise decision-makers' time for high impact tasks",
    qboAdvancedAutoApprovalsList4: 'Accelerate your approval process',

    qboAdvancedJournalEntryApprovalsTitle: 'Stressed about errors in your accounting data?',
    qboAdvancedJournalEntryApprovalsSubtitle: 'Protect your books with Journal Entry Approvals:',
    qboAdvancedJournalEntryApprovalsList1: 'Implement approval processes for Journal entries',
    qboAdvancedJournalEntryApprovalsList2: 'Ensure proper review before posting changes',
    qboAdvancedJournalEntryApprovalsList3: 'Eliminate errors and unauthorised entries',
    qboAdvancedJournalEntryApprovalsList4: 'Protect the integrity of your general ledger',

    qboAdvancedOnHoldStatusTitle: 'Hold Request on Approval',
    qboAdvancedOnHoldStatusSubtitle: 'Pause a request without cancelling it via the On Hold status.',
    qboAdvancedOnHoldStatusList1:
        ' Requests On Hold won’t move to Approved until their status is changed so important items aren’t overlooked.',

    qboPremiumStandAloneWorkflowsTitle: 'Need approvals for different documents?',
    qboPremiumStandAloneWorkflowsSubtitle: 'Approve them with Stand-alone workflows:',
    qboPremiumStandAloneWorkflowsList1: 'Broaden control beyond financial documents',
    qboPremiumStandAloneWorkflowsList2: 'Cover diverse business areas (HR, CapEx, Payroll)',
    qboPremiumStandAloneWorkflowsList3: 'Tailor approvals to your specific requirements',
    qboPremiumStandAloneWorkflowsList4: 'Enhance control across business operations',

    qboAdvancedGoodsReceivedNotesTitle: "Paying for goods you haven't received?",
    qboAdvancedGoodsReceivedNotesSubtitle: 'Take control with Goods Received Notes:',
    qboAdvancedGoodsReceivedNotesList1: 'Verify deliveries before processing payments',
    qboAdvancedGoodsReceivedNotesList2: 'Match deliveries to Purchase Orders',
    qboAdvancedGoodsReceivedNotesList3: 'Add notes to keep your team in the loop',
    qboAdvancedGoodsReceivedNotesList4: 'Let your finance team know when to pay bills',

    qboAdvancedWorkflowVersionHistoryTitle: 'Lacking visibility for workflow changes?',
    qboAdvancedWorkflowVersionHistorySubtitle: 'Catch every edit with Workflow Version History:',
    qboAdvancedWorkflowVersionHistoryList1: 'Record all workflow modifications',
    qboAdvancedWorkflowVersionHistoryList2: 'Access past versions of workflows',
    qboAdvancedWorkflowVersionHistoryList3: 'Maintain comprehensive audit trails',
    qboAdvancedWorkflowVersionHistoryList4: 'Simplify audit preparation',
    qboAdvancedWorkflowVersionHistoryList5: 'Detect and prevent internal fraud',

    qboAdvancedRequestReviewStepTitle:
        'Edit the request before the approval workflow starts to reduce errors and avoid unnecessary back-and-forth',
    qboAdvancedRequestReviewStepList1: 'Reviewers can edit the requests before submitting them for approval.',
    qboAdvancedRequestReviewStepList2: 'Requests are routed to Reviewers based on the predefined rules.',

    qboAdvancedRequestVersionHistoryTitle: 'Finding it challenging to see changes in requests?',
    qboAdvancedRequestVersionHistorySubtitle: 'Take control with Request Version History:',
    qboAdvancedRequestVersionHistoryList1: 'View all changes made to your requests.',
    qboAdvancedRequestVersionHistoryList2: 'Track changes with a detailed Audit Trail.',
    qboAdvancedRequestVersionHistoryList3: 'Compare versions easily to understand changes.',
    qboAdvancedRequestVersionHistoryList4: 'Access version history for Purchase Orders, Bills, and Expenses.',

    qboPremiumPrioritySupportTitle: 'Need quick answers?',
    qboPremiumPrioritySupportSubtitle: 'Get faster help with Premium Support:',
    qboPremiumPrioritySupportList1: 'Resolve issues quickly with dedicated support',
    qboPremiumPrioritySupportList2: 'Receive personalised guidance from expert support staff',
    qboPremiumPrioritySupportList3: 'Optimise your use of ApprovalMax features',

    qboPremiumPublicAPITitle: 'Want to integrate ApprovalMax data with other systems?',
    qboPremiumPublicAPISubtitle: 'Get started with our Public API',
    qboPremiumPublicAPIList1: 'Integrate with your existing software ecosystem',
    qboPremiumPublicAPIList2: 'Expand your data analysis capabilities',
    qboPremiumPublicAPIList3: 'Maximise the value of your ApprovalMax data',

    addOnsCaptureTitle: 'Drowning in manual data entry?',
    addOnsCaptureSubTitle: 'Let ApprovalMax Capture do the work:',
    addOnCaptureList1: 'Upload individual or multiple bills effortlessly',
    addOnCaptureList2: 'Focus on high-value review and approval tasks',
    addOnCaptureList3: 'Reduce errors with automatic data pre-population',
    addOnCaptureList4: 'Send bills directly to a dedicated email inbox',
    addOnCaptureEndNote: 'Try ApprovalMax Capture with 100 free documents',

    addOnsPayTitle: 'Want a smarter, faster, safer way to pay bills?',
    addOnsPaySubTitle: 'Let ApprovalMax Pay take care of it',
    addOnPayList1: 'Process payments directly in ApprovalMax',
    addOnPayList2: 'Connect to your bank securely using open banking',
    addOnPayList3: 'Route payments accurately every time',
    addOnPayList4: 'Track invoices and payments in real-time',
    addOnPayList5: 'Automate reconciliation tasks',
});
