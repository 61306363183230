import { toast } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';

import { messages } from './handleTemplatePayerErrors.messages';

export const handleTemplatePayerErrors = (template: domain.Template) => {
    const integrationCode = template.integrationCode;
    const hasPayer = Boolean(template.payerMatrix?.length);

    if (
        (integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment ||
            integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment) &&
        !hasPayer
    ) {
        toast.error(messages.payerRequiredError);
    }
};
