import { ExtractComponentProp } from '@approvalmax/types';
import { ChangeEvent, KeyboardEvent, RefObject, useCallback, useEffect, useState } from 'react';

import TextField from '../TextField/TextField';
import { allowedChars } from './NumberField.constants';
import { getNewValue, toStateValue } from './NumberField.helpers';
import { HTMLNumberFieldElement, NumberFieldProps, UseValueProps } from './NumberField.types';

export const useKeyEvents = (
    props: Pick<NumberFieldProps, 'onEnter' | 'allowCalculation'> & {
        fieldRef: RefObject<HTMLNumberFieldElement | null>;
    }
) => {
    const { allowCalculation, onEnter, fieldRef } = props;

    const handleKeyPress = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            const isAllowedChar = allowedChars.includes(e.key);

            if (!isAllowedChar && !allowCalculation) {
                e.preventDefault();
            }
        },
        [allowCalculation]
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                fieldRef.current?.blur();
                onEnter?.(e);
            }
        },
        [onEnter, fieldRef]
    );

    return {
        handleKeyPress,
        handleKeyDown,
    };
};

export const useValue = (props: UseValueProps) => {
    const { value = null, changeOnBlur, onChange, onBlur, allowCalculation } = props;

    const [fieldValue, setFieldValue] = useState(toStateValue(value));

    // Update the stateValue if props.value changes
    useEffect(() => {
        setFieldValue(toStateValue(value));
    }, [value]);

    const handleChange: ExtractComponentProp<typeof TextField, 'onChange'> = useCallback(
        (value = '', event: ChangeEvent<HTMLNumberFieldElement> | Event) => {
            setFieldValue(value);

            if (!changeOnBlur && !allowCalculation) {
                const newValue = getNewValue(value, props);

                if (props.value !== newValue) {
                    onChange?.(newValue, event);
                }
            }
        },
        [changeOnBlur, onChange, props, allowCalculation]
    );

    const handleBlur: React.FocusEventHandler<HTMLNumberFieldElement> = useCallback(
        (event) => {
            const newValue = getNewValue(fieldValue, props);

            setFieldValue(toStateValue(newValue));

            if (changeOnBlur || allowCalculation) {
                if (props.value !== newValue) {
                    onChange?.(newValue, event);
                }
            }

            onBlur?.(event);
        },
        [fieldValue, props, changeOnBlur, onBlur, onChange, allowCalculation]
    );

    return {
        fieldValue,
        handleChange,
        handleBlur,
    };
};
