import { TransparentButton } from '@approvalmax/ui';
import { domHelpers, intl } from '@approvalmax/utils';
import { TbDownloadColorlessIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import moment from 'moment';
import { darken } from 'polished';
import { FC, memo } from 'react';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';
import styled from 'styled-components';

import { useProfileContext } from '../../ProfileContext';
import Section from '../Section/Section';

const i18nPrefix = 'profile/containers/ProfilePopup/CertificationSection/CertificationSection';

const messages = defineMessages({
    certificationHeader: {
        id: `${i18nPrefix}.certificationHeader`,
        defaultMessage: 'Certification',
    },
    certificationDescription: {
        id: `${i18nPrefix}.certificationDescription`,
        defaultMessage: 'You have passed ApprovalMax Certification',
    },
    certificateDownload: {
        id: `${i18nPrefix}.certificateDownload`,
        defaultMessage: 'Download',
    },
});

const Description = styled.div`
    ${font(13, '#000')}
`;

const DateText = styled.div`
    margin-top: 5px;
    ${font(12, '#5e5c5c')}
`;

const DownloadButton = styled.button`
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    color: #477753;

    &:hover,
    &:focus {
        color: ${darken(0.05, '#477753')};
    }
`;

const DownloadIcon = styled(TbDownloadColorlessIcon)`
    margin-right: 8px;
    fill: #477753;
`;

const DownloadButtonText = styled.div`
    ${font(13, '#477753', 'semibold')}
    text-decoration: underline;
`;

/**
 * @deprecated Redesigned
 */
const CertificationSection: FC = () => {
    const { profile } = useProfileContext();

    return (
        <>
            {profile.lastCertification && (
                <Section header={intl.formatMessage(messages.certificationHeader)}>
                    <Description>{intl.formatMessage(messages.certificationDescription)}</Description>

                    <DateText>{moment(profile.lastCertification.date).format('ll')}</DateText>

                    <TransparentButton
                        execute={() =>
                            domHelpers.downloadUrl(
                                api.expandUrl(
                                    `certifications/getCertification?certificationId=${profile.lastCertification!.id}`,
                                    'myAccount'
                                )
                            )
                        }
                    >
                        {({ elementProps }) => (
                            <DownloadButton {...elementProps}>
                                <DownloadIcon />

                                <DownloadButtonText>
                                    {intl.formatMessage(messages.certificateDownload)}
                                </DownloadButtonText>
                            </DownloadButton>
                        )}
                    </TransparentButton>
                </Section>
            )}
        </>
    );
};

export default memo(CertificationSection);
