import { messages } from './WeekDayPicker.messages';

export enum Days {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export const options = Object.values(Days);

export const labels = {
    [Days.Monday]: messages.dayShortnameMonday,
    [Days.Tuesday]: messages.dayShortnameTuesday,
    [Days.Wednesday]: messages.dayShortnameWednesday,
    [Days.Thursday]: messages.dayShortnameThursday,
    [Days.Friday]: messages.dayShortnameFriday,
    [Days.Saturday]: messages.dayShortnameSaturday,
    [Days.Sunday]: messages.dayShortnameSunday,
};
