import { memo } from 'react';

import { Success } from './components/Success/Success';
import { Wizard } from './components/Wizard/Wizard';
import { useGoToTwoFaProfileEnablingStage, useTwoFaProfileEnabling } from './TwoFaProfileEnabling.hooks';

export const TwoFaProfileEnabling = memo(() => {
    const { stage, handleClose } = useTwoFaProfileEnabling();
    const { gotoSuccess, handleFinish } = useGoToTwoFaProfileEnablingStage();

    switch (stage) {
        case 'wizard':
            return <Wizard onClose={handleClose} onFinish={gotoSuccess} />;

        case 'success':
            return <Success onFinish={handleFinish} />;

        default:
            return null;
    }
});

TwoFaProfileEnabling.displayName = 'TwoFaProfileEnabling';
