import { domain } from 'modules/data';

import {
    Action,
    ADD_QBOOKS_ACCOUNT_LINE_ITEM,
    ADD_QBOOKS_LINE_ITEM,
    ADD_XERO_LINE_ITEM,
    CHANGE_CURRENCY,
    CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS,
    CHANGE_NETSUITE_REQUEST,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE,
    CHANGE_QBOOKS_CUSTOM_FIELD,
    CHANGE_QBOOKS_CUSTOMER_MEMO,
    CHANGE_QBOOKS_DATE,
    CHANGE_QBOOKS_DEPARTMENT,
    CHANGE_QBOOKS_DISCOUNT,
    CHANGE_QBOOKS_DISCOUNT_TYPE,
    CHANGE_QBOOKS_DUE_DATE,
    CHANGE_QBOOKS_EXPENSE_NUMBER,
    CHANGE_QBOOKS_JOURNAL_ENTRY_REQUEST,
    CHANGE_QBOOKS_LINE_AMOUNT_TYPE,
    CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION,
    CHANGE_QBOOKS_LINE_ITEM_QTY,
    CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE,
    CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE,
    CHANGE_QBOOKS_LINE_ITEMS_CLASS,
    CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER,
    CHANGE_QBOOKS_LINE_ITEMS_ITEM,
    CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE,
    CHANGE_QBOOKS_MAILING_ADDRESS,
    CHANGE_QBOOKS_MEMO,
    CHANGE_QBOOKS_NEW_CREATED_CUSTOMER,
    CHANGE_QBOOKS_NEW_CREATED_VENDOR,
    CHANGE_QBOOKS_NUMBER,
    CHANGE_QBOOKS_PAYEE,
    CHANGE_QBOOKS_PAYEE_TYPE,
    CHANGE_QBOOKS_PAYMENT_ACCOUNT,
    CHANGE_QBOOKS_PAYMENT_METHOD,
    CHANGE_QBOOKS_PAYMENT_TYPE,
    CHANGE_QBOOKS_REQUEST_CURRENCY,
    CHANGE_QBOOKS_SHIPPING_ADDRESS,
    CHANGE_QBOOKS_SHIPPING_CUSTOMER,
    CHANGE_QBOOKS_SHIPPING_DATE,
    CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS,
    CHANGE_QBOOKS_SHIPPING_METHOD,
    CHANGE_QBOOKS_TAX_CODE,
    CHANGE_QBOOKS_TERMS,
    CHANGE_QBOOKS_TRACKING_NUMBER,
    CHANGE_QBOOKS_VENDOR,
    CHANGE_QBOOKS_VENDOR_MESSAGE,
    CHANGE_REQUEST_DESCRIPTION,
    CHANGE_REQUEST_NAME,
    CHANGE_STANDALONE_AMOUNT,
    CHANGE_STANDALONE_CURRENCY,
    CHANGE_STANDALONE_DATE,
    CHANGE_STANDALONE_FIELD_VALUE,
    CHANGE_XERO_CONTACT_REQUEST,
    CHANGE_XERO_DELIVERY_DETAILS,
    CHANGE_XERO_LINE_AMOUNT_TYPE,
    CHANGE_XERO_LINE_ITEM_DESCRIPTION,
    CHANGE_XERO_LINE_ITEM_DISCOUNT,
    CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT,
    CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE,
    CHANGE_XERO_LINE_ITEM_QTY,
    CHANGE_XERO_LINE_ITEM_TAX_AMOUNT,
    CHANGE_XERO_LINE_ITEM_UNIT_PRICE,
    CHANGE_XERO_LINE_ITEMS_ACCOUNT,
    CHANGE_XERO_LINE_ITEMS_ITEM,
    CHANGE_XERO_LINE_ITEMS_TAX,
    CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE,
    CHANGE_XERO_MANUAL_JOURNAL_REQUEST,
    CHANGE_XERO_SUPPLIER,
    CHANGE_XERO_TERMS,
    CLEAR_MODIFIED,
    CLONE_QBOOKS_ACCOUNT_LINE_ITEMS,
    CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL,
    CLONE_QBOOKS_LINE_ITEMS,
    CLONE_QBOOKS_LINE_ITEMS_ALL,
    CLONE_XERO_LINE_ITEMS,
    CLONE_XERO_LINE_ITEMS_ALL,
    CREATE_XERO_CONTACT_RESPONSE,
    DELETE_REQUEST_RESPONSE,
    EDIT_REQUEST_FIELD,
    EDIT_REQUEST_NOTE,
    LOAD_PAGE_DATA,
    QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE,
    QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA,
    QBOOKS_EMAIL_TO_VENDOR_TOGGLE,
    REMOVE_ATTACHMENT,
    REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS,
    REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL,
    REMOVE_QBOOKS_LINE_ITEMS,
    REMOVE_QBOOKS_LINE_ITEMS_ALL,
    REMOVE_XERO_LINE_ITEMS,
    REMOVE_XERO_LINE_ITEMS_ALL,
    REORDER_QBOOKS_ACCOUNT_LINE_ITEM,
    REORDER_QBOOKS_LINE_ITEMS,
    REORDER_XERO_LINE_ITEMS,
    SAVE_AND_APPROVE_REQUEST_RESPONSE,
    SAVE_AND_RESUBMIT_REQUEST_RESPONSE,
    SAVE_AND_SUBMIT_REQUEST_RESPONSE,
    SAVE_DRAFT_REQUEST_RESPONSE,
    SELECT_XERO_DELIVERY_ADDRESS,
    TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE,
    TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE,
    TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE,
    TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE,
    UPLOAD_ATTACHMENTS_RESPONSE,
    XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA,
    XERO_EMAIL_TO_SUPPLIER_TOGGLE,
} from '../../actions';

export type ModifiedType = boolean;

const INITIAL_STATE: ModifiedType = false;

export default function (state = INITIAL_STATE, action: Action): ModifiedType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
        case SAVE_DRAFT_REQUEST_RESPONSE:
        case SAVE_AND_APPROVE_REQUEST_RESPONSE:
        case SAVE_AND_RESUBMIT_REQUEST_RESPONSE:
        case SAVE_AND_SUBMIT_REQUEST_RESPONSE:
        case DELETE_REQUEST_RESPONSE:
        case CLEAR_MODIFIED:
            return false;

        case EDIT_REQUEST_FIELD:
        case REMOVE_ATTACHMENT:
        case CHANGE_CURRENCY:
        case SELECT_XERO_DELIVERY_ADDRESS:
        case CHANGE_XERO_DELIVERY_DETAILS:
        case XERO_EMAIL_TO_SUPPLIER_TOGGLE:
        case XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA:
        case CHANGE_XERO_SUPPLIER:
        case CHANGE_XERO_TERMS:
        case CHANGE_XERO_LINE_ITEMS_ACCOUNT:
        case CHANGE_XERO_LINE_ITEM_DESCRIPTION:
        case CHANGE_XERO_LINE_ITEMS_TAX:
        case CHANGE_XERO_LINE_ITEMS_ITEM:
        case CHANGE_XERO_LINE_ITEM_QTY:
        case CHANGE_XERO_LINE_ITEM_UNIT_PRICE:
        case CHANGE_XERO_LINE_ITEM_DISCOUNT:
        case CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT:
        case CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE:
        case CHANGE_XERO_LINE_ITEM_TAX_AMOUNT:
        case CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE:
        case CHANGE_XERO_LINE_AMOUNT_TYPE:
        case REORDER_XERO_LINE_ITEMS:
        case REMOVE_XERO_LINE_ITEMS:
        case REMOVE_XERO_LINE_ITEMS_ALL:
        case CLONE_XERO_LINE_ITEMS:
        case CLONE_XERO_LINE_ITEMS_ALL:
        case ADD_XERO_LINE_ITEM:
        case CHANGE_REQUEST_NAME:
        case CHANGE_REQUEST_DESCRIPTION:
        case CHANGE_STANDALONE_CURRENCY:
        case CHANGE_STANDALONE_FIELD_VALUE:
        case CHANGE_STANDALONE_DATE:
        case CHANGE_STANDALONE_AMOUNT:
        case CHANGE_QBOOKS_VENDOR_MESSAGE:
        case CHANGE_QBOOKS_MEMO:
        case CHANGE_QBOOKS_CUSTOMER_MEMO:
        case CHANGE_QBOOKS_VENDOR:
        case CHANGE_QBOOKS_MAILING_ADDRESS:
        case CHANGE_QBOOKS_DATE:
        case CHANGE_QBOOKS_DUE_DATE:
        case CHANGE_QBOOKS_NUMBER:
        case CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS:
        case CHANGE_QBOOKS_SHIPPING_DATE:
        case CHANGE_QBOOKS_TAX_CODE:
        case CHANGE_QBOOKS_TRACKING_NUMBER:
        case CHANGE_QBOOKS_TERMS:
        case CHANGE_QBOOKS_SHIPPING_ADDRESS:
        case CHANGE_QBOOKS_SHIPPING_CUSTOMER:
        case CHANGE_QBOOKS_SHIPPING_METHOD:
        case CHANGE_QBOOKS_DEPARTMENT:
        case CHANGE_QBOOKS_DISCOUNT:
        case CHANGE_QBOOKS_DISCOUNT_TYPE:
        case CHANGE_QBOOKS_CUSTOM_FIELD:
        case CHANGE_QBOOKS_LINE_AMOUNT_TYPE:
        case CHANGE_QBOOKS_LINE_ITEMS_ITEM:
        case CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION:
        case CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE:
        case CHANGE_QBOOKS_LINE_ITEM_QTY:
        case CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE:
        case CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE:
        case CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER:
        case CHANGE_QBOOKS_LINE_ITEMS_CLASS:
        case REORDER_QBOOKS_LINE_ITEMS:
        case REMOVE_QBOOKS_LINE_ITEMS:
        case REMOVE_QBOOKS_LINE_ITEMS_ALL:
        case ADD_QBOOKS_LINE_ITEM:
        case CLONE_QBOOKS_LINE_ITEMS:
        case ADD_QBOOKS_ACCOUNT_LINE_ITEM:
        case REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS:
        case REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL:
        case REORDER_QBOOKS_ACCOUNT_LINE_ITEM:
        case CLONE_QBOOKS_ACCOUNT_LINE_ITEMS:
        case CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL:
        case CLONE_QBOOKS_LINE_ITEMS_ALL:
        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT:
        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT:
        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION:
        case TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE:
        case TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE:
        case TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE:
        case TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE:
        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER:
        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS:
        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE:
        case QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA:
        case QBOOKS_EMAIL_TO_VENDOR_TOGGLE:
        case QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE:
        case CREATE_XERO_CONTACT_RESPONSE:
        case EDIT_REQUEST_NOTE:
        case CHANGE_QBOOKS_PAYEE:
        case CHANGE_QBOOKS_PAYEE_TYPE:
        case CHANGE_QBOOKS_PAYMENT_ACCOUNT:
        case CHANGE_QBOOKS_PAYMENT_METHOD:
        case CHANGE_QBOOKS_PAYMENT_TYPE:
        case CHANGE_QBOOKS_REQUEST_CURRENCY:
        case CHANGE_QBOOKS_EXPENSE_NUMBER:
        case CHANGE_QBOOKS_NEW_CREATED_VENDOR:
        case CHANGE_QBOOKS_NEW_CREATED_CUSTOMER:
        case CHANGE_NETSUITE_REQUEST:
        case CHANGE_QBOOKS_JOURNAL_ENTRY_REQUEST:
        case CHANGE_XERO_MANUAL_JOURNAL_REQUEST:
        case CHANGE_XERO_CONTACT_REQUEST:
            return true;

        case CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS: {
            const source = action.payload.exchangeRateParams.exchangeRateSource;
            // setting or clearing a manual course is determined as a change
            const changed = source === domain.ExchangeRateSource.Manual || source === domain.ExchangeRateSource.None;

            return state || changed;
        }

        case UPLOAD_ATTACHMENTS_RESPONSE:
            return state || action.payload.accepted.length > 0;

        default:
            return state;
    }
}
