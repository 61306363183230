import { Popup } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';

import { TwoFaActionWizardProps } from './TwoFaActionWizard.types';
import { TwoFaActionWizardContent } from './TwoFaActionWizardContent';

export const TwoFaActionWizard = memo<TwoFaActionWizardProps>((props) => {
    const { open, onClose, disableAutoClose } = props;

    const handleToggle = useCallback((open: boolean) => !open && onClose(), [onClose]);

    return (
        <Popup open={open} size='medium' onToggle={handleToggle} preventAutoClose={disableAutoClose}>
            <TwoFaActionWizardContent {...props} />
        </Popup>
    );
});

TwoFaActionWizard.displayName = 'TwoFaActionWizard';
