import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { profileFormResetState } from '../ProfileEditPopup.states';

export const useResetProfileForm = () => {
    const resets = useRecoilValue(profileFormResetState);

    return useCallback(() => {
        Object.values(resets).map((reset) => {
            reset();
        });
    }, [resets]);
};
