import { Box, Button, Grid, Progress, Text } from '@approvalmax/ui/src/components';
import { useSortedCompanies } from 'modules/common';
import { integrationActions } from 'modules/integration';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';

import { ConnectedCompany, DisconnectedCompany } from './components';
import { isSyncInProgress } from './SyncDropdownContent.helpers';
import { useSyncData } from './SyncDropdownContent.hooks';
import { messages } from './SyncDropdownContent.messages';
import { SyncDropdownContentProps } from './SyncDropdownContent.types';

export const SyncDropdownContent: FC<SyncDropdownContentProps> = memo((props) => {
    const { onPopupClose } = props;

    const [isSyncAllButtonDisabled, setIsSyncAllButtonDisabled] = useState(false);

    const dispatch = useDispatch();

    const companies = useSortedCompanies();
    const companiesAvailableForSyncing = useMemo(
        () => companies.filter((company) => !!company.integrationId),
        [companies]
    );

    const { data = [], isInitialLoading, refetch, isFetching } = useSyncData(companiesAvailableForSyncing);
    const syncInProgress = isSyncInProgress(data);

    const syncIntegration = useCallback(
        async (companyId: string) => {
            if (!syncInProgress) {
                setIsSyncAllButtonDisabled(true);
            }

            await dispatch(integrationActions.syncIntegration({ companyId }));

            if (!isInitialLoading && !isFetching) {
                refetch();
            }
        },
        [dispatch, isFetching, isInitialLoading, refetch, syncInProgress]
    );

    const syncAllIntegrations = useCallback(async () => {
        setIsSyncAllButtonDisabled(true);

        await dispatch(integrationActions.syncAllIntegrations());

        if (!isInitialLoading && !isFetching) {
            refetch();
        }
    }, [dispatch, isFetching, isInitialLoading, refetch]);

    const showLoading = isInitialLoading || syncInProgress;

    const appropriateCompanyIds = useMemo(
        () => data?.filter((item) => item.syncProgress.length).map((company) => company.companyId),
        [data]
    );

    const companiesContentFiltered = useMemo(
        () => companiesAvailableForSyncing.filter((company) => appropriateCompanyIds?.includes(company.id)),
        [appropriateCompanyIds, companiesAvailableForSyncing]
    );

    useMount(() => {
        if (!isInitialLoading && !isFetching) {
            refetch();
        }
    });

    useEffect(() => {
        setIsSyncAllButtonDisabled(false);
    }, [syncInProgress]);

    return (
        <Box spacing='16'>
            <Box spacing='16 24'>
                <Grid gridTemplateColumns='auto auto' justifyContent='space-between' alignItems='center'>
                    <Text font='headline' fontSize='xsmall' fontWeight='medium' color='midnight100'>
                        {messages.title}
                    </Text>

                    <Button
                        size='small'
                        color='blue80'
                        disabled={syncInProgress || isSyncAllButtonDisabled}
                        onClick={syncAllIntegrations}
                    >
                        {messages.syncAllButtonText}
                    </Button>
                </Grid>
            </Box>

            <Box height={12}>{showLoading && <Progress size='small' />}</Box>

            {companiesContentFiltered.map((company) =>
                company.flags.hasActiveIntegration ? (
                    <ConnectedCompany
                        syncInProgress={data}
                        onSyncIntegration={syncIntegration}
                        key={company.id}
                        company={company}
                    />
                ) : (
                    <DisconnectedCompany key={company.id} company={company} onPopupClose={onPopupClose} />
                )
            )}
        </Box>
    );
});

SyncDropdownContent.displayName = 'SyncDropdownContent';
