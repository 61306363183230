import { domain } from 'modules/data';
import { NetSuiteRefDataClientContractsModelsDocumentType } from 'shared/data/v2';

export const convertIntegrationCodeToDocumentType = (integrationCode: domain.IntegrationCode) => {
    switch (integrationCode) {
        case domain.IntegrationCode.NetSuiteBill:
            return NetSuiteRefDataClientContractsModelsDocumentType.VendorBill;

        case domain.IntegrationCode.NetSuitePO:
            return NetSuiteRefDataClientContractsModelsDocumentType.PurchaseOrder;

        case domain.IntegrationCode.NetSuiteSalesOrder:
            return NetSuiteRefDataClientContractsModelsDocumentType.SalesOrder;

        case domain.IntegrationCode.NetSuiteExpenseReport:
            return NetSuiteRefDataClientContractsModelsDocumentType.ExpenseReport;

        case domain.IntegrationCode.NetSuiteVRA:
            return NetSuiteRefDataClientContractsModelsDocumentType.VendorReturnAuthorization;

        case domain.IntegrationCode.NetSuiteRA:
            return NetSuiteRefDataClientContractsModelsDocumentType.ReturnAuthorization;

        case domain.IntegrationCode.NetSuiteBillPayment:
            return NetSuiteRefDataClientContractsModelsDocumentType.BillPayment;

        case domain.IntegrationCode.NetSuiteInvoice:
            return NetSuiteRefDataClientContractsModelsDocumentType.SalesInvoice;

        case domain.IntegrationCode.NetSuiteVendor:
            return NetSuiteRefDataClientContractsModelsDocumentType.Vendor;

        case domain.IntegrationCode.NetSuiteJournalEntry:
            return NetSuiteRefDataClientContractsModelsDocumentType.JournalEntry;

        default:
            return null;
    }
};
