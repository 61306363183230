import { useCallback } from 'react';
import { DropzoneProps as DropzoneExternalProps } from 'react-dropzone';

import { toast } from '../Toast/Toast.helpers';
import { getErrorMessage, prepareFiles } from './AvatarDropzone.helpers';
import { errorMessages } from './AvatarDropzone.messages';
import { AvatarDropzoneProps } from './AvatarDropzone.types';
import { FileErrorToast } from './components';

export const useAvatarDropzone = (props: AvatarDropzoneProps) => {
    const { onDropRejected, onDropAccepted, minSize = 0, maxSize = 100000 } = props;

    const onDropAcceptedFiles = useCallback<Required<DropzoneExternalProps>['onDropAccepted']>(
        async (acceptedFiles, event) => {
            const file = prepareFiles(acceptedFiles)[0];

            onDropAccepted?.([file], event);
        },
        [onDropAccepted]
    );

    const onDropRejectedFiles = useCallback<Required<DropzoneExternalProps>['onDropRejected']>(
        (rejectedFiles, event) => {
            if (rejectedFiles.length > 1) {
                toast.error(errorMessages.tooManyFiles);
            } else {
                const { file, errors } = rejectedFiles[0];

                errors.map((error) => {
                    toast.error(
                        <FileErrorToast title={file.name} message={getErrorMessage(error, { maxSize, minSize })} />
                    );
                });
            }

            onDropRejected?.(rejectedFiles, event);
        },
        [maxSize, minSize, onDropRejected]
    );

    return {
        onDropAcceptedFiles,
        onDropRejectedFiles,
    };
};
