import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteOpportunities } from './useGetNetSuiteOpportunities.types';

export const useLazyGetNetSuiteOpportunities = (queryOptions?: RequestQueryOptions<GetNetSuiteOpportunities>) => {
    return useLazyGet<
        LazyGetDataParams<{}, GetNetSuiteOpportunities['params']['query']>,
        GetNetSuiteOpportunities['response']
    >(netSuiteApiPaths.getOpportunities, {
        queryOptions,
        apiVersion: 'v2',
    });
};
