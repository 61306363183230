import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AllFeatureTrialPaywallPopup', {
    xeroAdvancedBillToPOMatching: 'Bill-to-PO Matching',
    xeroAdvancedBudgetChecking: 'Budget Checking',
    xeroAdvancedSupplierOnboarding: 'Supplier Onboarding',
    xeroAdvancedAutoApprovals: 'Automated Approvals',
    xeroAdvancedSalesQuoteApprovals: 'Sales Quotation',
    xeroAdvancedManualJournalApprovals: 'Manual Journal Approvals',
    xeroAdvancedGoodsReceivedNotes: 'Goods Received Notes',
    xeroAdvancedWorkflowVersionHistory: 'Workflow Version History',
    xeroAdvancedRequestReviewStep: 'Review Step',
    xeroAdvancedAccrualReports: 'Accrual Reports',
    xeroAdvancedEditingOnApproval: 'Editing on Approval',
    xeroAdvancedRequestVersionHistory: 'Request Version History',
    xeroAdvancedHoldRequestOnApproval: 'Hold Request on Approval',

    xeroPremiumBatchPaymentApprovals: 'Xero Batch Payment Processing',
    xeroPremiumPaymentsWithAirwallex: 'Payments with Airwallex',
    xeroPremiumStandAloneWorkflows: 'Stand-alone Workflows',

    xeroPremiumPublicAPI: 'Public API',
    xeroPremiumPrioritySupport: 'Premium Support',

    qboAdvancedBillToPOMatching: 'Bill-to-PO Matching',
    qboAdvancedVendorOnboarding: 'Vendor Onboarding',
    qboAdvancedAutoApprovals: 'Automated Approvals',
    qboAdvancedJournalEntryApprovals: 'Journal Entry Approvals',
    qboAdvancedGoodsReceivedNotes: 'Goods Received Notes',
    qboAdvancedWorkflowVersionHistory: 'Workflow Version History',
    qboAdvancedRequestReviewStep: 'Review Step',
    qboAdvancedRequestVersionHistory: 'Request Version History',
    qboAdvancedHoldRequestOnApproval: 'Hold Request on Approval',

    qboPremiumStandAloneWorkflows: 'Stand-alone Workflows',
    qboPremiumPrioritySupport: 'Premium Support',
    qboPremiumPublicAPI: 'Public API',

    addOnCaptureTitle: 'ApprovalMax Capture',
    addOnPayTitle: 'ApprovalMax Pay',
});
