import { NetSuiteExpenseLineExpanded } from '../../data/types/NetSuiteExpenseLineExpanded';
import { NetSuiteItemLineExpanded } from '../../data/types/NetSuiteItemLineExpanded';
import { setCurrencyPrecision } from '../amounts/amounts';

export const setItemLineCurrencyPrecision = (line: NetSuiteItemLineExpanded, currencyPrecision: number) => ({
    ...line,
    amount: setCurrencyPrecision(line.amount, currencyPrecision),
    grossAmount: setCurrencyPrecision(line.grossAmount, currencyPrecision),
    taxAmount: setCurrencyPrecision(line.taxAmount, currencyPrecision),
    amortizationResidual: setCurrencyPrecision(line.amortizationResidual, currencyPrecision),
    unitPrice: setCurrencyPrecision(line.unitPrice, currencyPrecision),
    defaultRate: setCurrencyPrecision(line.defaultRate, currencyPrecision),
});

export const setExpenseLineCurrencyPrecision = (line: NetSuiteExpenseLineExpanded, currencyPrecision: number) => ({
    ...line,
    amount: setCurrencyPrecision(line.amount, currencyPrecision),
    grossAmount: setCurrencyPrecision(line.grossAmount, currencyPrecision),
    taxAmount: setCurrencyPrecision(line.taxAmount, currencyPrecision),
    amortizationResidual: setCurrencyPrecision(line.amortizationResidual, currencyPrecision),
});
