import { numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

import { NetSuiteInvoiceListItemExpanded } from '../../data/types/NetSuiteInvoiceItems';
import { setCurrencyPrecision } from '../amounts/amounts';

export const checkInvoiceListItemIsEmpty = (itemLine: domain.NetSuiteInvoiceListItem) =>
    !(
        itemLine.item ||
        itemLine.description ||
        numberHelpers.isNumber(itemLine.quantity) ||
        itemLine.units ||
        itemLine.priceLevel ||
        numberHelpers.isNumber(itemLine.unitPrice) ||
        numberHelpers.isNumber(itemLine.amount) ||
        itemLine.taxCode ||
        numberHelpers.isNumber(itemLine.grossAmount) ||
        itemLine.class ||
        itemLine.department ||
        itemLine.location
    );

export const setInvoiceListItemCurrencyPrecision = (
    itemLine: NetSuiteInvoiceListItemExpanded,
    currencyPrecision: number
) => ({
    ...itemLine,
    amount: setCurrencyPrecision(itemLine.amount, currencyPrecision),
    grossAmount: setCurrencyPrecision(itemLine.grossAmount, currencyPrecision),
    taxAmount: setCurrencyPrecision(itemLine.taxAmount, currencyPrecision),
    unitPrice: setCurrencyPrecision(itemLine.unitPrice, currencyPrecision),
    defaultRate: setCurrencyPrecision(itemLine.defaultRate, currencyPrecision),
});
