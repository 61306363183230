import { TextButton } from '@approvalmax/ui';
import { useSortedCompanies } from 'modules/common';
import { integrationActions } from 'modules/integration';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';

import { isSyncInProgress } from '../../helpers';
import { useSyncData } from '../../hooks';
import ConnectedCompany from '../ConnectedCompany/ConnectedCompany';
import DisconnectedCompany from '../DisconnectedCompany/DisconnectedCompany';
import { messages } from './SyncContent.messages';
import { ContentBlock, ContentScrollable, StyledLoadingBar, TitleBlock, TitleText } from './SyncContent.styles';
import { SyncContentProps } from './SyncContent.types';

const SyncContent = memo<SyncContentProps>((props) => {
    const { onPopupClose } = props;

    const [isSyncAllButtonDisabled, setIsSyncAllButtonDisabled] = useState(false);

    const dispatch = useDispatch();

    const companies = useSortedCompanies();
    const companiesAvailableForSyncing = useMemo(
        () => companies.filter((company) => !!company.integrationId),
        [companies]
    );

    const { data = [], isInitialLoading, refetch, isFetching } = useSyncData(companiesAvailableForSyncing);

    const syncInProgress = isSyncInProgress(data);

    const syncIntegration = async (companyId: string) => {
        if (!syncInProgress) {
            setIsSyncAllButtonDisabled(true);
        }

        await dispatch(integrationActions.syncIntegration({ companyId }));

        if (!isInitialLoading && !isFetching) {
            refetch();
        }
    };

    const syncAllIntegrations = async () => {
        setIsSyncAllButtonDisabled(true);

        await dispatch(integrationActions.syncAllIntegrations());

        if (!isInitialLoading && !isFetching) {
            refetch();
        }
    };

    const showLoading = isInitialLoading || syncInProgress;

    const appropriateCompanyIds = data?.filter((item) => item.syncProgress.length).map((c) => c.companyId);
    const companiesContent = companiesAvailableForSyncing
        .filter((c) => appropriateCompanyIds?.includes(c.id))
        .map((c) =>
            c.flags.hasActiveIntegration ? (
                <ConnectedCompany syncInProgress={data} onSyncIntegration={syncIntegration} key={c.id} company={c} />
            ) : (
                <DisconnectedCompany key={c.id} company={c} onPopupClose={onPopupClose} />
            )
        );

    useMount(() => {
        if (!isInitialLoading && !isFetching) {
            refetch();
        }
    });

    useEffect(() => {
        setIsSyncAllButtonDisabled(false);
    }, [syncInProgress]);

    return (
        <>
            <TitleBlock>
                <TitleText>{messages.title}</TitleText>

                <TextButton execute={syncAllIntegrations} disabled={syncInProgress || isSyncAllButtonDisabled}>
                    {messages.syncAllButtonText}
                </TextButton>

                <StyledLoadingBar showLoading={showLoading} />
            </TitleBlock>

            <ContentBlock>
                <ContentScrollable>{companiesContent}</ContentScrollable>
            </ContentBlock>
        </>
    );
});

export default SyncContent;
