import { type RefObject, useEffect } from 'react';

export const useFocusOnMount = <T extends HTMLElement>(
    focusOnMount: boolean | undefined,
    elRef: RefObject<T | null>
) => {
    useEffect(() => {
        if (focusOnMount) {
            elRef.current?.focus();
        }
    }, [elRef, focusOnMount]);
};
