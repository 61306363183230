import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css, keyframes } from 'styled-components';

import { DropzoneProps } from './Dropzone.types';

const showZoneAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const Zone = styled.div<
    StyledMods<Mods<'active' | 'hide' | 'drag' | 'invalid'> & Pick<DropzoneProps, 'height' | 'width'>>
>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: ${theme.color.white100};
    transition: background-color ${theme.duration.medium}ms ease-in-out;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: ${theme.radius.small};

    :before {
        transition: border-width ${theme.duration.medium}ms ease-in-out;
        content: '';
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: ${theme.radius.small};
        // powered by https://kovart.github.io/dashed-border-generator/
        // stroke of theme.color.blue100 is %232C4DF9
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%232C4DF9' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }

    ${mods.hide.true`
        animation: ${showZoneAnimation} ${theme.duration.medium}ms ease-in-out;
        ${mixins.position.absoluteFill};
        z-index: 100;

        ${mods.drag.false`
            display: none;
        `};
    `};

    ${mods.drag.true`
        background-color: ${theme.color.midnight20};
    `};

    ${mods.drag.false`
        ${mods.invalid.true`
            background-color: ${theme.color.red20};

            :before {
                // %23D5162D is a red100
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D5162D' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
            }
        `}
    `};

    ${mods.active.true`
        background-color: ${theme.color.midnight30};

        :before {
            background-image: none;
            border: 2px solid ${theme.color.blue100};
        }
    `};
`;

export const Root = styled.div<StyledMods<Pick<DropzoneProps, 'size' | 'height' | 'width'> & Mods<'hide' | 'drag'>>>`
    position: relative;

    ${mods.hide.true`
        ${mods.drag.true`
            ${mods.size.small` min-height: 122px; `};
            ${mods.size.medium` min-height: 134px; `};
        `};
    `};

    ${mods('height')(
        (value) => css`
            height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;
