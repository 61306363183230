import { TrialCompany, TrialIntegrationType } from '@approvalmax/ui/src/components/complex';
import { selectors, useSortedCompanies } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { useMemo } from 'react';

import { getPlanType } from '../StartTrialPeriodPopup/StartTrialPeriodPopup.helpers';

export const useGetCompanies = () => {
    const profile = useUserProfile();
    const allCompanies = useSortedCompanies();
    const subscriptions = useSelector(selectors.subscription.getAllSubscriptions);

    return useMemo(
        () =>
            allCompanies.reduce<TrialCompany[]>((companies, company) => {
                if (
                    company.allFeaturesTrialStatus !== domain.AllFeaturesTrialStatus.AwaitingActivation &&
                    company.allFeaturesTrialStatus !== domain.AllFeaturesTrialStatus.Expired
                ) {
                    return companies;
                }

                if (company.integration?.integrationType === domain.IntegrationType.Xero) {
                    const subscription = subscriptions.find(({ id }) => company.subscriptionId === id);

                    return [
                        ...companies,
                        {
                            id: company.id,
                            name: company.name,
                            integrationType: TrialIntegrationType.Xero,
                            plan: getPlanType(company?.licenseProductPlan),
                            isUpgradeSubcriptionVisible:
                                company.author === profile.email && !subscription?.isFrozenForUpgrade,
                            isNotManager: !company.flags.isManager,
                            hasAddOnCapture: subscription?.addonStatistics.some(
                                ({ feature }) => feature === domain.AddonTypes.Capture
                            ),
                            hasAddOnPay:
                                subscription?.addonStatistics.some(
                                    ({ feature }) => feature === domain.AddonTypes.Pay
                                ) || company.betaFeatures.includes(domain.CompanyBetaFeature.PayOpenBankingUK),
                            isSubscriptionActive:
                                subscription?.status &&
                                subscription.status !== domain.SubscriptionStatus.Canceled &&
                                subscription.status !== domain.SubscriptionStatus.Expired,
                            currency: subscription?.nextBillingCurrency ?? undefined,
                            allFeaturesTrialStatus: company.allFeaturesTrialStatus,
                            licenseProductPlan: company.licenseProductPlan,
                        },
                    ];
                } else if (company.integration?.integrationType === domain.IntegrationType.QBooks) {
                    return [
                        ...companies,
                        {
                            id: company.id,
                            name: company.name,
                            integrationType: TrialIntegrationType.Quickbooks,
                            plan: getPlanType(company?.licenseProductPlan),
                            isUpgradeSubcriptionVisible: company.author === profile.email,
                            isNotManager: !company.flags.isManager,
                            allFeaturesTrialStatus: company.allFeaturesTrialStatus,
                            licenseProductPlan: company.licenseProductPlan,
                        },
                    ];
                }

                return companies;
            }, []),
        [allCompanies, profile.email, subscriptions]
    );
};

export const useGetRedirectTemplate = (companyId: string) => {
    const companyTemplates = useSelector((state) => selectors.template.getAllTemplatesByCompanyId(state, companyId));

    const template = companyTemplates.find(
        (template) =>
            template.integrationCode === domain.IntegrationCode.XeroBill ||
            template.integrationCode === domain.IntegrationCode.QBooksBill
    );

    return template;
};
