import { memo } from 'react';

import { Success } from './components/Success/Success';
import { Wizard } from './components/Wizard/Wizard';
import { useGoToTwoFaProfileDisablingStage, useTwoFaProfileDisabling } from './TwoFaProfileDisabling.hooks';

export const TwoFaProfileDisabling = memo(() => {
    const { stage, handleClose } = useTwoFaProfileDisabling();
    const { gotoSuccess, handleFinish } = useGoToTwoFaProfileDisablingStage();

    switch (stage) {
        case 'wizard':
            return <Wizard onClose={handleClose} onFinish={gotoSuccess} />;

        case 'success':
            return <Success onFinish={handleFinish} />;

        default:
            return null;
    }
});

TwoFaProfileDisabling.displayName = 'TwoFaProfileDisabling';
