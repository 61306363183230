import { mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { LayoutState } from './Layout.types';

export const Main = styled.main`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    z-index: 0;
`;

export const Root = styled.div<StyledMods<Pick<LayoutState, 'containerMinSize'>>>`
    display: flex;
    height: 100vh;

    ${mods('containerMinSize')(
        (size) => css`
            min-width: ${theme.container.viewport[size]};
        `
    )};
`;
