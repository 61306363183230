import { CompanyPlans, TrialIntegrationType } from '@approvalmax/ui/src/components/complex';
import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

export const plansMapper: Partial<Record<domain.CompanyPlan, CompanyPlans>> = {
    [domain.CompanyPlan.Xero]: 'standard',
    [domain.CompanyPlan.XeroAdvanced]: 'advanced',
    [domain.CompanyPlan.XeroPremium]: 'premium',

    [domain.CompanyPlan.QBooks]: 'standard',
    [domain.CompanyPlan.QBooksAdvanced]: 'advanced',
    [domain.CompanyPlan.QBooksPremium]: 'premium',
};

export const getTrialIntegrationTypeOfOrganisation = (
    integrationType: domain.IntegrationType.Xero | domain.IntegrationType.QBooks
): TrialIntegrationType => {
    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return TrialIntegrationType.Xero;
        case domain.IntegrationType.QBooks:
            return TrialIntegrationType.Quickbooks;
        default:
            throw errorHelpers.notImplementedError(
                'Wrong integrationType in the "getTrialIntegrationTypeOfOrganisation" function'
            );
    }
};
