import { ErrorCode } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCurrentUser } from 'modules/utils';
import { reloadRequest } from 'pages/requestList/actions';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNudgeApprovers } from 'shared/data/v2';
import { useCompanyBetaFeatures, useNudgeLocalStorage, useTimer } from 'shared/hooks';

import { nudgeLockingTimeSeconds } from './NudgeAction.constants';

export const useNudgeAction = (request: selectors.types.ExpandedRequest) => {
    const dispatch = useDispatch();
    const approversToNudge = useGetApproversIdsToNudge(request);

    const { getLockingTime, setLockingTime } = useNudgeLocalStorage(request.companyId);

    const { timerFormatted: timer, startTimer: updateTimer } = useTimer();

    const isLocked = !!getLockingTime(request.id);

    const { mutate: nudgeApprovers, isLoading: isLoadingNudgeApprovers } = useNudgeApprovers({
        onSuccess: () => {
            setLockingTime(request.id, nudgeLockingTimeSeconds);
            updateTimer({
                seconds: nudgeLockingTimeSeconds,
                onFinish: () => {
                    setLockingTime(request.id, 0);
                },
            });

            dispatch(reloadRequest(request.id, request.companyId));
        },
        onError: ({ error }) => {
            if (error && error.code === ErrorCode.LimitReached && 'timeLeftSeconds' in error) {
                const lockingTime = error.timeLeftSeconds ?? 0;

                setLockingTime(request.id, lockingTime);
                updateTimer({
                    seconds: lockingTime,
                    onFinish: () => {
                        setLockingTime(request.id, 0);
                    },
                });
            }
        },
    });

    const handleNudge = useCallback(() => {
        nudgeApprovers({
            params: {
                path: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
        });
    }, [nudgeApprovers, request.companyId, request.id]);

    useEffect(() => {
        if (request.companyId && request.id) {
            updateTimer({
                seconds: getLockingTime(request.id),
                onFinish: () => {
                    setLockingTime(request.id, 0);
                },
            });
        }
    }, [getLockingTime, request.companyId, request.id, setLockingTime, updateTimer]);

    return {
        approversToNudge,
        handleNudge,
        timer,
        isLocked,
        isLoading: isLoadingNudgeApprovers,
    };
};

export const useCheckNudgeActionAvailable = (request: selectors.types.ExpandedRequest) => {
    const { isUserNudge } = useCompanyBetaFeatures(request.companyId);

    const isRequestNudgeable =
        request.statusV2 === domain.RequestStatusV2.OnReview ||
        request.statusV2 === domain.RequestStatusV2.OnApproval ||
        request.statusV2 === domain.RequestStatusV2.OnHold;

    return isUserNudge && isRequestNudgeable;
};

export const useGetApproversIdsToNudge = (request: selectors.types.ExpandedRequest) => {
    const me = useCurrentUser();

    const { activeStep, reviewStep } = request;

    return useMemo(() => {
        const isReviewStep = reviewStep && !reviewStep.isCompleted;

        if (isReviewStep) {
            const reviewers = reviewStep.reviewers ?? [];

            return reviewers.filter((reviewer) => reviewer.id !== me.id).map((reviewer) => reviewer.id);
        } else {
            const participants = activeStep?.participants ?? [];

            return participants
                .filter(
                    (participant) =>
                        participant.userId !== me.id &&
                        participant.decision === domain.RequestStepParticipantDecision.NoResponse
                )
                .map((participant) => participant.userId);
        }
    }, [activeStep?.participants, me.id, reviewStep]);
};
