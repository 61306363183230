import { AmIcon, FileSearchIcon } from '@approvalmax/ui';
import { constants } from '@approvalmax/utils';
import {
    AirwallexShortLogoIcon,
    AutoApprovalIcon,
    BillToPoIcon,
    BudgetingIcon,
    NavBulkIcon,
    NavContactsIcon,
    NavManualJournalIcon,
    NavSalesQuotesIcon,
    VersionControlIcon,
} from 'modules/sprites';

import * as domain from '../../data/domain';
// Bill/PO
export const PO_REFERENCE_MAX_LENGTH = 4000;
export const descriptionMaxLength = 4000;

// Invoice
export const INVOICE_REFERENCE_MAX_LENGTH = 255;
export const INVOICE_NUMBER_MAX_LENGTH = 255;

// Quote
export const TITLE_MAX_LENGTH = 100;
export const SUMMARY_MAX_LENGTH = 3000;
export const TERMS_MAX_LENGTH = 4000;
export const QUOTE_NUMBER_MAX_LENGTH = 100;
export const CUSTOMER_FIRST_NAME_MAX_LENGTH = 128;
export const CUSTOMER_LAST_NAME_MAX_LENGTH = 128;
export const CUSTOMER_COMPANY_NAME_MAX_LENGTH = 256;
export const CUSTOMER_JOB_TITLE_MAX_LENGTH = 128;
export const CUSTOMER_COMMENT_MAX_LENGTH = 4000;

// Contact
export const CONTACT_NAME_MAX_LENGTH = 255;
export const ACCOUNT_NUMBER_MAX_LENGTH = 50;
export const FIRST_NAME_MAX_LENGTH = 255;
export const LAST_NAME_MAX_LENGTH = 255;
export const ANOTHER_PERSON_FIRST_NAME_MAX_LENGTH = 80;
export const ANOTHER_PERSON_LAST_NAME_MAX_LENGTH = 80;
export const EMAIL_ADDRESS_MAX_LENGTH = 255;
export const BANK_ACCOUNT_DETAILS_MAX_LENGTH = 50;
export const TAX_NUMBER_MAX_LENGTH = 15;
export const SKYPE_MAX_LENGTH = 255;
export const COMPANY_NUMBER_MAX_LENGTH = 50;
export const sortCodeMaxLength = 6;

// batch Payment
export const BATCH_PAYMENT_REFERENCE_DEFAULT_MAX_LENGTH = 12;
export const BATCH_PAYMENT_REFERENCE_AU_MAX_LENGTH = 18;
export const BATCH_PAYMENT_NARRATIVE_MAX_LENGTH = 18;
export const BATCH_PAYMENT_DETAILS_MAX_LENGTH = 18;
export const BATCH_PAYMENT_PARTICULARS_MAX_LENGTH = 12;
export const BATCH_PAYMENT_CODE_MAX_LENGTH = 12;
export const BATCH_PAYMENT_BANK_ACCOUNT_MAX_LENGTH = BANK_ACCOUNT_DETAILS_MAX_LENGTH;

// airwallex batch Payment
export const AIRWALLEX_BATCH_PAYMENT_REFERENCE = 140;

// AmaxPay Batch Payment
export const amaxPayBatchPaymentReference = 50;
export const amaxPayBatchPaymentMergeBySupplierReference = 18;

// Manual Journal
export const manualJournalNarrationMaxLength = 4000;

// Delivery
export const DELIVERY_ADDRESS_MAX_LENGTH = 3000;
export const DELIVERY_ATTENTION_TO_MAX_LENGTH = 255;
export const DELIVERY_PHONE_MAX_LENGTH = 80;
export const DELIVERY_INSTRUCTIONS_MAX_LENGTH = 500;

// Address
export const ADDRESS_ATTENTION_TO_MAX_LENGTH = 255;
export const ADDRESS_CITY_MAX_LENGTH = 255;
export const ADDRESS_MAX_LENGTH = 255;
export const ADDRESS_REGION_MAX_LENGTH = 255;
export const ADDRESS_POST_CODE_MAX_LENGTH = 50;
export const ADDRESS_COUNTRY_MAX_LENGTH = 50;

// Phone editor
export const PHONE_AREA_CODE_MAX_LENGTH = 10;
export const PHONE_NUMBER_MAX_LENGTH = 50;
export const PHONE_COUNTRY_CODE_MAX_LENGTH = 20;

// Numeric
export const TOTAL_MAX_AMOUNT = 999999999999;
export const MATRIX_TOTAL_MAX_AMOUNT = 100000000000000;
export const MATRIX_PO_TOTAL_MAX_AMOUNT = 1000000000000;
export const TOTAL_MIN_AMOUNT = 0;
export const MAX_AMOUNT = 999999999999;
export const MIN_AMOUNT = -MAX_AMOUNT;
export const MIN_DISCOUNT_VALUE = 0;
export const MAX_DISCOUNT_VALUE = 100;
export const PRECISION_SMALL = 2;
export const PRECISION_BIG = 4;
export const PRECISION_TO_SERVER_STRING = 4;
export const maxFileCount = 9;

export const XERO_AIRWALLEX_KB_LINK =
    'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/airwallex-integration';

// Advanced plan
export const XERO_AUTO_APPROVAL_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-xero';

export const XERO_REVIEW_STEP_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-review-step';

export const XERO_AMXV_FEATURES = [
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/batch-payment-workflow',
        label: 'Xero Batch Payment workflow',
        icon: NavBulkIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-standalone-solution',
        label: 'Stand-alone workflows',
        icon: AmIcon,
    },
];

export const XERO_AIRWALLEX_FEATURE = {
    href: XERO_AIRWALLEX_KB_LINK,
    label: 'Payments with Airwallex',
    icon: AirwallexShortLogoIcon,
};
export const XERO_QUOTE_FEATURE = {
    href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/quote-workflow',
    label: 'Xero Quote workflow',
    icon: NavSalesQuotesIcon,
};

export const XERO_AMXO_FEATURES = [
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/bill-to-po-matching',
        label: 'Bill-to-PO matching',
        icon: BillToPoIcon,
    },
    {
        href: XERO_AUTO_APPROVAL_LEARN_MORE_LINK,
        label: 'Auto-approval step',
        icon: AutoApprovalIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/budget-checking',
        label: 'Budget checking',
        icon: BudgetingIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/contact-workflow',
        label: 'Contact workflow',
        icon: NavContactsIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/manual-journal-workflow',
        label: 'Manual Journal workflow',
        icon: NavManualJournalIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/articles/workflow-version-control',
        label: 'Workflow history',
        icon: VersionControlIcon,
    },
    {
        href: XERO_REVIEW_STEP_LEARN_MORE_LINK,
        label: 'Request Review Step',
        icon: FileSearchIcon,
    },

    ...XERO_AMXV_FEATURES,

    {
        href: 'https://support.approvalmax.com/portal/en/kb/articles/how-to-create-line-item-accrual-report-and-export-it-to-a-manual-journal',
        label: 'Accrual Reports',
        icon: NavManualJournalIcon,
    },
];

export const XERO_MATCHING_MIGRATION_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/migration-process-from-matching-v1-to-matching-v2';
export const XERO_DEXT_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en-gb/kb/articles/how-to-establish-connection-between-receipt-bank-approvalmax-and-xero';

export const XERO_ALLOWED_FILE_EXTENSIONS = [
    '.jpg',
    '.jpeg',
    '.gif',
    '.png',
    '.bmp',
    '.tiff',
    '.tif',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.odf',
    '.csv',
    '.txt',
    '.rtf',
    '.eml',
    '.msg',
    '.ods',
    '.odt',
    '.keynote',
    '.key',
    '.pages-tef',
    '.pages',
    '.numbers-tef',
    '.numbers',
    '.zip',
    '.rar',
    '.zipx',
    '.xzip',
    '.7z',
];

export const xeroAllowedFileExtensions: (keyof typeof constants.extensions)[] = [
    'jpg',
    'gif',
    'png',
    'bmp',
    'tiff',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'odf',
    'csv',
    'text',
    'rtf',
    'eml',
    'msg',
    'ods',
    'odt',
    'keynote',
    'pages',
    'numbers',
    'zip',
    'rar',
    '7z',
];

export const XERO_NOT_ALLOWED_SYMBOLS = ['<', '>'];

export const XERO_TWO_FA_SUPPORT_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-2fa';

export const ocrFileMaxSize = 3 * 1024 * 1024;

export const batchPaymentIntegrationCodes = [
    domain.IntegrationCode.XeroBillBatchPayment,
    domain.IntegrationCode.XeroAirwallexBatchPayment,
    domain.IntegrationCode.XeroAmaxPayBatchPayment,
];
