import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import { GetAmaxPayWalletsFund } from './useGetAmaxPayWalletsFund.types';

export const useGetAmaxPayWalletsFund = (
    params?: RequestParams<GetAmaxPayWalletsFund>,
    queryOptions?: RequestQueryOptions<GetAmaxPayWalletsFund>
) => {
    return useGet(paymentServicesApiPaths.walletsFund, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
