import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetTwoFaAlternativeMethods } from './useGetTwoFaAlternativeMethods.types';

export const useGetTwoFaAlternativeMethods = (
    params?: RequestParams<GetTwoFaAlternativeMethods>,
    queryOptions?: RequestQueryOptions<GetTwoFaAlternativeMethods>
) => {
    return useGet(authApiPaths.getTwoFaAlternativeMethods, {
        params,
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
        method: 'post',
    });
};
