import uniqueId from 'lodash/uniqueId';
import { useRef } from 'react';

/**
 * IDs are often used for a11y, see: `<label for="ui-1">`, `aria-owns` and many other examples.
 *
 * Note: when using server-side rendering, it is important to reset the underlying counter at the server-side
 * each time you start the render. Otherwise, the IDs will not match with the client.
 *
 * @deprecated use `useId` imported from `react` instead
 */
export const useId = (): string => {
    const idRef = useRef<string>('');

    if (!idRef.current) {
        idRef.current = uniqueId('ui-');
    }

    return idRef.current;
};
