import { z } from 'zod';

/**
 * Validation schema for {@link FileRecord} type. Used by {@link Dropzone} and {@link AvatarDropzone} components
 */
export const fileRecordSchema = z.object({
    id: z.string(),
    name: z.string().optional(),
    source: z.any().optional(),
});
