import { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import Dropzone from '../../Dropzone';
import { ControllerProps } from './Controller.types';

/**
 * The `Dropzone.Controller` allows you to use the `Dropzone` with the `react-hook-form` library.
 * They take the same properties as the `Dropzone` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
export const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, defaultValue, ...restProps } = props;

        const { field, fieldState } = useController({
            control,
            name,
            defaultValue,
        });

        return (
            <Dropzone
                {...field}
                value={field.value}
                {...fieldState}
                {...restProps}
                ref={ref}
                hint={fieldState.error?.message || restProps.hint}
            />
        );
    })
);

Controller.displayName = 'Controller';
