import { toast } from '@approvalmax/ui/src/components';
import { actions } from 'modules/profile';
import { useDispatch } from 'modules/react-redux';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useUpdateProfile } from 'shared/data/v1';

import { useCheckIsInvalidProfileForm } from '../../hooks/useCheckIsInvalidProfileForm';
import { useCheckIsModifiedProfileForm } from '../../hooks/useCheckIsModifiedProfileForm';
import { useOpenProfileEditPopup } from '../../ProfileEditPopup.hooks';
import { profileFormDataState } from '../../ProfileEditPopup.states';
import { getSectionGeneralSaveToProfileValues } from '../SectionGeneral/SectionGeneral.helpers';
import { getSectionNotificationsSaveToProfileValues } from '../SectionNotifications/SectionNotifications.helpers';
import { getSectionSecuritySaveToProfileValues } from '../SectionSecurity/SectionSecurity.helpers';
import { messages } from './Content.messages';
import { ContentProps } from './Content.types';

export const useProfileEditForm = (props: ContentProps) => {
    const { profile } = props;

    const dispatch = useDispatch();
    const profileEditPopup = useOpenProfileEditPopup();
    const isModified = useCheckIsModifiedProfileForm();
    const isInvalid = useCheckIsInvalidProfileForm();
    const formData = useRecoilValue(profileFormDataState);

    const { mutate: updateProfile, isLoading: isLoadingUpdateProfile } = useUpdateProfile({
        onSuccess: (_response, data) => {
            if (data.body) {
                const newProfile: typeof profile = {
                    ...profile,
                    ...getSectionGeneralSaveToProfileValues(profile, data.body),
                    ...getSectionNotificationsSaveToProfileValues(profile, data.body),
                    ...getSectionSecuritySaveToProfileValues(profile, data.body),
                };

                profileEditPopup.handleClosePopup();
                dispatch(actions.applySavedProfileChanges({ newProfile }));
            }

            toast.success(messages.messageProfileSaved);
        },
    });

    const handleClickSave = useCallback(() => {
        if (!isInvalid && isModified) {
            const { email, ...body } = formData;

            updateProfile({ body });
        }
    }, [formData, isInvalid, isModified, updateProfile]);

    return {
        handleClickSave,
        isModified,
        isInvalid,
        isSubmitting: isLoadingUpdateProfile,
    };
};
