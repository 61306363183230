import { Reference } from '@approvalmax/types';
import isEqual from 'lodash/isEqual';

import { GroupOfXeroAccounts } from '../../GroupOfValuesPopup.types';

export const getNoChanges = (group: GroupOfXeroAccounts, selectedItems: Reference[]) => {
    const groupValuesIds = (group.latestVersion?.values || []).map((value) => value.fieldValueId);
    const selectedItemsIds = selectedItems.map((selectedItem) => selectedItem.id);

    return isEqual(groupValuesIds.sort(), selectedItemsIds.sort());
};
