import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.SubsectionPasskeys', {
    optionPasskeys: 'Passkeys and security keys',
    optionPasskeysUnavailable:
        'With passkeys, you can securely sign in to your ApprovalMax account using just your fingerprint, face, screen lock or security key. Passkeys and security keys can also be used as a second step when signing in with your password. Make sure that you keep your screen locks private and security keys safe, so that only you can use them.',
    buttonSetup: 'Setup',
    buttonAddPasskey: 'Add passkey',
    buttonAddSecurityKey: 'Add security key',
    errorText: 'Failed to add a passkey.',
    alertRemovePasskey: 'Please setup password or enable Single sign on if wish to remove passkey or security key',
});
