import { useCallback, useState } from 'react';
import { useGetFido2Credentials } from 'shared/data/v2';

import { PasskeyType } from '../PasskeyItem/PasskeyItem.types';

export const useSubsectionPasskeys = () => {
    const {
        data: credentials,
        refetch: refetchCredentials,
        isLoading: isLoadingCredentials,
    } = useGetFido2Credentials({});

    const [newPasskeyType, setNewPasskeyType] = useState<PasskeyType | null>(null);

    const handleShowPasskeyCreate = useCallback(() => {
        setNewPasskeyType('passkey');
    }, []);

    const handleCancelCreate = useCallback(() => {
        setNewPasskeyType(null);
    }, []);

    const hasCredentials = Boolean(credentials?.length);

    return {
        credentials,
        isLoadingCredentials,
        refetchCredentials,
        handleShowPasskeyCreate,
        handleCancelCreate,
        newPasskeyType,
        showSetup: !hasCredentials && !newPasskeyType,
    };
};
