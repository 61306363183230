import { Divider, Flex, Form, Text } from '@approvalmax/ui/src/components';
import React, { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { PropertyRowWithSwitch } from '../PropertyRowWithSwitch/PropertyRowWithSwitch';
import { SubsectionPasskeys, SubsectionTwoFa } from './components';
import { SubsectionPassword } from './components/SubsectionPassword/SubsectionPassword';
import { fieldNameSecuritySection } from './SectionSecurity.constants';
import { useSectionSecuritySessionForm } from './SectionSecurity.hooks';
import { messages } from './SectionSecurity.messages';
import { SectionSecurityProps } from './SectionSecurity.types';

const qa = bemFactory.qa('profile-popup-security-section');

export const SectionSecurity = memo<SectionSecurityProps>((props) => {
    const { profile } = props;

    const { form } = useSectionSecuritySessionForm(profile);

    return (
        <>
            <Form form={form}>
                <Flex direction='column' spacing='0'>
                    <Text font='headline' fontSize='small' fontWeight='medium'>
                        {messages.titleAutomaticLogOut}
                    </Text>

                    <div>
                        <PropertyRowWithSwitch
                            name={fieldNameSecuritySection.session}
                            title={messages.optionSession}
                            qa={qa('use-session-toggle')}
                        />
                    </div>
                </Flex>
            </Form>

            <Flex direction='column' spacing='16'>
                <Text font='headline' fontSize='small' fontWeight='medium'>
                    {messages.titleSecurity}
                </Text>

                <div>
                    <SubsectionTwoFa profile={profile} qa={qa('2fa-toggle')} />

                    <Divider color='midnight40' />

                    <SubsectionPasskeys profile={profile} />

                    <Divider color='midnight40' />

                    <SubsectionPassword profile={profile} />
                </div>
            </Flex>
        </>
    );
});

SectionSecurity.displayName = 'SectionSecurity';
