import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'components.ProfileEditPopup.SectionSecurity.BackupEmailUpdatePopup.StepEmailEnter',
    {
        instruction:
            'Enter the 6-digit authentication code provided by the Authentication app connected to your ApprovalMax account.',
        codeFieldTitle: 'Alternative email address',
        invalidEmailErrorText: 'This email address is not valid.',
        emailLabel: 'Email',
        emailPlaceholder: 'Enter an alternative email address...',
        nextButton: 'Send me the code',
    }
);
