import { selectors } from 'modules/common';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { TwoFaActionWizard } from 'shared/components';

import { useWizard } from './Wizard.hooks';
import { messages } from './Wizard.messages';
import { WizardProps } from './Wizard.types';

export const Wizard = memo<WizardProps>((props) => {
    const { onClose } = props;

    const { handleEnterAppCode, handleEnterEmailCode, handleEnterBackupCode, handleRequestEmailCode } =
        useWizard(props);

    const profile = useSelector(selectors.profile.getProfile);

    return (
        <TwoFaActionWizard
            open
            isLoading={false}
            title={messages.title}
            onEnterAppCode={handleEnterAppCode}
            onEnterBackupCode={handleEnterBackupCode}
            onEnterEmailCode={handleEnterEmailCode}
            onSendCodeViaEmail={handleRequestEmailCode}
            isTFAAlternativeEmailEnabled={profile.isTFAAlternativeEmailEnabled}
            isTFABackupCodesEnabled={profile.isTFABackupCodesEnabled}
            isTrustThisDeviceEnabled={false}
            onClose={onClose}
        />
    );
});

Wizard.displayName = 'Wizard';
