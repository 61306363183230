import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { VerifyTwoFaWithOneTimePassword } from './useVerifyTwoFaWithOneTimePassword.types';

export const useVerifyTwoFaWithOneTimePassword = (
    mutationOptions?: RequestMutationOptions<VerifyTwoFaWithOneTimePassword>
) => {
    return useMutate(authApiPaths.verifyTwoFaWithOneTimePassword, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
