import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionSecurity.BackupEmailUpdatePopup', {
    errorStateIdEmpty: 'State ID is empty',
    errorSetupCodeEmpty: 'Setup code is empty',
    errorSetupVerificationCodeEmpty: 'Wrong code',
    errorBackupMethodEmpty: 'Wrong backup method',
    errorCodesAreSaved: 'This checkbox is required',
    errorEmailEmpty: 'Email address is required.',
    errorEmailInvalid: 'This email address is not valid.',
    errorEmailVerificationCodeRequired: 'Wrong code',
});
