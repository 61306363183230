import { defineMessages } from '@approvalmax/utils';

/**
 * @deprecated Redesigned
 */
export const messages = defineMessages('modules.profile.ProfilePopup.PasskeyItem', {
    created: 'Created: {createdDate}',
    areYouSure: 'Are you sure you want to delete this passkey?',
    yes: 'Yes, Delete it',
});
