import { fieldNames } from './BackupEmailUpdatePopup.constants';

export interface BackupEmailUpdatePopupProps {
    onClose: VoidFunction;
    title: string;
}

export enum Stages {
    emailEnter = 'emailEnter',
    emailVerify = 'emailVerify',
}

export interface BackupEmailUpdateForm {
    [fieldNames.stateId]: string | null;
    [fieldNames.email]: string;
    [fieldNames.verificationCode]: string;
}
