import each from 'lodash/each';
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';
import { FC, PropsWithChildren, useEffect } from 'react';

export interface GlobalHotKeysProps extends PropsWithChildren {
    handlers: {
        [key: string]: (e: ExtendedKeyboardEvent, combo: string) => any;
    };
    onlyTriggerWhenNoFocus?: boolean;
    disabled?: boolean;
    element?: HTMLElement;
}

const _originalStopCallback = Mousetrap.prototype?.stopCallback;

const GlobalHotKeys: FC<GlobalHotKeysProps> = (props) => {
    const { children, handlers, onlyTriggerWhenNoFocus, element, disabled = false } = props;

    useEffect(() => {
        const createMousetrap = (handlers: GlobalHotKeysProps['handlers'], onlyTriggerWhenNoFocus?: boolean) => {
            const mouseTrapInstance = new Mousetrap(element || (window as any));

            mouseTrapInstance.stopCallback = (...args) => {
                if (disabled) {
                    return true;
                }

                if (!onlyTriggerWhenNoFocus) {
                    return false;
                }

                return _originalStopCallback.call(mouseTrapInstance, ...args);
            };

            each(handlers, (handler, key) => {
                mouseTrapInstance.bind(key, handler);
            });

            return mouseTrapInstance;
        };

        const mouseTrap = createMousetrap(handlers, onlyTriggerWhenNoFocus);

        return () => {
            mouseTrap.reset();
        };
    }, [disabled, element, handlers, onlyTriggerWhenNoFocus]);

    return <>{children}</>;
};

export default GlobalHotKeys;
