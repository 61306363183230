import { atom } from 'recoil';
import { z } from 'zod';

import { getSchemaSectionGeneral } from './components/SectionGeneral/SectionGeneral.schema';
import { schemaSectionNotifications } from './components/SectionNotifications/SectionNotifications.schema';
import { schemaUseSession } from './components/SectionSecurity/SectionSecurity.schema';

export const showProfileEditPopupState = atom({
    key: 'showProfileEditPopupState',
    default: false,
});

export const profileFormDataState = atom<
    Partial<
        z.infer<ReturnType<typeof getSchemaSectionGeneral>> &
            z.infer<typeof schemaSectionNotifications> &
            z.infer<typeof schemaUseSession>
    >
>({
    key: 'profileFormDataState',
    default: {},
});

export const profileFormModifiedState = atom<Record<string, boolean>>({
    key: 'profileFormModifiedState',
    default: {},
});

export const profileFormInvalidState = atom<Record<string, boolean>>({
    key: 'profileFormInvalidState',
    default: {},
});

export const profileFormResetState = atom<Record<string, VoidFunction>>({
    key: 'profileFormResetState',
    default: {},
});
