import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import { GetAmaxPayWallets } from './useGetAmaxPayWallets.types';

export const useGetAmaxPayWallets = (
    params?: RequestParams<GetAmaxPayWallets>,
    queryOptions?: RequestQueryOptions<GetAmaxPayWallets>
) => {
    return useGet(paymentServicesApiPaths.wallets, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
