import { Button, Flex, Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { SectionCertification } from '../SectionCertification/SectionCertification';
import { SectionGeneral } from '../SectionGeneral/SectionGeneral';
import { SectionNotifications } from '../SectionNotifications/SectionNotifications';
import { SectionSecurity } from '../SectionSecurity/SectionSecurity';
import { useProfileEditForm } from './Content.hooks';
import { messages } from './Content.messages';
import { ContentProps } from './Content.types';

export const Content = memo<ContentProps>((props) => {
    const { profile } = props;

    const { handleClickSave, isInvalid, isModified, isSubmitting } = useProfileEditForm(props);

    return (
        <>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button
                        color='blue80'
                        size='medium'
                        title={messages.buttonSaveTitle}
                        disabled={!isModified || isInvalid}
                        progress={isSubmitting}
                        onClick={handleClickSave}
                    >
                        {messages.buttonSaveText}
                    </Button>
                }
            />

            <Popup.Body>
                <Flex direction='column' spacing='40'>
                    <SectionGeneral progress={isSubmitting} profile={profile} />

                    <SectionCertification progress={isSubmitting} profile={profile} />

                    <SectionNotifications progress={isSubmitting} profile={profile} />

                    <SectionSecurity progress={isSubmitting} profile={profile} />
                </Flex>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
