import { constants } from '@approvalmax/utils';

import * as domain from '../../data/domain';

export const EMPTY_VALUE_ID = '#$EMPTY_SYNTETIC_ID_VALUE$#';
export const NOT_EMPTY_VALUE_ID = '#$ALL_SYNTETIC_ID_VALUE$#';
export const ANY_VALUE_ID = '#$ANY_SYNTETIC_ID_VALUE$#';
export const DECLINE_ALL_INVITATIONS_OPTION = 'DeclineAllInvitations';
export const DATE_FORMAT_ONLY_DATE = 'YYYY-MM-DD';
export const DATE_FORMAT_DELEGATES = 'YYYY-MM-DD';
export const DATE_FORMAT_DELEGATES_CALENDAR = 'LL';
export const TEMPLATE_ID_PREFIX = 'temp.';

export const EMAIL_TO_SUPPLIER_SUBJECT_MAX_LENGTH = 998;
export const EMAIL_TO_SUPPLIER_BODY_MAX_LENGTH = 4000;

export const DEXT_CONNECTION_EXTERNAL_URL = 'https://app.receipt-bank.com/settings/integration';

export const SYNC_DATA_API_CALL_INTERVAL = 60000;

export const REQUEST_NOTE_MAX_LENGTH = 1000;
export const REQUEST_NOTE_EXCLUDED_ATTRIBUTES = ['class', 'data-placeholder'];

export const ONLY_SPACES_REG_EXP = /^[ ]+$/;

export const SLACK_APP_URL = 'https://slack.com/apps/A05B8J5K3N3';
export const reviewStepLearnMoreLink = 'https://support.approvalmax.com/portal/en/kb/articles/what-is-a-review-step';
export const payLearnMoreLink = 'https://support.approvalmax.com/portal/en/kb/articles/what-is-approvalmax-pay';
export const captureLearnMoreLink = 'https://support.approvalmax.com/portal/en/kb/articles/what-is-approvalmax-capture';

export const maxUploadFilesPerMinute = 30;

export const defaultMemoMaxLength = 4000;

export const ocrAvailableIntegrationCodes = [
    domain.IntegrationCode.XeroBill,
    domain.IntegrationCode.XeroInvoice,
    domain.IntegrationCode.NetSuiteBill,
    domain.IntegrationCode.QBooksBill,
];

export const dextAvailableIntegrationCodes = [domain.IntegrationCode.XeroBill, domain.IntegrationCode.QBooksBill];

export const requestMinRightPanelWidth = 960;
export const requestMinLeftPanelWidth = 500;

export const ocrAllowedFileExtensions: (keyof typeof constants.extensions)[] = ['pdf', 'jpg', 'png', 'bmp', 'tiff'];

export const ocrAllowedFileExtensionsOldFormat = ['.pdf', '.jpg', '.png', '.bmp', '.tiff'];

export const addonPromotionIntegrationCodes = [domain.IntegrationCode.XeroBill, domain.IntegrationCode.QBooksBill];
