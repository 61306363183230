import { BackupCodes, Popup } from '@approvalmax/ui';
import { changeTwoFaBackupCodesIsEnabled } from 'modules/profile/actions';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notificationService } from 'services/notification';
import { useFinishAddingTwoFaBackupCodes, useStartAddingTwoFaBackupCodes } from 'shared/data/v1';

import { messages } from './AddBackupCodesPopup.messages';
import { Content, Root, Title } from './AddBackupCodesPopup.styles';
import { AddBackupCodesPopupProps } from './AddBackupCodesPopup.types';

const AddBackupCodesPopup: FC<AddBackupCodesPopupProps> = memo((props) => {
    const { onClose } = props;

    const { mutate: start, data, isLoading } = useStartAddingTwoFaBackupCodes();
    const { mutate: finish } = useFinishAddingTwoFaBackupCodes();
    const dispatch = useDispatch();

    useEffect(() => {
        start({ body: {} });
    }, [start]);

    const onSubmit = useCallback(() => {
        if (data?.stateId) {
            finish(
                { body: { stateId: data.stateId } },
                {
                    onSuccess: () => {
                        notificationService.showInfoToast(messages.onSuccess);
                        dispatch(changeTwoFaBackupCodesIsEnabled(true));
                        onClose();
                    },
                }
            );
        }
    }, [data?.stateId, dispatch, finish, onClose]);

    return (
        <Popup isOpen onRequestClose={onClose}>
            <Root title={messages.popupTitle}>
                <Content>
                    <Title>{messages.title}</Title>

                    <BackupCodes
                        isLoading={isLoading}
                        backupCodes={data?.backupCodes}
                        onDone={onSubmit}
                        showInfoToast={notificationService.showInfoToast}
                    />
                </Content>
            </Root>
        </Popup>
    );
});

export default AddBackupCodesPopup;
