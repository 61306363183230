import { Alert, Button, Dropzone, Form, Grid, Link, List, Popup, Text } from '@approvalmax/ui/src/components';
import { HelpCircleIcon } from '@approvalmax/ui/src/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'modules/react-redux';
import { getRequest } from 'pages/requestForm/selectors/pageSelectors';
import { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useImportManualJournal } from 'shared/data';

import { accept, downloadTemplateLink, FormFieldId, learnMoreLink, maxFileSize, rules } from './Content.constants';
import { messages } from './Content.messages';
import { schema } from './Content.schema';
import { ContentProps, FormValues } from './Content.types';

export const Content: FC<ContentProps> = memo((props) => {
    const { onSuccess, setLoading } = props;

    const { companyId, id: requestId } = useSelector(getRequest);

    const form = useForm<FormValues>({
        defaultValues: {
            [FormFieldId.file]: [],
        },
        resolver: zodResolver(schema),
    });

    const { mutate: importManualJournal, isLoading } = useImportManualJournal();

    const handleSubmit = form.handleSubmit((values) => {
        const file = values.file[0];

        const formData = new FormData();

        formData.append('companyId', companyId);
        formData.append('requestId', requestId);
        formData.append('file', file.source);

        return importManualJournal(
            { body: formData },
            {
                onSuccess,
                onError: () => {
                    form.reset();
                },
            }
        );
    });

    useEffect(() => {
        setLoading?.(isLoading);
    }, [setLoading, isLoading]);

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button
                        color='blue80'
                        size='medium'
                        type='submit'
                        disabled={!form.formState.isValid}
                        progress={isLoading}
                    >
                        {messages.button}
                    </Button>
                }
            />

            <Popup.Body>
                <Grid gap={24}>
                    <Alert size='medium' color='midnight20' startIcon={<HelpCircleIcon />}>
                        {messages.warningText}
                    </Alert>

                    <div>
                        <Text as='span' font='label' fontSize='medium' color='midnight80'>
                            {messages.description}{' '}
                        </Text>

                        <Link font='label' fontSize='medium' external href={learnMoreLink}>
                            {messages.learnMoreText}
                        </Link>
                    </div>

                    <Link font='label' fontSize='medium' external href={downloadTemplateLink}>
                        {messages.downloadLinkText}
                    </Link>

                    <Text font='body' fontSize='medium' color='midnight80'>
                        {messages.rulesTitle}

                        <List listStyle='inside'>
                            {rules.map((rule, index) => (
                                <List.Item key={index}>{rule}</List.Item>
                            ))}
                        </List>
                    </Text>

                    <Grid gap={12}>
                        <Text font='label' fontSize='large' fontWeight='medium' color='midnight80'>
                            {messages.dropzoneTitle}
                        </Text>

                        <Dropzone name={FormFieldId.file} accept={accept} maxSize={maxFileSize} progress={isLoading} />
                    </Grid>
                </Grid>
            </Popup.Body>
        </Form>
    );
});

Content.displayName = 'Content';
