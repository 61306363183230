import isObject from 'lodash/isObject';
import { Children, createElement, isValidElement, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const prepareChildren = (children: ReactNode, form?: UseFormReturn): ReactNode => {
    return Children.map(children, (child) => {
        if (!child) return null;

        if (!isValidElement(child)) return child;

        let type = child.type;
        let control = {};

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const ref = child.ref ? { ref: child.ref } : {};

        if (typeof child.type === 'object' && 'Controller' in child.type) {
            // we have an issue here. We are not passing className from styled component,
            // because it isn't yet been generated at this level.
            // so the solution here is to declare styled input in the following way
            // `styled<JSXElementConstructor<TextFieldProps>>((props) => (
            //     <Form.Part>
            //         <TextField {...props} />
            //     </Form.Part>
            // ))` instead of `styled(TextField)`
            type = child.type['Controller'];
            control = { control: form?.control };
        }

        return createElement(
            type,
            {
                ...(child.props || {}),
                ...control,
                ...ref,
            },
            isObject(child.props) && 'children' in child.props && child.props.children
                ? prepareChildren(child.props.children as ReactNode, form)
                : null
        );
    });
};
